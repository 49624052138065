<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Oficial world premiere is here
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/img_detect.jpg" alt="premiere img" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <p style="text-align: center;">
      <strong
        ><span style="color: #000000;">
          CREDO Detector is launched now!
        </span></strong
      ><strong
        ><span style="color: #000000;"
          >Use your smartphone as a detector of cosmic-ray particles</span
        ></strong
      >
    </p>
    <span style="color: #000000;"
      >The app has been substantailly upgraded and has now been launched
      officially &amp; goes global.</span
    >

    <span style="color: #000000;"
      >Hence, the very succesful beta phase has been finished. Many thanks for
      your valuable help. Now it's time for: <span class="text_exposed_show">
        ★ more exciting science ★ more fun ★ ...and more positive competition</span
      ></span
    >
    <div class="text_exposed_show">
      <span style="color: #000000;"
        >Re-install CREDO Detector Smartphone App &amp; enjoy team hunting for
        the deeply hidden treasures of the Universe!</span
      >

      <span style="color: #000000;"
        >We invite you to continue the journey with CREDO. If you are about to
        do so, please:</span
      >

      <span style="color: #000000;"
        >① Get the premiere CREDO Detector version <span style="color: #800000;"
          ><strong
            ><a
              style="color: #0000ff;"
              href="//play.google.com/store/apps/details?id=science.credo.mobiledetector"
              target="_blank"
              rel="noopener noreferrer">on Google Play and REINSTALL</a
            ></strong
          ></span
        > ►</span
      >
      <span style="color: #000000;"
        >② Visit the CREDO website to get familiar with the enriched app usage</span
      >
      <span style="color: #000000;"
        >③ Join and follow the first CREDO online experiment: Quantum Gravity
        Previewer</span
      >
      <span style="color: #000000;"
        >④ Follow us here on Facebook profile, enjoy contributing, attend the
        events!</span
      >

      <span style="color: #000000;"
        >Thank you very much for being part of the Cosmic-Ray Extremely
        Distributed Observatory (CREDO) to hunt with our CREDO Detector app!</span
      >
      <br />
      <span style="color: #000000;">Cheers,</span><br />

      <span style="color: #000000;">Piotr Homola</span><br />
      <span style="color: #000000;">the CREDO leader</span>
    </div>
  </div>
</div>
