<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Meet us at the Małopolska Night of Scientists
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/posts/malopolska-noc-naukowcow-2022.jpg"
      alt="images"
      width="400"
    />
    If you plan to take part in the Małopolska Night of Scientists (2022-09-30),
    we invite you to the
    <a class="text-blue-600" href="https://www.ifj.edu.pl/en/index.php">
      Institute of Nuclear Physics in Kraków,
    </a>where the CREDO team will be waiting for you in one of the Tents.
  </div>
</div>
