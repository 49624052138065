<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    PASIFIC competition for scientists
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/PASIFIC.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >The CREDO group at the Institute of Nuclear Physics Polish Academy of
        Sciences (INP PAS) invites experienced scientists interested in the
        CREDO science mission to consider a new postdoc opportunity dedicated to
        non-Polish residents - see the details below (Click "Career"). For
        questions concerning the CREDO group and activities at INP PAS please
        contact us at contact@credo.science.</span
      >
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >About<strong> PASIFIC</strong> (Polish Academy of Sciences’ Individual Fellowships:
        Innovation &amp; Creativity) is a new fellowship programme at the Polish
        Academy of Sciences. It will provide 50 scientists of any nationality with
        an opportunity to gain two years of research experience at one of the institutes
        of the Polish Academy of Sciences (PAS). The applicants will have freedom
        to choose their research area, a research supervisor, Host Institute and
        secondment organisations best suited to their individual research and career
        development.</span
      >
    </p>
    <span style="color: #000000;">read more:</span>

    <span style="color: #000000;"
      ><a style="color: #0000ff;" href="#/career/"><strong>Career</strong></a
      ></span
    >

    <span style="color: #000000;"
      ><a
        style="color: #0000ff;"
        href="//institution.pan.pl/index.php/pasific"
        target="_blank"
        rel="noopener noreferrer"><strong>IFJ PAS subpage: PASIFIC</strong></a
      ></span
    >
  </div>
</div>
