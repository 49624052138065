<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    The first light from the CREDO Quantum Gravity Previewer
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/182368_web-570x350.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >On Thursday, at the Institute of Nuclear Physics of the Polish Academy of
      Sciences in Cracow, the “first light” of the detector was presented, that
      is, the first data of scientific value, collected by the smartphones of
      project participants from around 20 countries.
      <br />
      About the first light from the CREDO Quantum Gravity Previewer you can read
      on
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.eurekalert.org/news-releases/665188"
          target="_blank"
          rel="noreferrer">EurekAlert!</a
        >
      </span><br />
      You can read the Polish version
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://press.ifj.edu.pl/news/2018/10/04/"
          target="_blank"
          rel="noreferrer">here,</a
        >
      </span>.<br />
      More experiments can be seen
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="#/quantum-gravity-previewer"
          target="_blank">here.</a
        >
      </span>.
    </span>
  </div>
</div>
