<div class="container px-10 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    A new peer-reviewed article "for CREDO".
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/sensor_2021_11.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center">
      Sensors 2021, 21(22), 7718;
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://doi.org/10.3390/s21227718"
          >https://doi.org/10.3390/s21227718!</a
        ></span
      ><br />
      A new peer-reviewed article "for CREDO" about a useful method of classifying
      footprints of penetrating radiation in pixel cameras has just been published.<br
      />
      Congratulations to the authors and big thank you to the users of our moblile
      applications for their dedication and patience in providing scientifically
      valuable data with their smartphones!
    </span>
  </div>
</div>
