<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO at the ICRC conference
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/ICRC2021.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:justify"
      >At the ICRC2021 conference, CREDO was represented by numerous scientists
      from various countries. Articles made from their speeches can be found on
      our subpage:
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="#/science/conferencereports"
          >Confernece reports.</a
        ></span
      ></span
    >
  </div>
</div>
