<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    The first regularly peer-reviewed publication “for the CREDO Collaboration
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/wywiad-570x350.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <p style="text-align: justify;">
      Join the CREDO bilingual (EN and PL) Questions &amp; Answers LIVE! with
      the Project Coordinator and Spokesperson Piotr Homola, this Monday, 18:30
      CET. The questions asked in English will be answered in English, those in
      Polish - in Polish. Don't waste the chance to ask your most pending CREDO
      questions directly, and get the response immediately <span
        class="_47e3 _5mfr"
        title="Emotikon smile"
        ><img
          class="img"
          role="presentation"
          src="//static.xx.fbcdn.net/images/emoji.php/v9/t4c/1/16/1f642.png"
          alt=""
          width="16"
          height="16"
        /><span class="_7oe" aria-hidden="true">:)</span></span
      > We are looking forward to your active participation although the session
      will be recorded so if you miss it on Monday you might watch later.
    </p>
    <a
      href="https://www.facebook.com/watch/live/?v=2475947696030663"
      target="_blank"
      rel="noopener noreferrer">see interwiew</a
    >
  </div>
</div>
