<script>
  import Link from "svelte-link";
  let adres = location.href;
  if (adres.endsWith("/")) {
    adres = adres.slice(0, -1);
  }
  adres = adres.split("#/")[1];
  adres = adres.split("/");
  if (adres.length > 1) {
    adres = adres[adres.length - 1];
  } else {
    adres = adres[0];
  }
  let options = [
    { name: "praktyki", link: "#/education/praktyki" },
    { name: "credonews", link: "#/credonews" },
    { name: "news", link: "#/credonews" },
    {
      name: "lowcyczastek",
      link: "https://credo.science/particle_hunters/",
    },

    { name: "aboutcredo", link: "#/about/aboutcredo" },
    { name: "mediaaboutus", link: "#/about/mediaaboutus" },
    { name: "institutional-members", link: "#/credo-institutional-members" },
    { name: "members", link: "#/credo-institutional-members" },

    { name: "career", link: "#/about/career" },
    { name: "kariera", link: "#/about/kariera" },
    { name: "people", link: "#/about/people" },
    { name: "materialyprasowe", link: "#/about/materialyprasowe" },
    { name: "presskits", link: "#/about/presskits" },
    { name: "forumpl", link: "https://credo.science/credodetektor/" },
    { name: "how-to-start", link: "#/how-to-start" },
    { name: "howtostart", link: "#/how-to-start" },
    { name: "tutorial", link: "#/detector/tutorial" },
    { name: "faq", link: "#/education/faq" },
    { name: "materialsforschools", link: "#/education/materialsforschools" },
    { name: "materialydlaszkol", link: "#/education/materialydlaszkol" },
    { name: "podcast", link: "#/education/podcast" },
    {
      name: "particlehunters",
      link: "https://credo.science/particle_hunters/",
    },
    {
      name: "particle hunters",
      link: "https://credo.science/particle_hunters/",
    },
    { name: "practices", link: "#/education/practices" },
    { name: "practices/", link: "#/education/practices" },
    { name: "conferences", link: "#/science/conferences" },
    { name: "publications", link: "#/science/publications" },
    { name: "conferencereports", link: "#/science/conferencereports" },
    { name: "posters", link: "#/science/posters" },
    { name: "event", link: "#/science/posters/event" },
  ];

  async function more_count() {
    opcji = 1;
  }
</script>

<div
  class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center"
>
  <div class="lg:max-w-lg lg:w-full md:w-1/3 w-5/6 mb-10 md:mb-0">
    <img
      class="object-cover object-center rounded"
      alt="hero"
      src="./images/question.jpg"
      width="300"
    />
  </div>
  <div
    class="lg:flex-grow md:w-2/3 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center"
  >
    <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
      I can't find the site
    </h1>
    <p class="mb-8 leading-relaxed">
      <span style="color:#be0000;">{location.href}</span><br />
      {#each options as item}
        {#if item.name == adres}
          Our system offers you the following subpage:<br />
          <span style="color: #be0000;"
            ><a style="color: #be0000;" href={item.link}
              >{item.name} (credo.science/{item.link})</a
            ></span
          >
          <br />If this is not what you are looking for on our website: <br /> You
          can
        {/if}
      {/each}
      Back to the
      <span style="color: #be0000;"
        ><a style="color: #be0000;" href="/">Main Page</a></span
      >
    </p>
  </div>
</div>
