<script>
  import Fa from "svelte-fa/src/fa.svelte";
  import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
</script>

<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <span
      ><a href="#/about/MediaAboutUs"
        ><Fa
          class="text-black hover:text-gray-400"
          icon={faArrowAltCircleLeft}
          size="2x"
          secondaryOpacity={1}
        />
      </a></span
    >
    <a
      class="text-indigo-800 hover:text-blue-600"
      rel="noopener noreferrer"
      href="#/about/presskits/"><strong>Press Kits (English)</strong></a
    >
    Press Kits (<a
      class="text-indigo-800 hover:text-blue-600"
      href="#/about/presskits_spanish"
      rel="noopener noreferrer"><strong>Spanish</strong></a
    >)
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900"
      >
        <b> Materiały prasowe </b>
      </h1>

      <h2 class="text-base text-red-700 tracking-widest font-medium title-font">
        (po polsku)
      </h2>
    </div>
    <div
      class="lg:w-4/5 mx-auto leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <p>
        <li>
          [14-Cze-2023]
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ230614a-Trzesienia-widoczne-w-kosmosie.pdf"
            target="_blank"
            >Intrygująca korelacja między trzęsieniami ziemi a promieniowaniem
            kosmicznym</a
          >
        </li>
        <li>
          [11-Wrz-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911a - CREDO ukonstytuowane.pdf"
            target="_blank"
            >Łowcy cząstek promieniowania kosmicznego łączą się w CREDO</a
          >
        </li>
        <li>
          [11-Wrz-2019] <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="./data/press-kit/IFJ190911c - Projekt CREDO - Pakiet prasowy.pdf"
              target="_blank"
              >Ogólnoplanetarne obserwatorium cząstek promieniowania kosmicznego
              CREDO – pakiet prasowy</a
            ></strong
          >
        </li>
        <li>
          [11-Wrz-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911d - Projekt CREDO - Podstawowe fakty.pdf"
            target="_blank"
            >CREDO i promieniowanie kosmiczne – podstawowe fakty</a
          >
        </li>
        <li>
          [11-Wrz-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911e - Projekt CREDO - Na przeszłości, ku przyszłości.pdf"
            target="_blank">CREDO: Na przeszłości, ku przyszłości</a
          >
        </li>
        <li>
          [11-Wrz-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911f - Projekt CREDO - Promieniowanie kosmiczne.pdf"
            target="_blank"
            >Promieniowanie kosmiczne – od zagadki do... zagadek</a
          >
        </li>
        <li>
          [11-Wrz-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911g - Projekt CREDO - Detektor, jakiego nie było.pdf"
            target="_blank">CREDO – detektor, jakiego nie było</a
          >
        </li>
        <li>
          [11-Wrz-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911h - Projekt CREDO - CREDO penetruje nieznane.pdf"
            target="_blank">CREDO penetruje nieznane</a
          >
        </li>
        <li>
          [11-Wrz-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911i - Projekt CREDO - Portal do świata prawdziwej nauki.pdf"
            target="_blank"
            >Projekt CREDO jako portal do świata prawdziwej nauki</a
          >
        </li>
      </p>
    </div>
  </div>
</section>
