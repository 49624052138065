<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-ML Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/about/team/credo-ml"
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>About</strong></a
      >
      <a
        href="#/about/team/credo-ml/research"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      >
      <a
        href="#/about/team/credo-ml/people"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <a
        href="#/about/team/credo-ml/activites"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Activites</a
      >
      <a
        href="#/about/team/credo-ml/publications"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href="#/about/team/credo-ml/contact"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b>Machine Learning Task</b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          The group was established in early 2020, when a group of computer
          scientists from the Pedagogical University (UP, faculty of computer
          science), as part of their activity in the CREDO project, decided to
          invite people from other research institutions in Krakow (including
          the IFJ PAN - Institute of Nuclear Physics of the Polish Academy of
          Sciences, PK - Krakow University of Technology, AGH - University of
          Science and Technology) to scientific cooperation.
          <br />
          <br />
          From the beginning, the team has set ambitious scientific and computer
          science goals. One of them was development of CREDO Detector application
          and searching for more accurate and professional ways of filtering data
          from smartphone application and recognizing particle images (detections)
          by shape. The main method of scientific analysis is the broad concept of
          "Machine Learning", for this reason the group decided to officially adopt
          the name CRED-ML.
          <br />
          <br />
          We are one of the most active and well-performing groups in the whole CREDO
          project. And our work results are presented at scientific conferences,
          internal project meetings and also published in scientific journals
          <a
            class="text-blue-900 tracking-wider font-serif "
            href="#/about/team/credo-ml/publications"
            rel="noopener noreferrer"
            >(read more about our publications)
          </a>. The methods created by the team have practical applications in
          the project and are used i.e in the school competition "<a
            class="text-blue-900  tracking-wider font-serif "
            href="https://credo.science/particle_hunters/"
            rel="noopener noreferrer"
            >Particle Hunters
          </a>".
        </p>
      </div>
    </div>
  </div>
</section>
