<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="lg:w-4/5 mx-auto text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b
          >Boosting the social impact of the Cosmic Ray Extremely Distributed
          Observatory (CREDO) project</b
        >
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          <b class="text-center">
            Boosting the social impact of the CREDO project" is a dual
            scientific-educational effort aimed at increasing social engagement
            for detection and analysis of cosmic ray signals. This is
            particularly important because contrary to conventional scientific
            infrastructures designed for and operated by specialists, CREDO
            operates in the form of a citizen science project where the
            involvement of a general audience like students, teachers, science
            enthusiasts, and NGOs is not only welcome but essential for project
            success. Engaging social media, blogosphere, and direct contacts are
            means to this end.</b
          >
        </p>
      </div>

      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        Main specific objectives and aims
      </h1>
      <h2
        class="text-base text-red-700 tracking-widest font-medium title-font  mb-6"
      >
        of the current project
      </h2>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          <b>1. </b>
          Development and strengthening the collaboration with high schools and possibly
          elementary schools by developing and offering to school teachers the high
          quality teaching materials involving CREDO and developing long-term collaboration
          programs.
          <br />
          <b>2. </b>
          Organization of visits in schools, inviting teachers and students to participate
          in educational events specifically organized for this purpose. This will
          enable us to engage increased numbers of young people with interests in
          science.
          <br />
          <b>3. </b>
          Integration of the CREDO data resources with the Linked Open Data network,
          which is the current standard for open data publishing and which should
          help to increase the outreach. This will provide wider availability and
          visibility of CREDO data gathered from the cosmic ray measurement and stored
          on CREDO servers.
          <br />
          <b>4. </b>
          Further improvement of public engagement not only in cosmic ray measurement
          activities but also in data analysis tasks by exploring the integration
          of CREDO infrastructure with popular existing citizen science platform
          such as BOINC and SciStarter.
          <br />
          <b>5. </b>
          During the project we plan to explore the possibility to employ cryptocurrency
          technology to be able to generate digital tokens from CREDO actions which
          will be awarded as a reward for the action.
          <br />
          <b>6. </b>
          Finally, we will also be actively seeking potential industrial partners
          and we will look into ways how to generate business opportunities for potential
          partners within the CREDO ecosystem.
        </p>
      </div>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          <b>Implementation period: </b>01/02/2023–31/07/2024<br />
        </p>
        <div class="flex items-center sm:flex-row flex-col m-2">
          <div
            class="sm:w-full lg:w-1/5 sm:mr-10 inline-flex items-center justify-center flex-shrink-0"
          >
            <img
              alt="team"
              class="flex-shrink-0 rounded-lg w-full object-cover object-center"
              src="./images/visegrad.jpg"
            />
          </div>
          <div class="flex-grow sm:text-left text-center">
            This project is generously supported by the
            <a
              style="color: #0000ff;"
              href="https://www.visegradfund.org"
              target="_blank"
              rel="noopener noreferrer">Visegrad Fund</a
            >
            under grant number <strong>22220116</strong> -
            <a
              style="color: #0000ff;"
              href="./data/Visegrad/VF-SHOWREEL-LONG.mp4"
              target="_blank"
              rel="noopener noreferrer">See a short video about this fund</a
            >.
          </div>
        </div>
      </div>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        CREDO partners
      </h1>
      <h2 class="text-base text-red-700 tracking-widest font-medium title-font">
        (for this project)
      </h2>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <div class="p-4 lg:w-1/4 md:w-1/2">
          <div class="h-full flex flex-col items-center text-center">
            <img
              alt="team"
              class="flex-shrink-0 rounded-lg w-full object-cover object-center mb-4"
              src="./images/flags/members/logo_ifj.jpg"
            />
            <div class="w-full">
              <h2 class="title-font font-medium text-lg text-gray-900">
                The Henryk Niewodniczański Institute of Nuclear Physics of the
                Polish Academy of Sciences,<br /> Poland
              </h2>
              <a
                style="color: #0000ff;"
                href="https://www.ifj.edu.pl/en/index.php"
                target="_blank"
                rel="noopener noreferrer">Visit website</a
              >
            </div>
          </div>
        </div>

        <div class="p-4 lg:w-1/4 md:w-1/2">
          <div class="h-full flex flex-col items-center text-center">
            <img
              alt="team"
              class="flex-shrink-0 rounded-lg w-full object-cover object-center mb-4"
              src="./images/flags/members/pk.jpg"
            />
            <div class="h-full">
              <h2 class="title-font font-medium text-lg text-gray-900">
                Cracow University of Technology, <br /> Poland
              </h2>
              <a
                style="color: #0000ff;"
                href="http://www.pk.edu.pl/index.php?lang=en&template=pk18-tpl"
                target="_blank"
                rel="noopener noreferrer">Visit website</a
              >
            </div>
          </div>
        </div>

        <div class="p-4 lg:w-1/4 md:w-1/2">
          <div class="h-full flex flex-col items-center text-center">
            <img
              alt="team"
              class="flex-shrink-0 rounded-lg w-full object-cover object-center mb-4"
              src="./images/flags/members/institute_of_experimental.jpg"
            />
            <div class="h-full">
              <h2 class="title-font font-medium text-lg text-gray-900">
                Czech Technical University in Prague, Institute of Experimental
                and Applied Physics, <br /> Czech Republic
              </h2>
              <a
                style="color: #0000ff;"
                href="https://www.up.krakow.pl/en/"
                target="_blank"
                rel="noopener noreferrer">Visit website</a
              >
            </div>
          </div>
        </div>

        <div class="p-4 lg:w-1/4 md:w-1/2">
          <div class="h-full flex flex-col items-center text-center">
            <img
              alt="team"
              class="flex-shrink-0 rounded-lg w-full object-cover object-center mb-4"
              src="./images/flags/members/logoUP_en-1.png"
            />
            <div class="h-full">
              <h2 class="title-font font-medium text-lg text-gray-900">
                Pedagogical University of Krakow, <br /> Poland
              </h2>
              <a
                style="color: #0000ff;"
                href="https://www.up.krakow.pl/en/"
                target="_blank"
                rel="noopener noreferrer">Visit website</a
              >
            </div>
          </div>
        </div>

        <div class="p-4 lg:w-1/4 md:w-1/2">
          <div class="h-full flex flex-col items-center text-center">
            <img
              alt="team"
              class="flex-shrink-0 rounded-lg w-full object-cover object-center mb-4"
              src="./images/flags/members/logo_web_su_en.png"
            />
            <div class="h-full">
              <h2 class="title-font font-medium text-lg text-gray-900">
                Silesian University in Opava, <br /> Czech Republic
              </h2>
              <a
                style="color: #0000ff;"
                href="https://www.slu.cz/slu/en/"
                target="_blank"
                rel="noopener noreferrer">Visit website</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
