<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Kariera
  </h1>
  <div class="flex flex-col  w-full mb-2">
    <p style="text-align: justify;">
      <span style="color: #000000;"
        ><a style="color: #000000;" href="#/about/career"
          ><img
            class="wp-image-6195 alignright"
            src="./images/flags/wb.jpg"
            alt=""
            width="44"
            height="25"
          /></a
        ></span
      >
    </p>

    <h2 style="text-align: center;">
      <strong
        ><em
          ><span style="color: #000080;"
            ><span class="VIiyi" lang="pl"
              ><span
                class="JLqJ4b ChMk0b"
                data-language-for-alternatives="pl"
                data-language-to-translate-into="en"
                data-phrase-index="0">Rozważ pracę w CREDO w Krakowie!</span
              >
            </span></span
          ></em
        ></strong
      >
    </h2>
    <p style="text-align: center;">
      <span style="color: #000000;"
        ><em
          ><span class="VIiyi" lang="pl"
            ><span
              >O <strong>stanowisko podoktoranckie</strong> w
              <span style="color: #000080;"
                ><a
                  style="color: #000080;"
                  href="https://www.ifj.edu.pl/index.php"
                  >Instytucie Fizyki Jądrowej PAN</a
                >
                (<a
                  style="color: #000080;"
                  href="https://www.ifj.edu.pl/en/index.php"
                  >Institute of Nuclear Physics Polish Academy of Sciences</a
                >)</span
              >
              w Krakowie
              <strong
                >pod kierunkiem Koordynatora Projektu CREDO <span
                  style="color: #000080;"
                  ><a
                    style="color: #000080;"
                    href="https://credo.science/homola/">Piotra Homoli</a
                  ></span
                ></strong
              > można ubiegać się w ramach programu PASIFIC - Postdoctoral Fellowships
              - szczegóły poniżej</span
            ></span
          >. W przypadku pytań dotyczących grupy CREDO i działań w INP PAN
          prosimy o kontakt pod adresem contact@credo.science.</em
        ></span
      >
    </p>
    &nbsp;
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >Biuro ds. Doskonałości Naukowej PAN uprzejmie informuje o uruchomieniu
        programu PASIFIC współfinansowanego przez Unię Europejską w
      </span><span style="color: #000000;"
        >ramach MSCA COFUND. Celem programu jest umożliwienie 50 naukowcom
        odbycia dwuletnich pobytów naukowych w instytutach Polskiej Akademii
        Nauk.</span
      >
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;">Aplikować mogą naukowcy* </span><strong
        style="color: #000000;"
        >na poziomie podoktorskim, dowolnej narodowości i reprezentujący dowolny
        obszar badań</strong
      ><span style="color: #000000;">.</span>
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;">Program PASIFIC oferuje:</span>
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >a) <span style="text-align: justify;"
          >finansowanie realizacji indywidualnego projektu badawczego i wsparcie
          w tworzeniu długoterminowego planu rozwoju kariery zawodowej; b)
        </span><span style="text-align: justify;"
          >udział w szkoleniach naukowych i uzupełniających w goszczących
          instytutach Polskiej Akademii Nauk; c)
        </span><span style="text-align: justify;"
          >bogatą ofertę szkoleń, treningów i warsztatów, dotyczących m. in.
          możliwości finansowania badań naukowych w Polsce i Europie, pisania
          wniosków o granty badawcze, praw własności intelektualnej oraz
          zarządzania projektami; d)
        </span><span style="text-align: justify;"
          >możliwość odbycia maksymalnie 6-miesięcznego stażu w sektorze
          pozaakademickim. e)
        </span><span style="text-align: justify;"
          >Stypendystom zostanie przyznane miesięczne
        </span><strong style="text-align: justify;"
          >wynagrodzenie w wysokości 2 500 EUR</strong
        ><span style="text-align: justify;">
          <span class="VIiyi" lang="pl"
            ><span
              >a jeśli zdecydujesz się na przeprowadzkę z rodziną będzie
              dodatkowe wsparcie finansowe - sprawdź szczegóły tutaj: <strong
                ><span style="color: #000080;">pasific.pan.pl</span></strong
              ></span
            ></span
          >. Pon<span style="color: #000000;"
            >adto, program PASIFIC zapewni naukowcom budżet na badania w
            wysokości
          </span></span
        ><strong style="text-align: justify;"
          >do 93 000 EUR na projekt.
        </strong><strong style="text-align: justify;">Nabór</strong><span
          style="text-align: justify;"
        >
          przeprowadzony będzie w ramach dwóch konkursów:</span
        ></span
      >
    </p>
    <span style="color: #000000;"
      >I KONKURS - <strong>od 15 marca 2021</strong>
      <strong>do 30 czerwca 2021</strong></span
    >

    <span style="color: #000000;"
      >II KONKURS - <strong>od 15 września 2021 do 31 grudnia 2021</strong
      ></span
    >
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >Więcej informacji o projekcie PASIFIC można znaleźć <a
          style="color: #000000;"
          href="//institution.pan.pl/index.php/pasific"
          target="_blank"
          rel="noopener noreferrer"
          ><strong>na angielskiej stronie internetowej</strong></a
        > Polskiej Akademii Nauk.</span
      >
    </p>
    <span class="VIiyi" lang="pl"
      ><span
        >Zainteresowany? Zarejestruj się na webinarium jak aplikować:
        <a href="https://pasific.pan.pl/webinar-for-pasific-call-1-applicants"
          ><span style="color: #000080;"
            ><strong
              >pasific.pan.pl/webinar-for-pasific-call-1-applicants</strong
            ></span
          ></a
        >
        <span style="color: #000000;"
          >(15.04.2021, 10.30-11.00 czasu warszawskiego).</span
        ></span
      ><span style="color: #000000;">
        <span>Nie jesteś zainteresowany? Powiedz znajomym!</span></span
      ></span
    >

    <span style="color: #000000;"
      ><em
        >*Zainteresowani aplikujący muszą przestrzegać zasady mobilności MSCA:
        nie mogą mieszkać ani wykonywać głównej działalności (pracy, studiów
        itp.) w Polsce przez okres dłuższy niż 12 miesięcy w ciągu trzech lat
        bezpośrednio poprzedzających termin składania wniosków.</em
      ></span
    >
  </div>
</div>
