<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Summary of the CREDO Anniversary Symposium
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/credo-symposium.jpg"
      alt="credo-symposium.jpg"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >The CREDO Anniversary Symposium and the subsequent Collaboration Meeting
      both are turning out to be fruitful events. Quite widely spread reaction
      of the media, including country-wide Polish portals, generate a very
      positive effect: new colleagues contact CREDO, they are ready to join and
      actually do this, both professional and non-professional, both individual
      and institutional. We will hopefully announce some good news concerning
      new members soon. For the time being we just inform that there were 60
      registrants of around 15 nationalities representing around 10 countries
      and you can see some of them on the conference photo
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="./images/credo-symposium.jpg"
          target="_blank">here</a
        >. If you do not find yourself please make sure that you are with us by
        the next Anniversary, and do not wait too long with getting on board –
        the CREDO train is accelerating dynamically</span
      >.
    </span>
  </div>
</div>
