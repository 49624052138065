<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font text-red-900 "
      >
        <b> Access to data </b>
      </h1>
    </div>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <p>
        1. The CREDO data are available at
        <a
          href="https://api.credo.science/web/"
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
        >
          api.credo.science</a
        >. To get access you should: <br />
        a)
        <a
          href="https://api.credo.science/web/register/"
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank">Create an account</a
        >
        (If you have not previously created an account with CREDO Detector Application),
        <br />
        b) Contact CREDO Science Coordinator (credodetector@credo.science),<br
        />
        c) Describe how you are going to use the data. <br />Remember to include
        your username and send the message from the email address you used
        during registration.
      </p>
      <p class="mt-4 text-left">
        2. Documentation about <a
          href="https://github.com/credo-science/credo-webapp/tree/master/credoapiv2#apiv2data_export"
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank">data export</a
        >
        as well as a
        <a
          href="https://github.com/credo-science/credo-api-tools"
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank">collection of tools</a
        >
        for exporting and working with data collected by CREDO can be found in the
        <a
          href="https://github.com/credo-science"
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank">CREDO GitHub repository</a
        >.
      </p>
      <p class="mt-4 text-left">
        3. If your work is concluded in some written or visual form please do
        not forget to acknowledge the CREDO Collaboration properly,<br /> e.g.
        by citing our main publication:<br />

        <span style="color: #000000;"
          ><strong>"Cosmic Ray Extremely Distributed Observatory",</strong
          ></span
        ><br />
        <span style="color: #000000;"
          >P. Homola, et al. (CREDO Collab.), Symmetry 2020, 12(11), 1835, 2020.<br
          />
          [arXiv:<a
            href="//arxiv.org/abs/2010.08351"
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            target="_blank">2010.08351</a
          >, DOI:<a
            href="https://doi.org/10.3390/sym12111835"
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            target="_blank">10.3390/sym12111835</a
          >].
        </span>
      </p>
    </div>
  </div>
</section>
