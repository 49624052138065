<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    II edition of the “Particle Hunters” competition
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/Konkurs-570x350.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:justify"
      >From 1/10/2019 to 30/06/2019 there will be a competition involving team
      catching particles using the CREDO Detector application. The competition
      is mainly dedicated to Polish schools, but we also invite teams from other
      countries to join. You can find more information on the competition
      website
    </span>
  </div>
</div>
