<div class="container px-10 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    MICRO-WORKSHOP ON MACHINE LEARNING AIDED SHOWER DETECTION.
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/ML_workshoop.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center">
      CREDO had a busy time last months with several papers released, a few
      others close to completion or under review as well as large hardware and
      software projects at full swing. Among them were Machine Learning (ML)
      aided cosmic ray hit analyses conducted by the CREDO-ML group. The results
      of ML studies turned out to be very promising and we are excited to extend
      them towards search for signatures of air Showers in the CREDO data.
      Towards this goal we need extensive Monte Carlo simulations of both the
      air shower in atmosphere propagation and sensors’ response. The
      simulations will serve to build the training datasets for ML models.<br />

      <br /> To proceed, we need your expertise, advice and participation.<br
      /><br />
      The simulations will help us to answer the following questions: What event
      rates we need to accumulate to obtain the statistically significant results?
      What detector density is required to make the measurements feasible?How to
      evaluate the signal to background ratio ? what is the optimal measurement strategy
      ? The least is of course not complete, we look forward for your input.<br
      />
      Therefore we invite all CREDO affiliates and other interested parties to join
      us at Micro-worksop on Machine Learning aided shower detection to be held on
      Nov, 24, 11:00 CET both at:<br />
      the Pedagogical University of Krakow, ul. Podchorążych 2, room 421N and on-line
      on the zoom platform:<br />
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://us02web.zoom.us/j/86336675554?pwd=QTYxekNHOWdVUkV6ZmI0ZUhCT3hOZz09"
          >https://us02web.zoom.us/j/86336675554?pwd=QTYxekNHOWdVUkV6ZmI0ZUhCT3hOZz09</a
        ></span
      ><br />

      Please register using workshops indico page:

      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://indico.ifj.edu.pl/event/740/"
          >https://indico.ifj.edu.pl/event/740/</a
        ></span
      ><br />
    </span>
  </div>
</div>
