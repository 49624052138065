<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>Recordings of presentations from the 1st CREDO Visegrad Workshop</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2 mb-5">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
      class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 mb-3"
      alt="img"
      src="./images/posts/intro_1stVisegrad.png"
    />

      Most of the presentations from the 1st CREDO Visegrad Workshop from Tuesday (16.01.2024) and Wednesday (17.01.2024) have been made available on CREDO YouTube channel. 
      <br/><br/>
      <a
      class="text-blue-900 hover:text-blue-500"
      href="https://www.youtube.com/playlist?list=PLun_-7RrVwDv8myNzkuQViopLr2cVKB5k"
      >[See playlist "1st CREDO Visegrad Workshop 2024" on YouTube].</a
    >
    </p>
       </div>
</section>
