<script>
  import Link from "svelte-link";
  import * as animateScroll from "svelte-scrollto";
</script>

<footer class="text-black bg-gray-50 body-font bottom pt-12">
  <div class="container px-5 py-10 mx-auto">
    <div class="flex flex-wrap md:flex-row text-center -mb-10 -mx-4">
      <div class="lg:w-1/6 md:w-1/3 w-full px-4 sm:w-1/2 font-serif">
        <h2
          class="title-font font-semibold text-gray-900 tracking-widest text-sm mb-3 uppercase"
        >
          about us
        </h2>
        <nav class="list-none mb-10">
          <li>
            <Link
              href="#/about/AboutCredo"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              About CREDO
            </Link>
          </li>
          <li>
            <Link
              href="#/credo-institutional-members"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Institutional members
            </Link>
          </li>
          <li>
            <Link
              href="#/about/People"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              People
            </Link>
          </li>
          <!-- <li>
            <Link
              href="#/about/Career"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Career
            </Link>
          </li> -->
          <li>
            <Link
              href="#/about/MediaAboutUs"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Media about us
            </Link>
          </li>
          <li>
            <Link
              href="#/credonews"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              News
            </Link>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/6 md:w-1/3 w-full px-4 sm:w-1/2 font-serif">
        <h2
          class="title-font font-semibold text-gray-900 tracking-widest text-sm mb-3 uppercase"
        >
          detectors
        </h2>
        <nav class="list-none mb-10">
          <li>
            <Link
              href="#/accesstodata"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Access to data
            </Link>
          </li>
          <li>
            <Link
              href="#/how-to-start"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              How to start
            </Link>
          </li>
          <li>
            <Link
              href="#/detector/tutorial"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Tutorial
            </Link>
          </li>
          <li>
            <Link
              href="https://credo.science/credodetector/viewforum.php?f=7"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              FAQ API
            </Link>
          </li>
          <li>
            <Link
              href="https://credo.science/credodetektor/"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Forum pl
            </Link>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/6 md:w-1/3 w-full px-4 sm:w-1/2 font-serif">
        <h2
          class="title-font font-semibold text-gray-900 tracking-widest text-sm mb-3 uppercase"
        >
          Science
        </h2>
        <nav class="list-none mb-10">
          <li>
            <Link
              href="#/science/publications"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Publications
            </Link>
          </li>
          <li>
            <Link
              href="#/science/conferencereports"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Conference reports
            </Link>
          </li>
          <li>
            <Link
              href="#/science/conferences"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Conferences
            </Link>
          </li>
          <li>
            <Link
              href="#/science/posters"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Posters
            </Link>
          </li>
          <li>
            <Link
              href="https://redmine.credo.science"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Internal
            </Link>
          </li>
        </nav>
      </div>

      <div class="lg:w-1/6 md:w-1/3 w-full px-4 sm:w-1/2 font-serif">
        <h2
          class="title-font font-semibold text-gray-900 tracking-widest text-sm mb-3 uppercase"
        >
          groups
        </h2>
        <nav class="list-none mb-10">
          <li>
            <Link
              href="#/about/team/credo-cs"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Cosmo-Seismic
            </Link>
          </li>
          <li>
            <Link
              href="#/about/team/credo-ml"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Machine Learning
            </Link>
          </li>

          <li>
            <Link
              href="https://www.credo-maze.org"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Maze
            </Link>
          </li>

          <li>
            <Link
              href="#/about/team/credo-edu"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Edu
            </Link>
          </li>
        </nav>
      </div>

      <div class="lg:w-1/6 md:w-1/3 w-full px-4 sm:w-1/2 font-serif">
        <h2
          class="title-font font-semibold text-gray-900 tracking-widest text-sm mb-3 uppercase"
        >
          Education
        </h2>
        <nav class="list-none mb-10">
          <li>
            <Link
              href="https://credo.science/particle_hunters/"
              class="text-gray-600 text-center hover:text-gray-800"
            >
              Particle Hunters
            </Link>
          </li>
          <li>
            <Link
              href="#/education/materialsforschools"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Materials for schools
            </Link>
          </li>
          <!-- <li>
            <Link
              href="https://credo.science/zajrzyjwglabwszechswiata/"
              class="text-gray-600 text-center hover:text-gray-800"
            >
              GRA „ZAJRZYJ W GŁĄB WSZECHŚWIATA”
            </Link>
          </li> -->
          <li>
            <Link
              href="#/education/practices"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              Practices
            </Link>
          </li>
          <li>
            <Link
              href="#/education/faq"
              class="text-gray-600 text-center hover:text-gray-800"
              on:click={() => animateScroll.scrollToTop()}
            >
              FAQ
            </Link>
          </li>
        </nav>
      </div>

      <div class="lg:w-1/6 md:w-1/3 w-full px-4 sm:w-1/2 font-serif">
        <h2
          class="title-font font-semibold text-gray-900 tracking-widest text-sm mb-3 uppercase"
        >
          contact
        </h2>
        <nav class="list-none mb-10">
          <!-- <li class="text-gray-600 hover:text-gray-800">Radzikowskiego 152,</li>
          <li class="text-gray-600 hover:text-gray-800">31-342 Kraków,PL</li> -->
          <li class="text-gray-600 hover:text-gray-800">
            <!-- svelte-ignore a11y-missing-attribute -->
            <img
              style="display:inline;vertical-align:middle;"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAVFJREFUSEvdlU1OwzAUhD8jKnVHFrEUiQXlBPQI3ITkBu1Jyg0absIRyg3CAimSswg7pCCM7NRtkuaPVhVSs33PM/NmnGfBmT9xZnwukMD3g0chWAOzI+1LtCbKsvTVnD+wyPeDRAjujgR3xxKl0vtWAikDXXbpHMRSqTQeQyZlEIJegfBMv1KpFX8wwZ5gBxsXxXSZ50neRuR5M28y+VoBYbU+hiACnoEb0Bu4jpT62FRBpLydw/caxBz4BBZg8xuewCgoAX5i0A9Ny+qWiDe4Co0A58DgBK5ha4GZ5Gmr3mXiLHkpiunCWThI0BVoqdhZZrusJc1L0EnQEu6Oy02zt8yUSkuagk4iGHld7TXvzGAMSF/PYAZdVjlFQwL+n2Cswr4+rXnPstQuy973oGlX+fO5XdVOYcDN2ujcpvVVUAcbm0MV4wJftKGQ/1o/u0W/BnmqGUdvwO4AAAAASUVORK5CYII="
            />
            <!-- svelte-ignore a11y-missing-attribute -->
            <a style="display:inline;vertical-align:top;padding-left:5px;"
              >contact@credo.science</a
            >
          </li>
          <li class="text-gray-600 hover:text-gray-800">
            <!-- svelte-ignore a11y-missing-attribute -->
            <img
              style="display:inline;vertical-align:middle;"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAL1JREFUSEvtlIEJwjAQRV836AY6ghtUJ9EROoJOoCO4ghvoBDqCbuAGlQ8JHGo9UhJUaKBw0Ob/+4+7VhQ+VWF9fs5gCmyBGaBa5wTM+0ikJJDgGaifxLIZ7IHlm06zGRyBJhgcgBVw94YkBVFnxDbA2hPX+68bCMMkdGo7Fi49cYpi/RLKS2C59xH5iCuHwcKkSU6wC0uli3GCVN+Aa1BrgUuORfvPKbLJxwTu4o+IXESDPvB+FYNE7aXiBg+0MykZNmDKCAAAAABJRU5ErkJggg=="
            />
            <a
              href="//www.facebook.com/credo.science/"
              target="_blank"
              rel="noreferrer"
              style="display:inline;vertical-align:top;padding-left:5px;"
              >@credo.science</a
            >
          </li>
          <li class="text-gray-600 hover:text-gray-800">
            <!-- svelte-ignore a11y-missing-attribute -->
            <img
              style="display:inline;vertical-align:middle;"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAUdJREFUSEvdleExBEEQhb+LgAzIABEgAkTgRIAIEAEhyAARkAGXAREgAuq7mrmanZuZ29uq/XNTdX92ut/r97qnb8LIZzIyPptHsA38rLDNmEvgFNgHPoFH4C7k+f05YuQWGWzAR4PkDTgs3Jsj4TXwUCLw8j0oOAMEyk+MqfFLMgsE8yJzBX9JplUoO7VMdU8Ndb/BgUVxOYHenWQAfvOnfQfAfYPgJRAsQlICm7cbwHYGvg8V36a5OcH3QOCY1mlwqQfaMLR68bSwM4F5D5wSG7Q1QMlXsLiTWloVTsp53qwehEv2lCzy2xHw2gMwDbF61S9tgdqycxJu1iA5rjzM5jZ1bH1s2tU6F2EXFWNqCrQpLrQauK/2qgWe90BQK97rYY0vVnDHunlKCqah0TYtkrnAbGC6NlZhz+837x+tl+x1gka36B/CnzgZFPaBJAAAAABJRU5ErkJggg=="
            />
            <!-- svelte-ignore a11y-missing-attribute -->
            <a style="display:inline;vertical-align:top;padding-left:5px;"
              >@RayExtremely</a
            >
          </li>
        </nav>
      </div>
    </div>
  </div>

  <div style="background: #02021B">
    <div
      class="border-b-2 border-gray-200 container px-5 py-10 flex flex-wrap mx-auto items-center"
    >
      <p class="text-center font-serif mx-auto text-white">
        The CREDO Project is generously supported by the Visegrad Fund under
        grant number 22220116.
        <a
          class="text-indigo-300 hover:text-blue-600"
          target="_blank"
          rel="nofollow"
          href="#/visegrad-grant/">More information</a
        >
      </p>
    </div>
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-row">
      <div class="lg:w-2/5 w-full text-center">
        <div
          class="container mx-auto py-4 flex flex-wrap flex-row justify-center"
        >
          <div class="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full text-center">
            <img
              src="./logotyp_biale.png"
              alt="Logo CREDO"
              class="object-contain h-20 m-auto"
            />
          </div>
          <div
            class="xl:w-1/2 lg:w-1/2 md:w-1/2 sm:w-full m-auto text-center sm:-p-10"
          >
            <span class="text-xl text-gray-300">CREDO.SCIENCE</span>
          </div>
        </div>
      </div>
      <div class="lg:w-2/5 w-full text-center">
        <div
          class="container mx-auto py-4 flex flex-wrap flex-row gap-y-4 justify-center"
        >
          <div class="xl:w-1/3 lx:w-1/3 md:w-1/3 sm:w-full px-4 text-center">
            <img
              class="object-contain h-20 w-full"
              src="./images/visegrad.jpg"
              alt=""
            />
          </div>
          <div class="xl:w-1/3 lx:w-1/3 md:w-1/3 sm:w-full px-4 text-center">
            <img
              class="object-contain h-20 w-full"
              src="./images/asterics.png"
              alt=""
            />
          </div>
          <div class="xl:w-1/3 lx:w-1/3 md:w-1/3 sm:w-full px-4 text-center">
            <img
              class="object-contain h-20 w-full"
              src="./images/EU-Logo.gif"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="lg:w-1/5 w-full flex items-center sm:py-10 md:py-10">
        <span
          class="sm:ml-auto sm:mt-0 mt-2 w-full text-center text-gray-500 text-sm"
        >
          © 2024 CREDO</span
        >
      </div>
    </div>
  </div>
</footer>
