<script>
  import Link from "svelte-link";

  import Fa from "svelte-fa/src/fa.svelte";
  import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
  } from "@fortawesome/free-solid-svg-icons";

  let home = "https://credo.science/";
</script>

<div class="bg-gray-100" id="post">
  <div
    class="mt-10 w-10 h-10 ml-2 md:ml-10 flex relative text-3xl font-bold leading-tight text-center z-10 inset-y-0 float-left"
  >
    <button
      on:click|preventDefault={() => {
        location.replace(home + "#project");
      }}
      ><Fa
        class="text-black hover:text-gray-400"
        icon={faArrowAltCircleDown}
        size="2x"
        secondaryOpacity={1}
      /></button
    >
  </div>
  <div
    class="mt-10 w-10 h-10 mr-2 md:mr-10 relative text-3xl font-bold leading-tight text-center z-10 inset-y-0 float-right"
  >
    <button
      on:click|preventDefault={() => {
        location.replace(home + "#slider");
      }}
      ><Fa
        class="text-black hover:text-gray-400"
        icon={faArrowAltCircleUp}
        size="2x"
        secondaryOpacity={1}
      /></button
    >
  </div>
  <div class="container flex-grow px-5 py-20 mx-auto">
    <div class="flex flex-col text-center w-3/4 mx-auto">
      <Link
        class="text-4xl font-semibold title-font  text-gray-900 underline hover:text-gray-700"
        href="#/credonews"
      >
        CREDO NEWS
      </Link>
    </div>
    <div
      class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5"
    >
      <!--Card 2-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/italy2025/1.jpg"
          alt="post img"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO Science Camp – Italy@Kraków 2025
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">02.04.2025</p>
        <a href="#/posts/2025/04-02" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 3-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/eq_IFJ.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            The CREDO group at Institute of Nuclear Physics PAN (IFJ PAN) is
            hiring!
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">06.03.2025</p>
        <a href="#/posts/2025/03-06" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 1-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/PH7th_info.png"
          alt="new peer-reviewed"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Announcement of the VII edition of "Particle Hunters"
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">12.11.2024</p>
        <a href="#/posts/2024/11-12" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>

      <!-- rest -->
    </div>
    <div class="flex sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-4">
      <div class="p-2 w-full flex justify-center">
        <a
          href="#/credonews"
          target="_blank"
          class="flex text-white bg-red-500 border-0 py-2 px-8
          focus:outline-none hover:bg-red-600 rounded xl:text-2xl lg:text-xl md:text-lg sm:text-md"
        >
          SEE ALL NEWS
        </a>
      </div>
    </div>
  </div>
</div>

<style>
  .my-brown {
    background: rgba(92, 75, 81, 0.8);
  }
</style>
