<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Publication about the CREDO Detector application in the Symmetry journal
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/post3ba-570x350.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000;"
      >Curious about the technical details and future perspectives of our
      flagship application CREDO Detector? Here is a freshly published
      peer-review article "for the CREDO Collaboration" in the Symmetry Journal.
      Big congratulations to all the co-authors! Enjoy, share, cite!</span
    >

    <span style="color: #0000ff;"
      ><a
        href="https://www.mdpi.com/2073-8994/12/11/1802"
        target="_blank"
        rel="noopener noreferrer">Go to article</a
      ></span
    >
  </div>
</div>
