<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO at International Cosmic Day 2017
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/tlo-3-570x350.png" alt="img" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >A unique opportunity for all the CREDO members, FB fans, CREDO Detector
      users, and others who are interested in joining the project: on Nov 30
      we’ve got a full day to meet each other – virtually through Skype,
      Facebook and YouTube, or in person, for those near Krakow.
    </span>
  </div>
</div>
