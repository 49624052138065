<script>
  let step = 1;
  let activeButton = "btn1";

  async function goToStep(value) {
    step = value;

    let btn = document.getElementById(activeButton);
    btn.classList.remove("bg-gray-100");
    btn.classList.remove("border-indigo-500");
    btn.classList.remove("text-indigo-500");
    // console.log("prev: " + activeButton + ", new: btn" + value);

    btn = document.getElementById("btn" + value);
    btn.classList.add("bg-gray-100");
    btn.classList.add("border-indigo-500");
    btn.classList.add("text-indigo-500");
    activeButton = "btn" + value;
  }
</script>

<section class="text-gray-600 body-font">
  <h2 style="text-align: center;">
    <span style="color: #02021f;">CREDO workshop 2019</span>
  </h2>
  <div class="container px-5 py-12 mx-auto flex flex-col max-h-max">
    <div class="flex mx-auto flex-wrap mb-5">
      <button
        id="btn1"
        on:click|preventDefault={() => goToStep(1)}
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
          />
        </svg> Start
      </button>
      <button
        id="btn2"
        on:click|preventDefault={() => goToStep(2)}
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        Plakat
      </button>
      <button
        id="btn3"
        on:click|preventDefault={() => goToStep(3)}
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
          />
        </svg>
        Kalendarium
      </button>
      <button
        id="btn4"
        on:click|preventDefault={() => goToStep(4)}
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
        Kontakt
      </button>
      <button
        id="btn5"
        on:click|preventDefault={() => goToStep(5)}
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          />
        </svg>
        Dojazd
      </button>
      <button
        id="btn6"
        on:click|preventDefault={() => goToStep(6)}
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
        Potwierdzenia dla szkół
      </button>
      <button
        id="btn7"
        on:click|preventDefault={() => goToStep(7)}
        class="sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
          />
        </svg>
        Prezentacje
      </button>
    </div>

    {#if step == 1}
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >W dniach <strong>19-21 września</strong> .b.r.
          <strong>w Instytucie Fizyki Jądrowej PAN w Krakowie</strong>
          <strong>odbędzie się konferencja</strong>
          <strong>projektu CREDO</strong>
          (credo.science). Będzie ona miała charakter warsztatów i przeznaczona będzie
          w całości dla nauczycieli i uczniów. Celem warsztatów będzie przedstawienie
          specjalnie przygotowanych dla nich prezentacji/wykładów
          <strong>na następujący temat:<br /> </strong><strong
            >przedstawienie zjawiska promieniowania kosmicznego, jego natury,
            źródeł i konsekwencji dla ludzi, sposobów rejestracji przez duże
            obserwatoria i przez indywidualnych ludzi a także omówienie projektu
            CREDO.</strong
          ></span
        >
      </p>
      <hr class="my-2" />
      <h3>
        <span style="color: #02021f;"><strong>O warsztatach</strong></span>
      </h3>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >W tym roku projekt CREDO po raz pierwszy organizuje warsztaty z
          użyciem większych (niż smartfony) detektorów. Zostaną one dokładnie
          przedstawione przez specjalistów w tej dziedzinie. Celem
          przedstawienia detektorów jest planowe w przyszłości instalowanie ich
          w szkołach. Ma to być początkiem tworzenia sieci pomiarowych w
          szkołach najpierw w Polsce, a potem za granicą. Pomiary te będą miały
          znaczenie naukowe (dane z nich będą przesyłane do banku danych w
          Cyfronecie w Krakowie, a potem analizowane), dydaktyczne i
          popularyzatorskie. Nawiązanie kontaktu z CREDO planowane jest jako
          utworzenie stałego kontaktu między nauczycielami, uczniami i
          naukowcami.</span
        >
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >Dodatkowo w trakcie warsztatów przedstawione zostaną wykłady i
          prezentacje na następujące tematy:</span
        ><br />
        <span style="color: #02021f;">&#8211; cząstki elementarne,</span><br />
        <span style="color: #02021f;"
          >&#8211; promieniowanie kosmiczne – źródła i rozchodzenie się,</span
        ><br />
        <span style="color: #02021f;"
          >&#8211; CREDO w erze smartfonów i w erze stacji detektorów,</span
        ><br />
        <span style="color: #02021f;"
          >&#8211; rejestracja promieniowania kosmicznego (m.in. ślady cząstek
          na matrycach),</span
        ><br />
        <span style="color: #02021f;"
          >&#8211; duże obserwatoria promieniowania kosmicznego – Pierre Auger,
          IceCube, Bajkał,</span
        ><br />
        <span style="color: #02021f;"
          >&#8211; radioaktywność naturalna, pomiary i wpływ na ludzi,</span
        ><br />
        <span style="color: #02021f;"
          >&#8211; detektor promieniowania kosmicznego w Dubnie w projekcie
          Nica,</span
        ><br />
        <span style="color: #02021f;"
          >&#8211; czarne dziury, gwiazdy neutronowe, fale grawitacyjne</span
        ><br />
        <span style="color: #02021f;"
          >Wykładowcami będą profesorowie, doktorzy habilitowani, doktorzy i
          doktoranci IFJ PAN (m.in. twórca projektu CREDO prof. Piotr Homola),
          Uniwersytetu Łódzkiego, NCBJ Świerk i ZIBJ w Dubnie (Rosja). Będzie
          można też zwiedzić laboratoria IFJ PAN, m.in. bardzo chronione CCB
          (Centrum Cyklotronowe Bronowice).</span
        >
      </p>

      <hr class="my-2" />
      <h3>
        <span style="color: #02021f;"><strong>Miejsce spotkania</strong></span>
      </h3>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >Wydarzenie odbędzie się w Krakowie, a dokładnie w Instytucie Fizyki
          Jądrowej Państwowej Akademik Nauk, w głównej auli w centralnym
          budynku. Instytut znajduję się na ul. Radzikowskiego 152 (31-342
          Kraków), niedaleko ronda ofiar Katynia.Więcej o Instytucie można
          poczytać na <a
            style="color: #02021f;"
            href="//web.archive.org/web/20200220175516/https://www.ifj.edu.pl/"
            target="_blank"
            rel="noopener noreferrer">oficjalnej stronie</a
          >.</span
        >
      </p>

      <hr class="my-2" />
      <h3>
        <span style="color: #02021f;"
          ><strong>Wejście do instytutu:</strong></span
        >
      </h3>
      <p style="text-align: justify;">
        <span style="color: #02021f;">
          Na bramie głównej trzeba będzie pokazać jakiś dowód tożsamości w celu
          znalezienia naszego nazwiska na liście uczestników (w razie jego braku
          tam portier powiadomi o tym organizatorów).</span
        >
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >Po przejściu bramy należy iść cały czas prosto koło fontanny aż do
          głównego wejścia po schodach do budynku. Po wejściu do niego należy
          przejść główny hol dalej na wprost i wejść po schodach na pierwsze
          piętro. Tam będą stały duże stoły z identyfikatorami i teczkami dla
          nas. Osoba siedząca przy nich udzieli wszystkich potrzebnych
          informacji o np. toaletach, jadalni etc. Dwa wejścia do auli
          wykładowej będą blisko stołów.</span
        >
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >Jadalnia: jest czynna od 8:00 do 16:00. Obiady wydawane są od 11:30
          ale my i tak jesteśmy związani planem wykładów. Można jednak np. nie
          czekać na przerwę kawową i pójść tam na kawę lub inne co nieco.</span
        >
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >Tak jak jest napisane w kalendarium grupa „czwartkowa” może w piątek
          przed 11 zwiedzać laboratoria instytutu. Reszta osób może to zrobić w
          trakcie przerwy obiadowej.</span
        >
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >Jest zaplanowanych sporo referatów wiec bardzo wskazane jest
          punktualne przychodzenie na ich początki.</span
        >
      </p>

      <hr class="my-2" />
      <h3>
        <strong
          ><span style="color: #02021f;"
            >Informacje praktyczne w instytucie</span
          ></strong
        >
      </h3>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >W czwartek do godziny 16:00 można zjeść obiad w stołówce (dojście
        </span><span style="color: #02021f;"
          >pokaże obsługa konferencji przy stolikach przed aulą na pierwszym
        </span><span style="color: #02021f;">piętrze).</span>
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >&#8211; Obiad w czwartek: w stołówce na początku należy wziąć tackę,
          talerz i
        </span><span style="color: #02021f;"
          >sztućce (i może też zupę), następnie podejść do kontuaru z daniami i
        </span><span style="color: #02021f;"
          >wybrać na talerz co chcemy. Przy kasie należy położyć talerz z drugim</span
        ><br />
        <span style="color: #02021f;"
          >daniem na wadze. Pani przy kasie będzie życzyła smacznego i
          zainkasuje
        </span><span style="color: #02021f;">należność.</span>
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >&#8211; Obiady w piątek i sobotę: będą wydawane w sali koło stołówki.
          Będzie
        </span><span style="color: #02021f;"
          >jedna zupa, danie mięsne i bezmięsne (+ surówki i kompot). Każdy kto
        </span><span style="color: #02021f;"
          >chciałby zjeść coś innego może wziąć obiad w sali głównej stołówki.</span
        >
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >&#8211; Wszyscy, którzy w czwartek będą uczestniczyć w referatach
          mogą w
        </span><span style="color: #02021f;"
          >piątek od 9:00 (lub od 9:15 &#8211; to ustalimy w czwartek) zwiedzać
        </span><span style="color: #02021f;"
          >laboratoria w instytucie. Powinni przyjść na przerwę kawową o
          godzinie
        </span><span style="color: #02021f;"
          >10:40 i potem uczestniczyć już we wspólnych referatach m.in. w gali
        </span><span style="color: #02021f;">Łowców Cząstek.</span>
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >&#8211; Ci, którym nie uda się uczestniczyć we wszystkich wykładach w
          czwartek
        </span><span style="color: #02021f;"
          >mogą wysłuchać ich nieco krótszych wersji w piątek od 9:00 do 10:40 a
        </span><span style="color: #02021f;"
          >zwiedzać laboratoria mogą w jednej z dwu części przerwy obiadowej.
          Ci,</span
        ><br />
        <span style="color: #02021f;"
          >którzy zwiedzali laboratoria w czwartek rano będą mieli po prostu
        </span><span style="color: #02021f;">dłuższą przerwę obiadową.</span>
      </p>
      <p style="text-align: justify;">
        <span style="color: #02021f;"
          >&#8211; Po przerwie kawowej w piątek o około 16:00-16:20 ci, którzy
          chcą
        </span><span style="color: #02021f;"
          >wysłuchać nieco krótszych wersji reszty wykładów czwartkowych mogą
        </span><span style="color: #02021f;"
          >zostać w auli do godziny 17:40.</span
        >
      </p>

      <hr class="my-2" />
      <h3>
        <span style="color: #02021f;"><strong>Noclegi w Krakowie:</strong></span
        >
      </h3>
      <p>
        <span style="color: #02021f;"
          >Dla osób, które chcą nocować w Krakowie i nie maja jeszcze
          zarezerwowanego miejsca (nie dotyczy to grupy osób zorganizowanej
          przez dra Chmielowskiego bo oni już maja zarezerwowane miejsca w
          Babilonie) proponujemy któryś z hoteli AGH (w roku akademickim są to
          akademiki).<br />
          Proszę wybrać na stronie:
          <a
            style="color: #02021f;"
            href="//web.archive.org/web/20200220175516/https://taniehostele.pl/pl/cennik.html"
            target="_blank"
            rel="noopener noreferrer"
            data-saferedirecturl="//www.google.com/url?q=//taniehostele.pl/pl/cennik.html&amp;source=gmail&amp;ust=1568205755590000&amp;usg=AFQjCNGa2QOdt_s6qeJWz_kZRMW0BI0XsQ"
            >//taniehostele.pl/pl/cen<wbr />nik.html</a
          ><br />
          Śniadania są w Babilonie.</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >dokładniejsze informacje o pobycie uczestników w Krakowie i
          instytucie zostaną umieszczone na stronie po zakończeniu rejestracji
          wszystkich uczestników ( 12 września).</span
        >
      </p>

      <hr class="my-2" />
      <h3>
        <span style="color: #02021f;"><strong>Koszty uczestnictwa</strong></span
        >
      </h3>
      <p>
        <span style="color: #02021f;">Uwaga!</span><br />
        <span style="color: #02021f;"
          >Instytut Fizyki Jądrowej nie zwraca kosztów podróży ani pobytu
          przyjeżdżającym osobom i grupom. Możliwe jest jednak formalne
          potwierdzenie pobytu w instytucie i udziału w warsztatach oraz gali
          Łowców Cząstek.</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >IFJ PAN nie pobiera żadnych dodatkowych opłat za udział w konferencji</span
        >
      </p>

      <hr class="my-2" />
      <h3>
        <span style="color: #02021f;"><strong>Dojazdy w Krakowie:</strong></span
        >
      </h3>
      <p>
        <span style="color: #02021f;"
          >Dla osób korzystających z komunikacji miejskiej proponujemy
          wykorzystanie aplikacji (strony) jakdojadę.pl</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong
            ><a
              style="color: #02021f;"
              href="//web.archive.org/web/20200220175516/https://jakdojade.pl/krakow/trasa/z--Kraków-Główny--do--Instytut-Fizyki-Jądrowej-im.-Henryka-Niewodniczańskiego-PAN?fn=Kraków%20Główny&amp;tn=Instytut%20Fizyki%20Jądrowej%20im.%20Henryka%20Niewodniczańskiego%20PAN&amp;tc=50.089995:19.88985&amp;fc=50.068453:19.947461&amp;fsn=Kraków%20Główny&amp;ft=LOCATION_TYPE_STOP&amp;tt=LOCATION_TYPE_POI&amp;d=10.09.19&amp;h=15:31&amp;aro=1&amp;t=1&amp;rc=3&amp;ri=1&amp;r=0"
              target="_blank"
              rel="noopener noreferrer"
              >Dworzec PKP &#8211; IFJ PAN,
            </a></strong
          ></span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Bus bezpośredni: nr 501 &#8211; wsiadamy na przystanku Politechnika,
          wysiadamy na przystanku Rondo Ofiar Katynia</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Bus z przesiadkami: nr 208 &#8211; wsiadamy na przystanku Kraków
          główny 1, wysiadamy na przystanku Bronowice Wiadukt, z Bronowice
          wiadukt wsiadamy do 114,501 (lub innego busu jadącego w stronę Ronda
          Ofiar Katynia)</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong
            ><a
              style="color: #02021f;"
              href="//web.archive.org/web/20200220175516/https://jakdojade.pl/krakow/trasa/z--Kraków-Główny--do--Miasteczko-Studenckie-AGH?fn=Kraków%20Główny&amp;tn=Miasteczko%20Studenckie%20AGH&amp;tc=50.069608:19.906378&amp;fc=50.068453:19.947461&amp;fsn=Kraków%20Główny&amp;tsn=Miasteczko%20Studenckie%20AGH&amp;ft=LOCATION_TYPE_STOP&amp;tt=LOCATION_TYPE_STOP&amp;d=10.09.19&amp;h=15:33&amp;aro=1&amp;t=1&amp;rc=3&amp;ri=1&amp;r=0"
              target="_blank"
              rel="noopener noreferrer">dworzec PKP -Miasteczko AGH,</a
            ></strong
          ></span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Bus bezpośredni: nr 501 &#8211; wsiadamy na przystanku Politechnika,
          wysiadamy na przystanku Miasteczko studenckie AGH</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Bus z przesiadkami: nr 208 &#8211; wsiadamy na przystanku Kraków
          główny 1, wysiadamy na przystanku Miasteczko studenckie AGH</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><a
            style="color: #02021f;"
            href="//web.archive.org/web/20200220175516/https://jakdojade.pl/krakow/trasa/z--Miasteczko-Studenckie-AGH--do--Instytut-Fizyki-Jądrowej-im.-Henryka-Niewodniczańskiego-PAN?fn=Miasteczko%20Studenckie%20AGH&amp;tn=Instytut%20Fizyki%20Jądrowej%20im.%20Henryka%20Niewodniczańskiego%20PAN&amp;tc=50.089995:19.88985&amp;fc=50.069608:19.906378&amp;fsn=Miasteczko%20Studenckie%20AGH&amp;ft=LOCATION_TYPE_STOP&amp;tt=LOCATION_TYPE_POI&amp;d=10.09.19&amp;h=15:33&amp;aro=1&amp;t=1&amp;rc=3&amp;ri=1&amp;r=0"
            target="_blank"
            rel="noopener noreferrer"
            ><strong>Miasteczko AGH &#8211; IFJ PAN</strong></a
          ></span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Bus bezpośredni: nr 501, 114,173 &#8211; wsiadamy na przystanku
          Miasteczko studenckie AGH, wysiadamy na przystanku Rondo Ofiar Katynia</span
        >
      </p>
      <p><span style="color: #02021f;">Bus pośredni &#8211; odradzamy</span></p>
      <p>
        <span style="color: #02021f;"
          ><a
            style="color: #02021f;"
            href="//web.archive.org/web/20200220175516/https://www.google.pl/maps/dir//50.0692131,19.904867/@50.0691722,19.9048365,365m/data=!3m1!1e3!4m2!4m1!3e0"
            target="_blank"
            rel="noopener noreferrer"
            >Mapa lokalizacji Babilonu (miasteczko studenckie agh)</a
          ></span
        >
      </p>

      <hr class="my-2" />
      <h3>
        <span style="color: #02021f;"
          ><strong>Komitet organizacyjny</strong></span
        >
      </h3>
      <p>
        <span style="color: #02021f;"
          >prof. IFJ PAN dr hab. Robert Kaminski &#8211; Przedstawiciel IFJ PAN
          w CREDO<br />
          dr Władysław Chmielowski &#8211; Starosta Grupy Polskiej ZIBJ (Zjednoczonego
          Instytutu Badań Jądrowych) w Dubnie<br />
          prof. IFJ PAN dr hab. Piotr Homola &#8211; Twórca i Koordynator Projektu
          CREDO<br />
          dr Marcin Bielewicz &#8211; NCBJ &#8211; Świerk, ZIBJ (Zjednoczonego Instytutu
          Badań Jądrowych) w Dubnie<br />
          mgr Artur Skwarek &#8211; Dział Edukacji i Szkoleń NCBJ &#8211; Świerk<br
          />
          inż. Sławomir Stuglik &#8211; Webadmin projektu CREDO</span
        >
      </p>
    {:else if step == 2}
      <img
        class="xl:w-1/2 lg:w-1/2 md:w-1/2 w-3/4 block mx-auto mb-10 object-cover object-center rounded"
        alt="save img"
        src="./data/workshop2019/final2.jpg"
      />
    {:else if step == 3}
      <img
        class="xl:w-1/2 lg:w-1/2 md:w-1/2 w-3/4 block mx-auto mb-10 object-cover object-center rounded"
        alt="save img"
        src="./data/workshop2019/Plan09IX-1.png"
      />
    {:else if step == 4}
      <p style="text-align: center;">
        <span style="color: #02021f;"
          ><strong>Kontakt z organizatorami</strong></span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong
            >prof. IFJ dr hab.Robert Kamiński &#8211; <b>lider</b><br />
            e-mail:
            <span style="color: #00ccff;">rkaminski[at]ifj.edu.pl</span></strong
          ></span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong>prof. IFJ dr hab. Piotr Homola &#8211; </strong></span
        ><span style="color: #02021f;"><b>założyciel</b></span><br />
        <span style="color: #02021f;"
          ><strong
            >e-mail: <span style="color: #00ccff;"
              >Piotr.Homola[at]credo.science</span
            ></strong
          ></span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong>Administracja<br /> </strong><strong
            >e-mail: <span style="color: #00ccff;"
              >contact[at]credo.science</span
            ></strong
          ></span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong>Adres:</strong>Za [at] podstaw @</span
        >
      </p>
    {:else if step == 5}
      <span style="color: #02021f;"
        >W celu łatwiejszego dotarcia do nas polecamy skorzystanie ze strony <span
          style="color: #00ccff;"
        >
          <a
            style="color: #00ccff;"
            href="https://jakdojade.pl/krakow/trasa/z--Dworzec-G%C5%82%C3%B3wny--do--Instytut-Fizyki-J%C4%85drowej-im.-Henryka-Niewodnicza%C5%84skiego-PAN?fn=Dworzec%20G%C5%82%C3%B3wny&amp;tn=Instytut%20Fizyki%20J%C4%85drowej%20im.%20Henryka%20Niewodnicza%C5%84skiego%20PAN&amp;tc=50.089994:19.88985&amp;fc=50.06482:19.94409&amp;fsn=Dworzec%20G%C5%82%C3%B3wny&amp;ft=LOCATION_TYPE_STOP&amp;tt=LOCATION_TYPE_POI&amp;d=02.10.18&amp;h=14:29&amp;aro=1&amp;t=1&amp;rc=3&amp;ri=1&amp;r=0"
            target="_blank"
            rel="noopener noreferrer">jakdojade.pl</a
          ></span
        > możemy podać aktualne swoję położenie i zobaczyć jakimi środkami transportu
        dotrzemy do celu (Radzikowskiego 152, Strona ma także swoją aplikacje na
        telefony).</span
      >

      <span style="color: #02021f;"><strong>Szczegółowy opis</strong></span><br
      />
      <span style="color: #02021f;"
        >Współrzędne GPS głównego budynku Instytutu: 50°05&#8217;19.5&#8243;N
        19°53&#8217;22.9&#8243;E (50.088754, 19.889687)<br />
      </span><span style="color: #02021f;"
        >Współrzędne GPS bramy głownej: 50.088754, 19.889687<br />
      </span><span style="color: #02021f;"
        >Współrzędne GPS bramy od ulicy Sosnowieckiej: 50.091328, 19.889202</span
      >
      <p style="text-align: center;">
        <strong
          ><span style="color: #02021f;"
            >Dojazd z Portu Lotniczego Kraków Balice</span
          ></strong
        >
      </p>
      <p><strong><span style="color: #02021f;">TAXI</span></strong></p>
      <p><span style="color: #02021f;">Przykładowe numery:</span></p>
      <ul>
        <li><span style="color: #02021f;">ICAR +48 12 653 5555</span></li>
        <li><span style="color: #02021f;">Megataxi +48 12 19625</span></li>
        <li><span style="color: #02021f;">Barbakan +48 12 19661</span></li>
      </ul>
      <p>
        <span style="color: #02021f;"
          >Przejazd na trasie lotnisko &#8211; Instytut to koszt rzędu 36-50 PLN</span
        >
      </p>
      <p>
        <strong><span style="color: #02021f;">Komunikacja miejska</span></strong
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong>Autobus linii 208</strong> &#8211; kierunek Dworzec Główny Wschód.
          Należy wsiąść na przystanku Krakow Airport T1 i po przejechaniu 17 przystanków
          (ok. 22 min) wysiąść na przystanku Balicka Wiadukt. Stąd pieszo ul. Armii
          Krajowej do Instytutu (ok. 10 min.) lub 1 przystanek autobusami (z przystanku
          Bronowice Wiadukt): 120, 172, 173, 210, 230, 238, 248, 258, 268, 278, 572
          w kierunku Ronda Ofiar Katynia. Łączny czas podróży ok. 32 min.</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Aby dojść do przystanku Bronowice Wiadukt należy przejść na drugą
          stronę ulicy Bronowickiej i zejść schodami w dół do ulicy Armii
          Krajowej, która przebiega pod wiaduktem.</span
        >
      </p>
      <p><strong><span style="color: #02021f;">Pociąg</span></strong></p>
      <p>
        <span style="color: #02021f;"
          >Pociąg &#8211; kierunek Dworzec Główny. Należy wsiąść na przystanku
          Kraków Lotnisko/Airport i po przejechaniu około 7 min. wysiąść na
          przystanku Kraków Zakliki: to drugi przystanek na trasie. Tu trzeba
          przesiąść się na autobus linii 139 (przystanek: Mydlniki) i wysiąść na
          przystanku Balicka Wiadukt po około 7 minutach jazdy (sześć
          przystanków).</span
        ><br />
        <span style="color: #02021f;"
          >Stąd pieszo ul. Armii Krajowej do Instytutu (ok. 10 min.) lub 1
          przystanek autobusami (z przystanku Bronowice Wiadukt): 120, 172, 173,
          210, 230, 238, 248, 258, 268, 278, 572 w kierunku Ronda Ofiar Katynia.
          Łączny czas podróży ok. 30 min.</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Aby dojść do przystanku Bronowice Wiadukt należy przejść na drugą
          stronę ulicy Bronowickiej i zejść schodami w dół do ulicy Armii
          Krajowej, która przebiega pod wiaduktem.</span
        >
      </p>
      <p>
        <span style="color: #00ccff;"
          ><a
            style="color: #00ccff;"
            href="https://malopolskiekoleje.pl/index.php/trasy/wieliczka-rynek-kopalnia-krakow-glowny-krakow-lotnisko-airport"
            target="_blank"
            rel="noopener noreferrer">Rozkład jazdy pociągu i cennik</a
          ></span
        >
      </p>
      <p style="text-align: center;">
        <strong
          ><span style="color: #02021f;"
            >Dojazd z Dworca Głównego PKS/PKP Kraków Główny</span
          ></strong
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Można skorzystać z usług firm taxi wskazanych powyżej. Przejazd na
          trasie Dworzec PKP/PKS – Instytut to wydatek rzędu 20-32 PLN.</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          >Alternatywnym sposobem dotarcia do Instytutu jest skorzystanie z
          publicznego transportu. Bilet godzinny to wydatek 6 PLN.</span
        >
      </p>
      <p><strong><span style="color: #02021f;">Tramwaje</span></strong></p>
      <p>
        <span style="color: #02021f;"
          >Z powodu remontów dojazd tramwajami nie jest możliwy. Kursują zamiast
          tramwaju autobusy.<br />
          <strong>Autobus zastępczy linii 704 </strong>&#8211; z przystanku
          Stary Kleparz jedziemy do przystanku Bronowice Wiadukt, następnie
          schodzimy schodami z mostu (na którym był przystanek) i idziemy wzdłuż
          ul. Armii Krajowej do Instytutu (ok. 10 min.).</span
        >
      </p>
      <p><strong><span style="color: #02021f;">Autobusy</span></strong></p>
      <p>
        <span style="color: #02021f;"
          ><strong>Autobus linii 501</strong> &#8211; z przystanku Politechnika (ul.
          Pawia, wyjście z Galerii Krakowskiej) &#8211; należy wysiąść po 9 przystankach
          (ok. 20 min) na przystanku Rondo Ofiar Katynia.</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong>Autobus linii 208</strong> &#8211; z przystanku Dworzec Główny
          Wschód jadący w kierunku portu lotniczego Balice Krakow Airport T1 &#8211;
          należy przejechać 11 przystanków (ok. 20 min) i wysiąść na przystanku Balicka
          Wiadukt. Stąd pieszo ul. Armii Krajowej do Instytutu (ok. 10 min.) lub
          1 przystanek autobusami (z przystanku Bronowice Wiadukt): 120, 172, 173,
          210, 230, 238, 248, 258, 268, 278, 572 w kierunku Ronda Ofiar Katynia.
          Łączny czas podróży ok. 30min.</span
        >
      </p>
      <p>
        <span style="color: #02021f;"
          ><strong>Autobus linii 130</strong> &#8211; z przystanku Dworzec
          Główny Zachód – ul. Pawia jadący w kierunku Azorów – po dwóch
          przystankach (ok. 5 min) należy wysiąść na przystanku Nowy Kleparz –
          przejść pieszo (ok. 7 min) na przystanek
          <strong>autobusu linii 139</strong> Nowy Kleparz/Al. Słowackiego i stamtąd
          jadąc 10 przystanków (17 min.) w kierunku Mydlniki; wysiąść na przystanku
          Bronowice Wiadukt. Stąd pieszo ul. Armii Krajowej do Instytutu (ok. 10
          min.) lub 1 przystanek autobusami (z tego samego przystanku): 120, 172,
          173, 210, 230, 238, 248, 258, 268, 278, 572 w kierunku Ronda Ofiar Katynia.
          Łączny czas ok. 40min.</span
        >
      </p>
      <p>
        <span style="color: #00ccff;"
          ><a
            style="color: #00ccff;"
            href="https://www.mpk.krakow.pl/pl/mapki-komunikacyjne/"
            target="_blank"
            rel="noopener noreferrer">Mapy komunikacyjne Krakowa</a
          ></span
        >
      </p>
      <p>
        <span style="color: #00ccff;"
          ><a
            style="color: #00ccff;"
            href="https://www.mpk.krakow.pl/en/page-70cdbe44/"
            target="_blank"
            rel="noopener noreferrer">Rozkłady jazdy MPK S.A. w Krakowie</a
          ></span
        >
      </p>
    {:else if step == 6}
      <span style="color: #000000;">
        By otrzymać pisemne potwierdzenie uczestnictwa dla szkół prosimy o
        przesyłanie na contact[at]credo.science następujących danych</span
      >
      <ul>
        <li>
          <span style="color: #000000;"
            >imię i nazwisko (nauczyciela, przedstawiciela szkoły)</span
          >
        </li>
        <li><span style="color: #000000;">liczba uczniów</span></li>
        <li>
          <span style="color: #000000;"
            >adres mailowy na który zostanie wysłane potwierdzenie (np. własny
            lub sekretariatu szkoły)</span
          >
        </li>
      </ul>
    {:else if step == 7}
      <head>
        <!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css"> -->
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <!-- <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="" crossorigin="anonymous"> -->
      </head>
      <style>
        th {
          padding: 15px;
          text-align: left;
          color: #000000;
          border: solid 1px rgb(0, 0, 0);
          text-transform: uppercase;
        }
        td {
          padding: 15px;
          text-align: left;
          color: #000000;
          border: solid 1px rgb(0, 0, 0);
        }

        .fa-file-pdf-o {
          font-size: 24px;
          color: red;
        }

        .fa-download {
          font-size: 24px;
          color: green;
        }
        .nieaktywny {
          color: grey !important;
        }
      </style>

      <h1>Plan wykładów</h1>
      <div class="tbl-header">
        <table cellpadding="0" cellspacing="0" border="0">
          <thead>
            <tr>
              <th style="width: 25%;"> IMIĘ I NAZWISKO</th>
              <th style="width: 55%;">TYTUŁ PREZENTACJI</th>
              <th style="width: 10%;">PODGLĄD</th>
              <th style="width: 10%;">POBRANIE</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td style="width: 25%;">Robert Kamiński</td>
              <td style="width: 55%;">Cząstki elementarne</td>
              <td style="width: 10%;"
                ><a
                  href="./data/workshop2019/TalkCREDOWorkshop2019.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td style="width: 10%;"
                ><a
                  href="./data/workshop2019/TalkCREDOWorkshop2019.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Henryk Wilczyński</td>
              <td>Promieniowanie kosmiczne</td>
              <td
                ><a
                  href="./data/workshop2019/2.-Wilczynski-CREDO12019-1.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/2.-Wilczynski-CREDO12019-1.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Piotr Homola</td>
              <td>Sedno CREDO</td>
              <td><i class="fa fa-file-pdf-o nieaktywny" /></td>
              <td><i class="fa fa-download nieaktywny" /></td>
            </tr>
            <tr>
              <td>Tadeusz Wibig</td>
              <td>O pękach</td>
              <td
                ><a
                  href="/data/workshop2019/T.Wibig/T.Wibig%20-%20opekach_serio.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="/data/workshop2019/T.Wibig/T.Wibig%20-%20opekach_serio.pptx"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Tadeusz Wibig</td>
              <td
                >Rozkład kątów kierunków przychodzenia mionów obserwowanych w
                telefonach CREDO
              </td>
              <td
                ><a
                  href="/data/workshop2019/T.Wibig/T.Wibig%20-%20rozklad_katowy.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="/data/workshop2019/T.Wibig/T.Wibig%20-%20rozklad_katowy.pptx"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Tadeusz Wibig</td>
              <td>Definicja śladów mionów w CREDO dla kamery PC</td>
              <td
                ><a
                  href="/data/workshop2019/T.Wibig/T.Wibig%20-%20obrazy_w_kamerze_PC.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="/data/workshop2019/T.Wibig/T.Wibig%20-%20obrazy_w_kamerze_PC.pptx"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Tadeusz Wibig</td>
              <td
                >Jak często widać ślady mionów w telefonach CREDO (Średni czas)</td
              >
              <td
                ><a
                  href="/data/workshop2019/T.Wibig/T.Wibig%20-%20sredni%20czas.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="/data/workshop2019/T.Wibig/T.Wibig%20-%20sredni%20czas.pttx"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Tadeusz Wibig</td>
              <td>Cząstki pękowe</td>
              <td
                ><a
                  href="https://onedrive.live.com/view.aspx?resid=829176A82D83DBB1!282&amp;ithint=file%2cpptx&amp;authkey=!AuXq3Xl0uXqlDP0"
                  target="_blank"
                  rel="noreferrer"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a href="./data/workshop2019/czastki_pekowe.pptx" download
                  ><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Michał Niedźwiecki</td>
              <td>Ślady na matrycach (czwartek)</td>
              <td
                ><a
                  href="./data/workshop2019/CREDO-czwartek.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a href="./data/workshop2019/CREDO-czwartek.pdf" download
                  ><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Marcin Bielewicz</td>
              <td>„NICA – projekt i detektor promieniowania kosmicznego”</td>
              <td
                ><a
                  href="./data/workshop2019/CREDO_NICA_MCORD-09_2019.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/CREDO_NICA_MCORD-09_2019.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Konrad Kopański</td>
              <td>Budowa detektora i zastosowanie</td>
              <td><i class="fa fa-file-pdf-o nieaktywny" /></td>
              <td><i class="fa fa-download nieaktywny" /></td>
            </tr>
            <tr>
              <td> Piotr Homola</td>
              <td> CREDO projekt dla wszystkich i o wszystkim</td>
              <td><i class="fa fa-file-pdf-o nieaktywny" /></td>
              <td><i class="fa fa-download nieaktywny" /></td>
            </tr>
            <tr>
              <td>Henryk Wilczyński</td>
              <td>Promieniowanie kosmiczne</td>
              <td
                ><a
                  href="./data/workshop2019/Wilczynski-CREDO2019-2.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/Wilczynski-CREDO2019-2.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Organizatorzy</td>
              <td> Gala Łowców Cząstek</td>
              <td
                ><a
                  href="./data/workshop2019/prezentacja-na-galę.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a href="./data/workshop2019/prezentacja-na-galę.pdf" download
                  ><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Władysław Chmielowski</td>
              <td> Dubna i udział polskiej strony w pracach w ZIBJ</td>
              <td
                ><a
                  href="./data/workshop2019/CREDO_NICA_MCORD-09_2019.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/CREDO_NICA_MCORD-09_2019.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Robert Kamiński</td>
              <td> Czarne dziury, gwiazdy neutronowe i fale grawitacyjne</td>
              <td><i class="fa fa-file-pdf-o nieaktywny" /></td>
              <td
                ><a
                  href="https://www.dropbox.com/s/2bcle1376ujuq2c/R.Kami%C5%84ski%20-%20CREDO%20Workshop.pptx?dl=1"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td>Marcin Bielewicz</td>
              <td
                >„NICA – projekt i detektor promieniowania kosmicznego, short”</td
              >
              <td
                ><a
                  href="./data/workshop2019/CREDO_NICA_MCORD-09_2019_short.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/CREDO_NICA_MCORD-09_2019_short.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Szymon Ryszkowski</td>
              <td> Zasada nieoznaczoności w szkole i Almukantarat,</td>
              <td
                ><a
                  href="./data/workshop2019/SzymonRyszkowski_Almukantarat_Czastki_Wirtualne.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/SzymonRyszkowski_Almukantarat_Czastki_Wirtualne.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Michał Witek i Dawid Barteczko</td>
              <td> CREDO i astroedukacja - szansa na program krajowy</td>
              <td
                ><a
                  href="./data/workshop2019/CREDO-i-astroedukacja.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/CREDO-i-astroedukacja.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Jerzy Mietelski</td>
              <td> Promieniowanie dla ludzkiego organizmu</td>
              <td
                ><a
                  href="./data/workshop2019/7.-Mietelskiredo-2019-promieniowanie-i-ludzki-organizm.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/7.-Mietelskiredo-2019-promieniowanie-i-ludzki-organizm.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Krzysztof Gorzkiewicz</td>
              <td>
                Detekcja mionów w osłonie spektrometru promieniowania gamma</td
              >
              <td
                ><a
                  href="./data/workshop2019/CREDO_prez_KG_V2.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a href="./data/workshop2019/CREDO_prez_KG_V2.pdf" download
                  ><i class="fa fa-download" /></a
                ></td
              >
            </tr>

            <tr>
              <td> Łukasz Bibrzycki, Dariusz Burakowski, Marcin Piekarczyk</td>
              <td>
                Nowe podejście do rejestracji promieniowania przy pomocy
                urządzeń mobilnych
              </td>
              <td
                ><a
                  href="./data/workshop2019/UP-CREDO-workshop-2019-rev.pdf"
                  target="_blank"><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a
                  href="./data/workshop2019/UP-CREDO-workshop-2019-rev.pdf"
                  download><i class="fa fa-download" /></a
                ></td
              >
            </tr>

            <tr>
              <td> Konrad Kopański</td>
              <td> Projekt Bajkał </td>
              <td><i class="fa fa-file-pdf-o nieaktywny" /></td>
              <td><i class="fa fa-download nieaktywny" /></td>
            </tr>
            <tr>
              <td> Michał Niedźwiecki</td>
              <td> Ślady na matrycach </td>
              <td
                ><a href="./data/workshop2019/CREDO-piątek.pdf" target="_blank"
                  ><i class="fa fa-file-pdf-o" /></a
                ></td
              >
              <td
                ><a href="./data/workshop2019/CREDO-piątek.pdf" download
                  ><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Sławomir Stuglik</td>
              <td> Interfejs użytkownika CREDO – co warto wiedzieć?</td>
              <td><i class="fa fa-file-pdf-o nieaktywny" /></td>
              <td
                ><a href="./data/workshop2019/interfejsCREDO.pdf" download
                  ><i class="fa fa-download" /></a
                ></td
              >
            </tr>
            <tr>
              <td> Marcin Bielewicz, Konrad Kopański</td>
              <td>
                Warsztaty z pracy z detektorami dla nauczycieli i uczniów</td
              >
              <td><i class="fa fa-file-pdf-o nieaktywny" /></td>
              <td><i class="fa fa-download nieaktywny" /></td>
            </tr>
            <tr>
              <td> Organizatorzy</td>
              <td> Zakończenie i wstępne podsumowanie warsztatów</td>
              <td><i class="fa fa-file-pdf-o nieaktywny" /></td>
              <td><i class="fa fa-download nieaktywny" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    {/if}
  </div>
</section>
