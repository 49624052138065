<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO on EurekAlert
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/newka.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >Are astrophysical phenomena occurring millions or even billions of light
      years from Earth responsible for some diseases? Does dark matter really
      exist? What is the true nature of our spacetime – is it continuous or
      digital? Can the exotic effects of quantum gravity be tested
      experimentally?Can the exotic effects of quantum gravity be tested
      experimentally? Install the CREDO Detector app, become part of the largest
      particle detector in history and help unveil the fundamental secrets of
      the Universe!
      <p style="text-align: justify;">
        <img
          class="size-medium aligncenter"
          src="./images/new2-300x300.jpg"
          alt="particle img"
          width="300"
          height="225"
        />
      </p>

      The article on<span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.eurekalert.org/news-releases/690604"
          target="_blank"
          rel="noreferrer">EurekAlert</a
        >
      </span>.<br />

      Polish version: Source versions on
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://press.ifj.edu.pl/news/2018/06/12/"
          target="_blank"
          rel="noreferrer">IFJ PAN Press Office</a
        >
      </span>,<br />

      Other versions:<span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://naukawpolsce.pap.pl/aktualnosci/news%2C29847%2Ccredo-twoj-smartfon-moze-stac-sie-czescia-globalnego-detektora-czastek.html"
          target="_blank"
          rel="noreferrer">Polish Press Agency</a
        >
      </span>,
    </span>
  </div>
</div>
