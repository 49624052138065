<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO at the Małopolska Scientists Night at the IFJ PAN
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <p class="text-justify">
      On the last Friday of September (29.09.2023), the CREDO team participated
      in the Małopolska Scientists' Night event where various detectors were
      presented in one of the tents behind the main building of the Institute of
      Nuclear Physics of the Polish Academy of Sciences in Krakow: Smartfons
      with CREDO Detector App, Advacam MiniPixEdu and scintillator detectors
      created by PhD student Jerzy Pryga. The team also presented the latest
      results from a recent publication on the topic of the correlation of
      Cosmic Radiation with Earthquakes
      <br /><br />
      We would like to thank everyone interested in the topic of cosmic radiation
      for the talk and the time we spent together.
    </p>
    <div class="flex flex-wrap m-4 mx-auto w-4/5">
      <img src="./images/posts/mnn23/1.jpg" alt="images1" class=" w-1/2" />
      <img src="./images/posts/mnn23/2.jpg" alt="images2" class=" w-1/2" />
      <img src="./images/posts/mnn23/3.jpg" alt="images3" class=" w-2/5" />
      <img src="./images/posts/mnn23/4.jpg" alt="images4" class=" w-3/5" />
    </div>
  </div>
</div>
