export let descriptionAbout = {
  part1: [
    "Information about CREDO Summer Camp",
    "Informacje o CREDO Summer Camp",
  ],
  part2: [
    "CREDO Summer Camp, will take place from 12-14 August in the the town of ",
    "Obóz letni CREDO Summer Camp, odbędzie się w dniach 12-14 sierpnia w miejscowości ",
  ],
  part2b: ["in the centre", "w ośrodku"],
  part3: [
    "During the camp we will focus on the topic of CREDO, science popularisation, cosmic radiation and astronomy. It will be an excellent opportunity to expand your knowledge, exchange experiences and make new friends among people with similar interests.",
    "Podczas obozu skupimy się na temacie CREDO, popularyzacji nauki, promieniowaniu kosmicznym i astronomii. Będzie to doskonała okazja do poszerzenia wiedzy, wymiany doświadczeń i nawiązania nowych znajomości wśród osób o podobnych zainteresowaniach.",
  ],
  part4: [
    "We are planning a series of lectures, workshops, astronomical observations and other activities that are sure to provide you with many unforgettable experiences. It will also be a time for joint reflection on opportunities for further cooperation and development of the CREDO project.",
    "Planujemy szereg wykładów, warsztatów, obserwacji astronomicznych oraz innych atrakcji, które z pewnością dostarczą Państwu wielu niezapomnianych wrażeń. Będzie to również czas na wspólną refleksję nad możliwościami dalszej współpracy i rozwoju projektu CREDO.",
  ],
  part5: [
    "The CREDO Summer Camp is organised by a Visegrad grant implemented by the Institute of Nuclear Physics of the Polish Academy of Sciences.",
    "Organizatorem Obozu letniego CREDO jest grant Wyszechradzki  realizowany przez Instytut Fizyki Jądrowej Polskiej Akademii Nauk.",
  ],
};
export let descriptionContact = {
  part1: [
    "If you have any questions, would like to contact the organisers, please send a message to both addresses:",
    "W przypadku jakichkolwiek pytań, chęci kontaktu z organizatorami prosimy o wiadomość na oba adresy:",
  ],
};

export let descriptionHeader = {
  part1: ["Information", "Informacje"],
  part2: ["Plan of events", "Plan wydarzeń"],
  part3: ["Remote participation", "Uczestnictwo zdalne"],
  part4: ["Contact", "Kontakt"],
};

export let descriptionPlan = {
  part0: [
    "At other times: elective activities according to the Amulkantarat schedule.",
    "W pozostałych godzinach: zajęcia obieralne wg planu Amulkantaratu.",
  ],
  part1: [
    "Timetable for Monday - 12.08.2024",
    "Plan zajęć na Poniedziałek - 12.08.2024",
  ],
  part2: [
    "Timetable for Tuesday - 13.08.2024",
    "Plan zajęć na Wtorek - 13.08.2024",
  ],
  part3: [
    "Timetable for Wednesday - 14.08.2024",
    "Plan zajęć na Środa - 14.08.2024",
  ],
  dr: ["Ph.D.", "Dr"],
};

export let descriptionPlanTable = {
  LP: ["L.P", "L.P"],
  Od: ["From (time)", "Od (godzina)"],
  Do: ["Until (time)", "Do (godzina)"],
  Temat: ["Topic", "Temat"],
  Opis: ["Description", "Opis"],
  Materiały: ["Materials", "Materiały"],
};

export let descriptionZoom = {
  part1: [
    "Videoconference is available via the following Zoom link:",
    "Wideokonferencja jest dostępna za pośrednictwem poniższego łącza Zoom:",
  ],
};
