<div class="container px-5 py-24 mx-auto">
  <div class="flex flex-col text-center w-full mb-20">
    <h1 class="text-9xl font-medium title-font mb-20 italic text-blue-800">
      101
    </h1>
    <p class="uppercase font-bold text-2xl">
      Oops! The website is not finished yet
    </p>
  </div>
</div>
