<script>
  let home_path = "#/about/team/credo-cs/";

  function pathes(subpage) {
    return home_path + subpage;
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-CS Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href={pathes("")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >About</a
      >
      <a
        href={pathes("research")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      ><strong>
        <a
          href={pathes("people")}
          class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
          >People</a
        ></strong
      >
      <!-- <a
        href={pathes("activites")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Activites</a
      > -->
      <a
        href={pathes("publications")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href={pathes("contact")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-5">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-4 text-red-900"
      >
        <b>Principal Investigators</b>
      </h1>
    </div>
    <div
      class="w-6/7 lg:w-4/5 items-center text-center flex flex-wrap mx-auto text-xl font-serif"
    >
      <!-- Marcin -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/MarcinBielewicz.jpg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Marcin Bielewicz, Ph.D. / Assoc. Prof.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:marcin.bielewicz[at]ncbj.gov.pl"
                target="_blank"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>marcin.bielewicz[at]ncbj.gov.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://orcid.org/0000-0001-8267-4874"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://www.researchgate.net/profile/M-Bielewicz"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <span class="text-white inline-flex items-center justify-center"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>Homepage</span
              ><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Noemi -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/Noemi_Zabari.jpg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Noemi Zabari, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">
              Co-Lead of the group & CEO of Astrotectonic
            </h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:noemi[at]astrotectonic.com"
                target="_blank"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>noemi[at]astrotectonic.com
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://orcid.org/0000-0001-6835-3687"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://www.researchgate.net/profile/Noemi-Zabari"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://pl.wikipedia.org/wiki/Noemi_Zabari"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>wikipedia
                </span>
              </a>
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://astrotectonic.com/team/noemi-zabari/"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>astrotectonic</span
                >
              </a><br />
            </p>
          </div>
        </div>
      </div>
      <!-- David Edwin Alvarez Castillo  -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/David-Alvarez-Castillo.jpg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              David Edwin Alvarez Castillo, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">
              Co-Lead of the group & Assistant professor
            </h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:dalvarez[at]ifj.edu.pl"
                target="_blank"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>dalvarez[at]ifj.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://orcid.org/0000-0003-1874-8116"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://www.researchgate.net/profile/David-Alvarez-Castillo"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <span class="text-white inline-flex items-center justify-center"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>Homepage</span
              ><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Ophir Ruimi  -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/Ophir_ruimy.jpg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Ophir Ruimi, M.Sc. / Ph.D. student
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Junior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:ophir.ruimi[at]mail.huji.ac.il"
                target="_blank"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>ophir.ruimi[at]mail.huji.ac.il
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://orcid.org/0000-0001-7376-670X"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="https://www.researchgate.net/profile/Ophir_Ruimi"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <span class="text-white inline-flex items-center justify-center"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>Homepage</span
              ><br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col text-center w-full mb-5 pt-8">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-4 text-red-900"
      >
        <b>Associates Researchers</b>
      </h1>
    </div>

    <div
      class="w-6/7 lg:w-4/5 text-center flex flex-wrap mx-auto text-xl font-serif"
    >
      <!-- Antonio Napolitano -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Antonio Napolitano, Ph.D. / Full Prof.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:	antonio.napolitano[at]uniparthenope.it"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>

                  antonio.napolitano[at]uniparthenope.it
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Guy Ron -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/guy_ron.jpg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Guy Ron Ph.D. / Full Prof.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:	guy.ron2[at]mail.huji.ac.il"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  guy.ron2[at]mail.huji.ac.il
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Łukasz Bratek -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Łukasz Bratek, Ph.D. / Assoc. Prof.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto: lukasz.bratek[at]pk.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  lukasz.bratek[at]pk.edu.pl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Tomasz H  -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/hachaj.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Tomasz Hachaj, Ph.D. D.Sc / ASSOC. PROF.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:tomasz.hachaj[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>tomasz.hachaj[at]agh.edu.pl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Piotr Homola -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/phomola.jpg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Piotr Homola, Ph.D. / Assoc. Prof.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:Piotr.Homola[at]ifj.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>

                  Piotr.Homola[at]ifj.edu.pl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Joanna Jałocha -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Joanna Jałocha, Ph.D. / Assoc. Prof.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto: jjalocha-bratek[at]pk.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  jjalocha-bratek[at]pk.edu.pl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Jaroslaw Stasielak -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Jarosław Stasielak, Ph.D. / Assoc. Prof.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:	jaroslaw.stasielak[at]ifj.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>jaroslaw.stasielak[at]ifj.edu.pl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Łukasz -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Bibrzycki.jpeg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Łukasz Bibrzycki, Ph.D. Eng.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:lukasz.bibrzycki[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>lukasz.bibrzycki[at]agh.edu.pl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Olaf  -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Bar.jpeg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Olaf Bar, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:olaf.bar[at]pk.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>olaf.bar[at]pk.edu.pl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Péter Kovács -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Péter Kovács, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:kovacs.peter[at]wigner.hu"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>

                  kovacs.peter[at]wigner.hu
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Marcin Piekarczyk-->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Piekarczyk.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Marcin Piekarczyk, Ph.D. Eng.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:marcin.piekarczyk[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>marcin.piekarczyk[at]agh.edu.pl
                </span></a
              ><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Cristina Oancea -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Cristina Oancea, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:cristina.oancea[at]advacam.cz"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>

                  cristina.oancea[at]advacam.cz
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Karel Smolek -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Karel Smolek, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:karel.smolek[at]utef.cvut.cz"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg> karel.smolek[at]utef.cvut.cz
                </span></a
              >
            </p>
          </div>
        </div>
      </div>

      <!-- Arman Tursunov -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/Arman-Tursunov.jpg"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Arman Tursunov, Ph.D. D.Sc.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:arman.tursunov[at]physics.slu.cz"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  arman.tursunov[at]physics.slu.cz
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Jilberto Zamora Saa -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Jilberto Zamora Saa, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Senior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:	jilberto.zamora[at]unab.cl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>jilberto.zamora[at]unab.cl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- Kevin Almeida Cheminant -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/credo-cs/no-img.png"
          />
          <div class="w-full">
            <h2 class="title-font uppercase tracking-wider text-gray-900">
              Kevin Almeida Cheminant, PH.D. / Postdoc
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider">Junior Researcher</h3>
            <p class="mb-4 tracking-wider">
              <a
                class="text-blue-900 tracking-wider hover:text-blue-500"
                href="mailto:	kevin.almeida-cheminant[at]ifj.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>kevin.almeida-cheminant[at]ifj.edu.pl
                </span></a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
