<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO in RMF and on naukawpolsce.pap.pl
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/rmf-570x350.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >About the events related to CREDO from the last days are written by two
      community portals: RMF and science in the Poland.
      <br />
      On RMF:An exclusive interview (in Polish) on the CREDO QGP first light was
      given by Piotr Homola for the leading Polish radio, RMF FM.
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.rmf24.pl/nauka/news-co-twoj-smartfon-moze-robic-w-nocy-podgladac-promieniowanie-,nId,2641227#crp_state=1"
          target="_blank"
          rel="noreferrer">See their post with the unauthorized text</a
        >
      </span>.
      <br />
      Read inform about the first light from the CREDO QGP on

      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://naukawpolsce.pap.pl/aktualnosci/news,31280,krakow-zaprezentowano-pierwsze-dane-z-detektora-czastek-credo.html"
          target="_blank"
          rel="noreferrer">naukawpolsce.pap.pl</a
        >
      </span>,<br />Articles only in Polish language.
    </span>
  </div>
</div>
