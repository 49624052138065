<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    A new peer-reviewed article "for CREDO"
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/posts/mdpi_2022_09_0.png" alt="images" width="400" />
  </div>
  <p class="justify-start">
    Cosmic rays interact with fields and background radiation as they propagate
    in space, producing particle cascades of various sizes, shapes and
    constituents. The potential observation of at least parts of such phenomena,
    referred to as cosmic-ray ensembles (CRE), from Earth would open a new
    channel of cosmic-ray investigation, since it might be a manifestation of
    fundamental symmetries of nature. Research dedicated to CRE is one of the
    main scientific objectives of the CREDO Collaboration, and with this article
    we address one of the cornerstones of the relevant scientific program: the
    simulation method dedicated to CRE studies. Here we focus on CRE resulting
    from synchrotron radiation by high energy electrons as one of the most
    prevalent energy loss processes. Providing the example of simulation output
    analysis, we demonstrate the advantages of our approach as well as discuss
    the possibility of generalization of current research.
    <br />
    If you are interested in this topic then read more:
    <a class="text-blue-600" href="https://doi.org/10.3390/sym14101961"
      >https://doi.org/10.3390/sym14101961</a
    >.
  </p>
</div>
