<script>
  let step = 1;
  let activeButton = "btn1";

  async function goToStep(value) {
    step = value;

    let btn = document.getElementById(activeButton);
    btn.classList.remove("border-red-700");
    btn.classList.remove("text-red-700");
    btn.classList.remove("bg-black");

    btn = document.getElementById("btn" + value);
    btn.classList.add("border-red-700");
    btn.classList.add("text-red-700");
    btn.classList.add("bg-black");
    activeButton = "btn" + value;
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-ML Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/about/team/credo-ml"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >About</a
      >
      <a
        href="#/about/team/credo-ml/research"
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>Research</strong></a
      >
      <a
        href="#/about/team/credo-ml/people"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <a
        href="#/about/team/credo-ml/activites"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Activites</a
      >
      <a
        href="#/about/team/credo-ml/publications"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href="#/about/team/credo-ml/contact"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto font-serif">
    <div class="flex flex-col text-center w-full ">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b>Current scientific topics</b>
      </h1>
    </div>

    <div class="container px-5 mx-auto flex flex-col max-h-max">
      <div class="flex mx-auto flex-wrap mb-5">
        <button
          id="btn1"
          on:click|preventDefault={() => goToStep(1)}
          class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium  items-center leading-none  tracking-wider rounded-t border-red-700 text-red-700 bg-black"
        >
          <img
            alt="testimonial"
            class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20  hover:opacity-70"
            src="./images/team/imgfiltr.png"
          />
          <strong
            ><p class="mt-2 uppercase tracking-wider font-serif ">
              Advanced<br />image processing
            </p></strong
          >
        </button>
        <button
          id="btn2"
          on:click|preventDefault={() => goToStep(2)}
          class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium  items-center leading-none  tracking-wider rounded-t"
        >
          <img
            alt="testimonial"
            class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20  hover:opacity-70"
            src="./images/team/sym.png"
          />
          <strong
            ><p class="mt-2 uppercase tracking-wider font-serif">
              Cosmic ray<br />simulations
            </p></strong
          >
        </button>
        <button
          id="btn3"
          on:click|preventDefault={() => goToStep(3)}
          class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium  items-center leading-none  tracking-wider rounded-t"
          ><img
            alt="testimonial"
            class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20  hover:opacity-70"
            src="./images/team/ml.png"
          />
          <strong
            ><p class="mt-2 uppercase tracking-wider font-serif">
              Detection of<br /> Cosmic Ray assemblies
            </p></strong
          >
        </button>
        <button
          id="btn4"
          on:click|preventDefault={() => goToStep(4)}
          class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium  items-center leading-none  tracking-wider rounded-t"
        >
          <img
            alt="testimonial"
            class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20  hover:opacity-70"
            src="./images/team/anom.png"
          />
          <strong
            ><p class="mt-2 uppercase tracking-wider font-serif">
              Anomaly<br />detection
            </p></strong
          >
        </button>
        <button
          id="btn5"
          on:click|preventDefault={() => goToStep(5)}
          class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium  items-center leading-none  tracking-wider rounded-t"
          ><img
            alt="testimonial"
            class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20  hover:opacity-70"
            src="./images/team/others.png"
          />
          <strong
            ><p class="mt-2 uppercase tracking-wider font-serif">
              Detector particle<br /> analysis
            </p></strong
          >
        </button>
      </div>
    </div>
    {#if step == 1}
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p class="leading-relaxed mb-4">
          Although the topic of analyzing images, distinguishing them from each
          other and dividing them into groups has been known for a long time,
          there is still no solution that would work in all areas. In detection
          image recognition using CCD/CMOS sensors, there are additional
          problems such as: small image size (60x60 pixels), different sources
          so different image quality, background. In this topic, we test
          different analysis approaches to see how a method performs in our
          case.
        </p>
      </div>
    {:else if step == 2}
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          Creating different simulation models about cosmic rays, what the
          theoretical way of hitting areas of Earth looks like. It will allow us
          to check, among other things, how big the detector elements should be
          and how densely you need to place the detectors (smartphones) in a
          given area to get satisfactory results.
        </p>
      </div>
    {:else if step == 3}
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          We use various "Machine Learning" methods for better performance and
          results. To this purpose, we create and test many proposals for
          auto-encoders or shape comparison metrics to increase accuracy in
          particle type recognition based on shape. In our analyses, we always
          use a training set (more than 13,000 images), which is very carefully
          and manually prepared by us.
        </p>
      </div>
    {:else if step == 4}
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          Our work also focuses on finding and identifying various anomalies
          that appear in the ever-growing collection of CR detections.
        </p>
      </div>
    {:else if step == 5}
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          One of the main tasks of the CREDO project is to analyze data from
          different detectors. The CREDO-ML Team is also actively involved in
          this, mainly analyzing data from mobile devices (Android, IOS).
        </p>
      </div>
    {/if}
  </div>
</section>
