<script>
  let home_path = "#/about/team/credo-cs/";

  function pathes(subpage) {
    return home_path + subpage;
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-CS Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href={pathes("")}
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>About</strong></a
      >
      <a
        href={pathes("people")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <a
        href={pathes("research")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      >
      <!-- <a
          href={pathes("activites")}
          class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
          >Activites</a
        > -->
      <a
        href={pathes("publications")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href={pathes("contact")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="sm:text-3xl text-3xl uppercase tracking-wider mb-4 text-red-900 "
      >
        <b>Cosmo-Seismic Task</b>
      </h1>
      <p
        class="lg:w-3/4 mx-auto leading-relaxed text-2xl text-justify  font-serif"
      >
        <b
          >The global scale cosmic ray research has recently been focused on the
          search for so-called cosmo-seismic correlations that are correlations
          between cosmic ray rates and seismic activity.</b
        >
        <br /><br />
        <strong>What is the concept:</strong>
        <br />
        Zwykły tekst
        <br /><br />
      </p>
    </div>
  </div>
</section>
