<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Rekrutacja/pokazy CREDO [12-13.05.2021]
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/credo-12_13.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000;"
      >Już jutro i w czwartek Transmisja pokazów popularnonaukowych "<span
        style="color: #0000ff;"
      >
        <a href="https://www.facebook.com/credo.science/">CREDO Science</a
        ></span
      >
      i promieniowanie kosmiczne"
      <span
        class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
        ><img
          src="https://static.xx.fbcdn.net/images/emoji.php/v9/t58/1/16/1f929.png"
          alt="🤩"
          width="16"
          height="16"
        /></span
      >
      <br />
      Wybierzcie pokaz, który najbardziej Wam odpowiada
      <br />
      <strong
        ><span
          class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
          ><img
            src="https://static.xx.fbcdn.net/images/emoji.php/v9/te4/1/16/270f.png"
            alt="✏️"
            width="16"
            height="16"
          /><br /></span
        > 12 maja 2021 roku:</strong
      >
      <br />
      <span
        class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
        ><img
          src="https://static.xx.fbcdn.net/images/emoji.php/v9/t51/1/16/1f449.png"
          alt="👉"
          width="16"
          height="16"
        /></span
      >
      godzina 12:00 – dla szkół średnich
      <span style="color: #0000ff;"
        ><a
          href="https://youtu.be/6bf09cnNPvA"
          target="_blank"
          rel="nofollow noopener noreferrer">youtu.be/6bf09cnNPvA</a
        ></span
      >
      <br />
      <span style="color: #0000ff;"
        ><span
          class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
          ><img
            src="https://static.xx.fbcdn.net/images/emoji.php/v9/t51/1/16/1f449.png"
            alt="👉"
            width="16"
            height="16"
          /></span
        ></span
      >
      godzina 18:00 - dla dorosłych
      <span style="color: #0000ff;"
        ><a
          href="https://youtu.be/pDPgwCDkP7U"
          target="_blank"
          rel="nofollow noopener noreferrer">youtu.be/pDPgwCDkP7U</a
        ></span
      >
      <br />
      <strong
        ><span
          class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
          ><img
            src="https://static.xx.fbcdn.net/images/emoji.php/v9/te4/1/16/270f.png"
            alt="✏️"
            width="16"
            height="16"
          /></span
        > 13 maja 2021 roku:</strong
      >
      <br />
      <span
        class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
        ><img
          src="https://static.xx.fbcdn.net/images/emoji.php/v9/t51/1/16/1f449.png"
          alt="👉"
          width="16"
          height="16"
        /></span
      >
      godzina 10:00 - dla szkół podstawowych
      <span style="color: #0000ff;"
        ><a
          href="https://youtu.be/H6PiEUCrW5o"
          target="_blank"
          rel="nofollow noopener noreferrer">youtu.be/H6PiEUCrW5o</a
        ></span
      >
      <br />
      <span
        class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
        ><img
          src="https://static.xx.fbcdn.net/images/emoji.php/v9/t51/1/16/1f449.png"
          alt="👉"
          width="16"
          height="16"
        /></span
      >
      godzina 12:00 – dla szkół średnich
      <span style="color: #0000ff;"
        ><a
          href="https://youtu.be/1Xve4p_612s"
          target="_blank"
          rel="nofollow noopener noreferrer">youtu.be/1Xve4p_612s</a
        ></span
      >
      <br />
      <span
        class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
        ><img
          src="https://static.xx.fbcdn.net/images/emoji.php/v9/t51/1/16/1f449.png"
          alt="👉"
          width="16"
          height="16"
        /></span
      >
      godzina 18:00 - dla rodziców z dziećmi
      <span style="color: #0000ff;"
        ><a
          href="https://youtu.be/z8-Ri58cXM4"
          target="_blank"
          rel="nofollow noopener noreferrer">youtu.be/z8-Ri58cXM4</a
        ></span
      >
      <br />
      Zapraszamy<span
        class="pq6dq46d tbxw36s4 knj5qynh kvgmc6g5 ditlmg2l oygrvhab nvdbi5me sf5mxxl7 gl3lb2sf hhz5lgdu"
        ><img
          src="https://static.xx.fbcdn.net/images/emoji.php/v9/t77/1/16/203c.png"
          alt="‼️"
          width="16"
          height="16"
        /></span
      >
    </span>
  </div>
</div>
