<script context="module">
  export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  export function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  export function deleteCookie(name) {
    const date = new Date();
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
  }

  export function getBrowserDetails() {
    let browserName = "",
      browserVersion = 0;
    let navUsrAg = navigator.userAgent,
      browserShortName = "";

    // The order matters here, and this may report false positives for unlisted browsers.
    if (navUsrAg.includes("Firefox")) {
      browserName = "Mozilla Firefox";
      browserShortName = "Firefox";
    } else if (navUsrAg.includes("SamsungBrowser")) {
      browserName = "Samsung Internet";
      browserShortName = "SamsungBrowser";
    } else if (navUsrAg.includes("Opera")) {
      browserName = "Opera";
      browserShortName = "Opera";
    } else if (navUsrAg.includes("OPR")) {
      browserName = "Opera";
      browserShortName = "OPR";
    } else if (navUsrAg.includes("Trident")) {
      browserName = "Microsoft Internet Explorer";
      browserShortName = "Trident";
    } else if (navUsrAg.includes("Edge")) {
      browserName = "Microsoft Edge";
      browserShortName = "Edge";
    } else if (navUsrAg.includes("Chrome")) {
      browserName = "Chromium or Google Chrome";
      browserShortName = "Chrome";
    } else if (navUsrAg.includes("Safari")) {
      browserName = "Apple Safari";
      browserShortName = "Safari";
    } else {
      browserName = "unknown";
    }

    if (browserShortName.length > 0) {
      browserVersion = navigator.userAgent.split(`${browserShortName}/`)[1];
    } else {
      browserVersion = 0;
    }

    return { browserName: browserName, browserVersion: browserVersion };
  }
</script>
