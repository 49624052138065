<div class="container px-10 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    4h edition of the "Particle Hunters" competition - first League event.
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/hunters/XI_start.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center">
      Tonight at 21:00 the first League of the fourth edition of the Particle
      Hunters competition will begin. The League is an event lasting 10 hours
      from 9 pm (12th day of the month) to 7 am (13th day of the month). The
      winner will be the team that will detect the most good detection
      candidates within 10 hours.<br />
      In this edition of the contest, team activity during League of the Month events
      will provide the team with additional points in the category: Marathon.
      <br /> We encourage you to participate, make sure that all people from
      your teams know about it, and will turn on devices during the event. If
      you want to take part in the competition and you have not registered your
      team yet, we encourage you to register on the website:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/particle_hunters/#/register"
          >https://credo.science/particle_hunters/#/register</a
        ></span
      ><br />

      Remember that the name of the team in the CREDO Detector app should be
      exactly the same as the one given in the registration by your coordinator.
    </span>
  </div>
</div>
