<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO Detector App on Google Play
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/12-570x324.png" alt="credo play" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >Since today everyone is welcome to participate in the CREDO detection
      program using own mobile devices and our CREDO Detector app. The updated
      info and useful links related to CREDO Detector can be found
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="#/detector/tutorial" target="_blank"
          >here</a
        >.
      </span>
    </span>
  </div>
</div>
