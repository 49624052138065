<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Tygodnik Powszechny about CREDO: “Flashes of Dark Matter”
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/darkmatter-nasa-570x350.jpg"
      alt="darkmatter-nasa-570x350.jpg"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >The 8th largest countrywide weekly newspaper in Poland, Tygodnik
      Powszechny (TP), published a popular article describing one of the CREDO
      scientific objectives: indirect search for super-heavy Dark Matter. The
      article, “Błyski Ciemnej Materii” (eng. flashes of Dark Matter), was
      written by Michał Krupiński and appeared in the 31st issue of TP, dated
      30th July, 2017.<br />
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.tygodnikpowszechny.pl/blyski-ciemnej-materii-149156"
          >Link to the article</a
        ></span
      >.
    </span>
  </div>
</div>
