import { writable } from "svelte/store";

export const device_info = writable([]);

const fetchData = async () => {
  // const url = "./data/teams.json";
  const url =
    "./data/device_stats_svelte.json";
  const res = await fetch(url);
  const data = await res.json();

  const loadDevices = data.map((data, index) => {
    return {
      id: index + 1,
      device_id: data.device_id,
      all_traces: data.all_traces,
      bad_margin: data.bad_margin,
      hot_pixels: data.hot_pixels,
      artefact: data.artefact,
      too_bright: data.too_bright,
      bad_size: data.bad_size,
      all_detect: data.all_detect,
      no_ml_detect: data.no_ml_detect,
      detect_proc: data.detect_proc,
      no_ml_proc: data.no_ml_proc,
      time_work: data.time_work,
      temperature_mean: data.temperature_mean,
      temperature_median: data.temperature_median,
      hour_detect: data.hour_detect,
      hour_traces: data.hour_traces,
      hour_no_ml_detect: data.hour_no_ml_detect,
      device_model: data.device_model,
      system_version: data.system_version,
    
    };
  });
  device_info.set(loadDevices);
};

setTimeout(() => {
  fetchData();
}, 500);
