<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto text-black">
    <div class="flex flex-col text-center w-full mb-10">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b>Conferences - CREDO meetings to date</b>
      </h1>
    </div>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              href="https://indico.ifj.edu.pl/event/740/"
              target="_blank"
              rel="noopener noreferrer"
              >MICRO-WORKSHOP ON MACHINE LEARNING AIDED SHOWER DETECTION</a
            >
          </strong>24.11.2021
        </li>
        <li>
          <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              href="https://moa.edu.pl/ogolnopolska-konferencja-edusky-17-05-2021/"
              target="_blank"
              rel="noopener noreferrer">EduSky 2021</a
            >
          </strong>17.05.2021
        </li>
        <li>
          <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              href="//indico.ifj.edu.pl/event/281/overview"
              target="_blank"
              rel="noopener noreferrer">CREDO Visegrad Workshop</a
            >
          </strong>21-23.11.2019
        </li>
        <li>
          <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              href="./credo-workshop-2019"
              target="_blank"
              rel="noopener noreferrer">CREDO Workshop Kraków</a
            >
          </strong>21-23.09.2019
        </li>
        <li>
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//indico.ifj.edu.pl/event/213/overview"
            target="_blank"
            rel="noopener noreferrer"><strong>CREDO Week 2018</strong></a
          >
          1-5.10. 2018
        </li>
        <li>
          <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              href="//indico.ifj.edu.pl/event/216/"
              target="_blank"
              rel="noopener noreferrer">CREDO w szkole 2018</a
            >
          </strong>3.10.2018
        </li>
        <li>
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//indico.ifj.edu.pl/event/201/"
            target="_blank"
            rel="noopener noreferrer"
            ><strong>CREDO Visegrad Project Meeting in Bratislava</strong>
          </a>19.03.2018
        </li>
        <li>
          <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              href="//indico.ifj.edu.pl/event/182/"
              target="_blank"
              rel="noopener noreferrer">CREDO Anniversary Symposium</a
            ></strong
          >
          30 - 31.08.2017
        </li>
        <li>
          <strong>International Cosmic Day 2017</strong>
          30.11.2017
        </li>
        <li>
          <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              href="//indico.ifj.edu.pl/event/142/contributions/618/"
              target="_blank"
              rel="noopener noreferrer">CREDO Inauguration</a
            >
          </strong>30.08.2016
        </li>
      </ol>
    </div>
  </div>
</section>
