<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>The CREDO Detector app plays a role in the Transglobal Car Expedition</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2 mb-5">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
      class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 lg:w-1/3"
      alt="img"
      src="./images/posts/tce/TCEs.png"
    /><img
        class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 lg:w-1/3"
        alt="img"
        src="./images/posts/tce/1.jpeg"
      />

      Today - 9 January 2024- the "Transglobal Car Expedition" set off from the Explorer Club in New York. 
      This expedition will use various scientific devices, including cosmic radiation detectors. 
      Smartphones with the CREDO Detector application will also be used in this expedition.
      <br/><br/>
      A few words about the expedition: <br/>
      &#9733; The Transglobal Car Expedition has set ambitious goals that encompass extreme sport adventure, technological innovation, scientific data collection, and educational initiatives. The crew is keen to make a meaningful contribution to sustainability and scientific knowledge by collecting accurate, in-field data along the way, from measurements of the polar ice caps to the detection of cosmic rays coming from distant regions of our universe.
      <br/>
      &#9733; The crew will champion 2 Citizen Science projects, Particle Hunters and Globe at Night.<br/>
      &#9733;For Particle Hunters they created the team “Transglobal” that people are invited to join to help them. They will have on the cars 4 smartphones running the CREDO app.
      <br/><br/>
      More information you can see on offical website:  <a
      class="text-blue-900 hover:text-blue-500"
      href="https://play.google.com/store/apps/details?id=science.credo.mobiledetector&pli=1"
      >https://transglobalcar.com/</a
    >
    </p>
       </div>
</section>
