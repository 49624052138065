<script>
  import Fa from "svelte-fa/src/fa.svelte";
  import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
</script>

<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <span
      ><a href="#/about/MediaAboutUs"
        ><Fa
          class="text-black hover:text-gray-400"
          icon={faArrowAltCircleLeft}
          size="2x"
          secondaryOpacity={1}
        />
      </a></span
    ><a
      class="text-indigo-800 hover:text-blue-600"
      rel="noopener noreferrer"
      href="#/about/materialyprasowe/"
      ><strong>Press Kits (Po polsku)</strong></a
    >
    Press Kits (<a
      class="text-indigo-800 hover:text-blue-600"
      href="#/about/presskits_spanish"
      rel="noopener noreferrer"><strong>Spanish</strong></a
    >)
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900"
      >
        <b> CREDO - Press Kit </b>
      </h1>

      <h2 class="text-base text-red-700 tracking-widest font-medium title-font">
        (english material)
      </h2>
    </div>
    <div class="lg:w-4/5 mx-auto text-xl text-justify font-serif mb-8">
      <p>
        <li>
          [14-Jun-2023] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ230614a-Earthquakes-visible-in-space.pdf"
            target="_blank"
            >Intriguing correlation between earthquakes and cosmic radiation</a
          >
        </li>
        <li>
          [11-Sept-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911a - CREDO.pdf"
            target="_blank"
            >Hunters of cosmic ray particles come together in CREDO</a
          >
        </li>
        <li>
          [11-Sept-2019] <strong
            ><a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="./data/press-kit/IFJ190911c - CREDO - Press kit.pdf"
              target="_blank"
              >Cosmic-Ray Extremely Distributed Observatory – press kit</a
            ></strong
          >
        </li>
        <li>
          [11-Sept-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911d - CREDO - Main facts.pdf"
            target="_blank">CREDO and cosmic radiation – main facts</a
          >
        </li>
        <li>
          [11-Sept-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911e - CREDO - On the past, towards the future.pdf"
            target="_blank">CREDO: On the past, towards the future</a
          >
        </li>
        <li>
          [11-Sept-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911f - CREDO - Cosmic radiation.pdf"
            target="_blank">Cosmic radiation – from riddle to... riddles</a
          >
        </li>
        <li>
          [11-Sept-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911g - CREDO - A detector like never before.pdf"
            target="_blank">CREDO – a detector like never before</a
          >
        </li>
        <li>
          [11-Sept-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911h - CREDO - CREDO penetrates the unknown.pdf"
            target="_blank">CREDO penetrates the unknown</a
          >
        </li>
        <li>
          [11-Sept-2019] <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ190911i - CREDO - A portal to the world of science.pdf"
            target="_blank">CREDO as a portal to the world of real science</a
          >
        </li>
      </p>
    </div>
  </div>
</section>
