<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto text-black">
    <div class="flex flex-col text-center w-full mb-2">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b> Our Conference Posters</b>
      </h1>
    </div>
    <div class="py-4 text-xl text-justify font-serif ">
      See also:
      <a
        href="#/science/posters/general"
        class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
        >General
      </a>
      |
      <a
        href="#/science/posters/event"
        class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
        >Event
      </a>
      posters.
    </div>
    <div class="flex flex-wrap -m-4 text-xl text-justify font-serif ">
      <div class="p-4 md:w-1/3">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-64 md:h-48 w-full object-cover object-center"
            src="./data/posters/NTNPD21_Tursunov.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              workshop NTNPD-2021, Warsaw
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              Arman Tursunov
            </h1>
            <p class="leading-relaxed mb-3">
              Exploring high-energy cosmic ray scenarios with global CREDO
              network of detectors
            </p>
            <div class="flex items-center flex-wrap ">
              <a
                href="./data/posters/NTNPD21_Tursunov.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-64 md:h-48 w-full object-cover object-center"
            src="./data/posters/ICRC2021 - K. A.Cheminant.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ICRC 2021
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              K. Almeida Cheminant
            </h1>
            <p class="leading-relaxed mb-3">
              Event rates of UHE photons cascading in the geomagnetic field at
              CTA-North.
            </p>
            <div class="flex items-center flex-wrap ">
              <a
                href="./data/posters/ICRC2021 - K. A.Cheminant.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
              |
              <a
                href="https://doi.org/10.22323/1.395.0726"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Read article
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-64 md:h-48 w-full object-cover object-center"
            src="./data/posters/ICRC2021-A.Tursunov.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ICRC 2021
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              Arman Tursunov
            </h1>
            <p class="leading-relaxed mb-3">
              Probing UHECR and cosmic ray ensemble scenarios with a global
              CREDO network.
            </p>
            <div class="flex items-center flex-wrap ">
              <a
                href="./data/posters/ICRC2021-A.Tursunov.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
              |
              <a
                href="https://doi.org/10.22323/1.395.0471"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600inline-flex items-center md:mb-2 lg:mb-0"
                >Read article
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-64 md:h-48 w-full object-cover object-center"
            src="./data/posters/ICRC2021-D.Alvarez.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ICRC 2021
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              D. Álvarez-Castillo
            </h1>
            <p class="leading-relaxed mb-3">
              Cosmic ray ensembles as signatures of ultra-high energy photons
              interacting with the solar magnetic field.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/ICRC2021-D.Alvarez.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
              |
              <a
                href="https://doi.org/10.22323/1.395.0457"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Read article
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-64 md:h-48 w-full object-cover object-center"
            src="./data/posters/ICRC2021-Ł.Bibrzycki.png"
            alt="poster png"
          />
          <div class="p-6 ">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ICRC 2021
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              Ł. Bibrzycki
            </h1>
            <p class="leading-relaxed mb-3">
              Machine Learning aided noise filtration and signal classification
              for CREDO experiment.
            </p>
            <div class="flex items-center flex-wrap ">
              <a
                href="./data/posters/ICRC2021-Ł.Bibrzycki.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
              |
              <a
                href="https://doi.org/10.22323/1.395.0227"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Read article
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-64 md:h-48 w-full object-cover object-center"
            src="./data/posters/ICRC2021-M.Karbowiak.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ICRC 2021
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              M. Karbowiak
            </h1>
            <p class="leading-relaxed mb-3">
              Small shower array for education purposes. The CREDO-Maze Project.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/ICRC2021-M.Karbowiak.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
              |
              <a
                href="https://doi.org/10.22323/1.395.0219"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Read article
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/ICRC2019_CTA.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ICRC 2019
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              K. Almeida Cheminant
            </h1>
            <p class="leading-relaxed mb-3">
              Search for Ultra-high Energy Photons: Observing the Preshower
              Effect with Gamma-ray Telescopes.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/ICRC2019_CTA.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
              |
              <a
                href="https://pos.sissa.it/358/688/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Read article
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/ICRC2019-M.Niedzwiecki.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ICRC 2019
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              M. Niedźwiecki
            </h1>
            <p class="leading-relaxed mb-3">
              Recognition and classification of the cosmic-ray events in images
              captured by CMOS/CCD camera.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/ICRC2019-M.Niedzwiecki.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
              |
              <a
                href="https://pos.sissa.it/358/367"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Read article
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/ICRC2019-N.Dhital.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ICRC 2019
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              N. Dhital
            </h1>
            <p class="leading-relaxed mb-3">
              Cosmic ray ensembles from ultra-high energy photons propagating in
              the galactic and intergalactic space.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/ICRC2019-N.Dhital.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
              |
              <a
                href="https://pos.sissa.it/358/239/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Read article
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/PARIS2019.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              Workshop, Paris 2019
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              K. Almeida Cheminant
            </h1>
            <p class="leading-relaxed mb-3">
              Cosmic-rays and earthquake prediction? Towards a multi-messenger
              strategy with the Cosmic-Ray Extremely Distributed Observatory.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/PARIS2019.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/Sushchov_ISCRA.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              ISCRA 2019
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              O. Sushchov
            </h1>
            <p class="leading-relaxed mb-3">
              Cosmic-Ray Extremely Distributed Observatory (CREDO) monitor: a
              tool for a global cosmic-ray data analysis.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/Sushchov_ISCRA.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/TeVPA2018.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              TeV Particle Astrophysics 2018
            </h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
              K. Almeida Cheminant
            </h1>
            <p class="leading-relaxed mb-3">
              Search for ultra-high energy photons through preshower effect:
              study of CTA-La Palma efficiency.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/TeVPA2018.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
