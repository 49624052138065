<script>
  let home_path = "#/about/team/credo-cs/";

  function pathes(subpage) {
    return home_path + subpage;
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-CS Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href={pathes("")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >About</a
      >
      <a
        href={pathes("research")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      >
      <a
        href={pathes("people")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <a
        href={pathes("activites")}
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>Activites</strong></a
      >
      <a
        href={pathes("publications")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href={pathes("contact")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full">
      <h1
        class="sm:text-3xl text-2xl uppercase tracking-wider font-serif title-font mb-4 text-red-900"
      >
        Activites
      </h1>
      <p
        class="lg:w-2/3 mx-auto leading-relaxed tracking-wider font-serif mb-4"
      >
        A calendar of the team's activities at various events.
      </p>
    </div>

    <div class="w-4/5 mx-auto -my-8 divide-y-2 divide-gray-100">
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">CONFERENCE</span>
          <span class="mt-1 text-gray-500 text-sm">2024.08.19-23</span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl tracking-wider font-serif text-gray-900 mb-2">
            FEL 2024 - 41st International Free Electron Laser Conference
          </h2>
          <p class="leading-relaxed tracking-wider font-serif">
            Participation and presentation of a poster on the applications of
            cosmic ray detectors in research from various fields of science and
            in one of the CREDO collaboration projects. Poster title "Modular
            Cosmic Ray Detector (MCORD) and its potential use in various physics
            experiments, astrophysics and geophysics". Publication in the
            conference proceedings under the same title.
            <br />
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-6"
              href="https://indico.jacow.org/event/72/overview"
              target="_blank"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/TUP229-THB_poster.pdf"
              target="_blank"
              >Poster
            </a>
            |
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/TUP229-THB.pdf"
              target="_blank"
              >Article
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">EDUCATION</span>
          <span class="mt-1 text-gray-500 text-sm">2024.06.17-24</span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl tracking-wider font-serif text-gray-900 mb-2">
            1st International School on Instrumentation and Radiation Detection
          </h2>
          <p class="leading-relaxed tracking-wider font-serif">
            Remote presentations as part of an invited lecture during a series
            of lectures for students at the University of Puebla, Mexico. Dr.
            Marcin Bielewicz presented a lecture on "Modular Cosmic Ray Detector
            (MCORD) and its potential use in various physics, astrophysics and
            geophysics experiments".
            <br />
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-6"
              href="https://www.fcfm.buap.mx/emoreno/instrumentacion.html"
              target="_blank"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/Bielewicz_School_Radiation_Detection_Mexico_2024_06_21.pdf"
              target="_blank"
              >Presentation
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">PUBLICATION</span
          >
          <span class="mt-1 text-gray-500 text-sm">2024.03</span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl tracking-wider font-serif text-gray-900 mb-2">
            Preparation of a publication within the CREDO collaboration
          </h2>
          <p class="leading-relaxed tracking-wider font-serif">
            Preparation of a joint publication on cosmic ray measurement
            techniques, which describes, among other things, the possibility of
            using the MCORD detector in the proposed program "Astroseismic
            project - CREDO MEXICO" The publication entitled "Recent
            developments within the cosmic ray extremely distributed
            observatory" was published in Revista Mexicana de Fisica, 4 021123
            (2023) 1–8,
            <br />
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-6"
              href="https://doi.org/10.31349/SuplRevMexFis.4.021123"
              target="_blank"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/Recent developments within the cosmic_Supl. Rev. Mex. Fis. 4(2) 021123 1-8 (2023).pdf"
              target="_blank"
              >Article
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">WORKSHOP</span>
          <span class="mt-1 text-gray-500 text-sm">2024.01.15-17</span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl tracking-wider font-serif text-gray-900 mb-2">
            1ST CREDO VISEGRAD WORKSHOP 2024
          </h2>
          <p class="leading-relaxed tracking-wider font-serif">
            Participation and presentations in the first CRED0-VISEGRAD workshop
            at IFJ PAN, Krakow, Poland. Dr. Marcin Bielewicz presented a lecture
            on &quot;Modular Cosmic Ray Detector (MCORD) possible use in the
            cosmo-seismic project&quot;. Dr. Noemi Zabari presented a lecture on
            "Exploring cosmo-seismic correlation with machine learning".
            <br />
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-6"
              href="https://indico.ifj.edu.pl/event/1176/timetable/#20240117.detailed"
              target="_blank"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/Bielewicz-abstract.pdf"
              target="_blank"
              >Abstract
            </a>
            |
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/MCORD_Bielewicz_CREDO_workshop_2024_01_16.pdf"
              target="_blank"
              >Presentation
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">EDUCATION</span>
          <span class="mt-1 text-gray-500 text-sm">2024.01.09</span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl tracking-wider font-serif text-gray-900 mb-2">
            Lecture for school youth and adults
          </h2>
          <p class="leading-relaxed tracking-wider font-serif">
            Preparation and presentation of a popular science lecture for the
            Szkolazharakterem.pl foundation as part of the regularly organized
            "Scientific Cauldron" meetings in the city of Gliwice, Poland. Dr.
            Marcin Bielewicz presented a lecture on cosmic radiation.
            <br />
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-6"
              href="https://szkolazcharakterem.pl/zapraszamy-na-kolejny-kociolek-naukowy-2/"
              target="_blank"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/Kociolek-naukowy-Marcin-Bielewicz.jpg"
              target="_blank"
              >Poster
            </a>
            |
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/Seminarium Kociloek Promieniowanie Kosmiczne_cz1 2024_01.pdf"
              target="_blank"
              >Presentation Part1
            </a>
            |
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-5"
              href="data/credo-cs/Seminarium Kociloek Promieniowanie Kosmiczne_cz2 2024_01.pdf"
              target="_blank"
              >Presentation Part2
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">PUBLICATION</span
          >
          <span class="mt-1 text-gray-500 text-sm">2023.06</span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl tracking-wider font-serif text-gray-900 mb-2">
            Participation in the work on the CREDO publication.
          </h2>
          <p class="leading-relaxed tracking-wider font-serif">
            Members of our group participated in the preparation of a joint
            large publication on the search for correlations between cosmic rays
            and earthquakes. The publication entitled "Observation of
            large-scale precursor correlations between cosmic rays and
            earthquakes with a periodicity similar to the solar cycle" was
            published in the Journal of Atmospheric and Solar-Terrestrial
            Physics, 247 (2023) 106068,
            <br />
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-6"
              href="https://doi.org/10.1016/j.jastp.2023.106068"
              target="_blank"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif"
              href="data/credo-cs/2023_06_published_Observation-of-large-scale-precursor-correlations-b_247_2023_Journal-of-
Atmosphe.pdf"
              target="_blank"
              >Article
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">MEETING</span>
          <span class="mt-1 text-gray-500 text-sm">2023.03.27-29</span>
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl tracking-wider font-serif text-gray-900 mb-2">
            Annual Meeting of the Division of Cosmic Rays of the Mexican
            Physical Society
          </h2>
          <p class="leading-relaxed tracking-wider font-serif">
            Remote presentations as an invited lecture during the meeting of the
            Division of Cosmic Rays of the Mexican Physical Society, Mexico. Dr.
            Marcin Bielewicz presented a lecture on "Practical use of the
            Modular Cosmic Ray Detector (MCORD)".
            <br />
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif pr-6"
              href="https://indico.nucleares.unam.mx/event/1929/overview"
              target="_blank"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <a
              class="text-red-800 inline-flex items-center mt-4 tracking-wider font-serif"
              href="data/credo-cs/MCORD_Bielewicz_meeting_Mexican_CosmicRay_2023_03_27.pdf"
              target="_blank"
              >Presentation
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
