<script>
  import { onMount } from "svelte";

  let questions = [];
  let answers = {}; // odpowiedzi użytkownika
  let score = null;
  let userName = "";
  let showResult = false;

  onMount(async () => {
    const res = await fetch("/download/Italy-quesions.json");
    questions = await res.json();
  });

  let grade = 1;

  // Funkcja do wysyłania wyników do PHP
  function submit() {
    let correct = 0;
    const answersArray = [];
    const correctAnswersArray = [];

    // Sprawdzamy odpowiedzi
    questions.forEach((q, i) => {
      const userAnswer = answers[i];
      answersArray.push(userAnswer); // zapisujemy odpowiedzi użytkownika
      correctAnswersArray.push(q.correct); // zapisujemy poprawne odpowiedzi

      if (userAnswer === q.correct) correct++;
    });

    score = correct;
    showResult = true;
    grade = getGrade(score, questions.length);

    // Wysyłamy dane do PHP
    fetch(
      "https://user.credo.science/user-interface/italy-25/save_results.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: userName,
          answers: answersArray.join(","), // Wysyłamy odpowiedzi jako ciąg znaków
          correct_answers: correctAnswersArray.join(","), // Wysyłamy poprawne odpowiedzi jako ciąg znaków
          score: score,
          total: questions.length,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Results saved:", data);
      })
      .catch((error) => {
        console.error("Error saving results:", error);
      });
  }

  let showReview = false;
  let reviewCode = "";
  const secretCode = "CR-ITA-25";

  function getClass(qIndex, optionKey) {
    const userAnswer = answers[qIndex];
    const correct = questions[qIndex].correct;

    if (userAnswer === correct && optionKey === correct) return "correct";
    if (userAnswer === optionKey && optionKey !== correct) return "wrong";
    if (userAnswer !== correct && optionKey === correct) return "missed";
    return "";
  }

  function getGrade(score, total) {
    const percent = (score / total) * 100;

    if (percent >= 95) return 6;
    if (percent >= 85) return 5;
    if (percent >= 70) return 4;
    if (percent >= 60) return 3;
    if (percent >= 50) return 2;
    return 1; // poniżej 50%
  }

  function generatePDF() {
    fetch("/print_pdf/italy-2025/quiz_pdf.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ questions }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url);
      });
  }
</script>

<div class="quiz-container w-4/5 mx-auto p-8 font-sans">
  <button class="py-3" on:click={generatePDF}
    >Download Quiz as <span class="text-red-600">PDF</span></button
  >

  <h1 class="text-lg mx-auto"><strong>Knowledge Quiz</strong></h1>

  {#if !showResult}
    <div class="mb-2">
      <label>
        Your name:
        <input
          type="text"
          bind:value={userName}
          placeholder="Enter your name"
          required
          style="margin-left: 0.5rem;"
          class="bg-gray-100"
        />
      </label>
    </div>

    {#each questions as q, i}
      <div class="question">
        <p><strong>{i + 1}. {q.question}</strong></p>
        <div class="options">
          {#each Object.entries(q.options) as [key, value]}
            {#if value !== ""}
              <label>
                ({key.toUpperCase()})<input
                  type="radio"
                  bind:group={answers[i]}
                  value={key}
                />
                {value}
              </label>
            {/if}
          {/each}
        </div>
      </div>
    {/each}

    <button class="submit-btn" on:click={submit}>Submit</button>
  {:else}
    <div class="text-lg mb-5">
      <p>Thank you, {userName}!</p>
      <p>
        You scored: <br /><strong>{score}</strong> out of
        <strong>{questions.length}</strong>.
      </p>
      <p>
        Your grade: <br /><strong>{grade}</strong> this is
        <strong>({Math.round((score / questions.length) * 100)}%) </strong>of
        good answers
      </p>
    </div>
    <div class="mb-5" style="margin-top: 2rem;">
      <label>
        Enter review code:
        <input bind:value={reviewCode} placeholder="CR-" />
        <button
          on:click={() => (showReview = reviewCode.trim() === secretCode)}
        >
          Check
        </button>
      </label>
    </div>
    {#if showReview}
      <h2 style="margin-top: 2rem;">Review</h2>
      {#each questions as q, i}
        <div class="question">
          <p><strong>{i + 1}. {q.question}</strong></p>
          <div class="options">
            {#each Object.entries(q.options) as [key, value]}
              <div class={getClass(i, key)}>
                ({key.toUpperCase()}) {value}
              </div>
            {/each}
          </div>
        </div>
      {/each}
    {/if}
  {/if}
</div>

<style>
  .question {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #fafafa;
  }

  .options label {
    display: block;
    margin: 0.25rem 0;
  }

  .submit-btn {
    margin-top: 2rem;
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
  }

  .submit-btn:hover {
    background-color: #0056b3;
  }

  .correct {
    color: green;
    font-weight: bold;
  }
  .wrong {
    color: red;
  }
  .missed {
    color: blue;
    font-style: italic;
  }
</style>
