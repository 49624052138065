<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    "Particle Hunters" Competition – 3th edition
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/post3ba-570x350.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000;"
      >The next, third edition of the "Particle Hunters" team competition will
      start on October 21, 2020 and will last until October 18, 2021.</span
    >
    <br />
    <span style="color: #000000;"
      >The competition is based on the team catching particle registration
    </span><span style="color: #000000;"
      >using our application (CREDO Detector), for each good detection approved
      by the filters, the team will get one point.</span
    >

    <span style="color: #000000;"
      >Each team competes against other teams in two categories:</span
    >
    <br />
    <span style="color: #000000;"
      >a) Marathon - runs from the beginning (October 12, 2020) to the end of
      the event (June 18, 2021),</span
    >
    <br />
    <span style="color: #000000;"
      >b) League - detection of incident registration during one selected night
      of the month from 12 to 13, lasting 10 hours (from 21:00 to 7:00). The
      first League will take place in November (2020).</span
    >
    <br />
    <span style="color: #000000;"
      >To be able to take part in the competition, the team must be registered
    </span><span style="color: #000000;"
      >by its coordinator (teacher, leader) on the website:</span
    >
    <span style="color: #0000ff;"
      ><a
        href="https://credo.science/particle_hunters/registration.html"
        target="_blank"
        rel="noreferrer">credo.science/particle_hunters/registration.html</a
      ></span
    >
    <br />
    <span style="color: #000000;"
      >A new element in the third edition of the competition is the possibility
      of joining the competition by all teams, not only from Poland. So we
      invite all teams to participate - not only from Poland!</span
    >
    <span style="color: #000000;"
      >More information about the competition can be obtained from the document
      available at the link:</span
    >
    <span style="color: #0000ff;"
      ><a
        href="https://credo.science/particle_hunters/info.html"
        target="_blank"
        rel="noreferrer">credo.science/particle_hunters/info.html</a
      ></span
    ><br />
    <span style="color: #000000;"
      >The results of the competition will be published on an ongoing basis in
      the Marathon section:</span
    >
    <span style="color: #0000ff;"
      ><a
        href="https://credo.science/particle_hunters/maraton.php"
        target="_blank"
        rel="nofollow noopener noreferrer"
        >credo.science/particle_hunters/maraton.php</a
      ></span
    >
  </div>
</div>
