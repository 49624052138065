<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO institutional members: Canada
  </h1>
  <a href="#/credo-institutional-members"
    ><svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      style="vertical-align:bottom;"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
      />
    </svg>Back</a
  >
  <div class="flex flex-wrap -m-4">
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="flex-shrink-0 rounded-lg w-full object-cover object-center mb-4"
          src="./images/flags/members/nscir.png"
        />
        <div class="w-full">
          <h2 class="title-font font-medium text-lg text-gray-900">
            NSCIR Canada
          </h2>
          <a
            style="color: #0000ff;"
            href="https://nscir.ca"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
    <div class="p-4 lg:w-1/4 md:w-1/2">
      <div class="h-full flex flex-col items-center text-center">
        <img
          alt="team"
          class="flex-shrink-0 rounded-lg w-full object-cover object-center mb-4"
          src="./images/flags/members/waterloo.png"
        />
        <div class="h-full">
          <h2 class="title-font font-medium text-lg text-gray-900">
            Waterloo Rocketry
          </h2>
          <a
            style="color: #0000ff;"
            href="https://waterloorocketry.com"
            target="_blank"
            rel="noopener noreferrer">Visit website</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
