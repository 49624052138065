<script>
  let searchTerm = "";
</script>

<div class="container px-5 py-6 mx-auto bg-white">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO NEWS
  </h1>

  <!-- <div class="my-4 flex justify-center bg-white">
    <div class="p-2 w-full">
      <input
        bind:value={searchTerm}
        type="text"
        placeholder="SEARCH BY TITLE"
        class="focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent w-full rounded-md text-md p-4 border-2 text-center bg-white"
        disabled="true"
      />
    </div>
  </div> -->
  <div class="flex flex-wrap -mx-1 lg:-mx-4">
    <!--2024-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2024</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Card 73-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/summer_camp.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO Summer Camp</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">11.08.2024</p>
        <a href="#/posts/2024/08-11" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 72-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/geo_grant.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            New grant for Georgian members of the CREDO collaboration.
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">17.07.2024</p>
        <a href="#/posts/2024/07-17" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 71-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/2ndCVisegrad.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            2nd CREDO Visegrad Workshop 2024
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">03.06.2024</p>
        <a href="#/posts/2024/06-03" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 70-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/intro_1stVisegrad.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Recordings of presentations from the 1st CREDO Visegrad Workshop
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">14.03.2024</p>
        <a href="#/posts/2024/03-14" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 69-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/tce/TCEs.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            The CREDO Detector app plays a role in the Transglobal Car
            Expedition
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">09.01.2024</p>
        <a href="#/posts/2024/01-09" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 68-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/post4.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            1st CREDO Visegrad Workshop 2024 (15-17.01.2024, IFJ PAN, Kraków)
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">05.01.2024</p>
        <a href="#/posts/2024/01-05" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>
    <!--2023-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2023</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Card 67-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/zgorzelec/1.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO on Seminar 03-06.12, Gorlitz,
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">12.12.2023</p>
        <a href="#/posts/2023/12-12" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 66-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/VIedPH.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Announcement of the VI edition of "Particle Hunters"
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">12.11.2023</p>
        <a href="#/posts/2023/11-12" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 65-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/mnn2023-baner.jpg"
          alt="new peer-reviewed"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO at the Małopolska Scientists Night at the IFJ PAN
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">02.10.2023</p>
        <a href="#/posts/2023/10-02" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 64-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/eq_IFJ.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Intriguing correlation between earthquakes and cosmic radiation
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">15.06.2023</p>
        <a href="#/posts/2023/06-15" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 63-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/ph_140623.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Particle Hunters 2022/23 competition summary
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">14.06.2023</p>
        <a href="#/posts/2023/06-14" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 62-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/25052023.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Can we see dark matter in CREDO? <br />(lecture as part of the
            Copernicus Festival)
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">25.05.2023</p>
        <a href="#/posts/2023/05-25" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 61-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/1lowchrzanowie/3a.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO in I LO Chrzanów</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">31.03.2023</p>
        <a href="#/posts/2023/03-31" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 60-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/astronarium-2023.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO in Astronarium</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">12.03.2023</p>
        <a href="#/posts/2023/03-12" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>

    <!--2022-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2022</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Card 58-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/malopolska-noc-naukowcow-2022.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Meet us at the Małopolska Night of Scientists
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">30.09.2022</p>
        <a href="#/posts/2022/09-30" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 57-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/mdpi_2022_09.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            A newest peer-reviewed article "for CREDO"
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">29.09.2022</p>
        <a href="#/posts/2022/09-29" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 56-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/mdpi_2022_09_0.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            A new peer-reviewed article "for CREDO"
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">26.09.2022</p>
        <a href="#/posts/2022/09-26" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 55-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/universe-2022-08.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            A next new peer-reviewed article "for CREDO"
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">06.09.2022</p>
        <a href="#/posts/2022/09-06" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 54-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/2022-06-10.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            A new peer-reviewed article "for CREDO"
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">10.06.2022</p>
        <a href="#/posts/2022/06-10" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 53-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/posts/29042022FN_Miedzylesie.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Lecture for schools about CREDO on XVIII Festival of Science in
            Miedzylesie.
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">29.04.2022</p>
        <a href="#/posts/2022/04-29" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>
    <!--2021-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2021</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Card 52-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/post4IOS.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Cosmic ray detection on iPhones is now at your fingertips with the
            CREDO Detector for iOS available in the App Store !
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">22.12.2021</p>
        <a href="#/posts/2021/12-22" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 51-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/sensor_2021_11.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            A new peer-reviewed article "for CREDO".
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">22.11.2021</p>
        <a href="#/posts/2021/11-22" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 50-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/ML_workshoop.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            MICRO-WORKSHOP ON MACHINE LEARNING AIDED SHOWER DETECTION.
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">22.11.2021</p>
        <a href="#/posts/2021/11-21" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 49-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/hunters/XI_start.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            4h edition of the "Particle Hunters" competition - first League
            event.
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">12.11.2021</p>
        <a href="#/posts/2021/11-12" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 48-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/Start_4_edycja.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            4h edition of the "Particle Hunters" competition.
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">29.10.2021</p>
        <a href="#/posts/2021/10-29" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 47-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/100-Hours-of-Astronomy-2021.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">100 Hours, 100 NOCs - Poland</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">01.10.2021</p>
        <a href="#/posts/2021/10-01" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 46-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/mini_wyniki.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Podsumowanie Gry "Zajrzyj w głąb Wszechświata"
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">06.09.2021</p>
        <a href="#/posts/2021/09-06" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 45-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/I_XI.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">"Noc z Grą"</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">31.08.2021</p>
        <a href="#/posts/2021/08-31" target="_blank" class="mb-4 bottom-4">
          <button
            class="bg-orange-400 py-3 px-8 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 44-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/ICRC2021.jpg"
          alt="ICRC21"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO at the ICRC conference</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">01.08.2021</p>
        <a href="#/posts/2021/08-01" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 43-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/sensor-2021-07.png"
          alt="Forest"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            SENSORS: CNN-BASED CLASSIFIER AS AN OFFLINE TRIGGER FOR THE CREDO
            EXPERIMENT
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">16.07.2021</p>
        <a href="#/posts/2021/07-16" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 42-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/newka.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Gra „Zajrzyj w głąb Wszechświata"
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">30.06.2021</p>
        <a href="#/posts/2021/06-30" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Card 41-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/credo-12_13.jpg"
          alt="pokazy credo 12-13 maj"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Rekrutacja/pokazy CREDO [12-13.05.2021]
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">12.05.2021</p>
        <a href="#/posts/2021/05-12" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 40-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/applsci.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Determination of Zenith Angle Dependence of Incoherent Cosmic Ray
            Muon Flux Using Smartphones of the CREDO Project
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">06.11.2020</p>
        <a href="#/posts/2021/11-06" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>
    <!--2020-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2020</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Post 39-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/credo3a-570x350.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Publication about the CREDO Detector application in the Symmetry
            journal
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">06.11.2020</p>
        <a href="#/posts/2020/11-06" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 38-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/publications2.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Publication about the CREDO Detector application in the Symmetry
            journal
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">31.10.2020</p>
        <a href="#/posts/2020/10-31" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 37-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/post3ba-570x350.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            “Particle Hunters” Competition – 3th edition
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">23.10.2020</p>
        <a href="#/posts/2020/10-23" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 36-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/wywiad-570x350.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Invitation to an interview with Piotr Homola in Transfer Dobra TV
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">13.07.2020</p>
        <a href="#/posts/2020/07-13" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 35-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/credo2a.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            The first regularly peer-reviewed publication “for the CREDO
            Collaboration
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">08.07.2020</p>
        <a href="#/posts/2020/07-08" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 34-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/PASIFIC.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            PASIFIC competition for scientists
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">22.06.2020</p>
        <a href="#/posts/2020/06-22" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>
    <!--2019-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2019</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Post 33-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/Konkurs-570x350.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            II edition of the “Particle Hunters” competition
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">01.10.2019</p>
        <a href="#/posts/2019/10-01" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 32-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/workshop.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Credo Workshop 19 – 21.09.2019
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">21.09.2019</p>
        <a href="#/posts/2019/09-21" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 31-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/newka.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO Memorandum of Understanding has been announced!
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">11.09.2019</p>
        <a href="#/posts/2019/09-11" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 30-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/backgrounda2-570x350.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO Workshop 2019 is comming
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">07.09.2019</p>
        <a href="#/posts/2019/09-07" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 29-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/poster-570x350.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO in Warsaw on “Particle Astrophysics in Poland 2019”
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">20.05.2019</p>
        <a href="#/posts/2019/05-20" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 28-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/IMG_20190314_093710-570x350.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            A lecture on the subject of cosmic particles traces on the matrices
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">14.03.2019</p>
        <a href="#/posts/2019/03-14" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>

      <!--Post 27-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/Festiwal-nauki-katowice-570x350.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO on "3. Śląski Festiwal Nauki" - Katowice
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">19.01.2019</p>
        <a href="#/posts/2019/01-19" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>
    <!--2018-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2018</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Post 26-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/BBC_Radio4.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO on BBC Radio 4</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">22.11.2018</p>
        <a href="#/posts/2018/11-22" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 25-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/rmf-570x350.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO in RMF and on naukawpolsce.pap.pl
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">06.10.2018</p>
        <a href="#/posts/2018/10-06" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 24-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/182368_web-570x350.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            The first light from the CREDO Quantum Gravity Previewer
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">05.10.2018</p>
        <a href="#/posts/2018/10-05" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 23-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/newka.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO on EurekAlert</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">14.06.2018</p>
        <a href="#/posts/2018/06-14" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 22-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/completeforscience-570x350.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            1st World Championships in Particle Hunting with Smartphones!
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">18.05.2018</p>
        <a href="#/posts/2018/05-18" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 21-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/img_detect.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Oficial world premiere is here
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">17.05.2018</p>
        <a href="#/posts/2018/05-17" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 20-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/visegrand-2-570x350.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO Visegrad Project Meeting
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">20.03.2018</p>
        <a href="#/posts/2018/03-20" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 19-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/credo.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">Lecture on CREDO in English</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">06.03.2018</p>
        <a href="#/posts/2018/03-06" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 18-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/Bez-tytułu-570x350.png"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO in SONDA2</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">11.02.2018</p>
        <a href="#/posts/2018/02-11" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 17-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/1-570x350.jpg"
          alt="images"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO invited by ASTERICS to Trieste for their DECS meeting
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">26.01.2018</p>
        <a href="#/posts/2018/01-26" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>
    <!--2017-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2017</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Post 16-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/tlo-3-570x350.png"
          alt="img"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO at International Cosmic Day 2017
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">29.11.2017</p>
        <a href="#/posts/2017/11-29" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 15-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/prometeus.jpg"
          alt="prometeus.jpg"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Meeting with the ACC CYFORNET team
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">24.11.2017</p>
        <a href="#/posts/2017/11-24" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 14-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/12-570x324.png"
          alt="credo app"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO Detector App on Google Play
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">14.11.2017</p>
        <a href="#/posts/2017/11-14" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 13-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/ty.png"
          alt="images yt.jpg"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">Find CREDO on YouTube</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">24.10.2017</p>
        <a href="#/posts/2017/10-24" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 12-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/29-540x350.jpg"
          alt="images.jpg"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Meet CREDO at Scientists Night in Krakow [20.09.2017]
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">20.09.2017</p>
        <a href="#/posts/2017/09-20" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 11-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/credo-symposium.jpg"
          alt="credo-symposium.jpg"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Summary of the CREDO Anniversary Symposium
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">03.09.2017</p>
        <a href="#/posts/2017/09-03" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 10-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/cosmicrays2-570x350.jpg"
          alt="cosmicrays2-570x350.jpg"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO Anniversary Symposium 2017 [30-31.08.2017]
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">30.08.2017</p>
        <a href="#/posts/2017/08-30" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 9-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/Mobile-world1-570x350.jpg"
          alt="Mobile-world1-570x350.jpg"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO Detector Mobile Application
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">29.08.2017</p>
        <a href="#/posts/2017/08-29" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 8-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/darkmatter-nasa-570x350.jpg"
          alt="darkmatter-nasa-570x350.jpg"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Tygodnik Powszechny about CREDO: “Flashes of Dark Matter”
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">30.07.2017</p>
        <a href="#/posts/2017/07-30" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 7-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/UW logo.png"
          alt="UW logo"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO in Wroclaw</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">16.06.2017</p>
        <a href="#/posts/2017/06-16" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 6-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/photon17_poster_small.png"
          alt="photon17"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">Photon 2017: CREDO at CERN</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">25.05.2017</p>
        <a href="#/posts/2017/05-25" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 5-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/Baner-1200-x-628-2-570x350.jpg"
          alt="baner festiwal"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Science Festival in Cracow [24-27.05.2017]
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">24.05.2017</p>
        <a href="#/posts/2017/05-24" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 4-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/centro_fermi_logo-1.jpg"
          alt="fermi_logo"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            Workshop of High School Cosmic Ray Experiments at the Centro Fermi
            in Rome [15-16.02.2017]
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">15.02.2017</p>
        <a href="#/posts/2017/02-15" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>
    <!--2016-->
    <p style="color: #000080;" class="text-center my-6 font-bold">2016</p>
    <div
      class="p-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-15 lg:gap-10 md:gap-5 sm:gap-5"
    >
      <!--Post 3-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/logoPAU.jpg"
          alt="logoPAU.jpg"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO at Astrophysics Commission PAU
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">16.12.2016</p>
        <a href="#/posts/2016/12-16" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 2-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/ppog.png"
          alt="CREDO & PPOG"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">
            CREDO & The International Particle Physics Outreach Group
          </div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">11.10.2016</p>
        <a href="#/posts/2016/10-11" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
      <!--Post 1-->
      <div
        class="rounded overflow-hidden shadow-lg bg-white flex flex-wrap text-center justify-center hover:shadow-md"
      >
        <img
          class="w-full object-cover h-64"
          src="./images/credo.png"
          alt="CREDO Inaguration"
        />
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2">CREDO Inaguration</div>
        </div>
        <p class="text-grey-darker text-sm w-full text-center">30.08.2016</p>
        <a href="#/posts/2016/08-30" target="_blank">
          <button
            class="bg-orange-400 py-3 px-8 mt-4 rounded text-sm font-semibold hover:bg-opacity-75 w-full"
            >Read More</button
          ></a
        >
      </div>
    </div>
  </div>
</div>

<style>
</style>
