<script>
    import Input from "./../../components/Input.svelte";
    let string_value = ""
    let types = 0;
    let option =["User", "Team"];
    let checkboxYes1 = false;

    function check(values){
        if (values.length > 0) {
            checkboxYes1 = true;
        } 
    }  

    async function GoTo() {
        if (types == 0) {
        window.open("https://api.credo.science/web/user/"+string_value);
        }
        else{
        window.open("https://api.credo.science/web/team/"+string_value);
        }
    }
</script>
<section class="text-gray-600 body-font">
    <div class="container px-5 py-14 w-full mx-auto">
      <div class="flex flex-col text-center w-full mb-20">
        <h1
          class="text-4xl uppercase tracking-wider font-serif title-font text-red-900 "
        >
          <b> Find your account at api.credo.science</b>
        </h1>
      </div>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <div class="w-full md:w-1/3">
        <p class="w-full mx-auto">
          1. View your sub-page for
        </p>
        <div class="w-full mx-auto pb-2 font-serif">
            <label class="inline-flex items-center mt-3 hover:bg-yellow-50">
              <input type="radio" bind:group={types} value={0} />
              <span class="ml-3"
                >User</span
              >
            </label>
            <br />
            <label class="inline-flex items-center mt-3 hover:bg-yellow-50">
              <input type="radio" bind:group={types} value={1} />
              <span class="ml-3">
                Team,</span
              >
            </label>
        </div></div>
        <div class="w-full md:w-1/3"><div class="mx-auto">
            <p class="w-full text-center mx-auto">
                2. Enter below your {option[types]} name<b class="text-red-800 pl-1 pt-1">*</b>
              </p>
            <div class="w-4/5 mx-auto py-2 font-serif">
              <Input
                inputType="text" 
                bind:value={string_value}
                on:change={() => check(string_value)}
              />
            </div>
        </div></div>
        <div class="w-full md:w-1/3">
            <p class="w-full text-center mx-auto">
                3. Go to api.credo.science
              </p>
        <div class="w-2/3 text-center mx-auto">
            {#if checkboxYes1 }
            <input
                value="View sub-page"
                type="button"
                on:click|preventDefault={GoTo}
                class="text-white bg-blue-900 border-0 py-2 px-8 focus:outline-none hover:bg-blue-600 rounded text-lg"
            />
            {:else}
            <input
                value="View sub-page"
                type="button"
                on:click|preventDefault={GoTo}
                class="text-white bg-blue-100 border-0 py-2 px-8 focus:outline-none rounded text-lg"
                disabled
            />
            {/if}
        </div></div>
    </div>
  </section>
  