<script>
  import { onMount } from "svelte";

  let questions = [];
  let answers = {}; // odpowiedzi użytkownika
  let score = null;
  let userName = "";
  let showResult = false;
  let answerSummary = {}; // Podsumowanie odpowiedzi z procentami

  onMount(async () => {
    const res = await fetch(
      "https://user.credo.science/user-interface/italy-25/get_answer_summary.php"
    );
    const data = await res.json();
    if (data.status === "success") {
      questions = data.questions;
      answerSummary = data.answer_summary;
    } else {
      console.error("Error fetching data:", data.message);
    }
  });

  let grade = 1;

  // Funkcja do wysyłania wyników do PHP
  function submit() {
    let correct = 0;
    const answersArray = [];
    const correctAnswersArray = [];

    // Sprawdzamy odpowiedzi
    questions.forEach((q, i) => {
      const userAnswer = answers[i];
      answersArray.push(userAnswer); // zapisujemy odpowiedzi użytkownika
      correctAnswersArray.push(q.correct); // zapisujemy poprawne odpowiedzi

      if (userAnswer === q.correct) correct++;
    });

    score = correct;
    showResult = true;
    grade = getGrade(score, questions.length);

    // Wysyłamy dane do PHP
    fetch(
      "https://user.credo.science/user-interface/italy-25/save_results.php",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: userName,
          answers: answersArray.join(","), // Wysyłamy odpowiedzi jako ciąg znaków
          correct_answers: correctAnswersArray.join(","), // Wysyłamy poprawne odpowiedzi jako ciąg znaków
          score: score,
          total: questions.length,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Results saved:", data);
      })
      .catch((error) => {
        console.error("Error saving results:", error);
      });
  }

  function getGrade(score, total) {
    const percent = (score / total) * 100;

    if (percent >= 95) return 6;
    if (percent >= 85) return 5;
    if (percent >= 70) return 4;
    if (percent >= 60) return 3;
    if (percent >= 50) return 2;
    return 1; // poniżej 50%
  }

  function getClass(qIndex, optionKey) {
    const userAnswer = answers[qIndex];
    const correct = questions[qIndex].correct;

    if (userAnswer === correct && optionKey === correct) return "correct";
    if (userAnswer === optionKey && optionKey !== correct) return "wrong";
    if (userAnswer !== correct && optionKey === correct) return "missed";
    return "";
  }
</script>

<div class="quiz-container">
  <h1>Knowledge Quiz</h1>

  {#if !showResult}
    <div>
      <label>
        Your name:
        <input
          type="text"
          bind:value={userName}
          placeholder="Enter your name"
          required
        />
      </label>
    </div>

    {#each questions as q, i}
      <div class="question">
        <p><strong>{i + 1}. {q.question}</strong></p>
        <div class="options">
          {#each Object.entries(q.options) as [key, value]}
            <label>
              <input type="radio" bind:group={answers[i]} value={key} />
              ({key.toUpperCase()}) {value}
            </label>
          {/each}
        </div>
      </div>
    {/each}

    <button on:click={submit}>Submit</button>
  {:else}
    <div>
      <p>Thank you, {userName}!</p>
      <p>
        You scored: <strong>{score}</strong> out of
        <strong>{questions.length}</strong>.
      </p>
      <p>
        Your grade: <strong>{grade}</strong> ({Math.round(
          (score / questions.length) * 100
        )}%)
      </p>
    </div>

    <h2>Answer Statistics</h2>
    {#each questions as q, i}
      <div class="question">
        <p><strong>{i + 1}. {q.question}</strong></p>
        <div class="options">
          {#each Object.entries(q.options) as [key, value]}
            <div>
              ({key.toUpperCase()}) {value} - {answerSummary[key] || 0}% users
              chose this answer
            </div>
          {/each}
        </div>
      </div>
    {/each}
  {/if}
</div>

<style>
  .question {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #fafafa;
  }

  .options label {
    display: block;
    margin: 0.25rem 0;
  }

  .submit-btn {
    margin-top: 2rem;
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }

  .correct {
    color: green;
    font-weight: bold;
  }
  .wrong {
    color: red;
  }
  .missed {
    color: blue;
    font-style: italic;
  }
</style>
