<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto text-black">
    <div class="flex flex-col text-center w-full mb-10">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b>Confernece reports</b>
      </h1>
    </div>
    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2023
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong
            >"Recent developments within the cosmic ray extremely distributed
            observatory",</strong
          ><br />
          D. A. Castillo on behalf of the CREDO Collaboration,<br />
          Supl. Rev. Mex. Fis., vol. 4, no. 2, pp. 021123 1–8, Sep. 2023..<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.31349/SuplRevMexFis.4.021123"
            target="_blank"
            rel="noopener noreferrer">10.31349/SuplRevMexFis.4.021123</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Cosmic-ray ensembles resulting from synchrotron radiation: status
            and prospects of simulations",</strong
          ><br />
          O. Sushchov on behalf of the CREDO Collaboration,<br
          />PoS(ICRC2023)351, 2023.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.444.0351"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.444.0351</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Detection of Extensive Air Showers with small array - measurement
            and estimations",</strong
          ><br />
          J.S. Pryga on behalf of the CREDO Collaboration,<br
          />PoS(ICRC2023)382, 2023.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.444.0382"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.444.0382</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Simulation of the isotropic ultra-high energy photons flux in the
            solar magnetic field and a comparison with observations made by the
            HAWC and Fermi-LAT observatories",</strong
          ><br />
          D. Alvarez-Castillo on behalf of the CREDO Collaboration,<br
          />PoS(ICRC2023)446, 2023.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.444.0446"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.444.0446</a
          >].
        </li>
      </ol>
    </div>

    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2021
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong
            >"Cosmic rays and the structure of the universe studied in Cosmic
            Ray Extremely Distributed Observatory with citizen science",</strong
          ><br />
          R. Kamiński, J. Firla, S. Stuglik on behalf of the CREDO Collaboration,<br
          />PoS(ICRC2021)1370, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.1370"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.1370</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Invitation to the Cosmic Ray Extremely Distributed Observatory",</strong
          ><br />
          P. Homola on behalf of the CREDO Collaboration,<br />
          PoS(ICRC2021)942, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0942"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0942</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Event rates of UHE photons cascading in the geomagnetic field at
            CTA-North",</strong
          ><br />
          K. Almeida Cheminant, D. Góra on behalf of the CREDO Collaboration,<br
          />
          PoS(ICRC2021)726, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0726"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0726</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Probing UHECR and cosmic ray ensemble scenarios with a global
            CREDO network",</strong
          ><br />
          A. Tursunov, P. Homola on behalf of the CREDO Collaboration,<br />
          PoS(ICRC2021)472, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0472"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0472</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Acceleration of ultra-high-energy cosmic rays by local
            supermassive black hole candidates",</strong
          ><br />
          A. Tursunov on behalf of the CREDO Collaboration,<br />
          PoS(ICRC2021)471, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0471"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0471</a
          >].
        </li>
        <br />
        <li>
          <strong>"Formation and propagation of cosmic-ray ensembles",</strong
          ><br />
          O. Sushchov, P. Homola on behalf of the CREDO Collaboration,<br />
          PoS(ICRC2021)465, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0465"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0465</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Particle density fluctuations and correlations in low energy
            Cosmic-Ray showers simulated with CORSIKA",</strong
          ><br />
          W. Stanek and J. Pryga on behalf of the CREDO Collaboration,<br />
          PoS(ICRC2021)462, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0462"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0462</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Simulations of Cosmic Ray Ensembles originated nearby the Sun",</strong
          ><br />

          D. Alvarez-Castillo, O. Sushchov, P. Homola on behalf of the CREDO
          Collaboration, PoS(ICRC2021)457, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0457"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0457</a
          >, ArXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://arxiv.org/abs/2112.11421"
            target="_blank"
            rel="noopener noreferrer">2112.11421</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Analysis of capability of detection of extensive air showers by
            simple scintillator detectors",</strong
          ><br />
          J. Pryga and W. Stanek on behalf of the CREDO Collaboration,<br />
          PoS(ICRC2021)430, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0430"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0430</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"On the possible method of identification of two probably cognate
            extensive air showers",</strong
          ><br />
          <span class="nowrap"
            >Y. Verbetsky, M. Svanidze, D. Beznosko, P. Homola on behalf of the
            CREDO Collaboration, PoS(ICRC2021)424, 2021.<br />
            [DOI:<a
              class="text-indigo-800 hover:text-blue-600"
              href="https://doi.org/10.22323/1.395.0424"
              target="_blank"
              rel="noopener noreferrer">10.22323/1.395.0424</a
            >].
          </span>
        </li>
        <br />
        <li>
          <strong
            >"A search for bursts at 0.1 PeV with a small air shower array",</strong
          ><br />
          R. Clay, J. Singh on behalf of the CREDO Collaboration,<br />
          PoS(ICRC2021)298, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0298"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0298</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Machine learning aided noise filtration and signal classification
            for CREDO experiment",</strong
          ><br />

          Ł. Bibrzycki, O. Bar, M. Niedźwiecki, M. Piekarczyk, K. Rzecki, S.
          Stuglik on behalf of the CREDO Collaboration,<br /> PoS(ICRC2021)227,
          2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0227"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0227</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Small shower array for education purposes -the CREDO-Maze
            Project",</strong
          ><br />

          M. Karbowiak, J. Orzechowski and T. Wibig on behalf of the CREDO
          Collaboration, PoS(ICRC2021)219, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0219"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0219</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Determination of Zenith Angle Dependence of Incoherent Cosmic Ray
            Muon Flux Using Smartphones of the CREDO Project",</strong
          ><br />
          T. Wibig, M. Karbowiak on behalf of the CREDO Collaboration,<br />
          PoS(ICRC2021)199, 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.22323/1.395.0199"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.395.0199</a
          >].
        </li>
      </ol>
    </div>
    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2019
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol start="6">
        <li>
          <strong style="text-align: justify;"
            >"Recognition and classification of the cosmic-ray events in images
            captured by CMOS/CCD cameras”,
          </strong><br />M. Niedźwiecki on behalf of the CREDO Collaboration,<br
          />
          PoS (ICRC2019) 367, 2019.<br />
          [arXiv:<a
            style="text-align: justify; color: #000080;"
            href="//arxiv.org/abs/1909.01929"
            target="_blank"
            rel="noopener noreferrer">1909.01929</a
          >, DOI:
          <a
            style="text-align: justify; color: #000080;"
            href="//pos.sissa.it/358/367"
            target="_blank"
            rel="noopener noreferrer">pos.sissa.it/358/367</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"A communication solution for portable detectors of the “Cosmic Ray
            Extremely Distributed Observatory”,</strong
          ><br />
          K. Smelcerz on behalf of the CREDO Collaboration,<br /> PoS (ICRC2019)
          428, 2019.<br />
          [arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1908.11288"
            target="_blank"
            rel="noopener noreferrer">1908.11288</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//pos.sissa.it/358/428/"
            target="_blank"
            rel="noopener noreferrer">pos.sissa.it/358/428/</a
          >].
        </li>
        <br />
        <li>
          <strong style="text-align: justify;"
            >"Public engagement as a scientific tool to implement
            multi-messenger strategies with the Cosmic-Ray Extremely Distributed
            Observatory ",
          </strong><br />P. Homola on behalf of the CREDO Collaboration,<br />
          PoS (Asterics2019) 034, 2019.<br />
          [arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1908.09734"
            target="_blank"
            rel="noopener noreferrer">1908.09734</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//pos.sissa.it/357/034/"
            target="_blank"
            rel="noopener noreferrer">pos.sissa.it/357/034/</a
          >].
        </li>
        <br />
        <li>
          <strong style="text-align: justify;"
            >"Search for ultra-high energy photons: observing the preshower
            effect with gamma-ray telescopes ",
          </strong><br />K. A. Cheminant on behalf of the CREDO Collaboration,<br
          />
          PoS (ICRC2019) 688, 2019.<br />
          [arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1908.08805"
            target="_blank"
            rel="noopener noreferrer">1908.08805</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//pos.sissa.it/358/688/"
            target="_blank"
            rel="noopener noreferrer">pos.sissa.it/358/688/</a
          >].
        </li>
        <br />
        <li>
          <strong style="text-align: justify;"
            >"Cosmic ray ensembles from ultra-high energy photons propagating in
            the galactic and intergalactic space ",
          </strong><br />N. Dhital on behalf of the CREDO Collaboration,<br />
          PoS (ICRC2019) 239, 2019.<strong
            style="text-align: justify; color: #000000;"><strong /></strong
          ><br />[arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1908.04600"
            target="_blank"
            rel="noopener noreferrer">1908.04600</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//pos.sissa.it/358/239/"
            target="_blank"
            rel="noopener noreferrer">pos.sissa.it/358/239/</a
          >].<strong style="text-align: justify; color: #000000;"
            ><strong /></strong
          >
        </li>
        <br />
        <li>
          <strong style="text-align: justify;"
            >"Cosmic Ray Extremely Distributed Observatory: Status and
            perspectives of a global cosmic ray detection framework",
          </strong><br />D. Gora on behalf of the CREDO Collaboration,<br /> PoS
          (ICRC2019) 272, 2019.
          <br />[arXiv:<a
            style="color: #000080;"
            href="//arxiv.org/abs/1908.04139"
            target="_blank"
            rel="noopener noreferrer">1908.04139</a
          >, DOI:
          <a
            style="color: #000080;"
            href="//pos.sissa.it/358/272/"
            target="_blank"
            rel="noopener noreferrer">pos.sissa.it/358/272/</a
          >].
        </li>
      </ol>
    </div>
    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2018
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol start="13">
        <li>
          <strong style="text-align: justify;"
            >"Detection of Cosmic-Ray Ensembles with CREDO",
          </strong><br />K. W. Woźniak (on behalf of the CREDO Collaboration)
          proceedings of the ISVHECRI 2018 conference<br />[arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1811.10248"
            target="_blank"
            rel="noopener noreferrer">1811.10248</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//www.epj-conferences.org/articles/epjconf/abs/2019/13/epjconf_isvhecri2018_15006/epjconf_isvhecri2018_15006.html"
            target="_blank"
            rel="noopener noreferrer">10.1051/epjconf/201920815006</a
          >]
        </li>
      </ol>
    </div>
    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2017
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol start="18">
        <li>
          <strong style="text-align: justify;"
            >"Search for electromagnetic super-preshowers using gamma-ray
            telescopes",
          </strong>K. A. Cheminant on behalf of the CREDO Collaboration,<br />
          PoS (ICRC2017) 860, 2017.<br />
          [arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1709.05180"
            target="_blank"
            rel="noopener noreferrer">1709.05180</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//doi.org/10.22323/1.301.0860"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.301.0860</a
          >].
        </li>
        <br />
        <li>
          <strong>"</strong><strong style="text-align: justify;"
            >We are all the Cosmic-Ray Extremely Distributed Observatory"</strong
          >, <br />N. Dhital on behalf of the CREDO Collaboration,<br /> PoS
          (ICRC2017) 1078, 2017.<br /> [arXiv:<a
            style="text-align: justify; color: #000080;"
            href="//arxiv.org/abs/1709.05196"
            target="_blank"
            rel="noopener noreferrer">1709.05196,</a
          >
          DOI:
          <a
            style="text-align: justify; color: #000080;"
            href="//doi.org/10.22323/1.301.1078"
            target="_blank"
            rel="noopener noreferrer">10.22323/1.301.1078</a
          >].
        </li>
      </ol>
    </div>
  </div>
</section>
