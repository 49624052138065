<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <a href="#/how-to-start"
        ><img
          class="alignleft wp-image-5484"
          src="./images/flags/wb.jpg"
          alt=""
          width="40"
          height="25"
        /></a
      >
      <h1
        class="lg:w-4/5 mx-auto text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        <b> How To Start</b>
      </h1>
      <h2
        class="text-base text-red-700 tracking-widest font-medium title-font mb-4"
      >
        Sposoby wniesienia prawdziwego, znaczącego wkładu w projekt CREDO
      </h2>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <h2 class="font-semibold">
          1. Pobierz aplikację do wykrywania cząstek i skutecznie uruchom
          detekcje
        </h2>
        <p class="w-full">
          Pobierz aplikacje na swój smartfon z systemem <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=science.credo.mobiledetector"
            target="_blank">Android</a
          >
          lub
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://apps.apple.com/pl/app/credo-detector/id1598629085"
            target="_blank">IOS</a
          >.
          <!-- A jeśli nie masz lub aplikacja na twoim urządzeniu działa gorzej
      to możesz wykorzystać naszą
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://user.credo.science/user-interface/web-detector/"
          target="_blank"
          rel="noopener"
          >przeglądarkową wersje
        </a></span
      >
      aplikacji. -->
          <br /> Instrukcje do aplikacji na Androidy:<br />a) w formie dokumentu
          pdf możesz znaleźć tutaj (<a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://credo.science/particle_hunters/download/ENG-UserGuide.pdf"
            target="_blank">ENG</a
          >,
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://credo.science/particle_hunters/download/PL-UserGuide.pdf"
            target="_blank">PL</a
          >)<br />
          b) w formie krótkiego filmiku do aplikacji (<a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/HowToStart-Android.mp4"
            target="_blank">PL</a
          >)

          <!-- Instrukcja do aplikacji przeglądarkowej – (<span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://user.credo.science/user-interface/web-detector/PL-WebDetector-UserGuide.pdf"
          target="_blank"
          rel="noopener">PL</a
        ></span
      >) -->
        </p>

        <strong class="py-2"
          >Pamiętaj! Przed włączeniem o zakryciu kamery</strong
        >
        <p>
          W tym celu można użyć magnesu lodówkowego (tak jak na zdjęciu) i
          umieścić go między obiektywem a etui telefonu. Można też wykorzystać
          kawałek grubej ciemnej taśmy do zaklejenia obiektywu – między taśmą a
          obiektywem dać kawałek kartonu (by nie zniszczyć, zabrudzić
          obiektywu).
        </p>
        <div class="flex w-full">
          <img
            src="./images/HTS-1.jpg"
            alt="cover camera 1"
            width="160"
            height="300"
          />
          <img
            src="./images/HTS-2.jpg"
            alt="cover camera 2"
            width="156"
            height="300"
          />
        </div>
        <h2 class="font-semibold py-2">
          2. Dołącz do gry/konkursu i pokaż, że Twój zespół jest najlepszy
        </h2>
        <p>
          Zbierz drużynę i dołącz do wydarzeń jakie organizuje projekt CREDO dla
          swoich użytkowników. Możesz dołączyć do konkursu<a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://credo.science/particle_hunters/pl/"
            target="_blank">"Łowcy Cząstek"</a
          >
          (wydarzenie/konkurs) organizowane co roku w okresie edukacji szkolnej od
          października do czerwca.
        </p>
        <h2 class="font-semibold py-2">
          3. Sprawdzaj jak wyglądają detekcje Twoje i innych.
        </h2>
        <p>
          Odwiedź stronę
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://api.credo.science/"
            target="_blank">api.credo.science</a
          >, odszukaj swoje detekcje np. zerkając na wyniki Twojej drużyny pod
          adresem
          <br /><span class="text-indigo-500"
            >api.credo.science/web/team/[tu wpisz nazwę swojej drużyny]<br
            /></span
          >(np.<a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://api.credo.science/web/team/FizykaKluczem/"
            target="_blank"
          >
            api.credo.science/web/team/FizykaKluczem</a
          >) i podziel się z innymi gdy zobaczysz coś niezwykłego.
        </p>

        <h2 class="font-semibold py-2">
          4. Polub i udostępnij fanpage projektu CREDO
        </h2>
        <p style="text-align: justify;">
          Wejdź na fanpage projektu credo (<a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="http://www.facebook.com/credo.science"
            target="_blank">www.facebook.com/credo.science</a
          >) i <br />
          <b>a)</b> kliknij przycisk “lubię to” pod nazwą strony i opisem.

          <img src="./images/HTS-3.png" alt="fb1" width="720" height="253" />
          <br />
          <b>b)</b> kliknij pod nazwą strony oraz opisem w przycisk “trzech
          kropek” i kliknij w opcje “udostępnij”. Udostepniając nasz fan page
          możesz także dodac kilka słów od siebie.
          <img src="./images/HTS-3.png" alt="fb2" width="720" height="649" />
        </p>
      </div>
    </div>
  </div>
</section>
