<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <a href="#/how-to-start-pl"
        ><img
          class="alignleft"
          src="./images/flags/polska.png"
          alt=""
          width="40"
          height="25"
        /></a
      >
      <h1
        class="lg:w-4/5 mx-auto text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        <b> How To Start</b>
      </h1>
      <h2
        class="text-base text-red-700 tracking-widest font-medium title-font mb-4"
      >
        Ways to make a real, meaningful contribution to CREDO
      </h2>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <h2 class="font-semibold">
          1. Download the particle detect application and start the detection
          successfully
        </h2>
        <p>
          Download the CREDO Detector app to your
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=science.credo.mobiledetector"
            target="_blank">Android</a
          >or

          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://apps.apple.com/pl/app/credo-detector/id1598629085"
            target="_blank">IOS</a
          >
          smartphone. And if you don't have one or the app on your device doesn't
          work as well, you can still use our

          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://user.credo.science/user-interface/web-detector/"
            target="_blank">browser version</a
          >
          of the app. <br />Instructions to the Android application can be found
          here (
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://credo.science/particle_hunters/download/ENG-UserGuide.pdf"
            target="_blank">ENG</a
          >,

          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://credo.science/particle_hunters/download/PL-UserGuide.pdf"
            target="_blank">PL</a
          >)<br />Instructions for web-detector (
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://user.credo.science/user-interface/web-detector/PL-WebDetector-UserGuide.pdf"
            target="_blank">PL</a
          >)
        </p>

        <strong class="py-2"
          >Before switching on the application, cover the camera</strong
        >
        <p>
          To do this, you can use a fridge magnet (as in the photo) and place it
          between the lens and the phone case. You can also use a piece of thick
          dark tape to cover the lens - put a piece of cardboard between the
          tape and the lens (in order not to destroy, dirty the lens).
        </p>
        <div class="flex w-full">
          <img
            src="./images/HTS-1.jpg"
            alt="cover camera"
            width="160"
            height="300"
          />
          <img
            src="./images/HTS-2.jpg"
            alt="cover camera"
            width="156"
            height="300"
          />
        </div>
        <h2 class="font-semibold py-2">
          2. Join the game/competition and show that your team is the best
        </h2>
        <p>
          Gather your team and join the events that the CREDO project organizes
          for their users. You can join the<span style="color: #0000ff;">
            <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://credo.science/particle_hunters/"
              >"particle hunters"</a
            ></span
          >
          competition (an event (competition) organized every year during the school
          education from October to June).
        </p>
        <h2 class="font-semibold py-2">
          3. Check how yours and others' detections look.
        </h2>
        <p>
          Visit the website
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://api.credo.science/"
            target="_blank">api.credo.science</a
          >, find your detections e.g. by looking at your team's results at<br
          />
          <span class="text-indigo-500"
            >api.credo.science/web/team/[enter your team name here]<br /></span
          >(i.e.
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="https://api.credo.science/web/team/FizykaKluczem/"
            target="_blank"
          >
            api.credo.science/web/team/FizykaKluczem</a
          >) and share with others when you see something unusual.
        </p>

        <h2 class="font-semibold py-2">
          4. Like us on the fan page of the CREDO project
        </h2>
        <p style="text-align: justify;">
          Go to the fan page of the credo project (<a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="http://www.facebook.com/credo.science"
            target="_blank">www.facebook.com/credo.science</a
          >) and <br />
          <b>a)</b> click the "like" button under the name of the page and
          description.
          <img src="./images/HTS-3.png" alt="fb1" width="720" height="253" />
          <br />
          <b>b)</b> click under the name of the page and the description on the
          "three dots" button and click on "share". By sharing our fan page you
          can also add a few words of your own.
          <img src="./images/HTS-3.png" alt="fb2" width="720" height="649" />
        </p>
      </div>
    </div>
  </div>
</section>
