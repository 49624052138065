<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO Detector Mobile Application
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/Mobile-world1-570x350.jpg"
      alt="Mobile-world1-570x350.jpg"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >The CREDO project is hunting for particle cascades from deep space in an
      effort to answer some of the fundamental questions about our Universe. You
      can directly contribute to this project in a variety of ways – one of
      which is by using our mobile application. [
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="#/detector/tutorial">read more</a
        >]</span
      >.
    </span>
  </div>
</div>
