<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong
        ><span class="ml-3 text-xl">CREDO Science Camp – Italy@Kraków 2025</span
        ></strong
      >
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/italy2025"
        class="text-blue-800 mr-5 uppercase tracking-wider font-serif hover:text-blue-700"
        ><strong>Information</strong></a
      >

      <a
        href="#/italy2025/plan"
        class="mr-5 uppercase tracking-wider font-serif hover:text-blue-700"
      >
        Plan of events
      </a>
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-blue-900"
      >
        <b> Information about</b> <br /><b
          >CREDO Science Camp – Italy@Kraków 2025</b
        >
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          📅 March 31 – April 11, 2025<br />
          📍
          <a
            class="text-blue-900"
            href="https://maps.app.goo.gl/vnapTNEgywDUZewi8"
            target="_blank"
            rel="noreferrer"
            >Henryk Niewodniczański Institute of Nuclear Physics</a
          >, Polish Academy of Sciences, Kraków
          <br />
          <br />CREDO Science Camp – Italy@Kraków 2025 is a two-week educational
          program organized as part of PON PCTO, an Italian initiative that
          integrates theoretical education with practical professional
          experience.
          <br /><br />
          During the camp, participants will explore topics related to cosmic radiation,
          astronomy, and scientific data analysis. The program includes theoretical
          lectures and hands-on programming workshops using Python.
          <br />
          <br />
          The activities of CREDO Science Camp – Italy@Kraków are carried out by
          the Institute of Nuclear Physics of the Polish Academy of Sciences in Kraków,
          in collaboration with invited experts. The participating students come
          from
          <a
            class="text-blue-900"
            href="https://cigna-baruffi-garelli.edu.it/"
            target="_blank"
            rel="noreferrer">Istituto G. Cigna in Mondovì</a
          >, and the program is supported by the Italian Ministry of Education,
          providing them with valuable skills as part of an international
          educational exchange.
        </p>
      </div>
      <!-- svelte-ignore a11y-img-redundant-alt -->
      <img
        class={"w-full h-auto rounded-lg shadow-md transition-transform duration-300 hover:scale-105"}
        src={"./images/posts/italy2025/doc_italy.jpg"}
        alt="Image"
      />
    </div>
  </div>
</section>
