<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="lg:w-4/5 mx-auto text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b> Praktyki </b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          W ramach naszego projektu oferujemy studentom praktyki, które pozwolą
          poznać lepiej nasz projekt, zdobyć doświadczenie, a także poznać jak
          wygląda praca w środowisku naukowym.
        </p>
        <br />
        <strong>Opiekun: Dr hab. Piotr Homola</strong>
      </div>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        Tematy prac naukowych:
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          <em
            >Jeśli chcesz możesz czytać w
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="/../../data/CREDO-praktyki.pdf"
              target="_blank"
              rel="noopener noreferrer">wersji pdf</a
            >
            lub w
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="#/education/practices/"
              rel="noopener noreferrer">języku angielskim</a
            ></em
          >
        </p>
        <p class=" py-2">
          <strong
            >1. Analiza danych satelitarnych dotyczących koncentracji aerozoli w
            rejonie Obserwatorium Pierre Auger.</strong
          ><br />
          W Obserwatorium Pierre Auger badamy wielkie pęki atmosferyczne: kaskady
          cząstek inicjowane przez promienie kosmiczne o najwyższych znanych energiach,
          czyli rzędu 10^20 eV. To wiele milionów razy więcej niż energie możliwe
          do uzyskania w ziemskich akceleratorach. Natura tych cząstek wciąż nie
          jest znana - pomóż nam rozwikłać zagadkę czekającą na rozwiązanie od ponad
          50-ciu lat. Twoja praca przyczyni się do lepszego zrozumienia wpływu aerozoli
          obecnych w atmosferze ziemskiej na interpretację danych.
        </p>
        <p class=" py-2">
          <strong
            >2. Badanie wpływu niejednorodności w rozkładzie aerozoli w rejonie
            Obserwatorium Pierre Auger na rekonstrukcję parametrów wielkich
            pęków atmosferycznych w kontekście poszukiwań efektów Nowej Fizyki.</strong
          ><br />
          W Obserwatorium Pierre Auger badamy wielkie pęki atmosferyczne: kaskady
          cząstek inicjowane przez promienie kosmiczne o najwyższych znanych energiach,
          czyli rzędu 10^20 eV. To wiele milionów razy więcej niż energie możliwe
          do uzyskania w ziemskich akceleratorach. Twoja praca przyczyni się do lepszego
          zrozumienia wpływu aerozoli obecnych w atmosferze ziemskiej na interpretację
          danych i być może do wskazania obserwowalnych śladów Nowej Fizyki.
        </p>
        <p class=" py-2">
          <strong
            >3. Testowanie modeli Nowej Fizyki poprzez obserwację (lub jej brak)
            wielkich pęków atmosferycznych inicjowanych przez duże kaskady
            fotonów.</strong
          ><br />
          W Obserwatorium Pierre Auger badamy wielkie pęki atmosferyczne: kaskady
          cząstek inicjowane przez promienie kosmiczne o najwyższych znanych energiach,
          czyli rzędu 10^20 eV. To wiele milionów razy więcej niż energie możliwe
          do uzyskania w ziemskich akceleratorach. Wielkie pęki mogą być inicjowane
          również przez kaskady fotonów i elektronów powstałe ponad atmosferą w wyniku
          niestandardowych procesów elektromagnetycznych. Identyfikacja przynajmniej
          niektórych typów takich kaskad powinna być możliwa poprzez analizę własności
          niestandardowych wielkich pęków. Rozejrzyj się ponad paradygmatem i działaj.
        </p>
        <p class=" py-2">
          <strong
            >4. Wpływ pola geomagnetycznego na własności wielkich pęków
            atmosferycznych inicjowanych przez duże kaskady elektromagnetyczne.</strong
          ><br />
          W Obserwatorium Pierre Auger badamy wielkie pęki atmosferyczne: kaskady
          cząstek inicjowane przez promienie kosmiczne o najwyższych znanych energiach,
          czyli rzędu 10^20 eV. To wiele milionów razy więcej niż energie możliwe
          do uzyskania w ziemskich mogą być inicjowane również przez kaskady fotonów
          i elektronów powstałe ponad atmosferą w wyniku niestandardowych procesów
          elektromagnetycznych. Identyfikacja przynajmniej niektórych typów takich
          kaskad powinna być możliwa poprzez analizę zależności własności wielkich
          pęków od pola geomagnetycznego. Pracując w tym kierunku będziesz miał/miała
          więc szansę na pośrednią obserwację przejawów zmodyfikowanej elektrodynamiki
          kwantowej - czyli na udział w dokonaniu długo oczekiwanego przełomu w nauce.
        </p>
        <p class=" py-2">
          <strong
            >5. Symulacje kaskadowania elektromagnetycznego fotonów o energiach
            rzędu 10^20 eV podczas propagacji w przestrzeni międzyplanetarnej.</strong
          ><br />
          W Obserwatorium Pierre Auger badamy wielkie pęki atmosferyczne: kaskady
          cząstek inicjowane przez promienie kosmiczne o najwyższych znanych energiach,
          czyli rzędu 10^20 eV. To wiele milionów razy więcej niż energie możliwe
          do uzyskania w ziemskich akceleratorach. Wielkie pęki mogą być inicjowane
          również przez kaskady fotonów i elektronów powstałe ponad atmosferą w wyniku
          oddziaływań zachodzących w niezbyt dokładnie znanych obszarach Układu Słonecznego.
          Jeżeli okazałoby się, że te kaskady występują powszechnie, należałoby gruntownie
          zweryfikować naszą wiedzę o promieniach kosmicznych skrajnie wysokich energii.
          Możesz w tym uczestniczyć.
        </p>
        <p class=" py-2">
          <strong
            >6. Numeryczna analiza równań elektrodynamiki kwantowej opisujących
            rozszczepienie fotonu o bardzo dużej energii na fotony wtórne.</strong
          ><br />
          W Obserwatorium Pierre Auger badamy wielkie pęki atmosferyczne: kaskady
          cząstek inicjowane przez promienie kosmiczne o najwyższych znanych energiach,
          czyli rzędu 10^20 eV. To wiele milionów razy więcej niż energie możliwe
          do uzyskania w ziemskich akceleratorach. Wielkie pęki mogą być inicjowane
          również przez kaskady fotonów i elektronów powstałe ponad atmosferą w wyniku
          rozszczepienia fotonu pierwotnego na fotony wtórne (ang. photon splitting).
          Zjawisko rozszczepienia fotonu nie zostało dotąd potwierdzone eksperymentalnie
          a wydaje się, że mogłoby być obserwowalne pośrednio w naszym Obserwatorium.
          Potrzebujemy jednak dokładnych obliczeń dotyczących przewidywań obserwacyjnych
          dla różnych założeń. Okazuje się, że numeryczne opracowanie istniejących
          równań jest wysoce nietrywialne. Potrzebujemy Twojej pomocy.
        </p>
        <p class=" py-2">
          <strong
            >7. Projektowanie i konstrukcja ekonomicznego detektora wielkich
            pęków atmosferycznych.</strong
          ><br />
          Niektóre egzotyczne procesy fizyczne mogą skutkować powstawaniem skorelowanych
          w czasie lecz znacznie odległych przestrzennie wielkich pęków atmosferycznych.
          Rejestracja takich zdarzeń może okazać się możliwa jedynie przy pomocy
          unikalnej infrastruktury detekcyjnej: światowej sieci niewielkich i tanich
          detektorów z dobrze zsynchronizowanymi zegarami. Projekt dopiero startuje,
          możesz być jednym z pionierów.
        </p>
        <p class=" py-2">
          <strong
            >8. Projektowanie i konstrukcja rozproszonej i zdywersyfikowanej
            sieci ekonomicznych detektorów promieniowania kosmicznego.</strong
          ><br />
          Niektóre egzotyczne procesy fizyczne mogą skutkować powstawaniem skorelowanych
          w czasie lecz znacznie odległych przestrzennie wielkich pęków atmosferycznych.
          Rejestracja takich zdarzeń może okazać się możliwa jedynie przy pomocy
          unikalnej infrastruktury detekcyjnej: światowej sieci niewielkich i tanich
          detektorów z dobrze zsynchronizowanymi zegarami. Projekt dopiero startuje,
          możesz być jednym z pionierów.
        </p>
        <p class=" py-2">
          <strong
            >9. Studium efektywności detekcji wielkich pęków atmosferycznych w
            rozproszonej i zdywersyfikowanej sieci małych detektorów
            promieniowania kosmicznego o różnych konfiguracjach.</strong
          ><br />
          Niektóre egzotyczne procesy fizyczne mogą skutkować powstawaniem skorelowanych
          w czasie lecz znacznie odległych przestrzennie wielkich pęków atmosferycznych.
          Rejestracja takich zdarzeń może okazać się możliwa jedynie przy pomocy
          unikalnej infrastruktury detekcyjnej: światowej sieci niewielkich i tanich
          detektorów z dobrze zsynchronizowanymi zegarami. Projekt dopiero startuje,
          możesz być jednym z pionierów.
        </p>
        <p class=" py-2">
          <strong
            >10. Poszukiwanie wielkich pęków atmosferycznych skorelowanych w
            czasie jako potencjalnej sygnatury procesów Nowej Fizyki.</strong
          ><br />Niektóre egzotyczne procesy fizyczne mogą skutkować
          powstawaniem skorelowanych w czasie lecz znacznie odległych
          przestrzennie wielkich pęków atmosferycznych. Rejestracja takich
          zdarzeń może okazać się możliwa w Obserwatorium Pierre Auger,
          największym istniejącym detektorze promieni kosmicznych. Pomóż nam to
          sprawdzić.
        </p>
        <p class=" py-2">
          <strong
            >11. Badanie własności wielkich pęków atmosferycznych inicjowanych
            przez monopole magnetyczne.</strong
          ><br />
          Monopole magnetyczne to jeszcze nie zaobserwowane, ale prawdopodobnie istniejące
          cząstki. Czy CREDO pomoże w ich wykryciu?
        </p>
        <p class=" py-2">
          <strong
            >12. Projekt i wykonanie prototypu internetowego systemu wymiany
            informacji w ramach dużej międzynarodowej współpracy naukowej.</strong
          ><br />
          Długo oczekiwany przełom w nauce może się dokonać w ramach rozległej współpracy
          naukowej. Aby taka współpraca była optymalnie efektywna potrzebny jest
          sprawny, wielofunkcyjny system komunikacyjny. Coś jak Facebook tylko do
          poważniejszych zastosowań. Popracuj z nami w tym kierunku.
        </p>
        <p class=" py-2">
          <strong
            >13. Poszukiwanie sygnatur procesów Nowej Fizyki w danych
            eksperymentów rejestrujących promieniowanie Czerenkowa indukowane
            przez cząstki o bardzo dużych energiach.</strong
          ><br />
          Promieniowanie Czerenkowa jest indukowane przez naładowane cząstki poruszające
          się w danym ośrodku szybciej niż światło w tym samym ośrodku. Fotony Czerenkowa
          są sygnałem dla astronomii gamma i tłem dla detektorów promieni kosmicznych
          o skrajnie wysokich energiach. Oczekujemy, że niektóre egzotyczne cząstki,
          na przykład monopole magnetyczne, mogą wywołać emisję światła Czerenkowa
          o niestandardowym natężeniu i rozkładzie kątowym. Takie "niepodobne do
          niczego" przypadki najczęściej nie są analizowane - odrzuca się je zgodnie
          z kryteriami doboru danych o odpowiednio "dobrej" jakości. Pomóż nam odszukać
          "dziwne" przypadki w danych z Obserwatorium Pierre Auger oraz z teleskopów
          gamma H.E.S.S. i MAGIC i sprawdź z nami czy przypadkiem nie są one przejawem
          Nowej Fizyki.
        </p>
      </div>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        Praktyki studenckie
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          1. Testowanie wydajności detektorów cząstek używanych w Obserwatorium
          Pierre Auger.
          <br />
          2. Budowa prototypu ekonomicznego licznika mionów<br />
          3. Projekt i wykonanie internetowego systemu wymiany informacji w ramach
          dużej, międzynarodowej współpracy naukowej
        </p>
      </div>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        Mini-jobs
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          1. Opracowanie założeń ćwiczenia „muon life time”
          <br />
          2. Budowa prototypu ekonomicznego licznika mionów
          <br />
          <strong
            >Chcesz więcej informacji o ofertach?<a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.ifj.edu.pl/edu/stud/?lang=pl"
              target="_blank"
              rel="noopener noreferrer">Odwiedź stronę IFJ</a
            ></strong
          ><br />
          <strong>
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.ifj.edu.pl/edu/praktyki/"
              target="_blank"
              rel="noopener noreferrer">Sprawy formalne</a
            >
            kontakt:
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="mailto:Marzena.Mitura-Nowak@ifj.edu.pl"
              >Marzena.Mitura-Nowak@ifj.edu.pl</a
            ></strong
          ><br />
          <strong>Kontakt z opiekunem: </strong><em
            ><strong>e-mail: </strong><a
              class="text-indigo-800 hover:text-blue-600"
              href="mailto: Piotr.Homola[at]ifj.edu.pl"
              ><strong>Piotr.Homola@ifj.edu.pl</strong></a
            ></em
          >
        </p>
      </div>
    </div>
  </div>
</section>
