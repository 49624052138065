<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>1st CREDO Visegrad Workshop 2024 (15-17.01.2024, IFJ PAN, Kraków)</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2 mb-5">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
      class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 lg:w-1/3"
      alt="img"
      src="./images/post4.png"
    />

    The first Visegrad Cosmic Ray Extremely Distributed Observatory (CREDO) Worshop 2024 under the framework of the project Boosting the social impact of CREDO is taking place in Cracow and comprises the Opening Worskhop and Educational fest.
<br/>
    The main topics of the workshop concern
    <br/>
    a) Sonification of data, Citizen Science and Educational aspects of CREDO,
    <br/>
    b) Interplay of the diverse cosmic rays detectors and standardisation of sharing and processing data,
    <br/>
    c) The physics of CREDO and related topics,
    <br/>
    which will be addressed each day of the workshop in the order above. <br/>
    All information regarding the Workshop can be found at 
    <a
    class="text-blue-900 hover:text-blue-500"
    href="https://indico.ifj.edu.pl/event/1176/"
    >Indico website</a
  >.
       </p>
       </div>
  
</section>
