<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    A new peer-reviewed article "for CREDO"
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/posts/2022-06-10.png" alt="images" width="400" />
  </div>
  Big CREDO milestone reached! Do extensive air shower walls exist? They could: ensembles
  of even millions of individual air showers induced in the Earth's atmosphere by
  high energy cosmic ray photons coming simultaneously at the Earth in groups, and
  forming characteristic, very elongated (size of the Earth or more) and very thin
  (hence "walls") footprints. If an observation of an air shower wall is made it
  would give an unprecedented insight into the physics processes occurring at the
  highest energies known, far beyond the reach of man-made accelerators, possibly
  giving us a clue about the very foundations of science. See the recent article
  "for CREDO" in which an air shower wall scenario is described in detail:<br />
  <a
    class="text-blue-600"
    href="https://iopscience.iop.org/article/10.1088/1475-7516/2022/03/038"
    >https://iopscience.iop.org/article/10.1088/1475-7516/2022/03/038</a
  >,<br />
  <a class="text-blue-600" href="https://arxiv.org/abs/1811.10334">
    https://arxiv.org/abs/1811.10334</a
  >.<br />
  Can we see air shower walls with a global network of detectors as proposed by CREDO?
  Or can we identify them in the already available datasets? Please join us and try
  to find out yourself - this analysis is in reach of non-professional science enthusiasts
  too!
</div>
