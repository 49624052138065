<script>
  let home_path = "#/about/team/credo-cs/";

  function pathes(subpage) {
    return home_path + subpage;
  }

  let step = 1;
  let activeButton = "btn1";

  async function goToStep(value) {
    step = value;

    let btn = document.getElementById(activeButton);
    btn.classList.remove("border-red-700");
    btn.classList.remove("text-red-700");
    btn.classList.remove("bg-black");
    // console.log("prev: " + activeButton + ", new: btn" + value);

    btn = document.getElementById("btn" + value);
    btn.classList.add("border-red-700");
    btn.classList.add("text-red-700");
    btn.classList.add("bg-black");
    activeButton = "btn" + value;
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-CS Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href={pathes("")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >About</a
      >
      <a
        href={pathes("research")}
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>Research</strong></a
      >
      <a
        href={pathes("people")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <a
        href={pathes("activites")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Activites</a
      >
      <a
        href={pathes("publications")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href={pathes("contact")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto font-serif">
    <div class="flex flex-col text-center w-full">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b>Current scientific topics</b>
      </h1>
    </div>

    <div class="container px-5 mx-auto flex flex-col max-h-max">
      <div class="flex mx-auto flex-wrap mb-5">
        <button
          id="btn1"
          on:click|preventDefault={() => goToStep(1)}
          class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t border-red-700 text-red-700 bg-black"
        >
          <img
            alt="testimonial"
            class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
            src="./images/team/credo-cs/astrotectonic.png"
          />
          <strong
            ><p class="mt-2 uppercase tracking-wider font-serif">
              ASTROTECTONIC<br /><br />
            </p></strong
          >
        </button>
        <button
          id="btn2"
          on:click|preventDefault={() => goToStep(2)}
          class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t"
          ><img
            alt="testimonial"
            class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
            src="./images/team/credo-cs/mexico.png"
          />
          <strong
            ><p class="mt-2 uppercase tracking-wider font-serif">
              CREDO-MEXICO<br /><br />
            </p></strong
          >
        </button>
        <button
          id="btn3"
          on:click|preventDefault={() => goToStep(3)}
          class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t"
        >
          <img
            alt="testimonial"
            class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
            src="./images/team/credo-cs/seismic.png"
          />
          <strong
            ><p class="mt-2 uppercase tracking-wider font-serif">
              Multi-messengers, cosmo-seismic<br /> and cosmo-medical correlations
            </p></strong
          >
        </button>
      </div>
    </div>
    {#if step == 1}
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p class="leading-relaxed mb-4">
          Cosmo-seismic correlation using Multimodal Neural Networks Time-Series
          Forcasting
          <br /><br />

          Any endeavor focused on defining the chances of a given event to occur
          in the future may heavily rely on a deep analysis of available
          historical data. The identification of cycles, patterns and anomalies
          can provide valuable insights on the expectation of the future data
          behavior. Naturally, such a purpose involves the study of time-series
          describing the evolution of a given physical phenomenon as a function
          of time and requires a strong understanding of the nature of the
          correlation between different types of measurements.
          <br /><br />
          The strategy is to combine time-series from various physical measurements
          in one single analysis procedure to look for specific variations that could
          indicate imminent seismic activity. For a given dataset, the challenge
          lies in the capacity to identify and discriminate variations preceding
          major earthquakes from natural fluctuations. These temporal variations
          may take different forms and conventional analytical models may struggle
          to find them.
          <br /><br />
          Developed Neural Net models are capable of providing extensive analysis
          of time-series and to extract the desired signal from the dominating background.
          We aim at making significant use of such a technology to analyze datasets
          obtained from geophysical and astrophysical measurements, and to search
          for anomalies in time-series
          <br /><br />

          See more at
          <a
            class="text-blue-900 tracking-wider"
            href="https://astrotectonic.com "
            rel="noopener noreferrer"
            >astrotectonic.com
          </a>
        </p>
      </div>
    {:else if step == 2}
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          Focused on finding the correlation between the level of cosmic rays
          and the occurrence of earthquakes based on data in Mexico. As part of
          the project, the use of previously constructed MCORD detector and the
          experience of the CREDO program is planned.
          <br /><br />This task is coordinated by Ph. D. Marcin Bielewicz.
        </p>
      </div>
    {:else if step == 3}
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          Investigation of different types of earthquake precursors based on
          data of signals from phenomena in the ionosphere or in earth's
          interior, as well as cosmic rays, planets/moons in the solar system
          (e.g. moonquakes and marsquakes), dark matter (in particular: UBDM,
          axions, ALPs and AQNs), and other multi-messengers; along with
          research aimed at studying relationships between these cosmic
          phenomena and human health (in particular: skin cancer and multiple
          sclerosis). <br /><br />Coordinator of this task: Ophir Ruimy.
        </p>
      </div>
    {/if}
  </div>
</section>
