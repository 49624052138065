<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b> Media About Us</b>
      </h1>

      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          Materiały prasowe (<a
            class="text-indigo-800 hover:text-blue-600"
            href="#/about/materialyprasowe"
            rel="noopener noreferrer"><strong>po polsku</strong></a
          >)
          <br />Press Kits (<a
            class="text-indigo-800 hover:text-blue-600"
            href="#/about/presskits"
            rel="noopener noreferrer"><strong>English</strong></a
          >)<br />Press Kits (<a
            class="text-indigo-800 hover:text-blue-600"
            href="#/about/presskits_spanish"
            rel="noopener noreferrer"><strong>Spanish</strong></a
          >)
        </p>
      </div>
      <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        EurekAlert!
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class="w-full">
          <li class="w-full">
            [15-Jun-2023] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.eurekalert.org/news-releases/992637"
              target="_blank"
              rel="noopener noreferrer"
              >Intriguing correlation between earthquakes and cosmic radiation</a
            >
          </li>
          <li class="w-full">
            [11-SEP-2019] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.eurekalert.org/pub_releases/2019-09/thni-hoc091119.php"
              target="_blank"
              rel="noopener noreferrer"
              >Hunters of cosmic ray particles come together in CREDO</a
            >
          </li>
          <li class="w-full">
            [4-OCT-2018] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.eurekalert.org/pub_releases/2018-10/thni-cfl100418.php"
              target="_blank"
              rel="noopener noreferrer"
              >CREDO's first light: The global particle detector begins its
              collection of scientific data</a
            >
          </li>
          <li class="w-full">
            [12-JUN-2018] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.eurekalert.org/pub_releases/2018-06/thni-cyt061218.php"
              target="_blank"
              rel="noopener noreferrer"
              >12-JUN-2018 CREDO: You too can help unveil the deepest puzzles of
              the universe</a
            >
          </li>
        </p>
      </div>

      <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        2023
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class="w-full">
          <li>
            [abcactionnews.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.abcactionnews.com/why-scientists-say-earthquakes-feel-powerful-after-cosmic-rays-strike"
              target="_blank"
            >
              Why scientists say earthquakes feel powerful after cosmic rays
              strike</a
            >
          </li>
          <li class="w-full">
            [bollyinside.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.bollyinside.com/news/latest-science-news/is-it-possible-to-predict-earthquakes-using-cosmic-radiation-an-unconventional-idea-with-scientific-basis/"
              target="_blank"
              rel="noopener noreferrer"
              >Is it possible to predict earthquakes using cosmic radiation? An
              unconventional idea with scientific basis</a
            >
          </li>
          <li class="w-full">
            [bljesak.info] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://bljesak.info/sci-tech/svemir/znanstvenici-na-rubu-epohlanog-otkrica-moci-ce-predvidjeti-potrese/424553"
              target="_blank"
              rel="noopener noreferrer"
              >Znanstvenici na rubu epohlanog otkrića - moći će predvidjeti
              potrese!?
            </a>
          </li>
          <li class="w-full">
            [downtoearth.org.in] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.downtoearth.org.in/news/world/scientists-find-link-between-surges-of-cosmic-radiation-from-space-and-earthquakes-90152"
              target="_blank"
              rel="noopener noreferrer"
              >Scientists find link between surges of cosmic radiation from
              space and earthquakes</a
            >
          </li>
          <li class="w-full">
            [earth.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.earth.com/news/breakthrough-surges-of-cosmic-radiation-from-space-directly-linked-to-earthquakes/"
              target="_blank"
              rel="noopener noreferrer"
              >Breakthrough: Surges of cosmic radiation from space directly
              linked to earthquakes</a
            >
          </li>
          <li class="w-full">
            [easternherald.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.easternherald.com/2023/06/19/scientists-have-noticed-jumps-in-cosmic-radiation-before-powerful-earthquakes/?amp=1"
              target="_blank"
              rel="noopener noreferrer"
              >Scientists have noticed jumps in cosmic radiation before powerful
              earthquakes</a
            >
          </li>
          <li>
            [edukemy.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://edukemy.com/daily-current-affairs/link-between-surges-of-cosmic-radiation-from-space-and-earthquakes/2023-06-23"
              target="_blank"
            >
              Link between surges of cosmic radiation from space and earthquakes</a
            >
          </li>
          <li class="w-full">
            [epochtimes.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.epochtimes.com/gb/23/6/26/n14022885.htm"
              target="_blank"
            >
              科学家第一次将宇宙辐射与地震联系起来</a
            >
          </li>
          <li>
            [fusilerias.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://fusilerias.com/radiacion-cosmica-y-sismos-un-vinculo-estadistico/"
              target="_blank"
            >
              Radiación cósmica y sismos tienen un vínculo estadístico</a
            >
          </li>
          <li>
            [futuroprossimo.it] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.futuroprossimo.it/2023/06/scoperta-sorprendente-ce-un-legame-tra-terremoti-e-raggi-cosmici/"
              target="_blank"
            >
              Scoperta sorprendente: c'è un legame tra terremoti e raggi cosmici</a
            >
          </li>
          <li class="w-full">
            [gigazine.net] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://gigazine.net/gsc_news/en/20230619-earthquake-cosmic-ray/"
              target="_blank"
              rel="noopener noreferrer"
              >It turns out that there is a remarkable correlation between the
              intensity of earthquakes and cosmic rays, and there is also the
              possibility that it can be applied to predict the occurrence of
              earthquakes</a
            >
          </li>
          <li>
            [gktoday.in] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.gktoday.in/the-link-between-earthquakes-and-cosmic-radiation/"
              target="_blank"
            >
              The Link between Earthquakes and Cosmic Radiation</a
            >
          </li>
          <li class="w-full">
            [greatgameindia.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://greatgameindia.com/scientists-find-link-between-surges-of-cosmic-radiation-from-space-and-earthquakes/"
              target="_blank"
              rel="noopener noreferrer"
              >Scientists Find Link Between Surges Of Cosmic Radiation From
              Space And Earthquakes</a
            >
          </li>
          <li>
            [iastoppers.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.iastoppers.com/articles/link-between-surges-of-cosmic-radiation-from-space-and-earthquakes"
              target="_blank"
            >
              Link between surges of cosmic radiation from space and earthquakes</a
            >
          </li>
          <li>
            [index.hu] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://index.hu/techtud/2023/06/20/geologia-csillagaszat-lengyel-akademia-krakko-foldrenges-kozmikus-sugarzas-credo-civil-program-statisztika/"
              target="_blank"
            >
              Áttörés: a kozmikus sugarak előre jelzik a földrengést</a
            >
          </li>
          <li>
            [inovacaotecnologica.com.br] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.inovacaotecnologica.com.br/noticias/noticia.php?artigo=descoberta-correlacao-entre-terremotos-raios-cosmicos&id=010125230616"
              target="_blank"
            >
              Descoberta correlação misteriosa entre terremotos e raios cósmicos</a
            >
          </li>
          <li>
            [kosmonauta.net] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://kosmonauta.net/2023/06/intrygujaca-korelacja-miedzy-trzesieniami-ziemi-a-promieniowaniem-kosmicznym/"
              target="_blank"
            >
              Intrygująca korelacja między trzęsieniami ziemi a promieniowaniem
              kosmicznym
            </a>
          </li>
          <li>
            [kp.ru] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.kp.ru/daily/27519/4782553/"
              target="_blank"
            >
              Угроза катаклизма нарастает: вспышки на Солнце могут вызвать
              землетрясения небывалой силы
            </a>
          </li>
          <li class="w-full">
            [mesonstars.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.mesonstars.com/space/earthquakes-would-be-related-to-solar-activity-according-to-a-study-by-physicists/"
              target="_blank"
              rel="noopener noreferrer"
              >Earthquakes would be related to solar activity according to a
              study by physicists</a
            >
          </li>
          <li>
            [natureworldnews.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.natureworldnews.com/articles/57008/20230619/cosmic-rays-possibly-connect-earthquakes-earth-study.htm"
              target="_blank"
            >
              Cosmic Rays Possibly Connect with Earthquakes on Earth [Study]</a
            >
          </li>
          <li>
            [naukawpolsce.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://naukawpolsce.pl/aktualnosci/news%2C97128%2Cintrygujaca-korelacja-miedzy-trzesieniami-ziemi-promieniowaniem-kosmicznym"
              target="_blank"
            >
              Intrygująca korelacja między trzęsieniami ziemi a promieniowaniem
              kosmicznym</a
            >
          </li>
          <li class="w-full">
            [newsbeezer.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://newsbeezer.com/malaysia/scientists-find-link-between-cosmic-ray-waves-from-space-and-earthquakes/"
              target="_blank"
              rel="noopener noreferrer"
              >Scientists find link between cosmic ray waves from space and
              earthquakes</a
            >
          </li>
          <li>
            [newsweek.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.newsweek.com/scientists-cosmic-radiation-earthquakes-1807690"
              target="_blank"
            >
              Scientists Link Cosmic Radiation to Earthquakes for the First Time</a
            >
          </li>
          <li>
            newsweekespanol.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://newsweekespanol.com/2023/06/radiacion-cosmica-terremotos-ciencia/"
              target="_blank"
            >
              ¿La radiación cósmica puede predecir los terremotos? Esto responde
              la ciencia</a
            >
          </li>
          <li>
            [noticiasdelaciencia.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://noticiasdelaciencia.com/art/46973/la-enigmatica-relacion-entre-la-radiacion-cosmica-y-los-terremotos-en-la-tierra"
              target="_blank"
            >
              La enigmática relación entre la radiación cósmica y los terremotos
              en la Tierra</a
            >
          </li>
          <li>
            [o2.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.o2.pl/technologia/sensacja-w-krakowie-odkrycie-ktore-moze-zmienic-caly-swiat-6912196023569056a"
              target="_blank"
            >
              Sensacja w Krakowie. Odkrycie, które może zmienić cały świat</a
            >
          </li>
          <li class="w-full">
            [phys.org] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://phys.org/news/2023-06-intriguing-earthquakes-cosmic.html"
              target="_blank"
              rel="noopener noreferrer"
              >The intriguing correlation between earthquakes and cosmic
              radiation</a
            >
          </li>
          <li>
            [popularmechanics.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.popularmechanics.com/science/environment/a44306755/earthquakes-and-cosmic-rays-connection/"
              target="_blank"
            >
              There's a Surprising Connection Between Earthquakes and Cosmic
              Rays.</a
            >
          </li>
          <li class="w-full">
            [pk.edu.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.pk.edu.pl/index.php?option=com_content&view=article&id=4886&catid=49&Itemid=1152&lang=pl"
              target="_blank"
              rel="noopener noreferrer"
              >Korelacja między trzęsieniami ziemi a promieniowaniem kosmicznym
              – ważne odkrycie z politechnicznym udziałem
            </a>
          </li>
          <li class="w-full">
            [prepp.in] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://prepp.in/news/e-1552-scientists-find-link-between-surges-of-cosmic-radiation-from-space-and-earthquakes-upsc-current-affairs"
              target="_blank"
              rel="noopener noreferrer"
              >Scientists Find Link Between Surges Of Cosmic Radiation From
              Space and Earthquakes</a
            >
          </li>
          <li>
            [projektpulsar.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.projektpulsar.pl/srodowisko/2216836,1,czy-promieniowanie-kosmiczne-powoduje-trzesienia-ziemi.read"
              target="_blank"
            >
              Czy promieniowanie kosmiczne powoduje trzęsienia ziemi?</a
            >
          </li>
          <li>
            [rmf24.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.rmf24.pl/nauka/news-czy-badania-promieniowania-kosmicznego-pomoga-przewidywac-tr,nId,6841024#crp_state=1"
              target="_blank"
            >
              Czy badania promieniowania kosmicznego pomogą przewidywać
              trzęsienia Ziemi?
            </a>
          </li>
          <li class="w-full">
            [scienceblog.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://scienceblog.com/538280/intriguing-correlation-found-between-earthquakes-and-cosmic-radiation/"
              target="_blank"
              rel="noopener noreferrer"
              >Intriguing Correlation Found Between Earthquakes and Cosmic
              Radiation</a
            >
          </li>
          <li class="w-full">
            [sciencemag.cz] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://sciencemag.cz/zemetreseni-a-kosmicke-zareni-spolu-souviseji-ale-jak-presne/"
              target="_blank"
              rel="noopener noreferrer"
              >Zemětřesení a kosmické záření spolu souvisejí, ale jak přesně?</a
            >
          </li>
          <li class="w-full">
            [scrippsnews.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://scrippsnews.com/stories/why-scientists-say-earthquakes-feel-powerful-after-cosmic-rays-strike/"
              target="_blank"
              rel="noopener noreferrer"
              >Why scientists say earthquakes feel powerful after cosmic rays
              strike</a
            >
          </li>
          <li class="w-full">
            [sondakika.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.sondakika.com/dunya/haber-depremler-kozmik-radyasyon-ile-iliskili-olabilir-16057542/"
              target="_blank"
              rel="noopener noreferrer"
              >Depremler Kozmik Radyasyon ile İlişkili Olabilir</a
            >
          </li>
          <li>
            [space.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.space.com/earthquakes-cosmic-radiation-link-found"
              target="_blank"
            >
              Earthquakes seem more intense after cosmic ray strikes. Scientists
              say this is why.</a
            >
          </li>
          <li>
            [space24.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://space24.pl/nauka-i-edukacja/korelacja-miedzy-trzesieniami-ziemi-a-promieniowaniem-kosmicznym"
              target="_blank"
            >
              Korelacja między trzęsieniami ziemi a promieniowaniem kosmicznym</a
            >
          </li>
          <li>
            [spaceref.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://spaceref.com/earth/intriguing-correlation-between-earthquakes-and-cosmic-radiation/"
              target="_blank"
            >
              Intriguing Correlation Between Earthquakes And Cosmic Radiation.</a
            >
          </li>
          <li>
            [spidersweb.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://spidersweb.pl/2023/06/promieniowanie-kosmiczne-trzesienia-ziemi.html"
              target="_blank"
            >
              Co mają wspólnego trzęsienia ziemi z promieniowaniem kosmicznym?
              Szokujące odkrycie.</a
            >
          </li>
          <li>
            [stoplusjednicka.cz] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://www.stoplusjednicka.cz/kosmicke-zareni-pravdepodobne-ovlivnuje-seismickou-aktivitu-zeme"
              target="_blank"
            >
              Kosmické záření pravděpodobně ovlivňuje seismickou aktivitu Země.</a
            >
          </li>
          <li class="w-full">
            [terra.com.br] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.terra.com.br/byte/ciencia/raios-cosmicos-podem-ajudar-a-prever-terremotos,2a7510664a28ca4efc429ab8afaf2916bsd94a9d.html"
              target="_blank"
              rel="noopener noreferrer"
              >Raios cósmicos podem ajudar a prever terremotos?</a
            >
          </li>
          <li class="w-full">
            [tiempo.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.tiempo.com/ram/correlacion-terremotos-radiacion-cosmica.html"
              target="_blank"
              rel="noopener noreferrer"
              >Los científicos encuentran una intrigante correlación entre
              terremotos y radiación cósmica</a
            >
          </li>
          <li class="w-full">
            [time.news] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://time.news/the-enigmatic-relationship-between-cosmic-radiation-and-earthquakes-on-earth/"
              target="_blank"
              rel="noopener noreferrer"
              >The enigmatic relationship between cosmic radiation and
              earthquakes on Earth</a
            >
          </li>
          <li class="w-full">
            [usakgundem.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.usakgundem.com/guncel/deprem-onceden-tahmin-edilebilir-mi-iste-arastirma-sonucu-h20294.html"
              target="_blank"
              rel="noopener noreferrer"
              >Deprem önceden tahmin edilebilir mi? İşte araştırma sonucu</a
            >
          </li>
          <li>
            [wattsupwiththat.com] <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              href="https://wattsupwiththat.com/2023/06/23/do-cosmic-rays-cause-earthquakes/"
              target="_blank"
            >
              Do Cosmic Rays Precede Earthquakes?</a
            >
          </li>
          <li class="w-full">
            [world-today-news.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.world-today-news.com/discovering-the-link-cosmic-radiation-and-seismic-activity-for-earthquake-prediction/"
              target="_blank"
              rel="noopener noreferrer"
              >Discovering the Link: Cosmic Radiation and Seismic Activity for
              Earthquake Prediction</a
            >
          </li>
          <li class="w-full">
            [zmescience.com] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.zmescience.com/science/news-science/cosmic-radiation-predict-earthquakes/"
              target="_blank"
              rel="noopener noreferrer"
              >Could cosmic radiation predict earthquakes? A wild idea, but
              still rooted in science</a
            >
          </li>
          <li class="w-full">
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://youtu.be/0oC2sFJYMfg"
              target="_blank"
              rel="noopener noreferrer">CREDO - Astronarium 155</a
            >
          </li>
        </p>
      </div>

      <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        2021
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class="w-full">
          <li class="w-full">
            [magazynpismo.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://magazynpismo.pl/rzeczywistosc/z-pismem-u/piotr-homola-lowca-pokemionow/"
              target="_blank"
              rel="noopener noreferrer"
              >Z PISMEM U... Piotr Homola. Łowca pokémionów</a
            >
          </li>
          <li class="w-full">
            [quark.sk] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.quark.sk/mobilny-detektor/"
              target="_blank"
              rel="noopener noreferrer">Mobilný detektor.</a
            >
          </li>
        </p>
      </div>
      <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        2019
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class="w-full">
          <li class="w-full">
            [chip.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.chip.pl/2019/09/pomoz-polskim-naukowcom-znalezc-ciemna-materie-wystarczy-smartfon/"
              target="_blank"
              rel="noopener noreferrer"
              >Pomóż polskim naukowcom znaleźć ciemną materię – wystarczy
              smartfon, www.chip.pl</a
            >
          </li>
          <li class="w-full">
            [krakow.tvp.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://krakow.tvp.pl/44349797/!"
              target="_blank"
              rel="noopener noreferrer"
              >„CREDO” zbiera informacje z kosmosu, www.krakow.tvp.pl</a
            >
          </li>
          <li class="w-full">
            [krakow.wyborcza.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://krakow.wyborcza.pl/krakow/7,44425,25181897,wszechswiat-w-telefonie-zostan-lowca-czastek-promieniowania.html"
              target="_blank"
              rel="noopener noreferrer"
              >Wszechświat w telefonie. Zostań łowcą cząstek promieniowania
              kosmicznego, www.krakow.wyborcza.pl</a
            >
          </li>
          <li class="w-full">
            [naukawpolsce.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://naukawpolsce.pl/aktualnosci/news%2C78551%2Clowcy-czastek-promieniowania-kosmicznego-dzialaja-juz-na-pieciu-kontynentach"
              target="_blank"
              rel="noopener noreferrer"
              >"ŁOWCY" CZĄSTEK promieniowania kosmicznego działają już na pięciu
              kontynentach, www.naukawpolsce.pap.pl</a
            >
          </li>
          <li class="w-full">
            [polskieradio4] <a
              class="text-indigo-800 hover:text-blue-600"
              href="./data/20190911_POLSKIE_RADIO_PROGRAM_4.mp3"
              target="_blank"
              rel="noopener noreferrer">CREDO w Polskie Radio program 4 [mp3]</a
            >
          </li>
          <li class="w-full">
            [radiokrakow.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.radiokrakow.pl/audycje/pracuja-na-nobla/lowcy-czastek-kosmicznych/?fbclid=IwAR37y3dKy9qnrqyUcY090znvc_TWcGh4MCtwVDdZ6v7aTQxkHzCVM5mWMR4/"
              target="_blank"
              rel="noopener noreferrer">Łowcy kosmicznych cząstek</a
            >
          </li>
          <li class="w-full">
            [radiokrakow.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.radiokrakow.pl/aktualnosci/krakow/krakowski-projekt-naukowy-zyskal-swiatowy-zasieg"
              target="_blank"
              rel="noopener noreferrer"
              >Krakowski projekt naukowy zyskał światowy zasięg</a
            >
          </li>
          <li class="w-full">
            [radio zet] <a
              class="text-indigo-800 hover:text-blue-600"
              href="./data/20190911_RADIO_ZET_WIADOMOSCI.mp3"
              target="_blank"
              rel="noopener noreferrer">CREDO w Radio Zet [mp3]</a
            >
          </li>
          <li class="w-full">
            [TVP 3] <a
              class="text-indigo-800 hover:text-blue-600"
              href="./data/20190911_TVP3_KRAKOW_KRONIKA.mp4"
              target="_blank"
              rel="noopener noreferrer">CREDO w TVP3 Kraków - Kronika [mp4]</a
            >
          </li>
          <li class="w-full">
            [tygodnikpowszechny.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.tygodnikpowszechny.pl/jak-zmienic-swoj-smartfon-w-detektor-promieniowania-kosmicznego-160352"
              target="_blank"
              rel="noopener noreferrer"
              >Jak zmienić swój smartfon w detektor promieniowania kosmicznego.
              Tygodnik powszechny.</a
            ><br />
            Podcast with P. Homola [
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="#/education/podcast_pl"
              target="_blank"
              rel="noopener noreferrer">Text PL</a
            >
            /

            <a
              class="text-indigo-800 hover:text-blue-600"
              href="#/education/podcast"
              target="_blank"
              rel="noopener noreferrer">Text ENG</a
            > ]
          </li>
          <li class="w-full">
            [tvp.info] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.tvp.info/44348963/lapmy-kosmos-na-smartfona-ciekawy-pomysl-krakowskich-naukowcow"
              target="_blank"
              rel="noopener noreferrer"
              >Łapmy kosmos na smartfona. Ciekawy pomysł krakowskich naukowców</a
            >
          </li>
        </p>
      </div>
      <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        2018
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class="w-full">
          <li class="w-full">
            [krakow.tvp.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//krakow.tvp.pl/37275535/19052018"
              target="_blank"
              rel="noopener noreferrer">CREDO in TVP3</a
            >
          </li>
          <li class="w-full">
            [naukawpolsce.pap.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//naukawpolsce.pap.pl/aktualnosci/news,31280,krakow-zaprezentowano-pierwsze-dane-z-detektora-czastek-credo.html"
              target="_blank"
              rel="noopener noreferrer"
              >Kraków/ Zaprezentowano pierwsze dane z detektora cząstek CREDO</a
            >
          </li>
          <li class="w-full">
            [radiokrakow.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.radiokrakow.pl/audycje/pracuja-na-nobla/credo-detector/"
              target="_blank"
              rel="noopener noreferrer">RADIO KRAKÓW - CREDO DETECTOR</a
            >
          </li>
          <li class="w-full">
            [rmf24.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.rmf24.pl/nauka/news-co-twoj-smartfon-moze-robic-w-nocy-podgladac-promieniowanie-,nId,2641227"
              target="_blank"
              rel="noopener noreferrer"
              >RMF - Co twój smartfon może robić w nocy? Podglądać...
              promieniowanie kosmiczne</a
            >
          </li>
          <li class="w-full">
            [vod.tvp.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//vod.tvp.pl/video/sonda-2,odc-73-proznia,35698569"
              target="_blank"
              rel="noopener noreferrer">CREDO in SONDA2</a
            >
          </li>
        </p>
      </div>
      <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        2017
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class="w-full">
          <li class="w-full">
            [astronet.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//news.astronet.pl/index.php/2017/08/30/od-dzis-kazdy-moze-za-pomoca-smartfona-poszukac-ciemnej-materii/"
              target="_blank"
              rel="noopener noreferrer"
              >Od dziś każdy może za pomocą smartfona poszukać ciemnej materii</a
            >
          </li>
          <li class="w-full">CREDO Anniversary Symposium 2017</li>
          <li class="w-full">
            [crazynauka.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.crazynauka.pl/twoj-smartfon-poszuka-ciemnej-materii-dzieki-polskim-naukowcom/"
              target="_blank"
              rel="noopener noreferrer">About CREDO app on Google Play</a
            >
          </li>
          <li class="w-full">
            [crazynauka.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.crazynauka.pl/twoj-smartfon-poszuka-ciemnej-materii-dzieki-polskim-naukowcom/"
              target="_blank"
              rel="noopener noreferrer"
              >Twój smartfon poszuka ciemnej materii – dzięki polskim naukowcom</a
            >
          </li>
          <li class="w-full">
            [krakow.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="https://www.krakow.pl/aktualnosci/212711,34,komunikat,badaj_kosmos_smartfonem_i_wspieraj_rozwoj_nauki.html"
              target="_blank"
              rel="noopener noreferrer"
              >Badaj kosmos smartfonem i wspieraj rozwój nauki</a
            >
          </li>
          <li class="w-full">
            [krakow.ptma.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//krakow.ptma.pl/rocznicowe-seminarium-credo-w-instytucie-fizyki-jadrowej-w-krakowie-30-31-08-2017/"
              target="_blank"
              rel="noopener noreferrer"
              >Rocznicowe seminarium CREDO w Instytucie Fizyki Jądrowej w
              Krakowie, 30-31.08.2017</a
            >
          </li>
          <li class="w-full">
            [mlodytechnik.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//mlodytechnik.pl/news/29103-uzyj-smartfona-do-poszukiwania-ciemnej-materii"
              target="_blank"
              rel="noopener noreferrer"
              >Użyj smartfona do poszukiwania ciemnej materi</a
            >
          </li>
          <li class="w-full">
            [naukawpolsce.pap.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//naukawpolsce.pap.pl/aktualnosci/news,459490,od-srody-kazdy-moze-za-pomoca-smartfona-poszukac-ciemnej-materii.html"
              target="_blank"
              rel="noopener noreferrer"
              >Od środy każdy może za pomocą smartfona poszukać ciemnej materii</a
            >
          </li>
          <li class="w-full">
            [polsatnews.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.polsatnews.pl/wiadomosc/2017-08-30/chca-znalezc-ciemna-materie-potrzebny-milion-ochotnikow-ze-smartfonami/"
              target="_blank"
              rel="noopener noreferrer"
              >Chcą znaleźć ciemną materię. Potrzebny milion ochotników ze
              smartfonami</a
            >
          </li>
          <li class="w-full">
            [polskieradio.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.polskieradio.pl/9/5700/Artykul/1836570,Telefon-w-sluzbie-nauki"
              target="_blank"
              rel="noopener noreferrer">Telefon w służbie nauki</a
            >
          </li>
        </p>
      </div>
      <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        2016
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class="w-full">
          <li class="w-full">
            [krakow.ptma.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//krakow.ptma.pl/start-projektu-credo-i-jego-mozliwe-implikacje-dla-rozwoju-astrofizyki/"
              >Start projektu CREDO i jego możliwe implikacje dla rozwoju
              astrofizyki</a
            >
          </li>
          <li class="w-full">
            [krakow.tvp.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//krakow.tvp.pl/26748843/smartfonowe-obserwatorium"
              >TVP3 Kraków - Kronika, EVENING NEWS - Smartfonowe obserwatorium</a
            >
          </li>
          <li class="w-full">
            [krakow.wyborcza.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//krakow.wyborcza.pl/krakow/1,44425,20643867,masz-smartfona-zostan-odkrywca-obserwuj-promieniowanie-kosmiczne.html?disableRedirects=true "
              >Gazeta Wyborcza, Masz smartfona? Zostań odkrywcą, obserwuj
              promieniowanie kosmiczne</a
            >
          </li>
          <li class="w-full">
            [radiokrakow.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.radiokrakow.pl/wiadomosci/aktualnosci/kazdy-moze-przyczynic-sie-do-waznego-odkrycia-naukowego-wystarczy-telefon-komorkowy/"
              >Radio Kraków, Każdy może przyczynić się do ważnego odkrycia
              naukowego. Wystarczy... telefon komórkowy</a
            >
          </li>
          <li class="w-full">
            [rmf24.pl] <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.rmf24.pl/nauka/news-projekt-credo-pozycz-nauce-smartfona-moze-dostaniesz-nobla,nId,2262168"
              >RMF24, Projekt CREDO: Pożycz nauce smartfona, może
              dostaniesz...... Nobla</a
            >
          </li>
        </p>
      </div>
    </div>
  </div>
</section>
