<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b> People & Groups</b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          Spokesperson:<br />
          <strong>Piotr Homola</strong>
          <a
            style="color: #000080;"
            href="//credo.science/homola"
            target="_blank"
            rel="noopener noreferrer"
          >
            visit page</a
          >
        </p>
      </div>

      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          Scientific teams - which produce publications under the CREDO
          collaboration:
        </p>
        <div class="flex flex-wrap mb-2">
          <!-- CREDO - CS -->
          <div class="p-2 lg:w-1/3">
            <div
              class="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative"
            >
              <h1
                class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-1"
              >
                CREDO - CS
              </h1>
              <h3
                class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
              >
                <strong>C</strong>osmo <strong>S</strong>eismic
              </h3>
              <p class="leading-relaxed mb-3">
                A team that conducts work and research in the field of the
                search for so-called cosmo-seismic correlations that are
                correlations between cosmic ray rates and seismic activity.
              </p>
              <a
                href="#/about/team/credo-cs"
                class="text-indigo-500 hover:text-indigo-700 inline-flex items-center"
                >Read More
                <svg
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <!-- CREDO - ML -->
          <div class="p-2 lg:w-1/3">
            <div
              class="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative"
            >
              <h1
                class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-1"
              >
                CREDO - ML
              </h1>
              <h3
                class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
              >
                <strong>M</strong>achine <strong>L</strong>earning
              </h3>
              <p class="leading-relaxed mb-3">
                A group of computer scientists (from different universities)
                working on analysis of detection images from CMOS detectors.
              </p>
              <a
                href="#/about/team/credo-ml"
                class="text-indigo-500 hover:text-indigo-700 inline-flex items-center"
                >Read More
                <svg
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <!-- CREDO - maze -->
          <div class="p-2 lg:w-1/3">
            <div
              class="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative"
            >
              <h1
                class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-1"
              >
                CREDO - Maze
              </h1>
              <h3
                class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
              >
                <strong>Maze</strong>
              </h3>
              <p class="leading-relaxed mb-3">
                The goal of this project is to provide schools with the simplest
                and easy-to-use scientific instruments, real tools for
                practicing physics at a high level of sophistication both
                experimentally and interpretively.
              </p>
              <a
                href="https://www.credo-maze.org"
                class="text-indigo-500 hover:text-indigo-700 inline-flex items-center"
                >Read More
                <svg
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>Others teams</p>
        <div class="flex flex-wrap mb-2">
          <!-- CREDO - App -->
          <div class="p-2 lg:w-1/3">
            <div
              class="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative"
            >
              <h1
                class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-1"
              >
                CREDO-App
              </h1>
              <h3
                class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
              >
                <strong>App</strong>lication <strong />
              </h3>
              <p class="leading-relaxed mb-3">
                A group of computer scientists developing applications for
                particle detection on CMOS camera devices.
              </p>
              <p
                class="text-gray-200 hover:text-gray-400 inline-flex items-center disabled"
              >
                Read More
                <svg
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg>
              </p>
            </div>
          </div>
          <!-- CREDO - Edu -->
          <div class="p-2 lg:w-1/3">
            <div
              class="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative"
            >
              <h1
                class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-1"
              >
                CREDO-Edu
              </h1>
              <h3
                class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
              >
                <strong>Edu</strong>cation
              </h3>
              <p class="leading-relaxed mb-3">
                An educational group made up of researchers and teachers
                dedicated to teaching and disseminating knowledge to
                children/young people. This group organises conferences for
                schools, creates learning materials
              </p>
              <a
                href="#/about/team/credo-edu"
                class="text-indigo-500 hover:text-indigo-700 inline-flex items-center"
                >Read More
                <svg
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg></a
              >
            </div>
          </div>
          <!-- CREDO - Pop -->
          <div class="p-2 lg:w-1/3">
            <div
              class="h-full bg-gray-100 bg-opacity-75 px-8 pt-8 pb-12 rounded-lg overflow-hidden text-center relative"
            >
              <h1
                class="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-1"
              >
                CREDO-Popularization
              </h1>
              <h3
                class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
              >
                <strong>Popularization</strong>
              </h3>
              <p class="leading-relaxed mb-3">
                A team dedicated to popularising the CREDO project, e.g. by
                giving demonstrations or participating in Researchers' Nights,
                Open Days and organising competitions for schools.
              </p>
              <p
                class="text-gray-200 hover:text-gray-400 inline-flex items-center disabled"
              >
                Read More
                <svg
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5l7 7-7 7" />
                </svg>
              </p>
            </div>
          </div>
          <p class="text-center font-title text-xl my-4">
            Each group is open to new people - if you want to join a group,
            contact us by email contact[at]credo.science
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
