<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>New grant for Georgian members of the CREDO collaboration.</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2 mb-5">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
        class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/3 mb-3"
        alt="img"
        src="./images/posts/geo_grant.png"
      />

      A member of the CREDO collaboration, the Georgian group of the GELATICA
      project (<a
        class="text-blue-900 hover:text-blue-500"
        href="https://gelatica.tsu.ge/">https://gelatica.tsu.ge/</a
      >
      ) received a grant from the National Science Foundation of Georgia for three
      years in the amount of 220,000 GEL. <br /><br />
      Project topic: “Preliminary study of cosmic ray ensemble phenomenology through
      observations of remote extensive air showers using the operational GELATICA
      network.” <br /><br />
      As part of this grant <br />
      a) The team will be able to add a new station to the GELATICA detectors network
      in Tbilisi. <br />
      b) Active contacts with members of the CREDO collaboration are planned, in
      particular a visit to Lodz with Prof. Tadeusz Wibig this autumn based on a
      mutual agreement.
    </p>
  </div>
</section>
