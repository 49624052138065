<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO in Wroclaw
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/UW logo.png"
      alt="UW logo.png"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >16.06.2017 The objectives of the CREDO project were presented in the
      Institute of Theoretical Physics, Wroclaw University. The talk given by
      Dr. Piotr Homola, upon an invitation from Prof. Jan Sobczyk and Prof.
      Ziemowit Popowicz, was received with clear interest. The Institute of
      Theoretical Physics considers joining CREDO formally in the near future –
      declares Prof. Sobczyk, the head of the Institute.
    </span>
  </div>
</div>
