<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO on "3. Śląski Festiwal Nauki" - Katowice
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/Festiwal-nauki-katowice-570x350.png"
      alt="images"
      width="400"
    />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >Interview (in Polish) on CREDO given by Piotr Homola, Instytut Fizyki
      Jądrowej PAN, after his talk on the Main Stage of 3. Śląski Festiwal Nauki
      Katowice (part of transmission live, the CREDO stuff begins at 2:42:49). <span
        style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.youtube.com/watch?v=_Le4rY_Bidc&t=9769s"
          target="_blank"
          rel="noreferrer">Watch video</a
        >
      </span>.
    </span>
  </div>
</div>
