<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto text-black">
    <div class="flex flex-col text-center w-full mb-10">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b>Peer reviewed publications</b>
      </h1>
    </div>
    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2023
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong
            >"Recent Developments within The Cosmic Ray Extremely Distributed
            Observatory (CREDO)",</strong
          ><br />
          David Edwin Alvarez-Castillo et al. for the CREDO collaboration, Supl.
          Rev. Mex. Fis. 4(2) 021123 1-8 (2023).<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.31349/SuplRevMexFis.4.021123"
            target="_blank"
            rel="noopener noreferrer">10.31349/SuplRevMexFis.4.021123</a
          >].
        </li>
        <br />
        <li>
          <strong style="text-align: justify;"
            >"Observation of large scale precursor correlations between cosmic
            rays and earthquakes with a periodicity similar to the solar cycle",
          </strong><br />P. Homola, et al. (CREDO Collab.),
          <br />
          J. Atmos. Sol.-Terr. Phys. 247 (2023), 106068, June 2023.<br />
          [arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://arxiv.org/abs/2204.12310"
            target="_blank"
            rel="noopener noreferrer">2204.12310</a
          >, DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.1016/j.jastp.2023.106068"
            target="_blank"
            rel="noopener noreferrer">10.1016/j.jastp.2023.106068</a
          >].
        </li>
        <!-- <br /> -->
      </ol>
    </div>

    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2022
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong style="text-align: justify;"
            >"A New Method of Simulation of Cosmic-Ray Ensembles Initiated by
            Synchrotron Radiation",
          </strong><br />O. Sushchov, P. Homola, et al. (CREDO Collab.),
          <br />
          Symmetry 2022, 14(10), 1961, September 2022.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.3390/sym14101961"
            target="_blank"
            rel="noopener noreferrer">10.3390/sym14101961</a
          >].
        </li>
        <br />
        <li>
          <strong style="text-align: justify;"
            >"Simulation of the Isotropic Ultra-High Energy Photon Flux in the
            Solar Magnetic Field",
          </strong><br />B. Poncyljusz, T. Bulik, N. Dhital, O. Sushchov, S.
          Stuglik, P. Homola, D. Alvarez-Castillo, et al. (CREDO Collab.),
          <br />
          Universe 2022, 8, 498, September 2022.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.3390/universe8100498"
            target="_blank"
            rel="noopener noreferrer">10.3390/universe8100498</a
          >].
        </li>
        <br />
        <li>
          <strong style="text-align: justify;"
            >"Analysis of the Capability of Detection of Extensive Air Showers
            by Simple Scintillator Detectors",
          </strong><br />J.S. Pryga, W. Stanek, K.W. Woźniak, P. Homola, K.
          Almeida Cheminant, S. Stuglik, et al. (CREDO Collab.),
          <br />Universe 2022, 8, 425, August 2022.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.3390/universe8080425"
            target="_blank"
            rel="noopener noreferrer">10.3390/universe8080425</a
          >].
        </li>
        <br />
        <li>
          <strong style="text-align: justify;"
            >"Cosmic ray ensembles as signatures of ultra-high energy photons
            interacting with the solar magnetic field",
          </strong><br />N. Dhital, P. Homola, D. Alvarez-Castillo, D. Góra, H.
          Wilczyński, K. Almeida Cheminant, B. Poncyljusz, J. Mędrala, G. Opiła
          , et al. (CREDO Collab.), <br />Journal of Cosmology and Astroparticle
          Physics 2022(03), 038, March 2022.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.1088/1475-7516/2022/03/038"
            target="_blank"
            rel="noopener noreferrer">10.1088/1475-7516/2022/03/038</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"A Search for Cosmic Ray Bursts at 0.1 PeV with a Small Air Shower
            Array",</strong
          ><br />

          R. Clay, J. Singh, P. Homola, et al. (CREDO Collab.),
          <em> Symmetry 2022,14 (3)</em>, 501, February 2022.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.3390/sym14030501"
            target="_blank"
            rel="noopener noreferrer">10.3390/sym14030501</a
          >].
        </li>
      </ol>
    </div>
    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2021
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong
            >"Zernike Moment Based Classification of Cosmic Ray Candidate Hits
            from CMOS Sensors",</strong
          ><br />

          O. Bar, Ł. Bibrzycki, M. Niedźwiecki, M. Piekarczyk, K. Rzecki, T.
          Sośnicki, S. Stuglik, M. Frontczak, P. Homola, D.E. Alvarez-Castillo,
          T. Andersen, A. Tursunov, on behalf of CREDO Collaboration,<br />
          <em> Sensors 2021,</em><em>21 (22)</em>, 7718, November 2021.<br
          />[DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.3390/s21227718"
            target="_blank"
            rel="noopener noreferrer">10.3390/s21227718</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"CNN-Based Classifier as an Offline Trigger for the CREDO
            Experiment",</strong
          ><br />

          M. Piekarczyk, O. Bar, Ł. Bibrzycki, M. Niedźwiecki, K. Rzecki, S.
          Stuglik, et al. (CREDO Collab.),<br />
          <em> Sensors 2021,</em><em>21</em>, 4804, August 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.3390/s21144804"
            target="_blank"
            rel="noopener noreferrer">10.3390/s21144804</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Determination of Zenith Angle Dependence of Incoherent Cosmic Ray
            Muon Flux Using Smartphones of the CREDO Project",</strong
          ><br />
          M. Karbowiak, T. Wibig, et al. (CREDO Collab.),
          <em>Appl. Sci.</em>
          2021, <em>11</em>, 1185, January 2021.<br />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.3390/app11031185"
            target="_blank"
            rel="noopener noreferrer">10.3390/app11031185</a
          >].
        </li>
      </ol>
    </div>

    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2020
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong>"Cosmic Ray Extremely Distributed Observatory",</strong><br />
          P. Homola, et al. (CREDO Collab.), Symmetry 2020, 12(11), 1835, 2020.<br
          />
          [arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/2010.08351">2010.08351</a
          >, DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://doi.org/10.3390/sym12111835"
            target="_blank"
            rel="noopener noreferrer">10.3390/sym12111835</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Towards A Global Cosmic Ray Sensor Network: CREDO Detector as the
            First Open-Source Mobile Application Enabling Detection of
            Penetrating Radiation",</strong
          ><br />
          Ł.Bibrzycki, D.Burakowski, P.Homola, M.Piekarczyk, M.Niedźwiecki, K.Rzecki,
          S.Stuglik, A.Tursunov, et al. (CREDO Collab.) Symmetry, 12(11), 1802, 2020.<br
          />
          [DOI:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//www.mdpi.com/2073-8994/12/11/1802"
            target="_blank"
            rel="noopener noreferrer">10.3390/sym12111802</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"The first CREDO registration of extensive air shower”,
          </strong><br />M. Karbowiak, T. Wibig, et al. (CREDO Collab.), Physics
          Education, 55(5),055021, July 2020.
          <br />
          [DOI:
          <a
            class="doi text-indigo-800 hover:text-blue-600"
            title="Persistent link using digital object identifier"
            href="//doi.org/10.1088/1361-6552/ab9dbc"
            target="_blank"
            rel="noreferrer noopener"
            aria-label="Persistent link using digital object identifier"
            >10.1088/1361-6552/ab9dbc</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Search for ultra-high energy photons through preshower effect with
            gamma-ray telescopes: Study of CTA-North efficiency”,
          </strong><br />K. A. Cheminant, et al. (CREDO Collab.),<br />
          Astroparticle Physics, 123, 102489, December 2020.<br />
          [arXiv:<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/2007.11105"
            target="_blank"
            rel="noopener noreferrer">2007.11105</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            title="Persistent link using digital object identifier"
            href="//doi.org/10.1016/j.astropartphys.2020.102489"
            target="_blank"
            rel="noreferrer noopener">10.1016/j.astropartphys.2020.102489</a
          >].
        </li>
      </ol>
    </div>

    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2019
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong>"CREDO Project",</strong><br />
          R. Kamiński , T. Wibig, et al. (CREDO Collab.),<br /> Acta Phys. Pol.
          B 50, 2001 (2019).<br /> [<a
            class="text-indigo-800 hover:text-blue-600"
            href="https://arxiv.org/abs/2009.12314"
            target="_blank"
            rel="noopener noreferrer">arXiv: 2009.12314</a
          >,
          <a
            href="https://inspirehep.net/literature/1772375"
            target="_blank"
            rel="noopener noreferrer">DOI:10.5506/AphysPolB.50.2001</a
          >].
        </li>
      </ol>
    </div>

    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2018
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong
            >"Cosmic-Ray Extremely Distributed Observatory: status and
            perspectives",</strong
          ><br />
          D. Góra, et al. (CREDO Collab.),<br /> Universe 2018,4(11) 111, 2018.<br
          />[<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1810.10410"
            target="_blank"
            rel="noopener noreferrer">arXiv:1810.10410</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//www.mdpi.com/2218-1997/4/11/111"
            target="_blank"
            rel="noopener noreferrer">10.3390/universe4110111</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Cosmic Ray Extremely Distributed Observatory: a global network of
            detectors to probe contemporary physics mysteries",
          </strong><br />
          K. A. Cheminant, et al. (CREDO Collab.),<br /> Acta Physica Polonica B
          Proceedings Supplement , Vol. 11 No. 3 (2018), p.489, 2018.<br />[<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1810.06953"
            target="_blank"
            rel="noopener noreferrer">arXiv:1810.06953</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="//www.actaphys.uj.edu.pl/index_n.php?I=S&amp;V=11&amp;N=3#489"
            target="_blank"
            rel="noopener noreferrer">10.5506/APhysPolBSupp.11.489</a
          >].
        </li>
        <br />
        <li>
          <strong
            >"Search for Extensive Photon Cascades with the Cosmic-Ray Extremely
            Distributed Observatory" ,
          </strong><br />
          P. Homola, et al. (CREDO Collab.),<br /> CERN Proceedings, 1 (2018)
          289, 2018.<br />[<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1804.05614"
            target="_blank"
            rel="noopener noreferrer">arXiv: 1804.05614</a
          >
          , DOI:
          <a
            id="pub-id::doi"
            class="text-indigo-800 hover:text-blue-600"
            href="//dx.doi.org/10.23727/CERN-Proceedings-2018-001.289"
            target="_blank"
            rel="noopener noreferrer">10.23727/CERN-Proceedings-2018-001.289</a
          >].
        </li>
      </ol>
    </div>

    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900"
    >
      2017
    </h1>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <ol>
        <li>
          <strong
            >"Cosmic-Ray Extremely Distributed Observatory: a global cosmic ray
            detection framework",
          </strong><br />
          O. Sushchov, et al. (CREDO Collab.),<br /> Advances in Astronomy and
          Space Physics, Volume 7, Issue 1-2, PP. 23-29 , 2017.<br />[<a
            class="text-indigo-800 hover:text-blue-600"
            href="//arxiv.org/abs/1709.05230v2"
            target="_blank"
            rel="noopener noreferrer">arXiv:1709.05230v2</a
          >, DOI:
          <a
            class="text-indigo-800 hover:text-blue-600"
            href="https://arxiv.org/ct?url=https%3A%2F%2Fdx.doi.org%2F10.17721%2F2227-1481.7.23-29&amp;v=0a872425"
            target="_blank"
            rel="noopener noreferrer">10.17721/2227-1481.7.23-29</a
          >].<br />
        </li>
        <br />
      </ol>
    </div>
  </div>
</section>
