<script>
  let home_path = "#/about/team/credo-cs/";

  function pathes(subpage) {
    return home_path + subpage;
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-CS Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href={pathes("")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >About</a
      >
      <a
        href={pathes("research")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      >
      <a
        href={pathes("people")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <a
        href={pathes("activites")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Activites</a
      >
      <a
        href={pathes("publications")}
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>Publications</strong></a
      >
      <a
        href={pathes("contact")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <!-- Journal papers -->
    <!-- <div class="flex flex-col text-center w-full">
      <h1
        class="sm:text-3xl text-2xl uppercase tracking-wider font-serif title-font mb-4 text-red-900"
      >
        Journal papers
      </h1>
      <h2
        class="text-xs text-red-600 tracking-widest font-medium title-font  mb-4"
      >
        For CREDO Collaboration
      </h2>
    </div>
    <div class="flex flex-wrap ">
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-lg title-font  tracking-wider font-serif "
            >
              Observation of large scale precursor correlations between cosmic
              rays and earthquakes
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base tracking-wider font-serif ">
              O. Bar, Ł. Bibrzycki, M. Niedźwiecki, M. Piekarczyk, K. Rzecki, T.
              Sośnicki, S. Stuglik, M. Frontczak, et al. (CREDO Collab.),<br />
              <em> Sensors 2021,</em><em>21 (22)</em>, 7718, November 2021.
              <a
                style="color: #000080;"
                href="https://doi.org/10.3390/s21227718"
                class="tracking-wider font-serif "
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.3390/s21227718
              </a>
              <a
                href="https://github.com/credo-ml/feature-based-classifiers"
                target="_blank"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  ><path
                    d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
                  /></svg
                ></a
              >
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Conference papers -->
    <!-- <div class="flex flex-col text-center w-full py-10">
      <h1
        class="sm:text-3xl text-2xl font-medium title-font uppercase tracking-wider font-serif text-red-900"
      >
        Conference papers
      </h1>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-lg title-font tracking-wider font-serif "
            >
              Machine learning aided noise filtration and signal classification
              for CREDO experiment
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base tracking-wider font-serif ">
              Ł.Bibrzycki, O.Bar, M.Piekarczyk, M.Niedźwiecki, K.Rzecki,
              S.Stuglik, et al. (CREDO Collab.),
              <br />
              <em> PoS(ICRC2021)227, 2021.</em>

              <a
                style="color: #000080;"
                class="tracking-wider font-serif "
                href="https://doi.org/10.22323/1.395.0227"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.22323/1.395.0227.
              </a>
            </p>
            <a
              href="https://user.credo.science/user-interface/svelte/credo/data/posters/ICRC2021-Ł.Bibrzycki.pdf"
              target="_blank"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                />
              </svg></a
            >
          </div>
        </div>
      </div>
    </div> -->

    <!-- Co-authored journal papers -->
    <div class="flex flex-col text-center w-full py-10">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b>Co-authored journal papers</b>
      </h1>
    </div>
    <div class="w-4/5 mx-auto flex flex-wrap -m-4">
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Recent Developments within The Cosmic Ray Extremely Distributed
              Observatory (CREDO)
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              David Edwin Alvarez-Castillo et al. for the CREDO collaboration, <br
              /><em> Supl. Rev. Mex. Fis. 4(2) 021123 1-8 (2023).</em>

              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.31349/SuplRevMexFis.4.021123"
                target="_blank"
                rel="noopener noreferrer"
              >
                DOI: 10.31349/SuplRevMexFis.4.021123
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              A New Method of Simulation of Cosmic-Ray Ensembles Initiated by
              Synchrotron Radiation
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              O. Sushchov, P. Homola, et al. (CREDO Collab.), <br /><em>
                Symmetry 2022, 14(10), 1961, September 2022.</em
              >

              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.3390/sym14101961"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.3390/sym14101961.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Simulation of the Isotropic Ultra-High Energy Photon Flux in the
              Solar Magnetic Field
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              B. Poncyljusz, T. Bulik, N. Dhital, O. Sushchov, S. Stuglik, P.
              Homola, D. Alvarez-Castillo, et al. (CREDO Collab.), <br /><em>
                Universe 2022, 8, 498, September 2022.</em
              >

              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.3390/universe8100498"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.3390/universe8100498.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Analysis of the Capability of Detection of Extensive Air Showers
              by Simple Scintillator Detectors
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              J.S. Pryga, W. Stanek, K.W. Woźniak, P. Homola, K. Almeida
              Cheminant, S. Stuglik, et al. (CREDO Collab.), <br /><em>
                Universe 2022, 8, 425, August 2022.</em
              >

              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.3390/universe8080425"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.3390/universe8080425.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Cosmic ray ensembles as signatures of ultra-high energy photons
              interacting with the solar magnetic field
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              N. Dhital, P. Homola, D. Alvarez-Castillo, D. Góra, H. Wilczyński,
              K. Almeida Cheminant, B. Poncyljusz, J. Mędrala, G. Opiła , et al.
              (CREDO Collab.), <br /><em>
                Journal of Cosmology and Astroparticle Physics 2022(03), 038,
                March 2022.</em
              >

              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.1088/1475-7516/2022/03/038"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.1088/1475-7516/2022/03/038.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              A Search for Cosmic Ray Bursts at 0.1 PeV with a Small Air Shower
              Array
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              R. Clay, J. Singh, P. Homola, et al. (CREDO Collab.),
              <br /> Symmetry 2022,14 (3), 501, February 2022.

              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.3390/sym14030501"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.3390/sym14030501.
              </a>
            </p>
          </div>
        </div>
      </div>
      <!-- 2021 -->
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Zernike Moment Based Classification of Cosmic Ray Candidate Hits
              from CMOS Sensors
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              O. Bar, Ł. Bibrzycki, M. Niedźwiecki, M. Piekarczyk, K. Rzecki, T.
              Sośnicki, S. Stuglik, M. Frontczak, et al. (CREDO Collab.),<br />
              <em> Sensors 2021,</em><em>21 (22)</em>, 7718, November 2021.
              <a
                style="color: #000080;"
                href="https://doi.org/10.3390/s21227718"
                class="tracking-wider font-serif"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.3390/s21227718
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              CNN-Based Classifier as an Offline Trigger for the CREDO
              Experiment
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              M. Piekarczyk, O. Bar, Ł. Bibrzycki, M. Niedźwiecki, K. Rzecki, S.
              Stuglik, et al. (CREDO Collab.),<br />
              <em> Sensors 2021,</em><em>21</em>, 4804, August 2021.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.3390/s21144804"
                target="_blank"
                rel="noopener noreferrer">DOI: 10.3390/s21144804</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Determination of Zenith Angle Dependence of Incoherent Cosmic Ray
              Muon Flux Using Smartphones of the CREDO Project
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              M. Karbowiak, T. Wibig, et al. (CREDO Collab.),
              <br /> Appl. Sci. 2021, 11, 1185, January 2021.

              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.3390/app11031185"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.3390/app11031185.
              </a>
            </p>
          </div>
        </div>
      </div>
      <!-- 2020 -->
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Cosmic Ray Extremely Distributed Observatory
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              P. Homola, et al. (CREDO Collab.),
              <br /> Symmetry 2020, 12(11), 1835, 2020.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                target="_blank"
                rel="noopener noreferrer"
                href="//arxiv.org/abs/2010.08351">arXiv: 2010.08351</a
              >,
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                target="_blank"
                rel="noopener noreferrer"
                href="https://doi.org/10.3390/sym12111835"
                >DOI: 10.3390/sym12111835</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Towards A Global Cosmic Ray Sensor Network: CREDO Detector as the
              First Open-Source Mobile Application Enabling Detection of
              Penetrating Radiation
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              Ł.Bibrzycki, D.Burakowski, P.Homola, M.Piekarczyk, M.Niedźwiecki,
              K.Rzecki, S.Stuglik, A.Tursunov, et al. (CREDO Collab.),
              <br />
              <em> Symmetry 2020,</em><em>12</em>(11), 1802, 2020.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="http://www.mdpi.com/2073-8994/12/11/1802"
                target="_blank"
                rel="noopener noreferrer">DOI: 10.3390/sym12111802</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              The first CREDO registration of extensive air shower
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              M. Karbowiak, T. Wibig, et al. (CREDO Collab.),
              <br />
              <em> Physics Education, 55</em> (5),055021, July 2020.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.1088/1361-6552/ab9dbc"
                target="_blank"
                rel="noopener noreferrer">DOI: 10.1088/1361-6552/ab9dbc</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Search for ultra-high energy photons through preshower effect with
              gamma-ray telescopes: Study of CTA-North efficiency
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              K. A. Cheminant, et al. (CREDO Collab.),
              <br />
              <em> Astroparticle Physics, 123</em> (5), 102489, December 2020.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://arxiv.org/abs/2007.11105"
                target="_blank"
                rel="noopener noreferrer">arXiv:2007.11105</a
              >,
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://doi.org/10.1016/j.astropartphys.2020.102489"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.1016/j.astropartphys.2020.102489</a
              >
            </p>
          </div>
        </div>
      </div>

      <!-- 2019 -->
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              CREDO Project
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              R. Kamiński , T. Wibig, et al. (CREDO Collab.),
              <br />
              <em> Acta Phys. Pol. B </em> 50, 2001 (2019).
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://arxiv.org/abs/2009.12314"
                target="_blank"
                rel="noopener noreferrer">arXiv: 2009.12314</a
              >,
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://inspirehep.net/literature/1772375"
                target="_blank"
                rel="noopener noreferrer">DOI:10.5506/AphysPolB.50.2001</a
              >
            </p>
          </div>
        </div>
      </div>

      <!-- 2018 -->
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Cosmic-Ray Extremely Distributed Observatory: status and
              perspectives
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              D. Góra, et al. (CREDO Collab.),
              <br />
              <em> Universe 2018,4</em> (11) 111, 2018.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://arxiv.org/abs/1810.10410"
                target="_blank"
                rel="noopener noreferrer">arXiv:1810.10410</a
              >,
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://www.mdpi.com/2218-1997/4/11/111"
                target="_blank"
                rel="noopener noreferrer">DOI: 10.3390/universe4110111</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Cosmic Ray Extremely Distributed Observatory: a global network of
              detectors to probe contemporary physics mysteries
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              K. A. Cheminant, et al. (CREDO Collab.),
              <br />
              <em>
                Acta Physica Polonica B Proceedings Supplement , Vol. 11 No. 3
                (2018), p.489,</em
              >
              2018.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://arxiv.org/abs/1810.06953"
                target="_blank"
                rel="noopener noreferrer">arXiv:1810.06953</a
              >,
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://www.actaphys.uj.edu.pl/index_n.php?I=S&V=11&N=3#489"
                target="_blank"
                rel="noopener noreferrer">DOI: 10.5506/APhysPolBSupp.11.489</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Search for Extensive Photon Cascades with the Cosmic-Ray Extremely
              Distributed Observatory
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              P. Homola, et al. (CREDO Collab.), 2018.
              <br />
              <em> ACERN Proceedings, 1 (2018) 289, </em>
              2018.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://arxiv.org/abs/1804.05614"
                target="_blank"
                rel="noopener noreferrer">arXiv: 1804.05614</a
              >,
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://dx.doi.org/10.23727/CERN-Proceedings-2018-001.289"
                target="_blank"
                rel="noopener noreferrer"
                >DOI: 10.23727/CERN-Proceedings-2018-001.289</a
              >
            </p>
          </div>
        </div>
      </div>

      <!-- 2017 -->
      <div class="p-4 md:w-full">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center">
            <h2
              class="text-gray-900 text-2xl title-font tracking-wider font-serif"
            >
              Cosmic-Ray Extremely Distributed Observatory: a global cosmic ray
              detection framework
            </h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-xl tracking-wider font-serif">
              O. Sushchov, et al. (CREDO Collab.),
              <br />
              <em>
                Advances in Astronomy and Space Physics, Volume 7, Issue 1-2,
                PP. 23-29,
              </em>
              2017.
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://arxiv.org/abs/1709.05230v2"
                target="_blank"
                rel="noopener noreferrer">arXiv:1709.05230v2</a
              >,
              <a
                style="color: #000080;"
                class="tracking-wider font-serif"
                href="https://arxiv.org/ct?url=https%3A%2F%2Fdx.doi.org%2F10.17721%2F2227-1481.7.23-29&v=0a872425"
                target="_blank"
                rel="noopener noreferrer">DOI: 10.17721/2227-1481.7.23-29</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- end -->
  </div>
</section>
