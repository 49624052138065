<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Workshop of High School Cosmic Ray Experiments at the Centro Fermi in Rome
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/centro_fermi_logo-1.jpg"
      alt="fermi_logo"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >This meeting is devoted to presentation of existing networks of cosmic
      ray detectors and discussion on sharing the data. CREDO will be
      represented by Piotr Homola (INP PAS), Marek Magrys (CYFRONET) and Mateusz
      Sulek, Pawel Jagoda, Slawomir Stuglik.</span
    >
  </div>
</div>
