<div class="container py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO institutional members
  </h1>
  <div
    class="flex flex-row justify-center flex-wrap w-full py-10 bg-gray-900 filter drop-shadow-xl rounded-md"
  >
    <div class="w-full py-2">
      <p class=" text-white text-center">
        Countries: 20, Institutions: 52<br />
      </p>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/australia.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Australia
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 2
          </h1>
          <span class="leading-relaxed">
            <a
              href="#/credo-institutional-members/australia"
              class="w-full text-right">See more</a
            >
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/canada.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Canada
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 2
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/canada">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/chile.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Chile
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/chile">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/czech.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Czech Republic
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 3
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/czech">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/estonia.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Estonia
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/estonia">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/gruzja.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Georgia
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/georgia">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/wegry.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Hungary
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/hungary">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/india.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            India
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 2
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/india">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/wlochy.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Italy
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 2
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/italy">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/meksyk.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Mexico
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/mexico">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/nepal.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Nepal
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/nepal">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/polska.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Poland
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 21
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/poland">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/portugal.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Portugal
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/portugal">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90 opacity-60"
          src="./images/flags/rosja.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Russia
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/russia">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/slowacja.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Slovakia
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/slovakia">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/hiszpania.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Spain
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 2
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/spain">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/thailand.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Thailand
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 1
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/thailand">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/usa.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            United States
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 3
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/usa">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/ukraina.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Ukraine
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 3
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/ukraine">See more</a>
          </span>
        </div>
      </div>
    </div>
    <div class="xl:w-1/5 lg:w-1/5 md:w-1/5 sm:w-1/3 hover:shadow-md py-4 px-2">
      <div class="flex relative">
        <img
          alt="gallery"
          style="max-height:10em;height:100%"
          class="absolute object-contain mx-auto w-full transform scale-90"
          src="./images/flags/urugwaj.png"
        />
        <div
          class="px-8 py-5 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 flex-grow"
          style="max-height:10em;height:100%"
        >
          <h2
            class="tracking-widest text-sm title-font font-medium text-red-500 mb-1"
          >
            Uruguay
          </h2>
          <h1 class="title-font text-lg font-medium text-gray-900 mb-3">
            Institutions: 2
          </h1>
          <span class="leading-relaxed">
            <a href="#/credo-institutional-members/uruguay">See more</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
