<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Gra „Zajrzyj w głąb Wszechświata”
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/newka.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000;"
      ><span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/zajrzyjwglabwszechswiata/#/"
          >Gra „Zajrzyj w głąb Wszechświata”</a
        ></span
      >
      rozpocznie się 1 lipca 2021 roku. Już dziś:<br />
      1. Zapoznaj się z
      <span style="color: #0000ff;"
        ><a
          href="https://www.ifj.edu.pl/fizykakluczem/regulamin-gra-wszechswiat.pdf"
          target="_blank"
          rel="nofollow noopener noreferrer">regulaminem</a
        ></span
      ></span
    >

    <br /><span style="color: #000000;">
      2. Przyłącz się do projektu<span style="color: #0000ff;" />
      <a href="https://www.facebook.com/credo.science/">CREDO Science</a></span
    ><br />
    <span style="color: #000000;"
      >3. Przygotuj swoje urządzenie mobilne do obserwacji sygnałów z głębin
      Wszechświata!</span
    ><br />
    <span style="color: #000000;"> Cenne nagrody czekają! </span>
  </div>
</div>
