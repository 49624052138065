<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Science Festival in Cracow [24-27.05.2017]
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/Baner-1200-x-628-2-570x350.jpg"
      alt="Science festiwal baner"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >From 24th to 27th of May 2017 the Science Festival in Cracow will be held
      at the Main Market square, where you will have opportunity to visit two
      tents with exhibitions presenting IFJ PAN.<br /> In one of these tents, representatives
      of our CREDO project, both IFJ PAN staff and students from Cracow universities,
      who are engaged with the project, will be available to talk about our achievements
      and show the first prototype of the CREDO detector. You’re welcome.</span
    >
  </div>
</div>
