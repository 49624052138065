<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>CREDO on Seminar 03-06.12, Gorlitz,</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2 mb-5">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
      class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 lg:w-1/3"
      alt="img"
      src="./images/posts/zgorzelec/2.jpg"
    /><img
        class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 lg:w-1/3"
        alt="img"
        src="./images/posts/zgorzelec/1.jpg"
      />

      On 03-06.12, CREDO representatives took part in the German-Polish WE-Heraeus Seminar & Max Born Symposium 
      'Multiparticle Systems under Extreme Conditions', held in Zgorzelec. <br/><br/>
      CREDO was represented at the conference by Professor Robert Kaminski and Dr David Alvarez Castillo from the Institute of Nuclear Physics of the Polish Academy of Sciences.<br/>
       </p>
       </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2 mb-5">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
     <img
    class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 lg:w-1/3"
    alt="img"
    src="./images/posts/zgorzelec/3.jpg"
  />

      The aim of the presentation by the CREDO people was to familiarise participants with the aims and current activities of CREDO and to encourage broad participation on many levels: from data collection to data analysis to scientific conclusions.
    
  </p>
  </div>
</section>
