<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>2nd CREDO Visegrad Workshop 2024</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2 mb-5">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
        class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 mb-3"
        alt="img"
        src="./images/posts/2ndCVisegrad.jpg"
      />

      The second CREDO 2024 Visegrad Workshop begins today (at 4 pm) at the
      Vihorlatské Astronomical Observatory in Humennem, Slovakia. <br />
      The workshop will last 3 days (June 3-5, 2024) and is organized under the framework
      of the project Boosting the social impact of CREDO.
      <br /><br />
      The main topics of the workshop will be on<br />
      a) Sonification of data, citizen science and educational aspects of CREDO,<br
      />
      b) Observations of the Cosmos <br />
      c) The physics of CREDO and related topics,<br />
      Interested persons are invited to participate remotely in the workshop<br
      />
      More information (timetable, registration, link to zoom) can be found at

      <a
        class="text-blue-900 hover:text-blue-500"
        href="https://indico.ifj.edu.pl/event/1260"
        >https://indico.ifj.edu.pl/event/1260</a
      >.
    </p>
  </div>
</section>
