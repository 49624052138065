<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b> Podcast</b>
      </h1>
      <h1
        class="lg:w-4/5 mx-auto text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        Conversation comes from the Universal Podcast - <a
          style="color: #0000ff;"
          href="//www.tygodnikpowszechny.pl/podkast"
          >the official Podcast of "Tygodnik Powszechny"</a
        >
        as part of the
        <a
          style="color: #0000ff;"
          href="//www.tygodnikpowszechny.pl/wielkiepytania"
          >"Big Questions Again"</a
        > project
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          <strong>Translation from Polish: Beata Murzyn</strong>
          <br />
          <i>Cosmic radiation.</i>
          <i
            >A remnant of old and distant events in outer space. Its particles
            are messengers of the greatest mysteries of the Universe. Each
            moment highly penetrating particles, the result of primary cosmic
            rays interacting with the Earth's atmosphere, pass through our
            planet and through our own bodies. Scientists build special
            detectors to search for the keys to the biggest questions of science
            amidst those particles. But are you aware that cosmic rays can be
            detected by our own ordinary smartphones? A single smartphone won't
            do much, but today over 5 billion people have their own smartphones.
            What would happen if we put to work millions of those devices all
            over the world and joined forces with the existing special purpose
            detectors? This is what scientists from Cracow have asked
            themselves. They have launched the CREDO project: the Cosmic-Ray
            Extremely Distributed Observatory, which inaugurated its operation
            on 11 September 2019. From now on each of us can take part in the
            search for answers to the biggest questions of science.</i
          >
        </p>
        <p class="mt-3">
          This is Michał Kuźmiński in <i
            >a Weekly podcast from the series “Big Questions”.</i
          > Weekly podcast – go and listen.
        </p>
        <p class="mt-8">
          <b>Michał Kuźmiński (MK):</b>
          Today I am at the Institute of Nuclear Physics, Polish Academy of Sciences,
          sitting in the office of dr hab. Piotr Homola. Good morning.
        </p>
        <p class="mt-2">
          <b>Piotr Homola (PH):</b> Good morning.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Dr hab. Piotr Homola is the coordinator and, so to speak, the soul of the
          project aptly named CREDO, i.e. the Cosmic-Ray Extremely Distributed Observatory.
          Before I ask about the term “Extremely Distributed” and “the Observatory”,
          I'd like to ask you first about cosmic radiation. Here we are, sitting
          relaxed, while each second thousands of cosmic ray high-energy particles
          move through our bodies. What is this cosmic radiation at all?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Let's think about our heads. Every second approximately five highly penetrating
          particles called muons pass through our heads. Muons are low-energy particles
          which constitute the so called secondary cosmic rays originating in the
          interactions of higher energy particles with the Earth's atmosphere. Cosmic
          radiation is omnipresent, it reaches every corner of the world and is for
          free. For this reason it is a perfect subject of study. You do not have
          to build large apparatus – you just have to look around as there are many
          devices that can detect this type of radiation. What we propose in CREDO
          is to carry out this research on a global scale.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Exactly. A common practice in this kind of research is to build a large
          detector for an immense amount of money and equip this detector with highly
          sensitive apparatus, able to detect interactions of various sorts. But
          CREDO is a project that is based on entirely opposite premises. What seems
          most interesting is the fact that for the kind of study that CREDO wants
          to pursue you need a detector the size of the planet Earth, isn't it? And
          this is what this all is about.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          I would say it's even more than that. If we have a station on Mars or on
          the Moon, if we have satellites, the strategy of CREDO will require cosmic
          ray detectors to be put also in those places. Those detectors will serve
          such purposes as e.g. monitoring cosmic radiation on Mars for health reasons.
          The levels of radiation on Mars may differ from those on Earth and you
          will have to keep them under control.
        </p>
        <p class="mt-2">
          The strategy of CREDO includes searching for phenomena of a very
          extensive nature, i.e. cosmic rays that may be correlated on a large
          scale. Let‘s say that today in Cracow we detect an event such as an
          extensive air shower, a cascade of secondary particles initiated by an
          ultra-high energy cosmic ray, and simultaneously we detect such an
          excess of the signal on Mars, on the Moon and on the ISS. This
          phenomenon is our goal, at least as far as our “astro-goals” are
          concerned. We call this event, which should occur in nature but which
          so far has not been observed, the Cosmic Ray Ensemble, which also
          harmonizes with the acronym of our project. This ensemble may be in
          practice of a minor size, even local, such as in Cracow, but it may
          also happen on a planetary or even interplanetary scale. This is our
          chance to study the products, or effects, of particle interactions of
          the highest known energies in the Universe, or even a chance to
          observe phenomena which may help us achieve breakthroughs in our
          understanding of the Universe.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Before we discuss how to build a detector of the size of the globe and
          what is the CREDO idea, I want to ask you more about this radiation. How
          much do we really know about it? What are those particles that reach Earth
          and penetrate it, and us all? What are their sources, where do they come
          from? And what are those phenomena which are beautifully called “air showers”?
        </p>
        <p class="mt-2">
          <b>PH: </b>We could say that we know cosmic radiation quite well, at
          least when it comes to low energies. The closest source of cosmic rays
          is the Sun. But there are also all sorts of other processes, including
          the catastrophic ones, such as supernova explosions, galaxy
          collisions, or, recently popular, collisions of black holes with
          neutron stars. All those events can emit ultra-high-energy particles,
          and those particles reach our planet. That's a fact, and we can
          observe them indirectly and directly. At such considerable energies we
          have only a chance for indirect observations with the help of air
          showers. A high-energy particle, and here we are talking about the
          energies at a level of 10<sup>15</sup> eV…
        </p>
        <p class="mt-2">
          <b>MK:</b>
          How much is it, really?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          It is more or less a thousand times more than the highest energies that
          we can achieve at CERN. Let me give you an example of an extremely high
          energy, 10<sup>20</sup>
          eV, which is a hundred thousand times more than 10<sup>15</sup>. This
          energy is approximately equal to the energy of a well-served tennis
          ball or a punch of a well-trained heavyweight boxer, such as Tyson at
          his peak. Of course here we are talking about just one particle, a
          single particle.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Let me add that at CERN they compare the energy of a bunch of protons accelerated
          in the LHC tunnel to that of a collision with a mosquito in flight. This
          is, I think, quite a good comparison: between a mosquito and a boxer.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Yes. Such high energy means that a given particle has little chance to
          reach the surface of Earth, because on its way to the planet it interacts
          with the molecules in the atmosphere, producing a cascade of secondary
          particles. This cascade is called “an extensive air shower”. It may be
          composed of billions of particles covering the area of many square kilometers,
          but of course the closer we get to the axis, or the core, of this shower,
          the greater the concentration of the particles.
        </p>
        <p class="mt-2">
          These phenomena, extensive air showers, can be detected by large
          observatories as well as, we hope, by smaller observatories. By
          analyzing these already well-degenerated secondary cascades we can
          draw conclusions about such properties as the direction from which a
          primary particle comes or its energy, which is the easiest to
          calculate, or its type, which is the hardest to specify. When it comes
          to lower-energy cosmic rays, however, their composition is quite well
          understood by us, and it is dominated by protons. We also know that
          photons of high energies also reach our planet: after all, the whole
          great branch of science called gamma-ray astrophysics, which deals
          with high-energy photons, is based on this knowledge. What is most
          interesting is that we know the least about those events that are the
          rarest, that is the phenomena of extreme energies, like the
          before-mentioned Tyson's punch. Such events occur once in a blue moon:
          we could expect a single such event to happen within one square
          kilometer over a thousand years. So to study these phenomena you
          either require a lot of patience or a very big, or possibly a
          very-distributed, detector.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          There are two contraptions lying on the desk in front of us: the scintillators.
          They blink once in a time, and each blink means that a high-energy particle
          has just passed through them, is that right?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Yes. For demonstration, Michał, I have activated this device aptly named
          “Cosmic Watch”. Speaking by the way, this item has been designed on the
          open-source hardware basis by two not-just-any CREDO partners, i.e. Massachusetts
          Institute of Technology and the National Centre for Nuclear Research in
          Warsaw. They jointly have designed a detector, whose price I think I can
          reveal as the project is open and non-commercial, and it amounts to 100
          USD. This makes this device affordable to almost every school or even every
          class. Taking into account a very good response of teachers and pupils
          alike to the idea of their active involvement in the research at the highest
          level, I expect this project to quickly turn into a national, and soon
          international, program, so that every school will have its own detector.
          At least we will try to do that.
          <i>/laughs/</i>
        </p>
        <p class="mt-2">
          <b>MK:</b>
          But the core and clue of the CREDO program is a detector not just in every
          school, but rather in every pocket. With some astonishment I have learned
          that a well-known and common device such as a smartphone can serve quite
          well as a cosmic-ray detector. How's that possible?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Yes. Our listeners cannot see us, but I am just pulling such a device –
          a smartphone – out of my pocket. A smartphone can be used to take pictures,
          which means that it is equipped with a camera and an image sensor, the
          so-called CMOS image sensor. This sensor is sensitive not only to visible
          light but also to penetrating radiation. If we want to detect this radiation,
          first we must download a special application, such as the application of
          the CREDO project called “CREDO Detector”. Next we must cover the camera
          and launch the application. The best way to work with this application
          is putting a smartphone into work while you are sleeping, just because
          the energy-consumption may be high when constantly taking pictures, as
          this is what the application comes down to in practice. So it's best to
          use your smartphone during sleep when it is recharging. Next morning, when
          awakened, we can admire the beautiful detections that our smartphone has
          recorded throughout the night. These detections are sent automatically
          to the central system of data acquisition and processing. This is the first
          step that can be continued over and over again. With CREDO you can embark
          upon a journey of your whole life.
        </p>
        <p class="mt-2">
          <b>MK: </b>Is this image sensor somehow activated? Why do we have to
          cover the camera? To simply protect it from light, so when some
          interferences are recorded in the picture they would indicate that a
          particle has just passed through the smartphone?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Yes, that's the thing. If visible light could reach the camera, we would
          have a lot of trouble to distinguish a trace that could have been left
          by a particle. A similar mechanism is employed by the community of astronomers,
          who use telescopes equipped with CCD cameras, that is image sensors. From
          time to time, even if they do not want to, astronomers have to take a picture,
          or a series of pictures, with the lens covered to identify the so-called
          bad, i.e. damaged, pixels. If the lens is covered and light cannot reach
          the image sensor but still some bright dots are visible in successive pictures,
          most probably they are the before-mentioned bad pixels, which can be later
          removed to get a more accurate analysis. What is even more interesting,
          during this procedure cosmic ray events can also be recorded. Every astronomer
          knows these unwanted effects and tries to get rid of them. This is not
          something we have come up with – what we try to do is to make use of this
          effect on a massive and global scale to “attack” the big questions of science.
          And this is our mission.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Now I think is a good moment to explain what those “big questions” are.
          Why do you study cosmic rays, apart from the fact that this is just an
          interesting thing to do? What can we possibly learn thanks to a network
          of smartphones?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Let me first explain that the most exciting thing that we can learn is
          the observation of an unexpected phenomenon. There is a common saying in
          physics: “The least expected is the most interesting”. In this way the
          cosmic microwave background has after all been discovered! Arno Allan Penzias
          and Robert Woodrow Wilson first attributed what they could see in the data
          gathered by their radio telescope to the effect caused by pigeons perching
          on their instrument, but with time this observation had led them both to
          Nobel Prize. This type of a strategy in research is called “fishing”, i.e.
          angling or casting a net, but without any preconceived ideas. In short:
          if we know an average detection frequency in a given device, we can easily
          come up with a mechanism that would detect long- or short-term anomalies,
          and our goal would be to understand those anomalies. If we encounter an
          anomaly, we are far from panicking; on the contrary, we are happy and we
          try to take a closer look at it.
        </p>
        <p class="mt-2">
          Apart from fishing, or hunting for anomalies, and correlating them
          with the existing information and databases, we also want to verify
          relevant research hypotheses. But let me stress that this is not at
          all an exhaustive list – CREDO is an open observatory! Even if today
          we come up with five hypotheses with Nobel potential, tomorrow
          somebody else may put forward another interesting idea, and he or she
          is welcome to carry it out within CREDO. Let me repeat – CREDO is an
          open observatory: you are welcome not only to provide the data
          gathered by your private devices, no restrictions applied, neither
          cultural nor geographical, but you are also welcome to have access to
          those data, of course with respect for your privacy and rights. Here
          we're talking about research data with full anonymity, so there is no
          danger of infringement of privacy. On the contrary, this is an
          opportunity for us: each of us can have access to those data, even
          without signing a Memorandum of Understanding, and be awarded a prize,
          and may even not share it with us, we won't be cross with you!
          <i>/laughs/</i>
          This is our mission – to conduct an open project.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Let's go back to those three hypotheses with Nobel potential. When I read
          about CREDO, I learned that the answers which the data accumulated in this
          project may bring concern e.g. the structure of space time.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Yes, this is my favorite thing
          <i>/laughs/</i>. We have that potential! I know it may sound crazy,
          but this is a true, well-posed scientific question, a great challenge,
          but possible to achieve with the help of a global network of detectors
          sensitive to extensive air showers. The key to understand this problem
          is to realize that we have a chance to detect a Cosmic Ray Ensemble,
          an ensemble that may have a common beginning somewhere in deep space.
          Let's say that as a result of this first interaction, the first
          physical process, a group of particles is produced that propagates
          through the Universe. If those particles, let's say photons, had
          different energies, according to our standard knowledge of physics we
          would expect them all to arrive in the same time, as they all would
          travel with the speed of light with no delays. But let's assume that
          space time has a structure…
        </p>
        <p class="mt-2">
          When trying to reconcile, or combine, our knowledge of microworld with
          the knowledge of macroworld, i.e. quantum field theory with general
          relativity, and I'm talking here about approaches to quantum gravity,
          we would expect our space time (which is a physical object, which may
          not be so obvious to all), a stage on which we are “happening”, to
          have a structure. It may not be a plain stage; perhaps it has a form
          of a net or a foam. There is a hypothesis according to which it is a
          quantum foam, so perhaps our space time is foam-like. If this
          structure really exists, then the travelling group of particles,
          consisting of photons of different energies, might interact with it in
          various ways. Specifically, the photons of higher energies, i.e. those
          characterized by higher frequencies of the electromagnetic wave, might
          be more sensitive to the structure of space time than those of lower
          frequencies, lower energies. Let's compare this hypothesis to a
          travelling car: a car with bigger wheels would cross a road full of
          holes much more easily than a car with small wheels. If they both had
          the same speed in the beginning of their journey, the car with bigger
          wheels would reach its destination with no problems, but the other
          vehicle would fall into holes and arrive later.
        </p>
        <p class="mt-2">
          So this is what we are looking for in CREDO, and we are actually doing
          it right now. We are conducting an experiment with a massive
          participation of smartphone owners, called Quantum Gravity Previewer,
          in which every smartphone participates in the search for a group of
          events unnaturally “clustered” in time. Let's give an example of your
          device – if your device registers the particles once in an hour, one
          morning you may be surprised to make a discovery that last night you
          registered 63 events within 10 minutes. You might wonder that perhaps
          some radioactive animal had approached your device or some other
          phenomenon had occurred, or perhaps this was this unnatural “density”
          of cosmic rays in time. If that density lasted only a fraction of a
          second, thus was not expanded in time, this would be a confirmation
          that the particles did not interact with the structure of space time,
          whereas expansion in time might be a signature of such an interaction.
          If the latter was true, we would have to additionally confirm whether
          the primary particles of higher energies truly arrived later. This is
          the key: if the moment of start is common for both energies, and the
          higher energy arrives later, this is our signature, our “Wow!”
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Do I understand well that this is the meaning of the “extreme distribution”
          that appears in the CREDO name? The more expanded the structure of the
          project, the more distributed smartphones that detect those events, the
          better the detection of those correlations or perturbations?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          It's hard to say whether the detection would be better, but it would be
          different. If you have detectors that are 10 000 km away from each other,
          and the event is expanded over 10 000 km, with its particles not clustered
          but running in all directions, it is obvious that if your device has an
          area of 10 square meters, you will not be able to cover the whole phenomenon.
          This is the goal of the distribution: to get the biggest base.
        </p>
        <p class="mt-2">
          A similar effect is achieved when taking the famous photos of black
          holes. You need a large base, a large distance. Naturally, if an event
          takes place far in deep space, and it produces photons, they will
          probably not travel in parallel from their start but rather at some
          angle. Their opening angle could enable us to observe an event, i.e.
          at least two particles from that group for a given distance. If the
          angle is wide, and the distance from the first interaction to a
          detector array is also large, the paths of those particles will
          diverge significantly. If the distance between the particles amounts
          to 100 million km, than Earth itself will not be enough. And that's
          why we are talking about Mars.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          It's fantastic to realize that the atmosphere constitutes a natural buffer
          to particles, something similar to what scientists do in CERN, only happening
          in nature. In CERN it all happens in one specific place, surrounded by
          a gigantic number of detectors, whereas in CREDO these detectors must be
          provided by us, users, or the so-called social scientists, right?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Right. A single extensive air shower is of course a perfect subject of
          study. It is indeed a collision, in which particles of extremely high energies…
        </p>
        <p class="mt-2">
          <b>MK:</b>
          I am sorry to interrupt, but this noise that our listeners can hear at
          this moment is not being produced by high-energy particles but by planes
          flying over the Institute on their way to the Balice airport.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Yes, we seem to be safe here.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          <i>/laughs/</i>
        </p>
        <p class="mt-2">
          <b>PH:</b>
          An extensive air shower is very interesting in itself, even if it cannot
          be correlated with some distant events. Air showers have been studied for
          years, since the 1930s, when Pierre Auger first observed local correlations
          between the detectors. As you said, this is principally a collision, but
          in the case of air showers the trouble is that we can only receive already
          degenerated information – a generation of particles that have lost some
          information on their way to Earth. What we can see in accelerators, though,
          is products immediately following the collisions, so this is a different
          situation.
        </p>
        <p class="mt-2">
          What we do in CREDO is also contained in a philosophy of collisions,
          but we rather anticipate collisions happening in deep space, not here
          in the atmosphere, although such collisions are also important for us.
          Let us imagine that billions of years ago something happened in the
          Universe, a messenger of which you can detect in your private
          smartphone, a particle from that distant past. For many of us this is
          an extremely attractive element of our project.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          So fundamental are the questions our smartphones may help to answer…
        </p>
        <p class="mt-2">
          <b>PH:</b>
          The most fundamental question is what can be more fundamental than the
          fundamentality itself? I got stuck in a loop
          <i>/laughs/</i>. Space time is the foundation of the Universe. Some
          people speculate about space time being everything, with particles
          constituting only twists of that structure. We could possibly prove it
          by studying the data that arrive on Earth. This is a chance for
          observing new physical phenomena, and this is what theorists dream of.
          Theorists work hard on modelling the world, looking for quantum
          gravity, but what they really crave is new data and new phenomena. And
          we count on CREDO to provide us with those new data.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          What will happen next with the data registered on smartphones?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          They will be automatically sent to the central system of data acquisition,
          processing and back-up. This center is managed by a CREDO partner – Academic
          Computer Centre Cyfronet AGH, which also grants access to those data. Every
          person expressing his/her interest in the data may be granted access to
          them in accordance with a specific procedure, and may be involved in the
          creation and development of the project at a higher level.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          The acquisition of data itself, and the fact that my smartphone takes part
          in that process, may have serious implications for science. If my data
          can help study such subjects of science as the structure of space time
          or dark matter, it is reasonable to ask about that Nobel potential. As
          you said before, the acquisition of data translates into co-authorship,
          which means that every smartphone owner may be affiliated with scientific
          papers resulting from these studies and may be identified as a co-owner
          of that potential Nobel prize, is that right?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          There is more to it than that, Michał. Even if you do not install the CREDO
          application, you may still be undersigned as a co-author, because you still
          help acquire the data by popularizing the idea that the CREDO project is
          worth joining.
        </p>
        <p class="mt-2">
          <b>MK: </b>Let us then invite all our listeners to visit the project
          webpage…
        </p>
        <p class="mt-2">
          <b>PH:</b>
          …credo.science…
        </p>
        <p class="mt-2">
          <b>MK:</b>
          …or the Google Play. We are still waiting for applications for iPhones,
          right?
        </p>
        <p class="mt-2">
          <b>PH: </b>Yes, we are still waiting but we count on our partners from
          Australia to solve this problem. Recently they have informed us about
          an opportunity to get funds for developing an application dedicated to
          iOS<b>.</b>
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Then you can install and launch the application. We should add here that
          a constant progress in technology has armed newer smartphones with hardware
          noise filters that may interfere with this application.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          This problem is yet to be identified by us. Of course newer devices translate
          into newer technologies and greater noise reduction. But I also think that
          many things can be improved here from the software point of view as far
          as particle detection is concerned. At this moment an alternative application
          is being tested. This application will detect particles in a different
          way and in accordance with a different algorithm. It is not a mystery that
          a device I currently use registers a few events a day using an official
          application, and up to 300 using an alternative application, that is with
          a frequency expected at practically 100% efficiency of such a device.
        </p>
        <p class="mt-2">
          So to sum up – new devices are also welcome in CREDO.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          So, we install the application, plug the smartphone into the charger and
          leave it for a night. The cost of this venture will be similar to the cost
          of a diode flashing in our refrigerator, but satisfaction derived from
          contributing to such fundamental research – invaluable.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Right. I just want to add that the first step is of course very important,
          but we count on the next to come. We hope that the initial enthusiasm of
          registering data with your private devices will be later replaced with
          curiosity – what are those data needed for and what great questions can
          be answered thanks to them?
        </p>
        <p class="mt-2">
          You have asked me for a list of such questions, but we are running out
          of time. I can give you five problems that immediately come to my
          mind: the structure of space time, the mystery of dark matter, but
          also questions of inter- and trans-disciplinary potential. For example
          one of the challenges that CREDO might face in geophysics is searching
          for possible correlations between seismic phenomena and the frequency
          of cosmic ray detection: here the common denominator may be the
          geomagnetic field.
        </p>
        <p class="mt-2">
          The geomagnetic field is a shield that protects us against cosmic
          radiation, and any changes taking place in this shield may be
          reflected in cosmic ray detectors, thus we can observe a surplus or
          shortage of cosmic radiation. On the other hand, we know that the
          processes occurring inside Earth may have an influence on the
          geomagnetic field. Here we have a chance of confirming observable
          correlations. In practice we are sure that such correlations do exist,
          but we are not sure if they are clear enough to be observed and if our
          current techniques of detection will allow us to do that. We also do
          not know if by chance our cosmic ray detectors may be able to record a
          symptom of an earthquake even an hour before a seismic event. Here we
          are touching upon a very important issue – saving human lives. If
          there is just a slight chance of saving at least one human life as a
          result of our studies, it is our moral duty, and the duty of all
          project participants, to bring these studies to an end and answer the
          questions: is there a correlation to detect or not? Can we predict an
          earthquake, and if so, with what accuracy? This is a strictly
          scientific approach, but it carries a great social and emotional load
          in itself – we all have seen dramatic earthquake footage in which
          hundreds of thousands of human lives were lost. If we can do anything
          to reduce this dramatic toll, let's do it.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Let's add another dimension to what we have just discussed – an impact
          of ionizing radiation on human health. Here the data we gather may also
          come in handy.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Yes, they may also be useful. We know that ionizing radiation is not something
          to which we can remain indifferent. We are aware that X-ray images cannot
          be taken too often, especially in the case of children, whose nervous system
          is not fully developed. Also pregnant women, carrying unborn children,
          cannot be too careful. Penetrating radiation may leave traces in the human
          organism, either positive or negative. On the other hand it is possible
          that had it not been for mutations in the human body resulting from cosmic
          radiation, we wouldn't be talking right now! So when it comes to this phenomenon,
          we cannot just label it as something dangerous, but take it also as a chance.
        </p>
        <p class="mt-2">
          The question that CREDO may pose is not a question about the influence
          of average radiation on human health, because this subject has been
          quite well investigated. We know that average radiation is much lower
          than local radioactivity and in this sense we can feel safe. What we
          don't know is a possible influence of an extensive air shower on us if
          we happen to be close to its core. If we could identify those air
          showers in major population centers, such as in Cracow, and if we
          could find a group of volunteers who would not be afraid to realize
          that they happened to be close to the core of an extensive air shower,
          whether for their luck or misfortune, the road would be open for us to
          search for correlations in medical databases.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          From quantum gravity, through biology and geology, up to dark matter and
          the greatest structures of the Universe – CREDO data are useful for every
          level of knowledge organization, right?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          We could take our discussion even further, though I am not sure if our
          listeners are interested in science-fiction. There is a well-known project
          called SETI – the Search for Extraterrestrial Intelligence, and this search
          is conducted by studying radio waves. CREDO is a typical multi-messenger
          project, with many information channels, and for us “many” denotes cosmic
          rays of different energies. Why should we assume that “they” – “aliens”
          – “friends” – would try to communicate with us only through radio waves?
          They might as well use cosmic radiation, by means of extraordinary sequences
          of events. They can even send us “Morse code” messages, and the global
          monitoring might enable us to detect such a signal.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          I can see yet another positive aspect of this venture. Installing the application
          and leaving a smartphone in peace to amass data even for an hour may actually
          do us good instead of ceaseless scrolling through social media.
        </p>
        <p class="mt-2">
          Today, my guest, or in fact host at the Institute of Nuclear Physics,
          was dr hab. Piotr Homola. Thank you very much for this conversation!
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Thank you! Please join our CREDO project!
          <br />
          <b>MK:</b>
          Let's download the application and contribute to CREDO!

          <br />
          <br />Translation from Polish: Beata Murzyn
        </p>
      </div>
    </div>
  </div>
</section>
