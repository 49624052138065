<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO in Warsaw on “Particle Astrophysics in Poland 2019”
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/poster-570x350.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >20.05 and 21.05.2019 CREDO is being presented in Warsaw on “Particle
      Astrophysics in Poland 2019”. Apart from poster (by Oleksandr Sushchov in
      the middle) there was also a general talk by Kevin Almeida Cheminant (on
      the right).
    </span>
  </div>
</div>
