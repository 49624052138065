<script>
  import { setCookie, getCookie } from "./../../../__AllFunctions.svelte";
  import { descriptionPeople, descriptionHeader } from "./utils.js";

  let lang = getCookie("lang") != null ? getCookie("lang") : "pl";
  let langCode = lang === "eng" ? 0 : 1;

  async function reloads() {
    lang = lang === "eng" ? "pl" : "eng";
    langCode = lang === "eng" ? 0 : 1;
    setCookie("lang", lang, 1);
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-Edu Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/about/team/credo-edu"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >{descriptionHeader.part1[langCode]}</a
      >

      <a
        href="#/about/team/credo-edu/people"
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
      >
        <strong>{descriptionHeader.part2[langCode]}</strong>
      </a>
      <p
        href=""
        class="mr-5 uppercase tracking-wider font-serif hover:text-gray-700"
      >
        {descriptionHeader.part3[langCode]}
      </p>
      <!-- <p
        href=""
        class="mr-5 uppercase tracking-wider font-serif hover:text-gray-700"
      >
        {descriptionHeader.part4[langCode]}
      </p> -->
      <a
        href="#/about/team/credo-edu/contact"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
      >
        {descriptionHeader.part5[langCode]}
      </a>
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <span
    class="inline-flex sm:ml-auto sm:mt-0 mt-20 justify-center sm:justify-start left-4 absolute p-4"
  >
    <button class="right h-16" on:click|preventDefault={reloads}>
      <img
        alt={lang + " flag"}
        class="m-2"
        style="width: 80px;"
        src={"./images/" + (lang === "pl" ? "eng" : "pl") + ".png"}
      />
    </button>
  </span>
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-5">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-4 text-red-900"
      >
        <b> {descriptionPeople.title[langCode]}</b>
      </h1>
    </div>
    <div
      class="w-6/7 lg:w-4/5 items-center text-center flex flex-wrap mx-auto text-xl font-serif"
    >
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/KN.png"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Melania Deresz, {descriptionPeople.dr[langCode]}
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              {descriptionPeople.lider[langCode]}
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              {descriptionPeople.uw[langCode]}<br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:melania.deresz[at]fuw.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>melania.deresz[at]fuw.edu.pl
                </span></a
              ><br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
