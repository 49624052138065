<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Find CREDO on YouTube
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/ty.png" alt="credo yt" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >CREDO has inaugurated its <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.youtube.com/channel/UCJ0YBweH_slFRQYqGz_XZ_A"
          target="_blank"
          rel="noreferrer">YouTube channel</a
        >. The
        <span style="color: #0000ff;"
          ><a
            style="color: #0000ff;"
            href="https://www.youtube.com/watch?v=6rHnW--PZQk&t=6s"
            target="_blank"
            rel="noreferrer">first film</a
          >
          posted is the animation by ASTERICS explaining the concept of a global
          approach to cosmic rays sing smartpones – as proposed by CREDO. More clips
          will come, everyone is welcome to subscribe. You will be able to watch
          movies that will allow you to better understand the subject of cosmic ray
          ensembles and the corresponding CREDO research.
        </span>
      </span>
    </span>
  </div>
</div>
