<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto text-black">
    <div class="flex flex-col text-center w-full mb-2">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b> Our Event Posters</b>
      </h1>
    </div>
    <div class="py-4 text-xl text-justify font-serif ">
      See also:
      <a
        href="#/science/posters"
        class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
        >Conference
      </a>
      |
      <a
        href="#/science/posters/general"
        class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
        >General
      </a>
      posters.
    </div>
    <div class="flex flex-wrap -m-4 text-xl text-justify font-serif ">
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./images/plakacikZ_V0a.jpg"
            alt="poster jpg"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              CREDO 2021
            </h2>
            <p class="leading-relaxed mb-3">University MSC - Lublin.</p>
            <div class="flex items-center flex-wrap">
              <a
                href="./images/plakacikZ_V0a.png"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download png
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/credo-anniv-cm-krakow-201708.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              CREDO 2017
            </h2>
            <p class="leading-relaxed mb-3">
              Anniversary Symposium & Collaboration Meeting.
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/credo-anniv-cm-krakow-201708.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/credo-week-2018.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              CREDO Workshop
            </h2>
            <p class="leading-relaxed mb-3">CREDO week 2018</p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/credo-week-2018.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/festiwal_nauki_krakow.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              XIX Festiwal Nauki i Sztuki w Krakowie
            </h2>
            <p class="leading-relaxed mb-3">Poster na festiwal naukowy</p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/festiwal_nauki_krakow.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/postera.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              Ruzomberok
            </h2>
            <p class="leading-relaxed mb-3">
              Poster inviting to the lecture About CREDO at the Catholic
              University in Ruzomberok
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/postera.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
