<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO Memorandum of Understanding has been announced!
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/newka.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:justify"
      >September 11, 2019 at 12:00 at the Institute of Nuclear Physics of the
      Polish Academy of Sciences in Krakow a conference was led by dr. hab.
      Piotr Homola, the international CREDO coordinator, who announced the
      Memorandum of Understanding together with the list of CREDO institutional
      members.
      <br />
      The conference was attended by scientists associated with the project and teachers
      responsible for the implementation of the project in Polish schools.<br />
      he press information has just appeared on EurekAlert!: [
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.eurekalert.org/news-releases/710978"
          target="_blank"
          rel="noreferrer">go to website</a
        >
      </span>].
      <br />
      Links to the full press release in English and Polish can be found on the IFJ
      PAN Press Office website:
      <br />
      English:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://press.ifj.edu.pl/en/news/2019/09/11/"
          target="_blank"
          rel="noreferrer">news</a
        >
      </span>,
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://press.ifj.edu.pl/en/news/2019/09/11/press-kit.php"
          target="_blank"
          rel="noreferrer">website</a
        >
      </span>
      <br />
      Polish:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://press.ifj.edu.pl/news/2019/09/11/"
          target="_blank"
          rel="noreferrer">news</a
        >
      </span>,
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://press.ifj.edu.pl/news/2019/09/11/pakiet-prasowy.php"
          target="_blank"
          rel="noreferrer">website</a
        >
      </span>
      <br />
      And the list of the CREDO institutional members can be found here: [<span
        style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="#/about/institutionalmembers"
          target="_blank">go to website</a
        >
      </span>]
    </span>
  </div>
</div>
