<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Lecture for schools about CREDO on XVIII Festival of Science in Miedzylesie
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/posts/29042022FN_Miedzylesie.jpg"
      alt="images"
      width="400"
    />
  </div>
  A representative of IFJ PAN in CREDO, Ph. D. Robert Kaminski, introduced the school
  students to the subject of the CREDO project through a popularizing lecture. Additionally,
  students were able to learn interesting information on topics such as neutron stars,
  black holes, gravitational waves and cosmic radiation. The lecture took place as
  part of the XVIII Międzylesie Science Festival on April 29, 2022.
</div>
