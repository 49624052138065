<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Meeting with the ACC CYFORNET team
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/prometeus.jpg" alt="prometheus" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >Today a very promising meeting with the ACC CYFORNET team, the CREDO
      storage/computing partner. You recognize someone? If yes, visit our <span
        style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.facebook.com/credo.science"
          target="_blank"
          rel="noreferrer">facebook</a
        >
      </span>, for the first 4 FB fans who first recognize someone win the CREDO
      office set: writing pad, pen, and magnet – all with CREDO logo 🙂 Please
      recognize max 1 person, even if you know all. In the background:
      Prometheus, the most powerful computer in Poland, 79th in the world, now
      hosting CREDO. Thanks & looking forward to the future!
    </span>
  </div>
</div>
