<div class="container px-10 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    4h edition of the "Particle Hunters" competition
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/Start_4_edycja.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center">
      The 4th edition of the competition for schools "Particle Hunters" will
      start on 3 November this year and will last until 14 June 2022.<br />
      The aim of each edition of the "Particle Hunters" competition is to arouse
      interest in the physics and astrophysics of cosmic radiation among primary
      and secondary school pupils (but not only!) by using the CREDO Detector application.<br
      /><br />
      Each participating student downloads the app, joins a team with other students
      (and families) under the guidance of a team coordinator, usually a teacher
      from their school. The goal of each participating team is to capture as many
      cosmic ray particles as possible using the CREDO Detector application.<br
      />
      Read more:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/particle_hunters/#/about"
          >https://credo.science/particle_hunters/#/about</a
        ></span
      ><br />
      Registration of your team (school) can be made at:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/particle_hunters/#/register"
          >https://credo.science/particle_hunters/#/register</a
        ></span
      ><br />
      The current list of all registered teams can be viewed at:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/particle_hunters/#/teams"
          >https://credo.science/particle_hunters/#/teams</a
        ></span
      ><br />
      All schools are welcome to participate.<br />
      <hr class=" my-2 py-1 bg-gray-800" />
      <br />
      IV edycja konkursu dla szkół pt."Łowcy Cząstek" rozpocznie się 3 listopada
      br. i będzie trwać do 14 czerwca 2022 roku.<br />
      Celem każdej edycji konkursu "Łowcy Cząstek" jest zainteresowanie uczniów szkół
      podstawowych i średnich (ale nie tylko!) fizyką i astrofizyką promieniowania
      kosmicznego za pomocą aplikacji CREDO Detector. <br /><br />
      Każdy uczeń biorący udział w konkursie pobiera aplikację, dołącza do zespołu
      z innymi uczniami (i rodzinami) pod opieką koordynatora zespołu, którym zazwyczaj
      jest nauczyciel z jego szkoły. Celem każdego zespołu jest uchwycenie jak największej
      liczby cząstek promieniowania kosmicznego za pomocą aplikacji CREDO Detector.<br
      />
      Więcej o konkursie:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/particle_hunters/#/about"
          >https://credo.science/particle_hunters/#/about</a
        ></span
      ><br />Rejestracji swojego zespołu (szkoły) można dokonać na stronie:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/particle_hunters/#/register"
          >https://credo.science/particle_hunters/#/register</a
        ></span
      ><br />
      Aktualną listę wszystkich zarejestrowanych zespołów można zobaczyć na stronie:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/particle_hunters/#/teams"
          >https://credo.science/particle_hunters/#/teams</a
        ></span
      ><br />Zapraszamy wszystkie szkoły do uczestnictwa.
    </span>
  </div>
</div>
