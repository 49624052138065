<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO at Astrophysics Commission PAU
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/logoPAU.jpg"
      alt="logoPAU.jpg"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >Piotr Homola, the initiator and leader of the CREDO Collaboration gave an
      invited talk at the prestigious seminar organized by Polska Akademia
      Umiejętności (PAU) in Krakow. The talk titled “Cosmic-Ray Extremely
      Distributed Observatory: the new research possibilities in astrophysics”
      was addressed to the Astrophysics Commission of PAU, the body composed of
      the most esteemed professors active in the field of astrophysics, and
      received well.</span
    >
  </div>
</div>
