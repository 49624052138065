<script>
  import { onMount } from "svelte";

  let usersResults = [];
  let questions = [];
  let answerSummary = {};
  let showResults = true;
  let showAnswers = false;

  // Load user results from get_results.php
  onMount(async () => {
    // Fetching user results
    const res = await fetch(
      "https://user.credo.science/user-interface/italy-25/get_results.php"
    );
    const data = await res.json();

    if (data.status === "success") {
      usersResults = data.data;
    } else {
      console.error("Error fetching results:", data.message);
    }

    // Fetch local questions
    const resQuestions = await fetch("/download/Italy-quesions.json");
    questions = await resQuestions.json();

    // Fetching only answer summary
    const summaryRes = await fetch(
      "https://user.credo.science/user-interface/italy-25/get_answer_summary.php"
    );
    const summaryData = await summaryRes.json();

    if (summaryData.status === "success") {
      answerSummary = summaryData.answer_summary;
    } else {
      console.error("Error fetching answer summary:", summaryData.message);
    }
  });

  // Show/hide functions
  function toggleResults() {
    showResults = !showResults;
    showAnswers = false; // Hide answers when showing results
  }

  function toggleAnswers() {
    showAnswers = !showAnswers;
    showResults = false; // Hide results when showing answers
  }

  // Calculate percentage of correct answers
  function getPercentage(score, totalQuestions) {
    return Math.round((score / totalQuestions) * 100);
  }

  // Determine the CSS class for the answer
  function getAnswerClass(correctAnswer, userAnswer) {
    if (correctAnswer === userAnswer) {
      return "correct";
    }
    return "wrong";
  }

  // Get percentage for each answer
  function getAnswerPercentage(questionIndex, answerKey) {
    return answerSummary[questionIndex]?.[answerKey] || 0;
  }
</script>

<section class="text-gray-800 body-font w-4/5 mx-auto my-5 mb-20 p-8">
  <h1 class="text-lg mx-auto"><strong>Quiz Results and Answers</strong></h1>

  <button class="w-1/3" on:click={toggleResults}>Show Results</button>
  <button class="w-1/3" on:click={toggleAnswers}>Show Answers</button>

  {#if showResults}
    <h2>User Results</h2>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Score</th>
          <th>Percentage</th>
        </tr>
      </thead>
      <tbody>
        {#each usersResults as user}
          <tr>
            <td>{user.name}</td>
            <td>{user.score}</td>
            <td>{getPercentage(user.score, questions.length)}%</td>
          </tr>
        {/each}
      </tbody>
    </table>
  {/if}

  {#if showAnswers}
    <h2>Answer Summary</h2>
    {#each questions as question, i}
      <div class="question">
        <h3>{i + 1}. {question.question}</h3>
        <div class="options">
          {#each Object.entries(question.options) as [key, value]}
            {#if value !== ""}
              <div class={getAnswerClass(question.correct, key)}>
                <span>({key.toUpperCase()}) {value}</span>
                <span class="percentage">({getAnswerPercentage(i, key)}%)</span>
              </div>
            {/if}
          {/each}
        </div>
      </div>
    {/each}
  {/if}
</section>

<style>
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }

  th,
  td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
  }

  .question {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }

  .options {
    margin-top: 10px;
  }

  .options div {
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .correct {
    background-color: #d4edda;
    color: green;
    font-weight: bold;
  }

  .wrong {
    background-color: #f8d7da;
    color: red;
    font-weight: bold;
  }

  .percentage {
    margin-left: 10px;
    font-style: italic;
    color: #555;
  }

  button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }
</style>
