<script>
  let step = 1;
  let activeButton = "btn1";

  async function goToStep(value) {
    step = value;

    let btn = document.getElementById(activeButton);
    btn.classList.remove("border-red-700");
    btn.classList.remove("text-red-700");
    btn.classList.remove("bg-gray-200");
    // console.log("prev: " + activeButton + ", new: btn" + value);

    btn = document.getElementById("btn" + value);
    btn.classList.add("border-red-700");
    btn.classList.add("text-red-700");
    btn.classList.add("bg-gray-200");
    activeButton = "btn" + value;
  }
</script>

<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <p class="inline-flex">
        <a class="px-1" href="#/detector/tutorial-pl" rel="noopener noreferrer"
          ><img
            class="alignleft"
            src="./images/flags/polska.png"
            alt=""
            width="40"
            height="25"
          /></a
        >
        <a
          class="px-1"
          href="https://ciencia-cidada.blogspot.com/p/blog-page.html"
          rel="noopener noreferrer"
          target="_blank"
          ><img
            class="alignleft"
            src="./images/flags/portugal.png"
            alt=""
            width="40"
            height="25"
          /></a
        >
        <a class="px-1" href="#/detector/tutorial" rel="noopener noreferrer"
          ><img
            class="alignleft"
            src="./images/flags/wb.png"
            alt=""
            width="50"
            height="25"
          /></a
        >
      </p>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900"
      >
        <b>CREDO Detector Mobile App</b>
      </h1>
      <h2 class="text-base text-red-700 tracking-widest font-medium title-font">
        Information and Download
      </h2>

      <div class="container px-5 mx-auto flex flex-col max-h-max">
        <div class="flex mx-auto flex-wrap mb-5">
          <button
            id="btn1"
            on:click|preventDefault={() => goToStep(1)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t border-red-700 text-red-700 bg-gray-200"
          >
            <img
              alt="info"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/detector/info.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                Introduction
              </p></strong
            >
          </button>
          <button
            id="btn2"
            on:click|preventDefault={() => goToStep(2)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t"
            ><img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/detector/download.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                Télécharger l'application
              </p></strong
            >
          </button>
          <button
            id="btn3"
            on:click|preventDefault={() => goToStep(3)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t"
          >
            <img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/team/credo-cs/research.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                Aperçu de la détection
              </p></strong
            >
          </button>

          <button
            id="btn4"
            on:click|preventDefault={() => goToStep(4)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t"
          >
            <img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/detector/question.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                Liens Utiles
              </p></strong
            >
          </button>
        </div>
      </div>
      {#if step == 1}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-center font-serif"
        >
          <p class="w-full">
            Passez 2 / 4 minutes à regarder le tutoriel vidéo du détecteur CREDO
            (en anglais).
          </p>
          <div class="container px-5 py-6 mx-auto">
            <center>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/6rHnW--PZQk"
                title="YouTube video player"
                frameborder="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              /></center
            >
            <center class="py-3">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/4riZZANp1X4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              /></center
            >
            <p class="mt-4">
              Vous pouvez également lire les manuels (<a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="./data/PL-UserGuide.pdf">PL</a
              >
              |
              <a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="./data/ENG-UserGuide.pdf">EN</a
              >),
              <br />ou lisez le guide d'un de nos utilisateurs (<a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="https://mawmarecki.blogspot.com/2022/07/kilka-uwag-do-projektu-credo.html"
                >PL</a
              >)
              <br /><br />
              Si vous avez une question
              <br />consultez les FAQ
              <a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="#/education/faq">officielles</a
              >,ou
              <a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="https://mawmarecki.blogspot.com/2023/03/pytania-i-odpowiedzi-do-projektu-credo.html"
                >créées par les utilisateurs (en polonais)</a
              >
              <br /> si vous n'y trouvez pas de réponse, écrivez-nous un
              courriel
              <br /> contact[at]credo.science
            </p>
          </div>
        </div>
      {:else if step == 2}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-center font-serif"
        >
          <p class="w-full">
            <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=science.credo.mobiledetector"
              >Téléchargez l'application gratuite CREDO Detector
            </a> sur Google Play (version android &gt;= 4.0)
          </p>
          <div class="flex items-center flex-col m-auto text-center">
            <a
              href="//play.google.com/store/apps/details?id=science.credo.mobiledetector"
              ><img
                class="aligncenter wp-image-3193"
                src="./images/credodetector.png"
                alt="credo-detector google play"
                width="550"
                height="476"
              /></a
            >

            <p class="mt-4">
              ou <a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="https://apps.apple.com/pl/app/credo-detector/id1598629085"
                >Téléchargez l'application gratuite CREDO Detector
              </a> sur IOS Store (nécessite iOS 11.0 or later.)
            </p>
            <a href="https://apps.apple.com/pl/app/credo-detector/id1598629085"
              ><img
                class="aligncenter"
                src="./images/ios.png"
                alt="credo-detector IOS store"
                width="550"
                height="476"
              /></a
            >
          </div>
        </div>
      {:else if step == 3}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-center font-serif"
        >
          <p class="w-full">
            Visitez <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="//api.credo.science"
            >
              api.credo.science</a
            >
            et voyez vos traces de particules / profitez des classements
          </p>
          <div class="flex items-center flex-col m-auto text-center">
            <a href="https://api.credo.science/web/"
              ><img
                class="size-medium aligncenter"
                src="./images/detector/1.png"
                alt=""
                width="600"
                height="440"
              />
            </a>
          </div>
          <p class="w-full p-3">
            Vous pouvez profiter d'analyses supplémentaires (~2x par semaine)<br
            />
            garantie aux équipes participant au
            <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="https://credo.science/particle_hunters/"
            >
              Particle Hunters</a
            > la compétition.
          </p>
          <div class="flex items-center flex-col m-auto text-center">
            <img
              class="size-medium aligncenter"
              src="./images/detector/p_h.jpg"
              alt=""
              width="600"
              height="675"
            />
          </div>
          <!-- <p class="w-full p-3">
            Vous pouvez également participer aux travaux et aux essais de l <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="https://user.credo.science/user-interface/new/"
            >
              user-interface</a
            >,
          </p>
          <div class="flex items-center flex-col m-auto text-center">
            <img
              class="size-medium aligncenter"
              src="./images/detector/ui.png"
              alt=""
              width="600"
              height="390"
            />
          </div>
          <p class="w-full">
            il vous suffit de nous contacter par e-mail à
            contact[at]credo.science
          </p> -->
        </div>
      {:else if step == 4}
        Télécharger l'ensemble des données d'un an:<a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://user.credo.science/user-interface/dataset/"
          >user.credo.science/user-interface/dataset
        </a><br />
        Inscription:<a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://api.credo.science/web/register/"
          >api.credo.science/web/register</a
        ><br />
        Modifier le mot de passe :
        <a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://api.credo.science/web/password_reset/"
          >api.credo.science/web/password_reset</a
        ><br />
        Changer le nom de l'équipe:<a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://credo.science/particle_hunters/#/change_team"
          >credo.science/particle_hunters/#/change_team</a
        ><br />
        Principaux appareils actifs en 2023:<a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://credo.science/#/detector/api"
          >credo.science/#/detector/api</a
        ><br />
      {/if}
    </div>
  </div>
</section>
