<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Lecture on CREDO in English [6.03.2018]
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/credo.png" alt="credo img" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000;"
      >The lecture will be given by Piotr Homola on the occasion of
      Astrophysical Seminar at NCBJ. The talk will be given in English and it
      will be addressed mainly to scientists, although mostly a popular language
      will be used, so non-expert CREDO fans are welcome as well. During the
      lecture we hope to carry on another team and individual particle hunting
      with CREDO Detector, so please bring your smartphones and cam-covers.</span
    >

    <span style="color: #000000;"
      >When?  <strong>6.03.2018, from 12.30 to 13;30</strong></span
    >

    <span style="color: #000000;"
      >Where?  <strong>NCBJ, Hoża 69 pawilon room 22, Warszawa, Poland</strong
      ></span
    >
  </div>
</div>
