<script>
  import { setCookie, getCookie } from "./../../../__AllFunctions.svelte";
  import { descriptionContact, descriptionHeader } from "./utils.js";

  let lang = getCookie("lang") != null ? getCookie("lang") : "pl";
  let langCode = lang === "eng" ? 0 : 1;

  async function reloads() {
    lang = lang === "eng" ? "pl" : "eng";
    langCode = lang === "eng" ? 0 : 1;
    setCookie("lang", lang, 1);
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-Edu Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/about/team/credo-edu"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >{descriptionHeader.part1[langCode]}</a
      >

      <a
        href="#/about/team/credo-edu/people"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
      >
        {descriptionHeader.part2[langCode]}
      </a>
      <p
        href=""
        class="mr-5 uppercase tracking-wider font-serif hover:text-gray-700"
      >
        {descriptionHeader.part3[langCode]}
      </p>
      <!-- <p
        href=""
        class="mr-5 uppercase tracking-wider font-serif hover:text-gray-700"
      >
        {descriptionHeader.part4[langCode]}
      </p> -->
      <a
        href="#/about/team/credo-edu/contact"
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
      >
        <strong>{descriptionHeader.part5[langCode]}</strong>
      </a>
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <span
    class="inline-flex sm:ml-auto sm:mt-0 mt-20 justify-center sm:justify-start left-4 absolute p-4"
  >
    <button class="right h-16" on:click|preventDefault={reloads}>
      <img
        alt={lang + " flag"}
        class="m-2"
        style="width: 80px;"
        src={"./images/" + (lang === "pl" ? "eng" : "pl") + ".png"}
      />
    </button>
  </span>
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b>Contact</b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <p>
          {descriptionContact.part1[langCode]}
          <br /><br />
          {descriptionContact.part2[langCode]}
          <a
            class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
            href="mailto:melania.deresz[at]fuw.edu.pl"
          >
            <span class="inline-flex items-center justify-center">
              melania.deresz[at]fuw.edu.pl
            </span></a
          >
          <br />
        </p>
      </div>
    </div>
  </div>
</section>
