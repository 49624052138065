<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Cosmic ray detection on iPhones is now at your fingertips with the CREDO
    Detector for iOS available in the App Store !
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/post4IOS.jpg" alt="images" width="400" />
  </div>
  Cosmic ray detection on iPhones is now at your fingertips with the CREDO Detector
  for iOS available in the App Store !<br />This is a great leap forward in the
  CREDO project.<br /> The CREDO App team at the
  <span style="color: #000080;"
    ><a style="color: #000080;" href="https://ii.up.krakow.pl/en/home-page-2/"
      >Pedagogical University of Krakow</a
    >
  </span>
  with Michał Frontczak as the head developer and Łukasz Bibrzycki as the team leader
  had build on top of earlier apps, namely CREDO Detector for Android by Michal Niedzwiecki
  (Cracow University of Technology) and Cosmic Ray for IOS by Thomas Andersen (nSCIr).
  <br /><br />
  The app can be downloaded directly from the
  <span style="color: #000080;"
    ><a
      style="color: #000080;"
      href="https://apps.apple.com/pl/app/credo-detector/id1598629085"
      >iOS store</a
    >
  </span>.
</div>
