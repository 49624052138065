<script>
  import Tailwindcss from "./Tailwindcss.svelte";
  import Router from "svelte-spa-router";

  import Header from "./components/Header.svelte";
  import Footer from "./components/Footer.svelte";
  import Home from "./routes/Home.svelte";
  import NotFound from "./routes/NotFound.svelte";
  import VisegradGrant from "./routes/Visegrad-grant.svelte";

  import AboutCredo from "./routes/about/AboutCredo.svelte";
  import People from "./routes/about/People.svelte";
  import Career from "./routes/about/Career.svelte";
  import Kariera from "./routes/about/Kariera.svelte";
  import MediaAboutUs from "./routes/about/MediaAboutUs.svelte";
  import MaterialyPrasowe from "./routes/about/MaterialyPrasowe.svelte";
  import PressKit from "./routes/about/PressKit.svelte";
  import PressKitS from "./routes/about/PressKitS.svelte";
  import PressKitArtykulS from "./routes/about/PressKit_artykul.svelte";
  import InstitutionalMembers from "./routes/about/Old - InstitutionalMembers.svelte";
  import CREDOInstitutionalMembers from "./routes/about/Credo-institutional-members.svelte";

  // import SStuglik from "./routes/about/teams/people/sstuglik.svelte";

  // import TeamCREDOIFJ from "./routes/about/teams/credo-ifj/about.svelte";
  // import CREDOIFJpeople from "./routes/about/teams/credo-ifj/people.svelte";
  // import CREDOIFJpublications from "./routes/about/teams/credo-ifj/publications.svelte";
  // import CREDOIFJresearch from "./routes/about/teams/credo-ifj/research.svelte";
  // import CREDOIFJcontact from "./routes/about/teams/credo-ifj/contact.svelte";
  // import CREDOIFJactivites from "./routes/about/teams/credo-ifj/activites.svelte";

  import TeamCREDOML from "./routes/about/teams/credo-ml/about.svelte";
  import CREDOMLpeople from "./routes/about/teams/credo-ml/people.svelte";
  import CREDOMLpublications from "./routes/about/teams/credo-ml/publications.svelte";
  import CREDOMLresearch from "./routes/about/teams/credo-ml/research.svelte";
  import CREDOMLcontact from "./routes/about/teams/credo-ml/contact.svelte";
  import CREDOMLactivites from "./routes/about/teams/credo-ml/activites.svelte";

  import TeamCREDOCS from "./routes/about/teams/credo-cs/about.svelte";
  import CREDOCSpeople from "./routes/about/teams/credo-cs/people.svelte";
  import CREDOCSpublications from "./routes/about/teams/credo-cs/publications.svelte";
  import CREDOCSresearch from "./routes/about/teams/credo-cs/research.svelte";
  import CREDOCScontact from "./routes/about/teams/credo-cs/contact.svelte";
  import CREDOCStest from "./routes/about/teams/credo-cs/test.svelte";

  import TeamCREDOEDU from "./routes/about/teams/credo-edu/info.svelte";
  import TeamCREDOEDUPeople from "./routes/about/teams/credo-edu/people.svelte";
  import TeamCREDOEDUContact from "./routes/about/teams/credo-edu/contact.svelte";
  import CREDOCSactivites from "./routes/about/teams/credo-cs/activites.svelte";

  import Australia from "./routes/about/country/australia.svelte";
  import Canada from "./routes/about/country/canada.svelte";
  import Chile from "./routes/about/country/chile.svelte";
  import Czech from "./routes/about/country/czech.svelte";
  import Estonia from "./routes/about/country/estonia.svelte";
  import Georgia from "./routes/about/country/georgia.svelte";
  import Hungary from "./routes/about/country/hungary.svelte";
  import India from "./routes/about/country/india.svelte";
  import Italy from "./routes/about/country/italy.svelte";
  import Mexico from "./routes/about/country/mexico.svelte";
  import Nepal from "./routes/about/country/nepal.svelte";
  import Poland from "./routes/about/country/poland.svelte";
  import Portugal from "./routes/about/country/portugal.svelte";
  import Russia from "./routes/about/country/russia.svelte";
  import Slovakia from "./routes/about/country/slovakia.svelte";
  import Spain from "./routes/about/country/spain.svelte";
  import Thailand from "./routes/about/country/thailand.svelte";
  import Ukraine from "./routes/about/country/ukraine.svelte";
  import Uruguay from "./routes/about/country/uruguay.svelte";
  import USA from "./routes/about/country/usa.svelte";

  import DetectorApi from "./routes/detector/DetectorApi.svelte";
  import FaqApi from "./routes/detector/FaqApi.svelte";
  import ForumPl from "./routes/detector/ForumPL.svelte";
  import HowToStart from "./routes/detector/HowToStart.svelte";
  import HowToStartPL from "./routes/detector/HowToStartPl.svelte";
  import Tutorial from "./routes/detector/Tutorial.svelte";
  import TutorialPl from "./routes/detector/TutorialPl.svelte";
  import TutorialFr from "./routes/detector/TutorialFr.svelte";
  import ApiBrowser from "./routes/detector/ApiBrowser.svelte";
  import DeleteAccount from "./routes/detector/DeleteAccount.svelte";
  import Devices from "./routes/detector/Devices.svelte";

  import FAQ from "./routes/education/FAQ.svelte";
  import Practices from "./routes/education/Practices.svelte";
  import Praktyki from "./routes/education/Praktyki.svelte";
  import ParticleHunters from "./routes/education/ParticleHunters.svelte";
  import MaterialsForSchools from "./routes/education/MaterialsForSchools.svelte";
  import MaterialyDlaSzkol from "./routes/education/MaterialyDlaSzkol.svelte";
  import Podcast from "./routes/education/Podcast.svelte";
  import PodcastPL from "./routes/education/Podcast_pl.svelte";

  import Conferences from "./routes/science/Conferences.svelte";
  import Publications from "./routes/science/Publications.svelte";
  import ConferenceReports from "./routes/science/ConferenceReports.svelte";
  import Posters from "./routes/science/Posters.svelte";
  import PostersGeneral from "./routes/science/PostersGeneral.svelte";
  import PostersEvent from "./routes/science/PostersEvent.svelte";
  import Workshop2019 from "./routes/science/credo-workshop-2019.svelte";

  import CredoNews from "./routes/CredoNews.svelte";

  import SummerCamp from "./routes/SummerCamp/Home.svelte";
  import SummerCampPlan from "./routes/SummerCamp/Plan.svelte";
  import SummerCampContact from "./routes/SummerCamp/Contact.svelte";
  import SummerCampZoom from "./routes/SummerCamp/Zoom.svelte";

  import ScienceCampItaly2025 from "./routes/ScienceCamp/Italy2025/Home.svelte";
  import ScienceCampItaly2025Plan from "./routes/ScienceCamp/Italy2025/Plan.svelte";
  import ScienceCampItaly2025PTest from "./routes/ScienceCamp/Italy2025/Test.svelte";
  import ScienceCampItaly2025PTestScore from "./routes/ScienceCamp/Italy2025/TestScore.svelte";

  // 2025
  import Post_76 from "./routes/posts/2025/04-02.svelte";
  import Post_75 from "./routes/posts/2025/03-06.svelte";
  // 2024
  import Post_74 from "./routes/posts/2024/11-12.svelte";
  import Post_73 from "./routes/posts/2024/08-11.svelte";
  import Post_72 from "./routes/posts/2024/07-17.svelte";
  import Post_71 from "./routes/posts/2024/06-03.svelte";
  import Post_70 from "./routes/posts/2024/03-14.svelte";
  import Post_69 from "./routes/posts/2024/01-09.svelte";
  import Post_68 from "./routes/posts/2024/01-05.svelte";

  // 2023
  import Post_67 from "./routes/posts/2023/12-12.svelte";
  import Post_66 from "./routes/posts/2023/11-12.svelte";
  import Post_65 from "./routes/posts/2023/10-02.svelte";
  import Post_64 from "./routes/posts/2023/06-15.svelte";
  import Post_63 from "./routes/posts/2023/06-14.svelte";
  import Post_62 from "./routes/posts/2023/05-25.svelte";
  import Post_61 from "./routes/posts/2023/03-31.svelte";
  import Post_60 from "./routes/posts/2023/03-12.svelte";
  // 2022
  import Post_59 from "./routes/posts/2022/11-02.svelte";
  import Post_58 from "./routes/posts/2022/09-30.svelte";
  import Post_57 from "./routes/posts/2022/09-29.svelte";
  import Post_56 from "./routes/posts/2022/09-26.svelte";
  import Post_55 from "./routes/posts/2022/09-06.svelte";
  import Post_54 from "./routes/posts/2022/06-10.svelte";
  import Post_53 from "./routes/posts/2022/04-29.svelte";
  // 2021
  import Post_52 from "./routes/posts/2021/12-22.svelte";
  import Post_51 from "./routes/posts/2021/11-22.svelte";
  import Post_50 from "./routes/posts/2021/11-21.svelte";
  import Post_49 from "./routes/posts/2021/11-12.svelte";
  import Post_48 from "./routes/posts/2021/10-29.svelte";
  import Post_47 from "./routes/posts/2021/10-01.svelte";
  import Post_46 from "./routes/posts/2021/09-06.svelte";
  import Post_45 from "./routes/posts/2021/08-31.svelte";
  import Post_44 from "./routes/posts/2021/08-01.svelte";
  import Post_43 from "./routes/posts/2021/07-16.svelte";
  import Post_42 from "./routes/posts/2021/06-30.svelte";
  import Post_41 from "./routes/posts/2021/05-12.svelte";
  import Post_40 from "./routes/posts/2021/01-29.svelte";
  // 2020
  import Post_39 from "./routes/posts/2020/11-06.svelte";
  import Post_38 from "./routes/posts/2020/10-31.svelte";
  import Post_37 from "./routes/posts/2020/10-23.svelte";
  import Post_36 from "./routes/posts/2020/07-13.svelte";
  import Post_35 from "./routes/posts/2020/07-08.svelte";
  import Post_34 from "./routes/posts/2020/06-22.svelte";
  // 2019
  import Post_33 from "./routes/posts/2019/10-01.svelte";
  import Post_32 from "./routes/posts/2019/09-21.svelte";
  import Post_31 from "./routes/posts/2019/09-11.svelte";
  import Post_30 from "./routes/posts/2019/09-07.svelte";
  import Post_29 from "./routes/posts/2019/05-20.svelte";
  import Post_28 from "./routes/posts/2019/03-14.svelte";
  import Post_27 from "./routes/posts/2019/01-19.svelte";
  // 2018
  import Post_26 from "./routes/posts/2018/11-22.svelte";
  import Post_25 from "./routes/posts/2018/10-06.svelte";
  import Post_24 from "./routes/posts/2018/10-05.svelte";
  import Post_23 from "./routes/posts/2018/06-14.svelte";
  import Post_22 from "./routes/posts/2018/05-18.svelte";
  import Post_21 from "./routes/posts/2018/05-17.svelte";
  import Post_20 from "./routes/posts/2018/03-20.svelte";
  import Post_19 from "./routes/posts/2018/03-06.svelte";
  import Post_18 from "./routes/posts/2018/02-11.svelte";
  import Post_17 from "./routes/posts/2018/01-26.svelte";

  // 2017
  import Post_16 from "./routes/posts/2017/11-29.svelte";
  import Post_15 from "./routes/posts/2017/11-24.svelte";
  import Post_14 from "./routes/posts/2017/11-14.svelte";
  import Post_13 from "./routes/posts/2017/10-24.svelte";
  import Post_12 from "./routes/posts/2017/09-20.svelte";
  import Post_11 from "./routes/posts/2017/09-03.svelte";
  import Post_10 from "./routes/posts/2017/08-30.svelte";
  import Post_9 from "./routes/posts/2017/08-29.svelte";
  import Post_8 from "./routes/posts/2017/07-30.svelte";
  import Post_7 from "./routes/posts/2017/06-16.svelte";
  import Post_6 from "./routes/posts/2017/05-25.svelte";
  import Post_5 from "./routes/posts/2017/05-24.svelte";
  import Post_4 from "./routes/posts/2017/02-15.svelte";
  // 2016
  import Post_3 from "./routes/posts/2016/12-16.svelte";
  import Post_2 from "./routes/posts/2016/10-11.svelte";
  import Post_1 from "./routes/posts/2016/08-30.svelte";
</script>

<Tailwindcss />

<section class="text-gray-600 body-font flex flex-col h-screen">
  <Header />
  <!-- <div class="container flex-grow px-5 py-12 mx-auto"> -->
  <Router
    routes={{
      "/": Home,
      "/credonews": CredoNews,
      "/visegrad-grant": VisegradGrant,
      "/summer_camp": SummerCamp,
      "/summer_camp/plan": SummerCampPlan,
      "/summer_camp/contact": SummerCampContact,
      "/summer_camp/zoom": SummerCampZoom,
      "/about/aboutcredo": AboutCredo,
      "/about/mediaaboutus": MediaAboutUs,
      "/credo-institutional-members": CREDOInstitutionalMembers,
      "/credo-institutional-members/australia": Australia,
      "/credo-institutional-members/canada": Canada,
      "/credo-institutional-members/chile": Chile,
      "/credo-institutional-members/czech": Czech,
      "/credo-institutional-members/estonia": Estonia,
      "/credo-institutional-members/georgia": Georgia,
      "/credo-institutional-members/hungary": Hungary,
      "/credo-institutional-members/india": India,
      "/credo-institutional-members/italy": Italy,
      "/credo-institutional-members/mexico": Mexico,
      "/credo-institutional-members/nepal": Nepal,
      "/credo-institutional-members/poland": Poland,
      "/credo-institutional-members/portugal": Portugal,
      "/credo-institutional-members/russia": Russia,
      "/credo-institutional-members/slovakia": Slovakia,
      "/credo-institutional-members/spain": Spain,
      "/credo-institutional-members/thailand": Thailand,
      "/credo-institutional-members/ukraine": Ukraine,
      "/credo-institutional-members/uruguay": Uruguay,
      "/credo-institutional-members/usa": USA,

      "/about/career": Career,
      "/about/kariera": Kariera,
      "/about/people": People,
      "/about/materialyprasowe": MaterialyPrasowe,
      "/about/presskits": PressKit,
      "/about/presskits_spanish": PressKitS,
      "/una_correlacion_intrigante_entre_terremotos_y_radiacion_cosmica":
        PressKitArtykulS,

      // "/people/sstuglik": SStuglik,
      // "/about/team/credo-ifj": TeamCREDOIFJ,
      // "/about/team/credo-ifj/people": CREDOIFJpeople,
      // "/about/team/credo-ifj/publications": CREDOIFJpublications,
      // "/about/team/credo-ifj/research": CREDOIFJresearch,
      // "/about/team/credo-ifj/contact": CREDOIFJcontact,
      // "/about/team/credo-ifj/activites": CREDOIFJactivites,

      "/about/team/credo-ml": TeamCREDOML,
      "/about/team/credo-ml/people": CREDOMLpeople,
      "/about/team/credo-ml/publications": CREDOMLpublications,
      "/about/team/credo-ml/research": CREDOMLresearch,
      "/about/team/credo-ml/contact": CREDOMLcontact,
      "/about/team/credo-ml/activites": CREDOMLactivites,

      "/about/team/credo-cs": TeamCREDOCS,
      "/about/team/credo-cs/people": CREDOCSpeople,
      "/about/team/credo-cs/publications": CREDOCSpublications,
      "/about/team/credo-cs/research": CREDOCSresearch,
      "/about/team/credo-cs/contact": CREDOCScontact,
      "/about/team/credo-cs/activites": CREDOCSactivites,
      "/about/team/credo-cs/test": CREDOCStest,

      "/about/team/credo-edu": TeamCREDOEDU,
      "/about/team/credo-edu/people": TeamCREDOEDUPeople,
      "/about/team/credo-edu/contact": TeamCREDOEDUContact,

      "/detector/apibrowser": ApiBrowser,
      "/detector/delete_account": DeleteAccount,
      "/detector/api": Devices,
      "/detector/faqapi": FaqApi,
      "/detector/forumpl": ForumPl,
      "/how-to-start": HowToStart,
      "/how-to-start-pl": HowToStartPL,
      "/detector/tutorial": Tutorial,
      "/detector/tutorial-pl": TutorialPl,
      "/detector/tutorial-fr": TutorialFr,
      "/accesstodata": DetectorApi,

      "/education/faq": FAQ,
      "/education/materialsforschools": MaterialsForSchools,
      "/education/materialydlaszkol": MaterialyDlaSzkol,
      "/education/podcast": Podcast,
      "/education/podcast_pl": PodcastPL,
      "/education/particlehunters": ParticleHunters,
      "/education/practices": Practices,
      "/education/praktyki": Praktyki,

      "/science/conferences": Conferences,
      "/science/publications": Publications,
      "/science/conferencereports": ConferenceReports,
      "/science/posters": Posters,
      "/science/posters/event": PostersEvent,
      "/science/posters/general": PostersGeneral,
      "/credo-workshop-2019": Workshop2019,
      "/groups": People,

      "/italy2025": ScienceCampItaly2025,
      "/italy2025/plan": ScienceCampItaly2025Plan,
      "/italy2025/test": ScienceCampItaly2025PTest,
      "/italy2025/test-score": ScienceCampItaly2025PTestScore,

      // 2025
      "/posts/2025/04-02": Post_76,
      "/posts/2025/03-06": Post_75,
      // 2024
      "/posts/2024/11-12": Post_74,
      "/posts/2024/08-11": Post_73,
      "/posts/2024/07-17": Post_72,
      "/posts/2024/06-03": Post_71,
      "/posts/2024/03-14": Post_70,
      "/posts/2024/01-09": Post_69,
      "/posts/2024/01-05": Post_68,

      // 2023
      "/posts/2023/12-12": Post_67,
      "/posts/2023/11-12": Post_66,
      "/posts/2023/10-02": Post_65,
      "/posts/2023/06-15": Post_64,
      "/posts/2023/06-14": Post_63,
      "/posts/2023/05-25": Post_62,
      "/posts/2023/03-31": Post_61,
      "/posts/2023/03-12": Post_60,
      // 2022
      "/posts/2022/11-02": Post_59,
      "/posts/2022/09-30": Post_58,
      "/posts/2022/09-29": Post_57,
      "/posts/2022/09-26": Post_56,
      "/posts/2022/09-06": Post_55,
      "/posts/2022/06-10": Post_54,
      "/posts/2022/04-29": Post_53,
      // 2021
      "/posts/2021/12-22": Post_52,
      "/posts/2021/11-22": Post_51,
      "/posts/2021/11-21": Post_50,
      "/posts/2021/11-12": Post_49,
      "/posts/2021/10-29": Post_48,
      "/posts/2021/10-01": Post_47,
      "/posts/2021/09-06": Post_46,
      "/posts/2021/08-31": Post_45,
      "/posts/2021/08-01": Post_44,
      "/posts/2021/07-16": Post_43,
      "/posts/2021/06-30": Post_42,
      "/posts/2021/05-12": Post_41,
      "/posts/2021/01-29": Post_40,
      // 2020
      "/posts/2020/11-06": Post_39,
      "/posts/2020/10-31": Post_38,
      "/posts/2020/10-23": Post_37,
      "/posts/2020/07-13": Post_36,
      "/posts/2020/07-08": Post_35,
      "/posts/2020/06-22": Post_34,
      // 2019
      "/posts/2019/10-01": Post_33,
      "/posts/2019/09-21": Post_32,
      "/posts/2019/09-11": Post_31,
      "/posts/2019/09-07": Post_30,
      "/posts/2019/05-20": Post_29,
      "/posts/2019/03-14": Post_28,
      "/posts/2019/01-19": Post_27,
      // 2018
      "/posts/2018/11-22": Post_26,
      "/posts/2018/10-06": Post_25,
      "/posts/2018/10-05": Post_24,
      "/posts/2018/06-14": Post_23,
      "/posts/2018/05-18": Post_22,
      "/posts/2018/05-17": Post_21,
      "/posts/2018/03-20": Post_20,
      "/posts/2018/03-06": Post_19,
      "/posts/2018/02-11": Post_18,
      "/posts/2018/01-26": Post_17,
      // 2017
      "/posts/2017/11-29": Post_16,
      "/posts/2017/11-24": Post_15,
      "/posts/2017/11-14": Post_14,
      "/posts/2017/10-24": Post_13,
      "/posts/2017/09-20": Post_12,
      "/posts/2017/09-03": Post_11,
      "/posts/2017/08-30": Post_10,
      "/posts/2017/08-29": Post_9,
      "/posts/2017/07-30": Post_8,
      "/posts/2017/06-16": Post_7,
      "/posts/2017/05-25": Post_6,
      "/posts/2017/05-24": Post_5,
      "/posts/2017/02-15": Post_4,

      // 2016
      "/posts/2016/12-16": Post_3,
      "/posts/2016/10-11": Post_2,
      "/posts/2016/08-30": Post_1,
      "*": NotFound,
    }}
  />
  <!-- </div> -->
  <Footer />
</section>
