<script>
  import { device_info } from "./../../stores/devices.js";
  import DeviceCard from "./../../components/DeviceCard.svelte";
  import DeviceCardImg from "./../../components/DeviceCardImg.svelte";
  import DeviceCardSmall from "./../../components/DeviceCardSmall.svelte";
  import DeviceCardSmallImg from "./../../components/DeviceCardSmallImg.svelte";

  // filtrowanie po nazwach
  let searchTerm = "";
  let filteredResults = [];

  $: {
    if (searchTerm) {
      filteredResults = $device_info.filter((item) =>
        item.tname.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );
    } else {
      filteredResults = [...$device_info];
    }
  }

  // zmienne globalne uzywane przez funkcje zewnetrzne
  let currentSort = null;
  let sortReverse = false;

  function sortByKey(p) {
    if (currentSort === p) {
      sortReverse = !sortReverse;
    } else {
      currentSort = p;
      sortReverse = false;
    }

    let sign = sortReverse ? -1 : 1;

    filteredResults = filteredResults.sort((a, b) => {
      return (a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : a.id - b.id) * sign;
    });
  }
  let show_option = -1;
  let show_img_size = -1;

  function ChangeShow(value) {
    if (value == 0) {
      show_option *= -1;
    }
    if (value == 1) {
      show_img_size *= -1;
    }
  }
</script>

<section class="container py-10 mx-auto flex flex-col justify-evenly">
  <h1
    class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
  >
    <b>Statistics of devices with the CREDO Detector app in 2023</b>
  </h1>
  <div class="container mx-auto flex flex-col">
    <div class="flex flex-col text-center w-full mb-10">
      <p class="w-full mx-auto font-serif text-2xl pb-2">
        The statistics (of detections) include the results after the
        anti-artifact filter - rejection of hotpixels, too frequent traces. <br
        />
        <b>The results do not include ML analysis</b> - to better show which
        devices generally have a lot of traces.<br /><br />
        Additional condition: minimum 200 hours of device operation time and minimum
        300 traces on the device.
      </p>
      <br />
      <div class="my-2 w-1/3 mx-auto">
        {#if show_option == -1}
          <button
            on:click|preventDefault={() => {
              ChangeShow(0);
            }}
            class=" w-full border-0 bg-gray-900 text-white hover:bg-blue-900 uppercase py-2"
          >
            See Full statistic
          </button>
        {:else}
          <button
            on:click|preventDefault={() => {
              ChangeShow(0);
            }}
            class=" w-full border-0 bg-gray-900 text-white hover:bg-blue-900 uppercase py-2"
          >
            See Basic statistic
          </button>
        {/if}
        {#if show_img_size == -1}
          <button
            on:click|preventDefault={() => {
              ChangeShow(1);
            }}
            class=" w-full border-0 bg-gray-900 text-white hover:bg-blue-900 uppercase py-2"
          >
            See image sizes good for ML models
          </button>
        {:else}
          <button
            on:click|preventDefault={() => {
              ChangeShow(1);
            }}
            class=" w-full border-0 bg-gray-900 text-white hover:bg-blue-900 uppercase py-2"
            >See all image sizes
          </button>
        {/if}
      </div>
      <div class="w-full mx-auto overflow-auto">
        <table
          class="table-auto w-full text-left whitespace-no-wrap border-collapse border border-b-gray-800 py-4"
        >
          {#if show_option == -1}
            <thead>
              <tr>
                <!-- <th on:click={() => { sortByKey("id"); }}
            class="my-th text-md text-center title-font"
          >
            No.{currentSort === "id" ? sortReverse === false ? "▲" : "▼" : ""}
          </th> -->
                <th
                  on:click={() => {
                    sortByKey("device_id");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Device id{currentSort === "device_id"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("all_traces");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  All traces{currentSort === "all_traces"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                {#if show_img_size == -1}
                  <th
                    on:click={() => {
                      sortByKey("all_detect");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    Detect{currentSort === "all_detect"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("detect_proc");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    % Detect {currentSort === "detect_proc"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("hour_detect");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    Hour Detect{currentSort === "hour_detect"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                {:else}
                  <th
                    on:click={() => {
                      sortByKey("no_ml_detect");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    (all) Detect{currentSort === "no_ml_detect"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("no_ml_proc");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    (all) % Detect {currentSort === "no_ml_proc"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("hour_no_ml_detect");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    (all) Hour Detect{currentSort === "hour_no_ml_detect"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                {/if}
                <th
                  on:click={() => {
                    sortByKey("time_work");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Time work [H]{currentSort === "time_work"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("hour_traces");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Hour Traces{currentSort === "hour_traces"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("device_model");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Device Model{currentSort === "device_model"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
              </tr>
            </thead>
            <tbody>
              {#await $device_info}
                <tr><td colspan="6">Loading...</td></tr>
              {:then}
                {#each filteredResults as item, i}
                  {#if i % 2 == 0}
                    {#if show_img_size == -1}
                      <DeviceCardSmall device_info={item} />
                    {:else}
                      <DeviceCardSmallImg device_info={item} />
                    {/if}
                  {:else if show_img_size == -1}
                    <DeviceCardSmall
                      device_info={item}
                      tailwindcss="bg-gray-600 text-white"
                    />
                  {:else}
                    <DeviceCardSmallImg
                      device_info={item}
                      tailwindcss="bg-gray-600 text-white"
                    />
                  {/if}
                {/each}
              {/await}
            </tbody>
          {:else}
            <thead>
              <tr>
                <!-- <th on:click={() => { sortByKey("id"); }}
              class="my-th text-md text-center title-font"
            >
              No.{currentSort === "id" ? sortReverse === false ? "▲" : "▼" : ""}
            </th> -->
                <th
                  on:click={() => {
                    sortByKey("device_id");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Device id{currentSort === "device_id"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("all_traces");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  All traces{currentSort === "all_traces"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("bad_margin");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Bad margin{currentSort === "bad_margin"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("hot_pixels");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Hot pixel{currentSort === "hot_pixels"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("artefact");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Artefact{currentSort === "artefact"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("too_bright");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Too Bright{currentSort === "too_bright"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                {#if show_img_size == -1}
                  <th
                    on:click={() => {
                      sortByKey("bad_size");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    Bad size{currentSort === "bad_size"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("all_detect");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    Detect{currentSort === "all_detect"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("detect_proc");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    % Detect {currentSort === "detect_proc"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("hour_detect");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    Hour Detect{currentSort === "hour_detect"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                {:else}
                  <th
                    on:click={() => {
                      sortByKey("no_ml_detect");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    (all) Detect{currentSort === "no_ml_detect"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("no_ml_proc");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    (all) % Detect {currentSort === "no_ml_proc"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                  <th
                    on:click={() => {
                      sortByKey("hour_no_ml_detect");
                    }}
                    class="my-th text-md text-center title-font"
                  >
                    (all) Hour Detect{currentSort === "hour_no_ml_detect"
                      ? sortReverse === false
                        ? "▲"
                        : "▼"
                      : ""}
                  </th>
                {/if}
                <th
                  on:click={() => {
                    sortByKey("time_work");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Time work [H]{currentSort === "time_work"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("temperature_mean");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Temp[&#8451;]{currentSort === "temperature_mean"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("hour_traces");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Hour Traces{currentSort === "hour_traces"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("device_model");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  Device Model{currentSort === "device_model"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
                <th
                  on:click={() => {
                    sortByKey("system_version");
                  }}
                  class="my-th text-md text-center title-font"
                >
                  System Version{currentSort === "system_version"
                    ? sortReverse === false
                      ? "▲"
                      : "▼"
                    : ""}
                </th>
              </tr>
            </thead>
            <tbody>
              {#await $device_info}
                <tr><td colspan="6">Loading...</td></tr>
              {:then}
                {#each filteredResults as item, i}
                  {#if i % 2 == 0}
                    {#if show_img_size == -1}
                      <DeviceCard device_info={item} />
                    {:else}
                      <DeviceCardImg device_info={item} />
                    {/if}
                  {:else if show_img_size == -1}
                    <DeviceCard
                      device_info={item}
                      tailwindcss="bg-gray-600 text-white"
                    />
                  {:else}
                    <DeviceCardImg
                      device_info={item}
                      tailwindcss="bg-gray-600 text-white"
                    />
                  {/if}
                {/each}
              {/await}
            </tbody>
          {/if}
        </table>
      </div>
    </div>
  </div>
</section>
