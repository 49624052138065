<script>
  import Fa from "svelte-fa/src/fa.svelte";
  import Link from "svelte-link";
  import * as animateScroll from "svelte-scrollto";
  import {
    faMobileAlt,
    faInfo,
    faEnvelope,
    faArrowAltCircleUp,
  } from "@fortawesome/free-solid-svg-icons";

  const themeRavenclaw = {
    secondaryOpacity: 1,
    primaryColor: "#0438a1",
    secondaryColor: "#6c6c6c",
  };
  let home = "https://credo.science/";
</script>

<div class="bg-gray-300 w-full mx-auto" id="project">
  <div
    class="mt-10 w-10 h-10 mr-2 md:mr-10 relative text-3xl font-bold leading-tight z-10 inset-y-0 right-0 -mb-15 float-right"
  >
    <button
      on:click|preventDefault={() => {
        location.replace(home + "#post");
      }}
      ><Fa
        class="text-black hover:text-gray-400"
        icon={faArrowAltCircleUp}
        size="2x"
        secondaryOpacity={1}
      /></button
    >
  </div>
  <div class="px-5 py-20 w-full mx-auto">
    <div class="container flex-grow mx-auto">
      <div class="flex flex-col text-center w-3/4 mx-auto">
        <h1
          class="text-4xl font-semibold title-font mb-4 text-gray-900 underline"
        >
          CREDO PROJECT
        </h1>
      </div>

      <div class="flex flex-wrap">
        <div class="lg:w-1/3 md:w-full sm:w-full w-full">
          <div
            class="h-full flex items-center rounded-lg p-8 sm:flex-row flex-col"
          >
            <div
              class="w-24 h-24 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 flex-shrink-0"
            >
              <Fa icon={faInfo} size="2x" {...themeRavenclaw} />
            </div>
            <div class="text-center w-full">
              <h2
                class="text-gray-900 text-lg title-font font-medium mb-3 hover:text-blue-900"
              >
                <Link
                  href="#/about/AboutCredo"
                  on:click={() => animateScroll.scrollToTop()}
                >
                  About the Project
                </Link>
              </h2>
              <p class="leading-relaxed text-base">
                Find out more about the CREDO project
              </p>
            </div>
          </div>
        </div>
        <div class="lg:w-1/3 md:w-full sm:w-full w-full">
          <div
            class="h-full flex items-center rounded-lg p-8 sm:flex-row flex-col"
          >
            <div
              class="w-24 h-24 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 flex-shrink-0"
            >
              <Fa icon={faMobileAlt} size="2x" {...themeRavenclaw} />
            </div>
            <div class="text-center w-full">
              <h2
                class="text-gray-900 text-lg title-font font-medium mb-3 hover:text-blue-900"
              >
                <Link
                  href="#/detector/tutorial"
                  on:click={() => animateScroll.scrollToTop()}
                >
                  CREDO Detector
                </Link>
              </h2>
              <p class="leading-relaxed text-base">
                Mobile Application / Aplikacja Mobilna
              </p>
            </div>
          </div>
        </div>
        <div class="lg:w-1/3 md:w-full sm:w-full w-full">
          <div
            class="h-full flex items-center rounded-lg p-8 sm:flex-row flex-col"
          >
            <div
              class="w-24 h-24 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 flex-shrink-0"
            >
              <Fa icon={faEnvelope} size="2x" {...themeRavenclaw} />
            </div>
            <div class="text-center w-full">
              <h2
                class="text-gray-900 text-lg title-font font-medium mb-3 hover:text-blue-900"
              >
                <Link
                  href="mailto:contact[at]credo.science"
                  on:click={() => animateScroll.scrollToTop()}
                >
                  Contact Us
                </Link>
              </h2>
              <p class="leading-relaxed text-base">
                Please contacts us with questions or ideas
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
