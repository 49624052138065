<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO invited by ASTERICS to Trieste for their DECS meeting [22-24.01.2018]
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/1-570x350.jpg" alt="img" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000;"
      >Result - two fresh ideas of which you will hear more soon:</span
    >

    <span style="color: #000000;"
      >1) Private Particle Detective (new citizen science workflow to handle
      particle tracks caught by smartphones and other cameras - complementary to
      Dark Universe Welcome - you are invited to join the development team)</span
    >
    <span style="color: #000000;">
      2) Astronomical CCD Dark Frames for CREDO (Astronomical CCDs record tracks
      cosmic particles, the data are available and software to extract cosmic
      ray images exists! Idea by Massimo Ramella, INAF. Volunteers to work on
      this welcome!).</span
    >

    <span style="color: #000000;">Photos:</span>

    <span style="color: #000000;"
      ><img
        class="aligncenter wp-image-3220"
        src="./images/1-300x225.jpg"
        alt=""
        width="450"
        height="338"
      /></span
    >
    <p style="text-align: center;">
      <span style="color: #000000;">a) CREDO at seaside in Trieste</span>
    </p>
    <p style="text-align: center;">
      <span style="color: #000000;"
        ><img
          class="aligncenter wp-image-3221"
          src="./images/2-300x225.jpg"
          alt=""
          width="450"
          height="338"
        /></span
      >
    </p>
    <p style="text-align: center;">
      <span style="color: #000000;"
        >b) Niraj Dhital talking on CREDO at ASTERCIS DECS meeting</span
      >
    </p>
    <p style="text-align: center;">
      <span style="color: #000000;"
        ><img
          class="alignnone wp-image-3222"
          src="./images/3-300x225.jpg"
          alt=""
          width="450"
          height="338"
        /></span
      >
    </p>
    <p style="text-align: center;">
      <span style="color: #000000;"
        >c) visit in the Trieste INAF Observatory</span
      >
    </p>
    <p style="text-align: center;">
      <span style="color: #000000;"
        ><img
          class="alignnone wp-image-3223"
          src="./images/4-300x242.png"
          alt=""
          width="450"
          height="363"
        /></span
      >
    </p>
    <p style="text-align: center;">
      <span style="color: #000000;"
        >d) an example of a cosmic track on a CCD dark frame</span
      >
    </p>
  </div>
</div>
