<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-ML Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/about/team/credo-ml"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >About</a
      >
      <a
        href="#/about/team/credo-ml/research"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      ><strong>
        <a
          href="#/about/team/credo-ml/people"
          class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
          >People</a
        ></strong
      >
      <a
        href="#/about/team/credo-ml/activites"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Activites</a
      >
      <a
        href="#/about/team/credo-ml/publications"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href="#/about/team/credo-ml/contact"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-5">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-4 text-red-900"
      >
        <b>Principal Investigators</b>
      </h1>
    </div>
    <div
      class="w-6/7 lg:w-4/5 items-center text-center flex flex-wrap mx-auto text-xl font-serif"
    >
      <!-- Łukasz -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Bibrzycki.jpeg"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Łukasz Bibrzycki, Ph.D. Eng.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Chief Scientific
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:lukasz.bibrzycki[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>lukasz.bibrzycki[at]agh.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0002-6117-4894"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Lukasz-Bibrzycki"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://scholar.google.com/citations?hl=en&user=Yk8HR1QAAAAJ"
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500 pt-2"
                href="https://skos.agh.edu.pl/osoba/lukasz-bibrzycki-10155.html"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg> Homepage</span
                >
              </a><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Olaf  -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Bar.jpeg"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Olaf Bar, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Senior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:olaf.bar[at]pk.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>olaf.bar[at]pk.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0002-0581-5423"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Olaf-Bar-3"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br /><br /><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Tomasz H  -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/hachaj.png"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Tomasz Hachaj, Ph.D. D.Sc
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Senior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:thachaj[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>thachaj[at]agh.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0003-1390-9021"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Tomasz-Hachaj"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://scholar.google.pl/citations?user=njrQ-CAAAAAJ&hl=pl"
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://skos.agh.edu.pl/osoba/tomasz-jan-hachaj-10157.html"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>Homepage</span
                >
              </a><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Dariusz -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/DPalka.jpg"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Dariusz Pałka, Ph.D.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Senior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:dpalka[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>dpalka[at]agh.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0003-1420-981X"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Dariusz-Palka"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a>
              <!-- |
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href = " "
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a> -->
              <br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://skos.agh.edu.pl/osoba/dariusz-palka-7895.html"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>Homepage</span
                >
              </a><br />
              <br />
            </p>
          </div>
        </div>
      </div>
      <!-- Marcin -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Piekarczyk.png"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Marcin Piekarczyk, Ph.D. Eng.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Senior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:mpiekarczyk[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>mpiekarczyk[at]agh.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0003-3699-9955"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Marcin-Piekarczyk"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />

              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://scholar.google.com/citations?hl=en&user=STNoA0kAAAAJ"
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a><br />
              <!-- Pattern Recognition, Biometrics, Graph Languages, Data Science, Cybernetics<br
              /> -->
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://skos.agh.edu.pl/osoba/marcin-piekarczyk-10158.html"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>Homepage</span
                >
              </a><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Krzysiek  -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Rzecki.jpeg"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Krzysztof Rzecki, Ph.D. D.Sc
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Senior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:krz[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>krz[at]agh.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0002-6834-2344"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Krzysztof-Rzecki"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://scholar.google.pl/citations?user=bHhRjYgAAAAJ&hl=pl"
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://sites.google.com/view/krzysztof-rzecki/"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>Homepage</span
                >
              </a><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Michał F -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Frontczak.png"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Michał Frontczak, M.Sc.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Junior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:michał.frontczak[at]up.krakow.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>michał.frontczak[at]up.krakow.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0002-5264-4508"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Michal-Frontczak"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://scholar.google.com/citations?view_op=list_works&hl=pl&user=hFyurpYAAAAJ"
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://ii.up.krakow.pl/pracownicy-up/frontczak/"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>Homepage</span
                >
              </a><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Michał N -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Niedzwiecki.jpg"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Michał Niedźwiecki, M.Sc. Eng.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Junior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:nkg[at]pk.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>nkg[at]pk.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0003-2830-9133"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Michal-Niedzwiecki"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://scholar.google.pl/citations?user=kH60VhQAAAAJ&hl=en"
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://it.pk.edu.pl/?i=vc-u-contact.php&vc=u-117"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>Homepage</span
                >
              </a><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Tomek -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/TSośnicki.jpg"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Tomasz Sośnicki, M.Sc. Eng.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Junior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:sosnicki[at]agh.edu.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>sosnicki[at]agh.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0001-7059-7971"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Tomasz-Sosnicki-2"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://scholar.google.pl/citations?user=fzpI2NIAAAAJ&hl=pl"
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://skos.agh.edu.pl/osoba/tomasz-edward-sosnicki-9196.html"
                target="_blank"
                rel="noopener noreferrer"
                ><span class="inline-flex items-center justify-center"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    />
                  </svg>Homepage</span
                >
              </a><br />
            </p>
          </div>
        </div>
      </div>
      <!-- Sławek -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/Stuglik.jpg"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Sławomir Stuglik, M.Sc. Eng.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Junior Researcher
            </h3>
            <p class="tracking-wider font-serif">
              <a
                href="mailto:slawomir.stuglik[at]ifj.edu.pl"
                target="_blank"
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                rel="noopener noreferrer"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>slawomir.stuglik[at]ifj.edu.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0002-5490-0835"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Slawomir-Stuglik"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://scholar.google.com/citations?hl=pl&user=syn5keEAAAAJ"
                target="_blank"
                rel="noopener noreferrer"
                >Google Scholar
              </a><br /><br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col text-center w-full mb-5 pt-8">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-4 text-red-900"
      >
        <b>Associates Researcher</b>
      </h1>
    </div>
    <div
      class="w-6/7 lg:w-4/5 items-center text-center flex flex-wrap mx-auto text-xl font-serif"
    >
      <!-- Kasia -->
      <div class="lg:w-1/3 md:w-full mb-7 items-center text-center">
        <div class="h-full flex flex-col items-center text-center">
          <img
            alt="team"
            class="flex-shrink-0 rounded-lg w-1/4 object-cover object-center mb-4"
            src="./images/team/KN.png"
          />
          <div class="w-full">
            <h2
              class="title-font uppercase tracking-wider font-serif text-gray-900"
            >
              Katarzyna Nęcka Msc. Eng.
            </h2>
            <h3 class="text-gray-500 mb-1 tracking-wider font-serif">
              Junior Researcher
            </h3>
            <p class="mb-4 tracking-wider font-serif">
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="mailto:katarzyna.necka[at]up.krakow.pl"
              >
                <span class="inline-flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 26 26"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>katarzyna.necka[at]up.krakow.pl
                </span></a
              ><br />
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://orcid.org/0000-0002-8218-3247"
                target="_blank"
                rel="noopener noreferrer"
                >ORCID
              </a>|
              <a
                class="text-blue-900 tracking-wider font-serif hover:text-blue-500"
                href="https://www.researchgate.net/profile/Katarzyna-Necka-2"
                target="_blank"
                rel="noopener noreferrer"
                >ResearchGate
              </a><br /><br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
