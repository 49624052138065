<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO Visegrad Project Meeting [19.03.2018]
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/visegrand-2-570x350.png" alt="credo img" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >A very fruitful CREDO Visegrad Project Meeting in Bratislava, at the
      Comenius University: inspiring content (you can access the talks <span
        style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.facebook.com/credo.science"
          target="_blank"
          rel="noreferrer">here</a
        >
      </span>)<br /> attendance of the Slovak media (Slovak Press Agency, TA 3
      TV). Thanks to the Local Organizers: esp. Fedor, Tibor and Robert, thanks
      to the International Visegrad Fund. We are very much looking forward to
      see the first fruits of the meeting soon.
      <p style="text-align: justify;">
        <img
          class="size-medium aligncenter"
          src="./images/meeting-300x225.jpg"
          alt=""
          width="300"
          height="225"
        />
      </p>
      <p style="text-align: justify;">
        <span style="color: #000000;"
          >The photo has been taken during the meeting dinner, Restaurant
          Riviera.</span
        >
      </p>
    </span>
  </div>
</div>
