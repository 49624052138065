<script>
  import {
    descriptionPlan,
    descriptionHeader,
    descriptionPlanTable,
  } from "./utils.js";
  import { setCookie, getCookie } from "./../__AllFunctions.svelte";

  let lang = getCookie("lang") != null ? getCookie("lang") : "pl";
  let langCode = lang === "eng" ? 0 : 1;

  async function reloads() {
    lang = lang === "eng" ? "pl" : "eng";
    langCode = lang === "eng" ? 0 : 1;
    setCookie("lang", lang, 1);
  }

  let rows1 = [
    {
      lp: "X",
      from: "08:00",
      to: " ",
      topic: " ",
      description: "Śniadanie",
    },
    {
      lp: "X",
      from: "14:00",
      to: " ",
      topic: " ",
      description: "Obiad",
    },
    {
      lp: 1,
      from: "15:20",
      to: "15:35",
      topic: "CREDO",
      description: "Prezenter: R. Kamiński",
    },
    {
      lp: 2,
      from: "15:40",
      to: "16:15",
      topic: "Rejestracja promieniowania kosmicznego",
      description: "Prezenter: K. Kopański",
    },
    {
      lp: 3,
      from: "16:20",
      to: "16:40",
      topic: "Przedstawienie działań ESERO Polska",
      description: "Prezenter: M. Pietrow",
    },
    {
      lp: "X",
      from: "19:00",
      to: " ",
      topic: " ",
      description: "Kolacja",
    },
    {
      lp: 4,
      from: "20:00",
      to: "20:40",
      topic: "Prelekcja astrofotografia",
      description: "Prezenter: T.Zwoliński ",
    },
    {
      lp: 5,
      from: "20:40",
      to: "20:55",
      topic: "Łowcy Cząstek wczoraj i dzisiaj",
      description: "Prezenter: S.Stuglik",
    },
  ];

  let rows2 = [
    {
      lp: "X",
      from: "08:00",
      to: " ",
      topic: " ",
      description: "Śniadanie",
    },
    {
      lp: 1,
      from: "12:30",
      to: "13:25",
      topic: "Waga dla astronautów",
      description: "Prezenter: P. Kurzydło",
    },
    {
      lp: 2,
      from: "12:40",
      to: "13:25",
      topic: "Plasma Window",
      description: "Wykład w języku angielskim. Prezenter: O. Ruimi,",
    },
    {
      lp: "X",
      from: "14:00",
      to: " ",
      topic: " ",
      description: "Obiad",
    },
    {
      lp: 3,
      from: "15:20",
      to: "15:45",
      topic: "Grywalizacja w zarysie.",
      description: "Prezenter: M. Szklorz",
    },
    {
      lp: 4,
      from: "15:45",
      to: "16:10",
      topic: "Wskocz do świata nauki z grami i zabawami edukacyjnymi!",
      description: "Prezenter: J.Sulma",
    },
    {
      lp: 5,
      from: "16:10",
      to: "16:30",
      topic:
        "Pulsary na start. Jak metoda STEAM zmienia naukę w kosmiczną przygodę.",
      description: "Prezenter: M.Satoła",
    },
    {
      lp: 6,
      from: "16:30",
      to: "17:10",
      topic:
        "Uwagi techniczne do projektu CREDO - wyniki kilkuletniego udziału PTMA w projekcie",
      description: "Prezenter: M. Więckowski",
    },
    {
      lp: "X",
      from: "19:00",
      to: " ",
      topic: " ",
      description: "Kolacja",
    },
    {
      lp: 7,
      from: "20:00",
      to: "20:20",
      topic: "Astronarium #155",
      description: "Prezenter: M. Więckowski",
    },
    {
      lp: 8,
      from: "20:20",
      to: "21:00",
      topic: "cd. dyskusji",
      description: "Prezenter: R.Kamiński",
    },
  ];

  let rows3 = [
    {
      lp: "X",
      from: "08:00",
      to: " ",
      topic: " ",
      description: "Śniadanie",
    },
    {
      lp: 1,
      from: "09:00",
      to: "09:15",
      topic: "CREDO-edu",
      description: "Prezentacja online. Prezenter: M. Deresz",
    },
    {
      lp: "X",
      from: "14:00",
      to: " ",
      topic: " ",
      description: "Obiad",
    },
  ];
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO Summer Camp 2024</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/summer_camp"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >{descriptionHeader.part1[langCode]}</a
      >

      <a
        href="#/summer_camp/plan"
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
      >
        <strong>{descriptionHeader.part2[langCode]}</strong>
      </a>
      <a
        href="#/summer_camp/zoom"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
      >
        {descriptionHeader.part3[langCode]}
      </a>
      <a
        href="#/summer_camp/contact"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
      >
        {descriptionHeader.part4[langCode]}
      </a>
    </nav>
  </div>
</header>

<section class="text-gray-600 body-font">
  <span
    class="inline-flex sm:ml-auto sm:mt-0 mt-20 justify-center sm:justify-start left-4 absolute p-4"
  >
    <button class="right h-16" on:click|preventDefault={reloads}>
      <img
        alt={lang + " flag"}
        class="m-2"
        style="width: 80px;"
        src={"./images/" + (lang === "pl" ? "eng" : "pl") + ".png"}
      />
    </button>
  </span>
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b> {descriptionPlan.part1[langCode]}</b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <table>
          <thead>
            <tr>
              <th>{descriptionPlanTable.LP[langCode]}</th>
              <th>{descriptionPlanTable.Od[langCode]}</th>
              <th>{descriptionPlanTable.Do[langCode]}</th>
              <th>{descriptionPlanTable.Temat[langCode]}</th>
              <th>{descriptionPlanTable.Opis[langCode]}</th>
            </tr>
          </thead>
          <tbody>
            {#each rows1 as row}
              <tr>
                <td>{row.lp}</td>
                <td>{row.from}</td>
                <td>{row.to}</td>
                <td>{row.topic}</td>
                <td>{row.description}</td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
      <h1
        class="mt-9 text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b> {descriptionPlan.part2[langCode]}</b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <table>
          <thead>
            <tr>
              <th>{descriptionPlanTable.LP[langCode]}</th>
              <th>{descriptionPlanTable.Od[langCode]}</th>
              <th>{descriptionPlanTable.Do[langCode]}</th>
              <th>{descriptionPlanTable.Temat[langCode]}</th>
              <th>{descriptionPlanTable.Opis[langCode]}</th>
            </tr>
          </thead>
          <tbody>
            {#each rows2 as row}
              <tr>
                <td>{row.lp}</td>
                <td>{row.from}</td>
                <td>{row.to}</td>
                <td>{row.topic}</td>
                <td>{row.description}</td>
              </tr>
            {/each}
          </tbody>
        </table>
        <br />
        <p class="my-3 text-xl">
          <b> {descriptionPlan.part0[langCode]}</b>
          <a
            class="text-blue-600"
            href="./data/Plan Almukantaratu.pdf"
            target="_blank">PDF</a
          >
        </p>
        <br />
      </div>
      <br /><br />
      <h1
        class="mt-9 text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b> {descriptionPlan.part3[langCode]}</b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
      >
        <table>
          <thead>
            <tr>
              <th>{descriptionPlanTable.LP[langCode]}</th>
              <th>{descriptionPlanTable.Od[langCode]}</th>
              <th>{descriptionPlanTable.Do[langCode]}</th>
              <th>{descriptionPlanTable.Temat[langCode]}</th>
              <th>{descriptionPlanTable.Opis[langCode]}</th>
            </tr>
          </thead>
          <tbody>
            {#each rows3 as row}
              <tr>
                <td>{row.lp}</td>
                <td>{row.from}</td>
                <td>{row.to}</td>
                <td>{row.topic}</td>
                <td>{row.description}</td>
              </tr>
            {/each}
          </tbody>
        </table>
        <br />
        <p class="my-3 text-xl">
          <b> {descriptionPlan.part0[langCode]}</b>
          <a
            class="text-blue-600"
            href="./data/Plan Almukantaratu.pdf"
            target="_blank">PDF</a
          >
        </p>
        <br />
      </div>
    </div>
  </div>
</section>

<style>
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  tr:hover {
    background-color: #a09a9a;
  }
  th {
    background-color: #4caf50;
    color: white;
  }
</style>
