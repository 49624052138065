<script>
  import { push } from "svelte-spa-router";
  import Link from "svelte-link";
  import * as animateScroll from "svelte-scrollto";
  import Fa from "svelte-fa/src/fa.svelte";
  import {
    faMobileAlt,
    faInfo,
    faEnvelope,
    faBars,
    faMailBulk,
  } from "@fortawesome/free-solid-svg-icons";

  let step = 0;
  let activeButton = "btnM1";

  async function goToStep(value) {
    step = value;

    let btn = document.getElementById(activeButton);
    btn.classList.remove("bg-gray-100");
    btn.classList.remove("border-indigo-500");
    btn.classList.remove("text-indigo-500");
    // console.log("prev: " + activeButton + ", new: btn" + value);

    btn = document.getElementById("btnM" + value);
    btn.classList.add("bg-gray-100");
    btn.classList.add("border-indigo-500");
    btn.classList.add("text-indigo-500");
    activeButton = "btnM" + value;
  }

  let ShowMenuTop = -1;
  function ShowSettings() {
    ShowMenuTop *= -1;
    step = 0;
  }
</script>

<header
  class="text-gray-600 body-font sticky top-0 h-15 opacity-95"
  id="my-header"
>
  <div
    class="w-full mx-auto flex flex-wrap p-1 left-5 flex-col md:flex-row items-center"
  >
    <div class="w-1/6 lg:1/12">
      <button
        on:click={() => ShowSettings()}
        class="left-6 top-6 absolute border-transparent"
      >
        <Fa
          class="text-gray-200"
          icon={faBars}
          size="2x"
          secondaryOpacity={1}
        />
      </button>
    </div>
    <div class="w-1/4 lg:w-1/6">
      <a href="./" class="flex title-font font-medium items-center text-white">
        <img
          src="./logotyp_biale.png"
          alt="Logo CREDO"
          style="max-width:160px"
        />
      </a>
    </div>
    <div class="w-1/4 lg:w-1/6">
      <span
        class="flex ml-3 md:ml-auto sm:mt-0 mt-4 justify-center sm:items-center"
      >
        <a
          href="//www.facebook.com/credo.science/"
          class="ml-3 text-gray-200"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            fill="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-3 7h-1.924c-.615 0-1.076.252-1.076.889v1.111h3l-.238 3h-2.762v8h-3v-8h-2v-3h2v-1.923c0-2.022 1.064-3.077 3.461-3.077h2.539v3z"
            />
          </svg>
        </a>
        <a
          href="//twitter.com/RayExtremely"
          class="ml-3 text-gray-200"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            fill="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"
            />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/company/cosmicrayextremelydistributedobservatory/"
          class="ml-3 text-gray-200"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            fill="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              stroke="none"
              d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
            />
            <circle cx="4" cy="4" r="2" stroke="none" />
          </svg>
        </a>
        <a
          href="//www.youtube.com/channel/UCJ0YBweH_slFRQYqGz_XZ_A/videos"
          class="ml-3 text-gray-200"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            fill="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
            />
          </svg>
        </a>

        <a
          href="//www.instagram.com/credo.science/"
          class="ml-3 text-gray-200"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
            <path
              d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
            />
          </svg>
        </a>
        <a
          href="#/detector/tutorial"
          class="ml-3 text-gray-200"
          target="_blank"
        >
          <svg
            fill="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M5 4h-3v-1h3v1zm8 6c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-5v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm13 4c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z"
            />
          </svg>
        </a>

        <a
          href="//github.com/credo-science"
          class="ml-3 text-gray-200"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            fill="currentColor"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0"
            class="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"
            />
          </svg>
        </a>
      </span>
    </div>

    <div class="flex text-right md:w-1/3 lg:w-1/2" id="cytat">
      <p
        class="w-full text-white border-0 py-1 px-3 focus:outline-none hover:text-orange-700 rounded text-base mt-4 md:mt-0 text-right sm:text-sm font-serif"
      >
        "I think CREDO has a unique capability of entering<br />in and exploring
        a completely uncharted realm of science.” <br />
        Mikhail V. Medvedev
      </p>
    </div>
  </div>
  {#if ShowMenuTop == 1}
    <div class="m-2">
      <button
        on:click|preventDefault={() => push("/")}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t text-white hover:text-gray-400"
      >
        HOME
      </button>
      <button
        id="btnM1"
        on:click|preventDefault={() => push("/credonews")}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t text-white hover:text-gray-400"
      >
        NEWS
      </button>
      <button
        id="btnM1"
        on:click|preventDefault={() => goToStep(1)}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t text-white hover:text-gray-400"
      >
        ABOUT US
      </button>
      <button
        id="btnM2"
        on:click|preventDefault={() => goToStep(2)}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none rounded-t border-gray-200 text-white hover:text-gray-400 tracking-wider"
        >DETECTORS
      </button>
      <button
        id="btnM3"
        on:click|preventDefault={() => goToStep(3)}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none rounded-t border-gray-200 text-white hover:text-gray-400 tracking-wider"
        >SCIENCE
      </button>
      <button
        id="btnM6"
        on:click|preventDefault={() => goToStep(6)}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none rounded-t border-gray-200 text-white hover:text-gray-400 tracking-wider"
        >GROUPS
      </button>
      <button
        id="btnM4"
        on:click|preventDefault={() => goToStep(4)}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none rounded-t border-gray-200 text-white hover:text-gray-400 tracking-wider"
        >EDUCATION
      </button>
      <button
        id="btnM5"
        on:click|preventDefault={() => goToStep(5)}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none rounded-t border-gray-200 text-white hover:text-gray-400 tracking-wider"
        >CONTACT
      </button>
      <button
        id="btnM6"
        on:click|preventDefault={() => push("/detector/apibrowser")}
        class="ml-2 sm:px-6 py-3 w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t text-white hover:text-gray-400"
      >
        API BROWSER
      </button>
    </div>
    <div class="m-2">
      {#if step == 1}
        <Link
          href="#/about/AboutCredo"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          About CREDO
        </Link> |
        <Link
          href="#/credo-institutional-members"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Institutional members
        </Link> |
        <Link
          href="#/about/People"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          People
        </Link> |
        <!-- <Link
          href="#/about/Career"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Career
        </Link> | -->
        <Link
          href="#/about/MediaAboutUs"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Media about us
        </Link> |
        <Link
          href="#/credonews"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          News
        </Link>
      {/if}

      {#if step == 2}
        <Link
          href="#/accesstodata"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Access to data
        </Link> |
        <Link
          href="#/how-to-start"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          How to start
        </Link> |
        <Link
          href="#/detector/tutorial"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Tutorial
        </Link> |
        <!-- <Link
          href="https://credo.science/credodetector/viewforum.php?f=7"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          FAQ API
        </Link> |
        <Link
          href="https://credo.science/credodetektor/"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Forum pl
        </Link> -->
      {/if}
      {#if step == 3}
        <Link
          href="#/science/publications"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Publications
        </Link> |
        <Link
          href="#/science/conferencereports"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Conference reports
        </Link> |
        <Link
          href="#/science/conferences"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Conferences
        </Link> |
        <Link
          href="#/science/posters"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Posters
        </Link> |
        <Link
          href="https://redmine.credo.science"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Internal
        </Link>
      {/if}
      {#if step == 4}
        <Link
          href="https://credo.science/particle_hunters/"
          class="text-white text-center hover:text-gray-400"
        >
          Particle Hunters
        </Link> |
        <Link
          href="#/education/materialsforschools"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Materials for schools
        </Link> |
        <Link
          href="#/education/practices"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Practices
        </Link> |
        <Link
          href="#/education/faq"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          FAQ
        </Link>|
        <Link
          href="#/about/team/credo-edu"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          CREDO-EDU task
        </Link>
      {/if}
      {#if step == 5}
        <p class="text-white">
          <!-- <img
            style="display:inline;vertical-align:middle;"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAPpJREFUSEvtleERwiAMhd/bQCdQJ7BOoI7iZI6ibtBu0A10g3jpQa9SJGCtv+RfL+F94QVSYubFmfWRBIjIAcAZwDqzkBbAieTV51sA3bDKFPdpLclNLkA0kWSWlSIyyrdO8FtAWKH13Z0+5a8lYMWLAVazi3ogIgsAdye6JPn4GsCJXwBUTrQGcAwhH1kUiDcOsAUwghQDIuL6knXpy4xChrYlexAT95a4mAmxAGqBVqlr1NSg6TXJXZFFItID/Gh4JwCgIVkVAbRsa0PMguwexADhvf8D+h5YI8GKD/8fL9NURPSu7y0BI34j6R9oelxPBHXbs36FU0BPNNjpGXQJH9IAAAAASUVORK5CYII="
          />Radzikowskiego 152, 31-342 Kraków,PL -->
          <!-- svelte-ignore a11y-missing-attribute -->
          <img
            style="display:inline;vertical-align:middle;"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAATVJREFUSEvdVdFRwlAQ3K1AOxA70ArEDrADrACsRKgAOsAOxAqghNCBVLDOZu5lYpjgI5mMM9xPZnL3dm/3Li/EwMGB8XFFBJLGAFYARj1tKwC8ktwap7JIkhN3PcHT8YLkfZNAkX0kue9CJOkBwK4EJsvm6woSwXdI/LiERNIkLL79iyDhLki+5ZBIWgCY1WvPKTDoexR7UC8kreokJLnbDQAviKM620rgRHhp8BsABn9uziVqPgGY5GgS10gqrT5L4ILoznN4iu7mJJeRm9dUfgGYJJXZBMmPhr/reD+N55Kkyaq4mCA69oYY3JY5bMmU5MmmdSIIEu94paDtW+lMkLOu0UjekHMBm3X/qsD7nobYVUA6dyBZ3sr1u8hfo4fY90Y9xIb9vq77ttx2/op+mUNZ9AM2b6YZomtp4AAAAABJRU5ErkJggg=="
          />
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            class="hover:text-gray-400"
            style="display:inline;vertical-align:top;padding-left:5px;"
            >contact@credo.science</a
          >
          <!-- svelte-ignore a11y-missing-attribute -->
          <img
            style="display:inline;vertical-align:middle;"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAANZJREFUSEvtlW0NwjAYhO8UgIRJwAHMCRJAAaCAoIhNAeBgEkDBkTZtUwgfy9oSSOj/3tO796NE4cPC+vgsQFIDYJroqiFZe40bB5KUKG6vkwy6qYATgLN71ATAKBegBTAn2XnHcbypDi4AKpL+5ZaRE9CSnDlRE8s2imicI6IdyYUDrAGs7hsjNaINSSNsYskKWAI4Auh8cSVVph7OwT70/sA2rUmaQXx44hkaGtFTgCRT7EOqA3+/WA3+ANvXfbbpd9egz4fzzkHYVXYv5fhgXmn8PuAKbSibGVmZY4AAAAAASUVORK5CYII="
          />
          <a
            href="//www.facebook.com/credo.science/"
            target="_blank"
            rel="noreferrer"
            class="hover:text-gray-400"
            style="display:inline;vertical-align:top;padding-left:5px;"
            >@credo.science</a
          >
          <!-- svelte-ignore a11y-missing-attribute -->
          <img
            style="display:inline;vertical-align:middle;"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAVpJREFUSEvdVcFRwzAQ3K0AOoAOIBUAFRAqACoAKoBUQEpIB5AKoAOgA1IBUMEyy0geS5YV7Bl/cjP+SHe7d6u7MzGxcWJ87BiBpH2S3zXZ7APgBsAcwDGATwArkgvHSZqTfI4YiUSS7GyH9z4SSa8ATgr3jjHhHcllh0CSL98AuIILkgZKrOXTx2+SDwDLmGRegVqRzmLRlszlA3iqSPgTFGiSywms3XkG4DN/lm8G4LFCsCbpJBprCMLjHQawg5Hz4YofagRfI4FjWPLAPix10djsjTfLOzAncCf5gfZGVLIhaYkT66yK0CmXYZCG8HTk6UgUJvEUwMsQZAAbD1lpCxSXnSR3wv0AkrPSYBYriKChbT1slqtm1yRXfQ59FVimuND6Yj21tzXwpAJJBnXGR/+QZh3APd1VK3XRFQCTuWUjmReYl+Df2iC5FTiy7tgfbZueY+4nl+gX3293GUCOwckAAAAASUVORK5CYII="
          />
          <!-- svelte-ignore a11y-missing-attribute -->
          <a style="display:inline;vertical-align:top;padding-left:5px;"
            >@RayExtremely</a
          >
        </p>{/if}

      {#if step == 6}
        <Link
          href="#/about/team/credo-cs"
          class="text-white text-center hover:text-gray-400"
        >
          Cosmo-Seismic
        </Link> |
        <Link
          href="#/about/team/credo-ml"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Machine Learning
        </Link>
        |
        <Link
          href="https://www.credo-maze.org"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Maze
        </Link>|
        <Link
          href="#/about/team/credo-edu"
          class="text-white text-center hover:text-gray-400"
          on:click={() => animateScroll.scrollToTop()}
        >
          Edu
        </Link><!-- 
        | "cheapest" hardware | Visibility/Citizen Science -->
      {/if}
    </div>
  {/if}
</header>

<style>
  #my-header {
    background-image: url("./../images/headerimg3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 100;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
  }

  /* @media (min-width: 700px) {
    #cytat {
      width: 58.333333%;
    } 
  }*/
  @media (max-width: 700px) {
    #cytat {
      width: 100%;
    }
  }
</style>
