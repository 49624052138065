<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b
        >The CREDO group at Institute of Nuclear Physics PAN (IFJ PAN) is
        hiring!</b
      >
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
        class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 lg:w-2/5"
        alt="img"
        src="./images/posts/eq_IFJ.jpg"
      />

      The
      <a
        class="text-blue-900 hover:text-blue-500"
        href="https://www.ifj.edu.pl/en/index.php"
        target="_blank"
        rel="noreferrer">IFJ PAN</a
      >
      group (Kraków, Poland) of the Cosmic Ray Extremely Distributed Observatory
      (CREDO), is looking for an outstanding postdoc candidate interested in the
      field of experimental multi-messenger astrophysics. Below please find the link
      to the official advertisment, and the narrative summary. <br /><br
      />Please share the information with potential candidates.
      <br /><br />
      The offcial advertisment: <br />
      <a
        class="text-blue-900 hover:text-blue-500"
        href="https://www.ifj.edu.pl/en/career/job-offers/offers/recruitment/NZ15-1-Adiunkt_postdoc_AP-I-25-eng.pdf"
        target="_blank"
        rel="noreferrer"
        >https://www.ifj.edu.pl/en/career/job-offers/offers/recruitment/NZ15-1-Adiunkt_postdoc_AP-I-25-eng.pdf</a
      >
    </p>
  </div>
  <br />
  <div class="text-center lg:w-4/5 w-full mx-auto px-2">
    <p class="mb-8 leading-relaxed font-serif text-2xl text-justify mx-auto">
      Narrative summary: <br />The Cosmic Ray Extremely Distributed Observatory
      (CREDO) (<a
        class="text-blue-900 hover:text-blue-500"
        href="https://credo.science/">https://credo.science/</a
      >) is an international collaboration aiming at searching for large scale
      correlations of cosmic rays. The CREDO strategy is based on operating a
      global network of cosmic-ray detectors of various sizes and diverse
      technologies in order to reach sensitivity to cosmic ray ensembles (CRE):
      groups of correlated cosmic particles (including photons) that might have
      energies spanning the whole energy spectrum of cosmic rays. Until
      recently, cosmic ray research has been focused on detecting single air
      showers, while the search for ensembles of cosmic rays, which may spread
      over a significant fraction of the Earth surface, is a scientific terra
      incognita. The key idea of CREDO is to combine existing cosmic ray
      detectors (large professional arrays, educational instruments, local
      networks, individual detectors such as smartphones, etc.) into a worldwide
      network, thus enabling a global analysis. One of the recent successes of
      CREDO is an observation of a statistically solid (on the level of six
      sigma) correlation between the global seismic activity and changes in the
      intensity of cosmic radiation recorded at the surface of our planet,
      dubbed the cosmo-seismic effect [<a
        class="text-blue-900 hover:text-blue-500"
        href="https://doi.org/10.1016/j.jastp.2023.106068"
        target="_blank"
        rel="noreferrer"
        >P. Homola, et al. (CREDO Collaboration), J. Atmos. Sol.-Terr. Phys. 247
        (2023)</a
      >]. The relationship is physically intriguing: the available astrophysical
      and geophysical paradigms do not point to a plausible conventional
      scenario which would explain the phenomenon without an external steering
      factor capable of affecting both cosmic radiation, and seismicity. If the
      existence of such a factor is confirmed, we will witness a game-changing
      impact on science, even beyond astrophysics. The newly open job position
      creates a unique opportunity of being at the very center of a fascinating
      science adventure: the quest for the understanding of the cosmo-seismic
      puzzle. Who knows where this adventure may lead, let’s find out together!
    </p>
  </div>
</section>
