<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    A lecture on the subject of cosmic particles traces on the matrices
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/IMG_20190314_093710-570x350.jpg"
      alt="images"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:justify"
      >Today in the morning a working meeting was held during which a lecture on
      the subject of cosmic particles traces on the matrices was conducted by a
      well-known and respected scientist from the University of Lodz -prof.
      Tadeusz Wibig. Professor is also the author of the first major report
      based on the detection of particles by matrices (photographic). The
      lecture provoked a keen interest of the meeting participants and a heated
      discussion.<br />
      The report can be read in English

      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="./data/report_lodz_01_2019.pdf"
          target="_blank">here</a
        >
      </span>.
    </span>
  </div>
</div>
