<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    A next new peer-reviewed article "for CREDO"
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/posts/universe-2022-08.png" alt="images" width="400" />
  </div>
  How often small detectors can see air showers? See a new peer reviewed article
  "for CREDO", and please note that the key authors, Jerzy Pryga and Weronika Stanek,
  are young - at the time of submission they both were still undergrads!<br />
  They started the work reported in the article during their summer practice at Instytut
  Fizyki Jądrowej PAN, and then they continued until getting published in a respectable
  peer-reviewed journal, well done! Big congrats also for the supervisor, Prof. Krzysztof
  Woźniak.
  <br />
  Read more:
  <a class="text-blue-600" href="https://doi.org/10.3390/universe8080425"
    >https://doi.org/10.3390/universe8080425</a
  >.
</div>
