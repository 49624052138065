<script>
  import Fa from "svelte-fa/src/fa.svelte";
  import {
    faArrowCircleDown,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faDotCircle,
    faArrowAltCircleDown,
  } from "@fortawesome/free-solid-svg-icons";
  let home = "https://credo.science/";
</script>

<div class="carousel relative shadow-2xl bg-gray-900 -mt-15">
  <div class="carousel-inner relative overflow-hidden w-full" id="slider">
    <!-- slide 5 -->
    <input
      class="carousel-open"
      type="radio"
      id="carousel-1"
      name="carousel"
      aria-hidden="true"
      hidden="true"
      checked="checked"
    />
    <div
      class="carousel-item absolute opacity-0 my-flex-center h-screen -mt-24"
    >
      <img
        class="object-cover w-full absolute h-screen"
        src="./images/posts/geo_grant.png"
        alt="slide_image_1"
      />
      <div
        class="lg:mx-48 md:mx-24 sm:mx-24 block w-full text-white text-center relative content-center"
      >
        <h2
          class="lg:text-5xl md:text-3xl font-semibold lg:mb-6 md:mb-4 p-4 sm:text-xl mx-auto sm:mb-2 my-opacity font-serif uppercase"
        >
          New grant for Georgian members of the CREDO collaboration
        </h2>
        <p
          class="lg:text-3xl md:text-xl sm:text-sm mx-auto my-opacity-brown p-4 font-serif"
        >
          Project topic: “Preliminary study of cosmic ray ensemble phenomenology
          through observations of remote extensive air showers using the
          operational GELATICA network.”
        </p>
        <div class="flex sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-4">
          <div class="p-2 w-full flex justify-center">
            <a
              href="#/posts/2024/07-17"
              target="_blank"
              class="flex text-white bg-red-500 border-0 py-2 px-8
           focus:outline-none hover:bg-red-600 rounded xl:text-3xl lg:text-2xl md:text-xl sm:text-lg font-sans"
            >
              READ MORE
            </a>
          </div>
        </div>
      </div>
    </div>
    <label
      for="carousel-5"
      class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 left-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleLeft}
        size="2x"
        secondaryOpacity={1}
      /></label
    >
    <label
      for="carousel-2"
      class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 right-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleRight}
        size="2x"
        secondaryOpacity={1}
      /></label
    >
    <!-- slide 2 -->
    <input
      class="carousel-open"
      type="radio"
      id="carousel-2"
      name="carousel"
      aria-hidden="true"
      hidden="true"
    />
    <div
      class="carousel-item absolute opacity-0 my-flex-center h-screen -mt-24"
    >
      <img
        class="object-cover w-full absolute h-screen"
        src="./images/posts/eq_IFJ.jpg"
        alt="slide_image_2"
      />
      <div
        class="lg:mx-48 md:mx-24 sm:mx-24 block w-full text-white text-center relative content-center"
      >
        <h2
          class="lg:text-5xl md:text-3xl font-semibold lg:mb-6 md:mb-4 p-4 sm:text-xl mx-auto sm:mb-2 my-opacity font-serif uppercase"
        >
          Intriguing correlation between earthquakes and cosmic radiation
        </h2>
        <p
          class="lg:text-3xl md:text-xl sm:text-sm mx-auto my-opacity-brown p-4 font-serif"
        >
          News Release 15-Jun-2023
        </p>
        <div class="flex sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-4">
          <div class="p-2 w-full flex justify-center">
            <a
              href="#/posts/2023/06-15"
              target="_blank"
              class="flex text-white bg-red-500 border-0 py-2 px-8
              focus:outline-none hover:bg-red-600 rounded xl:text-3xl lg:text-2xl md:text-xl sm:text-lg font-sans"
            >
              READ MORE
            </a>
          </div>
        </div>
      </div>
    </div>
    <label
      for="carousel-1"
      class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 left-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleLeft}
        size="2x"
        secondaryOpacity={1}
      /></label
    >
    <label
      for="carousel-3"
      class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 right-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleRight}
        size="2x"
        secondaryOpacity={1}
      /></label
    >

    <!--Slide 3-->
    <input
      class="carousel-open"
      type="radio"
      id="carousel-3"
      name="carousel"
      aria-hidden="true"
      hidden="true"
    />
    <div
      class="carousel-item absolute opacity-0 my-flex-center h-screen -mt-24"
    >
      <img
        class="object-cover w-full absolute h-screen"
        src="./images/newka.jpg"
        alt=""
      />
      <div
        class="lg:mx-48 md:mx-24 sm:mx-24 block w-full text-white text-center relative"
      >
        <h2
          class="lg:text-5xl md:text-3xl font-semibold lg:mb-6 md:mb-4 p-4 sm:text-xl mx-auto sm:mb-2 my-opacity font-serif uppercase"
        >
          CREDO Memorandum of Understanding<br /> has been announced!
        </h2>
        <p
          class="lg:text-3xl md:text-xl sm:text-sm mx-auto my-opacity-brown p-4 font-serif"
        >
          The CREDO press conference at the Institute of Nuclear Physics of the
          Polish Academy of Sciences
        </p>
        <div class="flex sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-4">
          <div class="p-2 w-full flex justify-center">
            <a
              href="#/credo-institutional-members"
              target="_blank"
              class="flex text-white bg-red-500 border-0 py-2 px-8
              focus:outline-none hover:bg-red-600 rounded xl:text-3xl lg:text-2xl md:text-xl sm:text-lg font-sans"
            >
              READ MORE
            </a>
          </div>
        </div>
      </div>
    </div>
    <label
      for="carousel-2"
      class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 left-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleLeft}
        size="2x"
        secondaryOpacity={1}
      /></label
    >
    <label
      for="carousel-4"
      class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 right-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleRight}
        size="2x"
        secondaryOpacity={1}
      /></label
    >

    <!--Slide 4-->
    <input
      class="carousel-open"
      type="radio"
      id="carousel-4"
      name="carousel"
      aria-hidden="true"
      hidden="true"
    />
    <div
      class="carousel-item absolute opacity-0 my-flex-center h-screen -mt-24"
    >
      <img
        class="object-cover w-full absolute h-screen"
        src="./images/launch2.png"
        alt=""
      />
      <div
        class="lg:mx-48 md:mx-24 sm:mx-24 block w-full text-white text-center relative"
      >
        <h2
          class="lg:text-5xl md:text-3xl font-semibold lg:mb-6 md:mb-4 p-4 sm:text-xl mx-auto sm:mb-2 my-opacity font-serif uppercase"
        >
          You have an intergalactic particle detector<br /> in your pocket!
        </h2>
        <p
          class="lg:text-3xl md:text-xl sm:text-sm mx-auto my-opacity-brown p-4 font-serif"
        >
          Learn more about installing and effectively operating the CREDO
          detector app for Android and IOS smart phones. Join the hunt for
          particles from space and help answer the question – What is dark
          matter?
        </p>
        <div class="flex sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-4">
          <div class="p-2 w-full flex justify-center">
            <a
              href="#/detector/tutorial"
              target="_blank"
              class="flex text-white bg-red-500 border-0 py-2 px-8
              focus:outline-none hover:bg-red-600 rounded xl:text-3xl lg:text-2xl md:text-xl sm:text-lg font-sans"
            >
              READ MORE
            </a>
          </div>
        </div>
      </div>
    </div>
    <label
      for="carousel-3"
      class="prev control-4 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 left-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleLeft}
        size="2x"
        secondaryOpacity={1}
      /></label
    >
    <label
      for="carousel-5"
      class="next control-4 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 right-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleRight}
        size="2x"
        secondaryOpacity={1}
      /></label
    >

    <!--Slide 5-->
    <input
      class="carousel-open"
      type="radio"
      id="carousel-5"
      name="carousel"
      aria-hidden="true"
      hidden="true"
    />
    <div
      class="carousel-item absolute opacity-0 my-flex-center h-screen -mt-24"
    >
      <img
        class="object-cover w-full absolute h-screen"
        src="./images/1.jpeg"
        alt=""
      />
      <div
        class="lg:mx-48 md:mx-24 sm:mx-24 block w-full text-white text-center relative"
      >
        <h2
          class="lg:text-5xl md:text-3xl font-semibold lg:mb-6 md:mb-4 p-4 sm:text-xl mx-auto sm:mb-2 my-opacity font-serif uppercase"
        >
          Cosmic-Ray Extremely Distributed Observatory
        </h2>
        <p
          class="lg:text-3xl md:text-xl sm:text-sm mx-auto my-opacity-brown p-4 font-serif"
        >
          The Cosmic Ray Extremely Distributed Observatory (CREDO) collaboration
          is an ongoing research project involving scientists and the public
          from around the world. Our objective is to answer one of the most
          fundamental questions in the Universe – What is dark matter?
        </p>
        <div class="flex sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 mt-4">
          <div class="p-2 w-full flex justify-center">
            <a
              href="#/about/AboutCredo"
              target="_blank"
              class="flex text-white bg-red-500 border-0 py-2 px-8
              focus:outline-none hover:bg-red-600 rounded xl:text-3xl lg:text-2xl md:text-xl sm:text-lg font-sans"
            >
              READ MORE
            </a>
          </div>
        </div>
      </div>
    </div>
    <label
      for="carousel-4"
      class="prev control-5 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 left-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleLeft}
        size="2x"
        secondaryOpacity={1}
      /></label
    >
    <label
      for="carousel-1"
      class="next control-5 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold leading-tight text-center z-10 inset-y-0 right-0 my-auto"
      ><Fa
        class="text-white hover:text-gray-400"
        icon={faArrowAltCircleRight}
        size="2x"
        secondaryOpacity={1}
      /></label
    >

    <!-- Add additional indicators for each slide-->

    <div
      class="pt-20 w-10 h-10 ml-2 md:ml-10 absolute text-3xl font-bold leading-tight text-center z-10 inset-y-0 left-0 my-auto"
    >
      <button
        class="pt-5"
        on:click|preventDefault={() => {
          location.replace(home + "#post");
        }}
        ><Fa
          class="text-white hover:text-gray-400"
          icon={faArrowAltCircleDown}
          size="2x"
          secondaryOpacity={1}
        /></button
      >
    </div>
    <ol class="carousel-indicators">
      <li class="inline-block mr-3">
        <label
          for="carousel-1"
          class="carousel-bullet cursor-pointer block text-5xl text-white hover:text-red-400"
          >•</label
        >
      </li>
      <li class="inline-block mr-3">
        <label
          for="carousel-2"
          class="carousel-bullet cursor-pointer block text-5xl text-white hover:text-red-400"
          >•</label
        >
      </li>
      <li class="inline-block mr-3">
        <label
          for="carousel-3"
          class="carousel-bullet cursor-pointer block text-5xl text-white hover:text-red-400"
          >•</label
        >
      </li>
      <li class="inline-block mr-3">
        <label
          for="carousel-4"
          class="carousel-bullet cursor-pointer block text-5xl text-white hover:text-red-400"
          >•</label
        >
      </li>
      <li class="inline-block mr-3">
        <label
          for="carousel-5"
          class="carousel-bullet cursor-pointer block text-5xl text-white hover:text-red-400"
          >•</label
        >
      </li>
    </ol>
  </div>
</div>

<style>
  .carousel-open:checked + .carousel-item {
    position: static;
    opacity: 100;
  }
  .carousel-item {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity ease-in 0.3s;
  }
  #carousel-1:checked ~ .control-1,
  #carousel-2:checked ~ .control-2,
  #carousel-3:checked ~ .control-3,
  #carousel-4:checked ~ .control-4,
  #carousel-5:checked ~ .control-5 {
    display: block;
  }
  .carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
    background: rgba(105, 102, 102, 0.5);
  }
  #carousel-1:checked
    ~ .control-1
    ~ .carousel-indicators
    li:nth-child(1)
    .carousel-bullet,
  #carousel-2:checked
    ~ .control-2
    ~ .carousel-indicators
    li:nth-child(2)
    .carousel-bullet,
  #carousel-3:checked
    ~ .control-3
    ~ .carousel-indicators
    li:nth-child(3)
    .carousel-bullet,
  #carousel-4:checked
    ~ .control-4
    ~ .carousel-indicators
    li:nth-child(4)
    .carousel-bullet,
  #carousel-5:checked
    ~ .control-5
    ~ .carousel-indicators
    li:nth-child(5)
    .carousel-bullet {
    color: rgb(
      255,
      23,
      23
    ); /*Set to match the Tailwind colour you want the active one to be */
  }

  .my-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .my-opacity {
    background: rgba(0, 0, 0, 0.5);
  }
  .my-opacity-brown {
    background: rgba(92, 75, 81, 0.8);
  }
  .carousel {
    caret-color: transparent;
  }
</style>
