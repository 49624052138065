<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Credo Workshop 19 – 21.09.2019
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/workshop.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >For 3 days, students and teachers of Polish schools took part in the
      conference and workshops prepared by scientists and lecturers related to
      our project.
    </span>
  </div>
</div>
