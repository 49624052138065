<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO & The International Particle Physics Outreach Group
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/ppog.png"
      alt="credo.png"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >25 May 2017, CERN, Geneve.
      <br />
      The CREDO project has been presented at the
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://indico.cern.ch/event/604619/"
          >International Conference on the Structure and the Interactions of the
          Photon</a
        ></span
      >
      (Photon 2017) which takes place in these days (22-26 May) at CERN, Geneva.
      The talk titled “Search for Extensive Photon Cascades with the Cosmic-Ray Extremely
      Distributed Observatory” was given by the CREDO spokesperson, Piotr Homola,
      and can be accessed
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://indico.cern.ch/event/604619/"
          >here</a
        ></span
      >.
      <br />
      The “Photon 2017” conference is dedicated mostly to accelerator studies that
      can shed light on the structure of the photon. However, there is also an astrophysical
      perspective of the photon-oriented research. The flux of cosmic rays that comes
      to us from the depth of the Universe might contain photons of energies exceeding
      by orders of magnitude those available in accelerators. If we observe these
      extremely energetic photons or any manifestation of their interactions, it
      would give a unique chance for testing models based on the accelerator data.
      The global approach to the cosmic-ray data pursued by CREDO optimizes the chance
      for such observations.
    </span>
  </div>
</div>
