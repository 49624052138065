<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto text-black">
    <div class="flex flex-col text-center w-full mb-2">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b> Our General Posters</b>
      </h1>
    </div>
    <div class="py-4 text-xl text-justify font-serif ">
      See also:
      <a
        href="#/science/posters"
        class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
        >Conference
      </a>
      |
      <a
        href="#/science/posters/event"
        class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
        >Event
      </a>
      posters.
    </div>
    <div class="flex flex-wrap -m-4 text-xl text-justify font-serif ">
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/CREDO-Detector-Premiere_mini.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              CREDO Detector
            </h2>
            <p class="leading-relaxed mb-3">Did you know that you have..</p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/CREDO-Detector-Premiere.png"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/credo_journey_mini.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              CREDO Journey
            </h2>
            <p class="leading-relaxed mb-3">Photo from the press kit</p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/credo_journey.png"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download png
              </a>
              |
              <a
                href="#/about/presskits"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Read press kit
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/lowcy_czastek _mini.jpg"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              Łowcy Cząstek
            </h2>
            <p class="leading-relaxed mb-3">
              Oficjalny plakat konkursu "Łowcy Cząstek"
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/lowcy_czastek.jpg"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download jpg
              </a>
              |
              <a
                href="https://credo.science/particle_hunters/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Visit competition website
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/particle hunters_mini.jpg"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              Particle Hunters
            </h2>
            <p class="leading-relaxed mb-3">
              Official poster of the "Particle Hunters" competition
            </p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/particle hunters.jpg"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download jpg
              </a>
              |
              <a
                href="https://credo.science/particle_hunters/"
                target="_blank"
                rel="noopener noreferrer"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Visit competition website
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 md:w-1/4">
        <div
          class="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden"
        >
          <img
            class="lg:h-80 md:h-48 w-full object-cover object-center"
            src="./data/posters/credo-ulotka.png"
            alt="poster png"
          />
          <div class="p-6">
            <h2
              class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1"
            >
              Flyer (for events)
            </h2>
            <p class="leading-relaxed mb-3">about CREDO (in Polish)</p>
            <div class="flex items-center flex-wrap">
              <a
                href="./data/posters/CREDO - ulotka.pdf"
                target="_blank"
                class="text-indigo-800 hover:text-blue-600 inline-flex items-center md:mb-2 lg:mb-0"
                >Download pdf
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
