<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
  Intriguing correlation between earthquakes and cosmic radiation
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    
      <img
        src="./images/posts/eq_IFJ.jpg"
        alt="images1"
        class=" w-full md:w-1/3"
      />
    The cosmo-seismic article has a press release: <a class="text-blue-700" href="https://www.eurekalert.org/news-releases/992637" target="_blank" rel="noreferrer">https://www.eurekalert.org/news-releases/992637</a> 
    Enjoy reading and sharing! There has already been quite an impact in the media and first scientific effects like invitations to cooperation.
    <br/>Big thanks the Instytut Fizyki Jądrowej PAN science popularization team for preparing and publishing the release!
    <br/>The Polish version you can read on the portal: 
    <a class="text-blue-700" href="https://naukawpolsce.pl/aktualnosci/news,97128,intrygujaca-korelacja-miedzy-trzesieniami-ziemi-promieniowaniem-kosmicznym" target="_blank" rel="noreferrer">
      naukawpolsce.pl</a>
    
  </div>
</div>
