<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    V. edition of the "Particle Hunters" competition
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/posts/ParticleHunters2022.png"
      alt="images"
      width="400"
    />
    The 5th edition of the "Particle Hunters" contest for schools will start on November
    7 this year and will last until June 1, 2023. <br />
    The aim of each edition of the "Particle Hunters" competition is to arouse interest
    in the physics and astrophysics of cosmic radiation among primary and secondary
    school pupils (but not only!) by using the CREDO Detector application. Each participating
    student downloads the app, joins a team with other students (and families) under
    the guidance of a team coordinator, usually a teacher from their school. The
    goal of each participating team is to capture as many cosmic ray particles as
    possible using the CREDO Detector application. <br />
    Read more:
    <a
      class="text-blue-600"
      href="https://credo.science/particle_hunters/#/about"
      >https://credo.science/particle_hunters/#/about
    </a> <br />
    Registration of your team (school) can be made at:
    <a
      class="text-blue-600"
      href="https://credo.science/particle_hunters/#/register"
      >https://credo.science/particle_hunters/#/register</a
    > <br />
    The current list of all registered teams can be viewed at:
    <a
      class="text-blue-600"
      href="https://credo.science/particle_hunters/#/teams"
      >https://credo.science/particle_hunters/#/teams</a
    >
    All schools are welcome to participate.
  </div>
</div>
