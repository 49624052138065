<section class="text-gray-600 body-font">
  <div class="container px-5 pt-14 mx-auto">
    <a
      class="text-indigo-800 hover:text-blue-600"
      rel="noopener noreferrer"
      href="#/about/materialyprasowe/"
      target="_blank"><strong>Press Kits (Po polsku)</strong></a
    >
    <a
      class="text-indigo-800 hover:text-blue-600"
      rel="noopener noreferrer"
      href="#/about/presskits/"
      target="_blank"><strong>Press Kits (English)</strong></a
    >
  </div>
  <div class="container px-5 mx-auto">
    <p class="w-full text-center text-2xl font-serif mb-8">
      <strong class="text-4xl text-red-800"
        >Una correlación intrigante entre terremotos y radiación cósmica</strong
      ><br />

      INSTITUTO DE FÍSICA NUCLEAR "HENRYK NIEWODNICZANSKI" DE LA ACADEMIA DE
      CIENCIAS DE POLACA
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8 pl-16">
      Existe una clara correlación estadística entre la actividad sísmica global
      y los cambios en la intensidad de la radiación cósmica registrada en la
      superficie de nuestro planeta, lo que podría ayudar a predecir terremotos.
      Sorprendentemente, exhibe una periodicidad que escapa a una interpretación
      física irrefutable.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      Los más fuertes terremotos suelen resultar tanto en pérdidas humanas así
      como en enormes pérdidas materiales. La escala de la tragedia podría
      reducirse significativamente si tuviéramos la capacidad de predecir el
      momento y el lugar de tales eventos catastróficos. El proyecto CREDO,
      iniciado en 2016 por el Instituto de Física Nuclear de la Academia de
      Ciencias de Polonia en Cracovia, intenta verificar la hipótesis
      previamente conocida de que los terremotos podrían predecirse
      potencialmente observando cambios en la radiación cósmica. Los análisis
      estadísticos han demostrado que existe una correlación entre los dos
      fenómenos, que sorprendentemente manifiesta características que nadie
      esperaba.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      El proyecto internacional CREDO (Cosmic Ray Extremely Distributed
      Observatory) es un observatorio virtual de rayos cósmicos, accesible para
      todos, que recopila y procesa datos no solo de sofisticados detectores
      científicos, sino también de una gran cantidad de detectores más pequeños,
      entre los que se encuentran los sensores CMOS que se encuentran en los
      teléfonos inteligentes y que están a la vanguardia (para convertir un
      teléfono inteligente en un detector de rayos cósmicos, basta simplemente
      con instalar la aplicación gratuita CREDO Detector). Una de las
      principales tareas de CREDO es precisamente monitorear los cambios
      globales en el flujo de radiación cósmica secundaria que llega a la
      superficie de nuestro planeta. Esta radiación se produce en la
      estratosfera terrestre con mayor intensidad dentro del llamado máximo de
      Regener-Pfotzer, donde las partículas de radiación cósmica primaria chocan
      con las moléculas de gas de nuestra atmósfera e inician cascadas de
      partículas secundarias.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      <i
        >"A primera vista, la idea de que existe un vínculo entre los terremotos
        y la radiación cósmica, en su forma primaria que nos llega
        principalmente desde el Sol y el espacio profundo, puede parecer
        extraña. Sin embargo, sus fundamentos físicos son completamente
        racionales”</i
      >, enfatiza el Dr. Piotr Homola (IFJ PAN y AstroCeNT CAMK PAN),
      coordinador de CREDO y primer autor del artículo que describe el
      descubrimiento en el Journal of Atmospheric and Solar-Terrestrial Physics.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      La idea principal aquí es la observación de que las corrientes de Foucault
      en el núcleo líquido de nuestro planeta son responsables de generar el
      campo magnético de la Tierra. Este campo desvía las trayectorias de
      partículas cargadas de radiación cósmica primaria. De esta manera, si los
      grandes terremotos estuvieran asociados con perturbaciones en los flujos
      de materia que impulsan el dínamo de la Tierra, estas perturbaciones
      alterarían el campo magnético, que a su vez afectaría las trayectorias de
      las partículas de radiación cósmica primaria de una manera que depende de
      la dinámica de las perturbaciones en el interior de nuestro planeta. Como
      resultado, los detectores terrestres deberían ver algunos cambios en la
      cantidad de partículas secundarias de rayos cósmicos detectadas.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      Los físicos de CREDO analizaron los datos de intensidad de los rayos
      cósmicos de dos estaciones del proyecto base de datos del llamado "monitor
      de neutrones en tiempo real" o NMDB (recopilados durante el último medio
      siglo) y del Observatorio Pierre Auger (recopilados desde 2005). La
      elección de los observatorios estuvo determinada por el hecho de que están
      ubicados a ambos lados del ecuador y utilizan diferentes técnicas de
      detección. Los análisis incluyeron cambios en la actividad solar, como se
      describe en la base de datos mantenida por el Centro de Análisis de Datos
      Influenciados por el Sol (Solar Influences Data Analysis Centre). La
      información clave sobre la actividad sísmica de la Tierra se obtuvo a su
      vez del programa del Servicio Geológico de los Estados Unidos.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      Los análisis se llevaron a cabo utilizando varias técnicas estadísticas.
      En cada caso, para el período estudiado, surgió una clara correlación
      entre los cambios en la intensidad de la radiación cósmica secundaria y la
      suma de la magnitud de todos los terremotos con magnitudes mayores o
      iguales a 4 en la escala sismológica de Richter. Es importante señalar que
      esta correlación solo se hace evidente cuando el rayo cósmico los datos se
      desplazan 15 días hacia adelante en relación con los datos sísmicos. Esta
      es una buena noticia, ya que sugiere la posibilidad de detectar los
      próximos terremotos con mucha anticipación.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      <i
        >“En el mundo científico, se acepta que se puede afirmar que se ha
        realizado un descubrimiento cuando el nivel de confianza estadística de
        los datos corroborantes alcanza un valor de cinco sigmas, es decir cinco
        desviaciones estándar. Para la correlación observada, obtuvimos más de
        seis sigmas, lo que significa una probabilidad de menos de uno en mil
        millones de que la correlación se deba al azar. Por lo tanto, tenemos
        una muy buena base estadística para afirmar que hemos descubierto un
        fenómeno verdaderamente existente. La única pregunta es, ¿es realmente
        el que esperábamos?“</i
      > se pregunta el Dr. Homola.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      De hecho, resulta que la naturaleza global del fenómeno observado y el
      avance de 15 días en la actividad sísmica evidente en la radiación cósmica
      no son los únicos enigmas intrigantes asociados con el descubrimiento. Una
      gran sorpresa es la periodicidad a gran escala de la correlación, un
      fenómeno que nadie esperaba. Los análisis muestran que el máximo de
      correlación ocurre cada 10 u 11 años, un período similar al ciclo de
      actividad solar. Sin embargo, ¡no coincide en absoluto con la máxima
      actividad de nuestra estrella!
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      Además, existen otras periodicidades comunes de naturaleza desconocida
      tanto en datos sísmicos como de rayos cósmicos. Los ejemplos incluyen
      cambios periódicos en la actividad sísmica y la intensidad de la radiación
      cósmica secundaria durante un ciclo correspondiente al día estelar de la
      Tierra (igual a 24 horas menos ~236 segundos). ¿Será entonces que las
      correlaciones cósmico-sísmicas están provocadas por algún componente que
      nos llega desde fuera del Sistema Solar, capaz de producir simultáneamente
      radiación y efectos sísmicos? ¿Cuál fenómeno físico convencional podría
      incluso explicar cualitativamente las aparentes correlaciones?
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      La falta de explicaciones clásicas para las periodicidades observadas
      provoca la consideración del posible papel de otros fenómenos menos
      convencionales. Uno de ellos podría ser el paso de la Tierra a través de
      una corriente de materia oscura modulada por el Sol y otros cuerpos
      masivos de nuestro sistema planetario. La Tierra, con su gran campo
      magnético, es efectivamente un detector de partículas extremadamente
      sensible, muchas veces más grande que los detectores construidos por
      nosotros los humanos. Por lo tanto, es razonable permitir la posibilidad
      de que pueda responder a fenómenos que son invisibles para los
      dispositivos de medición existentes.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      <i
        >“Independientemente de la fuente de las periodicidades observadas, lo
        más importante en esta etapa de la investigación es que hemos demostrado
        un vínculo entre la radiación cósmica registrada en la superficie de
        nuestro planeta y su sismicidad, y si hay algo de lo que podamos estar
        seguros, es que nuestra observación apunta a oportunidades de
        investigación completamente nuevas y emocionantes”</i
      >, concluye el Dr. Homola.
    </p>
    <p class="w-full text-justify mb-8">
      El Instituto Henryk Niewodniczański de Física Nuclear (IFJ PAN) es
      actualmente uno de los institutos de investigación más grandes de la
      Academia de Ciencias de Polonia. Una amplia gama de investigaciones
      realizadas en IFJ PAN cubre estudios básicos y aplicados, desde física de
      partículas y astrofísica, pasando por física de hadrones, física nuclear
      de alta, media y baja energía, física de la materia condensada (incluida
      la ingeniería de materiales), hasta diversos aplicaciones de la física
      nuclear en la investigación interdisciplinaria, que abarca la física
      médica, la dosimetría, la radiación y la biología ambiental, la protección
      ambiental y otras disciplinas afines. La producción anual promedio de
      publicaciones de IFJ PAN incluye más de 600 artículos científicos en
      revistas internacionales de alto impacto. Cada año, el Instituto alberga
      alrededor de 20 conferencias científicas internacionales y nacionales. Una
      de las instalaciones más importantes del Instituto es el Cyclotron Center
      Bronowice (CCB), que es una infraestructura única en Europa Central,
      sirviendo como centro clínico y de investigación en el campo de la física
      médica y nuclear. Además, IFJ PAN administra cuatro laboratorios de
      investigación y medición acreditados. IFJ PAN es miembro del Marian
      Smoluchowski Kraków Research Consortium: "Matter-Energy-Future", que en
      los años 2012-2017 tuvo el estatus de Centro Nacional de Investigación
      Líder (Leading National Research Centre KNOW) en física. En 2017, la
      Comisión Europea otorgó al Instituto el premio HR Excellence in Research.
      Como resultado de la categorización del Ministerio de Educación y Ciencia,
      el Instituto ha sido clasificado en la categoría A+ (la categoría
      científica más alta de Polonia) en el campo de las ciencias físicas.
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      <strong> CONTACTOS:</strong><br />

      Dr. Piotr Homola<br />

      Instituto de Física Nuclear, Academia de Ciencias de Polonia<br />

      tel.: +48 12 6628341<br />

      correo electrónico: piotr.homola@ifj.edu.pl<br />
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      Dr. David Alvarez Castillo<br />

      Instituto de Física Nuclear, Academia de Ciencias de Polonia<br />

      tel.: +48 12 6628162<br />

      correo electrónico: dalvarez@ifj.edu.pl<br />
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      <strong> PUBLICACIONES CIENTÍFICAS</strong><br />

      “Observation of large scale precursor correlations between cosmic rays and
      earthquakes with a periodicity similar to the solar cycle”<br />

      P. Homola, V. Marchenko, A. Napolitano, R. Damian, R. Guzik, D.
      Alvarez-Castillo, S. Stuglik, O. Ruimi, O. Skorenok, J. Zamora-Saa, J.M.
      Vaquero, T. Wibig, M. Knap, K. Dziadkowiec, M. Karpiel, O. Sushchov, J.W.
      Mietelski, K. Gorzkiewicz, N. Zabari, K. Almeida Cheminant, B. Idźkowski,
      T. Bulik, G. Bhatta, N. Budnev, R. Kamiński, M.V. Medvedev, K. Kozak, O.
      Bar, Ł. Bibrzycki, M. Bielewicz, M. Frontczak, P. Kovacs, B. Łozowski, J.
      Miszczyk, M. Niedźwiecki, L. del Peral, M. Piekarczyk, M. D. Rodriguez
      Frias, K. Rzecki, K. Smelcerz, T. Sośnicki, J. Stasielak, A. A. Tursunov<br
      />

      Journal of Atmospheric and Solar-Terrestrial Physics 2023, 247, 106068<br
      />
      DOI:<a
        class="text-indigo-800 hover:text-blue-600"
        href="https://doi.org/10.1016/j.jastp.2023.106068"
        target="_blank"
        rel="noopener noreferrer">https://doi.org/10.1016/j.jastp.2023.106068</a
      ><br /><br />

      <strong>FECHA DE PUBLICACIÓN DEL ARTÍCULO</strong><br />

      13-abr-2023<br /><br />

      <strong>ENLACES:</strong><br />

      <a
        class="text-indigo-800 hover:text-blue-600"
        href="https://www.ifj.edu.pl/"
        target="_blank"
        rel="noopener noreferrer">https://www.ifj.edu.pl</a
      ><br />

      El sitio web del Instituto de Física Nuclear de la Academia Polaca de
      Ciencias.<br />

      Artículo original en inglés (traducido por Dr. D. Alvarez Castillo):<br />

      <a
        class="text-indigo-800 hover:text-blue-600"
        href="https://www.eurekalert.org/news-releases/992637"
        target="_blank"
        rel="noopener noreferrer"
        >https://www.eurekalert.org/news-releases/992637</a
      >
    </p>
    <p class="w-full text-justify text-xl font-serif mb-8">
      <strong>IMAGENES:</strong><br />

      <a
        class="text-indigo-800 hover:text-blue-600"
        href="http://press.ifj.edu.pl/news/2023/06/14/IFJ230614b_fot01.jpg"
        target="_blank"
        rel="noopener noreferrer"
        >http://press.ifj.edu.pl/news/2023/06/14/IFJ230614b_fot01.jpg</a
      ><br />

      En el espacio se pueden ver terremotos inminentes. No tan literalmente,
      como en el collage de fotos de arriba, pero sí claramente en los cambios
      en la intensidad de los rayos cósmicos registrados por los observatorios
      en la superficie de nuestro planeta. (Fuente: IFJ PAN/NASA/JSC)
    </p>
  </div>
</section>
