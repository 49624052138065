<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="lg:w-4/5 mx-auto text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b> Practices </b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          As part of our project, we offer students practice that will allow us
          to better understand our project, gain experience, and learn how we
          work in the scientific community.
        </p>
        <br />
        <strong>Supervisor: Dr. Piotr Homola</strong>
      </div>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        The topic of scientific work
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          <em
            >If you want you can read in <a
              class="text-indigo-800 hover:text-blue-600"
              href="/../../data/CREDO-practices.pdf"
              target="_blank"
              rel="noopener noreferrer"
              >pdf version
            </a>or
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="#/education/praktyki/"
              rel="noopener noreferrer">polish version</a
            ></em
          >
        </p>
        <p class=" py-2">
          <strong
            >1. Analysis of satellite data concerning the concentration of
            aerosols in the area of the Pierre Auger Observatory.</strong
          ><br />
          In the Pierre Auger Observatory we investigate on extensive air showers:
          cascades of secondary particles induced by cosmic rays of the highest energies
          known: 10^20 eV. This is many millions more than the energies achievable
          in the man-made accelerators. The nature of these extremely energetic particles
          is unknown – help us to solve the puzzle waiting for an answer since more
          than 50 years. Your work will help to understand the impact of aerosols
          present in the Earth atmosphere on the interpretation of the extensive
          air shower data.
        </p>
        <p class=" py-2">
          <strong
            >2. Influence of the inhomogeneities in the aerosol concentration
            over the Pierre Auger Observatory on reconstruction of extensive air
            showers as a way to explore exotic physics effects.</strong
          ><br />
          In the Pierre Auger Observatory we investigate on extensive air showers:
          cascades of secondary particles induced by cosmic rays of the highest energies
          known: 10^20 eV. This is many millions more than the energies achievable
          in the man-made accelerators. Your work will help to understand the impact
          of aerosols present in the Earth atmosphere on the interpretation of the
          extensive air shower data, with a particular stress on identifying observable
          signatures of the New Physics.
        </p>
        <p class=" py-2">
          <strong
            >3.Probing New Physics trough (non)observation of extensive air
            showers induced by large cascades of photons.
          </strong><br />
          In the Pierre Auger Observatory we investigate on extensive air showers:
          cascades of secondary particles induced by cosmic rays of the highest energies
          known: 10^20 eV. This is many millions more than the energies achievable
          in the man-made accelerators. Extensive air showers could be initiated
          also by ensembles of particles: cascades of photons and electrons originated
          above the atmosphere, possibly due to non-standard electromagnetic processes.
          Identification of such cascades might be possible with the analysis of
          non-standard air shower properties. Look beyond the paradigm and visit
          the scientific terra incognita.
        </p>
        <p class=" py-2">
          <strong
            >4. Influence of the geomagnetic field on the properties of
            extensive air showers initiated by super-preshowers.</strong
          ><br />
          In the Pierre Auger Observatory we investigate on extensive air showers:
          cascades of secondary particles induced by cosmic rays of the highest energies
          known: 10^20 eV. This is many millions more than the energies achievable
          in the man-made accelerators. Extensive air showers could be initiated
          also by ensembles of particles: cascades of photons and electrons originated
          above the atmosphere, possibly due to non-standard electromagnetic processes.
          Identification of such cascades might be possible with the analysis of
          the dependence of air shower properties on the geomagnetic field. Working
          in this direction you will have an opportunity for an indirect observation
          of a manifestation of modified quantum electrodynamics. It would ba a long-awaited
          breakthrough in science.
        </p>
        <p class=" py-2">
          <strong
            >5. Simulation of electromagnetic cascading of 10^20 eV photons
            during their propagation through the Solar System.</strong
          ><br />
          In the Pierre Auger Observatory we investigate on extensive air showers:
          cascades of secondary particles induced by cosmic rays of the highest energies
          known: 10^20 eV. This is many millions more than the energies achievable
          in the man-made accelerators. Extensive air showers could be initiated
          also by ensembles of particles: cascades of photons and electrons originated
          above the atmosphere, possibly due to the interactions occurring in the
          not-so-well known regions of the Solar System. If it turns out that such
          cascades emerge frequently, we would need a fundamental revision of our
          knowledge about ultra-high energy cosmic rays. You might contribute to
          such a breakthrough.
        </p>
        <p class=" py-2">
          <strong
            >6. Numerical analysis of QED equations describing the photon
            splitting effect in the regime of ultra-high energies.</strong
          ><br />
          In the Pierre Auger Observatory we investigate on extensive air showers:
          cascades of secondary particles induced by cosmic rays of the highest energies
          known: 10^20 eV. This is many millions more than the energies achievable
          in the man-made accelerators. Extensive air showers could be initiated
          also by ensembles of particles: cascades of photons and electrons originated
          above the atmosphere, possibly due to the splitting of a primary photon
          of extremely high energy into the secondary photons. The photon splitting
          effect, although allowed by the standard theories, has not yet been observed
          in experiments. It seems that it would be possible indirectly in our Observatory.
          To understand correctly the observations we need precise modelling of the
          electromagnetic processes at the highest energies, with different theoretical
          assumptions. It turns out, that numerical processing of the existing equations
          is highly non-trivial. We need your help.
        </p>
        <p class=" py-2">
          <strong
            >7. Design and construction of an economical detector of extensive
            air showers.</strong
          ><br />
          Some exotic physical processes might result in emerging of correlated in
          time but highly spatially spread air showers. Observation of such phenomena
          might be possible only with a unique infrastructure: a worldwide network
          of small and economic detectors with well synchronized clocks. The project
          has just started, you might be one of the pioneers.
        </p>
        <p class=" py-2">
          <strong
            >8. Design and construction of a distributed and diversified network
            of economical cosmic-ray detectors.</strong
          ><br />
          Some exotic physical processes might result in emerging of correlated in
          time but highly spatially spread air showers. Observation of such phenomena
          might be possible only with a unique infrastructure: a worldwide network
          of small and economic detectors with well synchronized clocks. The project
          has just started, you might be one of the pioneers.
        </p>
        <p class=" py-2">
          <strong
            >9. A study of an air shower detection efficiency in a distributed
            and diversified network of economical cosmic-ray detectors.</strong
          ><br />
          Some exotic physical processes might result in emerging of correlated in
          time but highly spatially spread air showers. Observation of such phenomena
          might be possible only with a unique infrastructure: a worldwide network
          of small and economic detectors with well synchronized clocks. The project
          has just started, you might be one of the pioneers.
        </p>
        <p class=" py-2">
          <strong
            >10. Search for cosmic-ray events clustered in time as a potential
            signature of New Physics.</strong
          ><br />
          Some exotic physical processes might result in emerging of correlated in
          time but highly spatially spread air showers. Observation of such phenomena
          might be possible in the Pierre Auger Observatory, the largest cosmic-ray
          detector in operation. Help us checking this.
        </p>
        <p class=" py-2">
          <strong
            >11. A study on characteristics of extensive air showers initiated
            by magnetic monopoles.</strong
          ><br />
          Magnetic monopoles are yet not observed but possibly existing particles.
          Will CREDO help in detecting them?
        </p>
        <p class=" py-2">
          <strong
            >12. Design and construction of a prototype of an internet system
            for an exchange of information in a large scientific collaboration.</strong
          ><br />
          The long-awaited breakthrough in science might happen within an exceptionally
          large collaboration, composed of even a million of contributors. An optimum
          efficiency of such a collaboration will require a complex, multi-function
          communication system. Something like Facebook but for professionals. Many
          of them. Work with us in this direction.
        </p>
        <p class=" py-2">
          <strong
            >13. Search for the New Physics signatures in the data of the
            experiments recording Cherenkov radiation induced by particles of
            extremely high energies.</strong
          ><br />
          Cherenkov radiation is induced by charged particles moving in a medium
          at the speed larger than the speed of light in this medium. Cherenkov photons
          are the signal for gamma astronomers and a background for the cosmic-ray
          detectors. It is expected that some exotic particle, for instance magnetic
          monopoles, might induce an emission of Cherenkov light of a non-standard
          intensity and angular distribution. Such „strange” events are most often
          rejected in the standard analysis where only the data of a superior quality
          are taken into account. Help us to find „strange” events in the data taken
          by the Pierre Auger Observatory as well as in those collected by H.E.S.S.
          and MAGIC gamma ray telescopes. Let us check together whether they are
          the New Physics manifestations or not.
        </p>
      </div>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        Topics of student practice
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          1. Testing the efficiency of the particle counters used in the Pierre
          Auger Observatory
          <br />
          2. Construction of an economic muon counter prototype
          <br />
          3. Design and coding of an internet platform to communicate and exchange
          information within a large, international scientific collaboration

          <br />
          4. <strong>[NEW] </strong>Bayesian Analysis of Cosmic Rays data for
          Earthquake Predictions
          <strong
            >[<a
              class="text-indigo-800 hover:text-blue-600"
              href="/../../data/Bayesian-Analysis-of-Cosmic-Rays-data-for-Earthquake-Predictions.pdf"
              target="_blank"
              rel="noopener noreferrer">Read more</a
            >]</strong
          >
        </p>
      </div>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900 "
      >
        Mini-jobs
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class=" py-2">
          1. Scientific description of the lab exercise „muon life time”<br />
          2. Construction of an economic muon counter prototype
          <br />
          <strong
            >You want more information about offert? <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.ifj.edu.pl/edu/stud/?lang=pl"
              target="_blank"
              rel="noopener noreferrer">visit website IFJ</a
            ></strong
          ><br />
          <strong>
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="//www.ifj.edu.pl/edu/praktyki/"
              target="_blank"
              rel="noopener noreferrer">Formal matters</a
            >
            contact
            <a
              class="text-indigo-800 hover:text-blue-600"
              href="mailto:Marzena.Mitura-Nowak@ifj.edu.pl"
              >Marzena.Mitura-Nowak@ifj.edu.pl</a
            ></strong
          ><br />
          <strong> contact with supervisor: </strong><em
            ><strong>e-mail: </strong><a
              class="text-indigo-800 hover:text-blue-600"
              href="mailto: Piotr.Homola[at]ifj.edu.pl"
              ><strong>Piotr.Homola@ifj.edu.pl</strong></a
            ></em
          >
        </p>
      </div>
    </div>
  </div>
</section>
