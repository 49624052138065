<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    1st World Championships in Particle Hunting with Smartphones!
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/completeforscience-570x350.png"
      alt="premiere img"
      width="400"
    />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <p style="text-align: center;">
      <span style="color: #000000;"
        ><strong>TODAY! Compete For Science! </strong></span
      >
      <span style="color: #000000;"
        ><strong
          >1st World Championships in Particle Hunting with Smartphones!
        </strong></span
      >
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >Start: May 18th 2018 (TODAY!), 16:20 CEST | Duration: 24h. See the
        view, check the rules & tips, compete in teams, be the champion, beat
        the world record, <strong
          ><span style="color: #800000;"
            ><a
              style="color: #0000ff;"
              href="//api.credo.science/web/"
              target="_blank"
              rel="noopener noreferrer">all here</a
            ></span
          ></strong
        ></span
      >
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >We can't wait too long to have more fun For Science after the mega
        successful Launch of the CREDO Detector app yesterday, so we announce
        the Championships.</span
      >
    </p>
    <p style="text-align: center;">
      <span style="color: #000000;">Enjoy your help </span>
    </p>
  </div>
</div>
