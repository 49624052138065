<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    The first regularly peer-reviewed publication “for the CREDO Collaboration
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/credo2a.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >We've got it! The first regularly peer-reviewed publication "for the
        CREDO Collaboration" is now available on-line, with full bibliographic
        details. Big congratulations to the main authors: Kevin Almeida
        Cheminant and Gora Dariusz. You can download the article using the 50
        days' free access link below, you are also welcome to share the link
        further. This is a big milestone on the CREDO road map, we are very
        happy to inaugurate the scientific harvest time, as there are more
        publications to come! One of them was accepted even before the one
        announced today, but still no official version is available - we are
        looking forward to celebrating this one too! The work by Kevin and
        Dariusz is dedicated to the simulations of one of the CRE scenarios, so
        no data engaged here, but the first paper based on the CREDO smartphone
        data has already been initially accepted by another journal, this one
        will be a big milestone too - opening the door for the publications that
        could be signed by all the colleagues engaged in the data acquisition,
        meaning at least one second of using the CREDO Detector app</span
      >
    </p>
  </div>
</div>
