<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
  Particle Hunters 2022/23 competition summary
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    
    We have sent an email to the coordinators of the active teams participating in the 5th edition of the competition, containing a summary of the completed edition of the competition with additional information about the certificates and prizes being prepared.
Anyone who would like to read the summary of the competition can read it in two forms<br/>
a) pdf version (Polish only) available at the link
<a class="text-blue-700" href="https://credo.science/particle_hunters/download/Particle_Hunters_2022_23_Podsumowanie.pdf" target="_blank" rel="noreferrer">https://credo.science/particle_hunters/download/Particle_Hunters_2022_23_Podsumowanie.pdf</a>
  

(b) graphics with the most important information (attached as post graphics).
<div class="flex flex-wrap m-4 mx-auto">
  <img
    src="./images/posts/ph_22/4.png"
    alt="images1"
    class=" px-1 w-full md:w-1/3"
  />
  <img
    src="./images/posts/ph_22/4.png"
    alt="images2"
    class=" px-1 w-full md:w-1/3"
  />
  <img
    src="./images/posts/ph_22/4.png"
    alt="images3"
    class=" px-1 w-full md:w-1/3"
  />
</div>
We thank all teams and participants for their participation and congratulate the winners on their success!
  </div>
</div>
