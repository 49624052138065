<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    100 Hours, 100 NOCs - Poland
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/100-Hours-of-Astronomy-2021.jpg"
      alt="images"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >As part of the 100 Hours of Astronomy (event from 1 to 4 October 2021),
      in the Polish presentation could not miss the CREDO Project, which will be
      presented by Prof. Robert Kamiński.<br />We invite everyone to the
      broadcast on Saturday 2.10.2021 at 18:00 on
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.youtube.com/watch?v=FMNIiWVcZC4">YouTube</a
        ></span
      >
      and
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://www.facebook.com/IAUPoland4"
          >Facebook</a
        ></span
      >.<br /><span style="color: #000000; text-align:justify">
        If you are interested in the transmission from other countries, you can
        find them on the facebook profile of the
      </span>
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://www.facebook.com/IAUoutreach"
          >IAU Office for Astronomy Outreach</a
        ></span
      >.
    </span>
  </div>
</div>
