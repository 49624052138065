<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Review article about CREDO in Symmetry
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/credo3a-570x350.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000;"
      >Proudly announcing: our flagship article [Symmetry 2020, 12(11), 1835;
    </span><span style="color: #000000;"
      ><span style="color: #0000ff;"
        ><a
          href="https://www.mdpi.com/2073-8994/12/11/1835"
          target="_blank"
          rel="nofollow noopener noreferrer"
          >https://doi.org/10.3390/sym12111835</a
        ></span
      >] has just been published, and it is now freely available online!
    </span>

    <span style="color: #000000;"
      >Celebrating a big CREDO milestone! Big thanks and congratulations to all
      the Authors, Institutions and Funding Agencies!
    </span>
  </div>
</div>
