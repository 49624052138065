<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto text-black">
    <div class="flex flex-col text-center w-full">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b>Materials For Schools</b>
      </h1>
    </div>

    <p>
      <a href="#/education/materialydlaszkol/"
        ><img
          src="./images/flags/polska.png"
          alt=""
          width="64"
          height="40"
        /></a
      >
    </p>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
    >
      <p>
        A package of materials has been prepared (presented below).
        <br />You can download the entire package
        <a
          class="text-indigo-800 hover:text-blue-600"
          href="//www.dropbox.com/sh/smtdro7bpmbz6hs/AAANtZVgCjR-j5XrBUVcSUuxa?dl=1"
          target="_blank"
          rel="noopener noreferrer"><strong>here</strong></a
        >. (~ 80 MB)
      </p>
      <p>
        <strong
          ><a
            class="text-indigo-800 hover:text-blue-600"
            href="./data/CLASSES-SCENARIO.pdf"
            target="_blank">Class Scenario</a
          ></strong
        >
      </p>
      <p>
        <strong
          ><a
            class="text-indigo-800 hover:text-blue-600"
            href="#/education/podcast/"
            target="_blank">Podcast with Piotr Homola</a
          ></strong
        >
        - Official
        <a
          class="text-indigo-800 hover:text-blue-600"
          target="_blank"
          rel="noopener noreferrer"
          href="//tygodnikpowszechny.pl/podkast"
          >podcast of 'Tygodnik Powszechny'</a
        >
        as part of the<a
          class="text-indigo-800 hover:text-blue-600"
          target="_blank"
          rel="noopener noreferrer"
          href="//www.tygodnikpowszechny.pl/wielkiepytania"
          >'Big Questions Again' project.</a
        >
      </p>
      <div class="flex items-center flex-col m-auto p-10 text-center">
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="//www.youtube.com/embed/8y07CuTQfOc?rel=0"
          width="460"
          height="265"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        />
      </div>
    </div>
    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
    >
      Intervievs
    </h1>

    <div class="flex items-center flex-col m-auto p-10 text-center">
      <!-- svelte-ignore a11y-missing-attribute -->
      <iframe
        src="//www.youtube.com/embed/CAiMrrqVu6M?rel=0"
        width="560"
        height="265"
        allowfullscreen="allowfullscreen"
      />
    </div>
    <div class="flex items-center flex-col m-auto p-10 text-center">
      <!-- svelte-ignore a11y-missing-attribute -->
      <iframe
        src="//www.youtube.com/embed/_bdMRDLMEmE?rel=0"
        width="560"
        height="265"
        allowfullscreen="allowfullscreen"
      />
    </div>
    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
    >
      MOVIES
    </h1>

    <div class="flex items-center flex-col m-auto p-10 text-center">
      <!-- svelte-ignore a11y-missing-attribute -->
      <iframe
        src="//www.youtube.com/embed/BSnQxpAN-gA?controls=0"
        width="560"
        height="315"
        frameborder="0"
        allowfullscreen="allowfullscreen"
      />
    </div>
    <div class="flex items-center flex-col m-auto p-10 text-center">
      <!-- svelte-ignore a11y-missing-attribute -->
      <iframe
        src="//www.youtube.com/embed/CfK_CGDUPDA?controls=0"
        width="560"
        height="315"
        frameborder="0"
        allowfullscreen="allowfullscreen"
      />
    </div>
    <div class="flex items-center flex-col m-auto p-10 text-center">
      <!-- svelte-ignore a11y-missing-attribute -->
      <iframe
        src="//www.youtube.com/embed/6rHnW--PZQk?rel=0"
        width="560"
        height="315"
        frameborder="0"
        allowfullscreen="allowfullscreen"
      />
    </div>
  </div>
</section>
