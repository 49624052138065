<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto text-black">
    <div class="flex flex-col text-center w-full">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b>Materiały dla Szkół</b>
      </h1>
    </div>

    <p>
      <a href="#/education/materialsforschools/"
        ><img src="./images/flags/wb.jpg" alt="" width="64" height="40" /></a
      >
    </p>
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8 mt-8"
    >
      <p>
        Przygotowany został pakiet materiałów który możesz pobrać
        <a
          class="text-indigo-800 hover:text-blue-600"
          href="//www.dropbox.com/sh/smtdro7bpmbz6hs/AAANtZVgCjR-j5XrBUVcSUuxa?dl=1"
          target="_blank"
          rel="noopener noreferrer"><strong>tutaj</strong></a
        >. (~ 80 MB)
      </p>
      <p>
        <strong
          ><a
            class="text-indigo-800 hover:text-blue-600"
            href="/data/CLASSES-SCENARIO.pdf"
            target="_blank">Class Scenario</a
          ></strong
        >
      </p>
      <p>
        <strong
          ><a
            class="text-indigo-800 hover:text-blue-600"
            href="#/education/podcast/"
            target="_blank">Podkast z Piotr Homola</a
          ></strong
        >
        - Oficjalny
        <span style="color: #0000ff;">
          <a
            class="text-indigo-800 hover:text-blue-600"
            target="_blank"
            rel="noopener noreferrer"
            href="//tygodnikpowszechny.pl/podkast"
            >podkast Tygodnika Powszechnego</a
          ></span
        >
        w ramach projektu<span style="color: #0000ff;">
          <a
            class="text-indigo-800 hover:text-blue-600"
            target="_blank"
            rel="noopener noreferrer"
            href="//www.tygodnikpowszechny.pl/wielkiepytania"
            >'Big Questions Again'.</a
          ></span
        ><br />(posłuchaj podkastu na
        <a
          class="text-indigo-800 hover:text-blue-600"
          href="https://www.tygodnikpowszechny.pl/jak-zmienic-swoj-smartfon-w-detektor-promieniowania-kosmicznego-160352"
          target="_blank"
          rel="noreferrer"
        >
          oficjalnej stronie</a
        >)<br />
        Czy w CREDO widzimy ciemną materię? - Piotr Homola (<a
          class="text-indigo-800 hover:text-blue-600"
          href="https://www.tygodnikpowszechny.pl/jak-zmienic-swoj-smartfon-w-detektor-promieniowania-kosmicznego-160352"
          target="_blank"
          rel="noreferrer"
        >
          zobacz nagranie na Facebooku</a
        >)
      </p>
    </div>

    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-2 text-red-900"
    >
      Wprowadzenie
    </h1>

    <div class="flex items-center justify-center flex-wrap">
      <div class="flex items-center flex-col m-auto p-10 text-center w-1/2">
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="//www.youtube.com/embed/CAiMrrqVu6M?rel=0"
          width="560"
          height="265"
          allowfullscreen="allowfullscreen"
        />
      </div>
      <div class="flex items-center flex-col m-auto p-10 text-center w-1/2">
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="//www.youtube.com/embed/_bdMRDLMEmE?rel=0"
          width="560"
          height="265"
          allowfullscreen="allowfullscreen"
        />
      </div>
      <div class="flex items-center flex-col m-auto p-10 text-center w-1/2">
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="//www.youtube.com/embed/3Pzddshmg9U?si=y_7uckaQ_NU2l1wb"
          width="560"
          height="315"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        />
      </div>
      <div class="flex items-center flex-col m-auto p-10 text-center w-1/2">
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="//www.youtube.com/embed/ZriAIVJq-kU?si=m_Ne7ccWDzIHErHI"
          width="560"
          height="315"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        />
      </div>
      <div class="flex items-center flex-col m-auto p-10 text-center w-1/2">
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="//www.youtube.com/embed/0oC2sFJYMfg?si=ABbIC4fYI2JciMSg"
          width="560"
          height="315"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        />
      </div>

      <div class="flex items-center flex-col m-auto p-10 text-center w-1/2">
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="//www.youtube.com/embed/6rHnW--PZQk?rel=0"
          width="560"
          height="315"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        />
      </div>
    </div>
    <hr class="w-2/3 mx-auto text-center bg-red-800 pt-1 border-1 my-5" />

    <h1
      class="text-3xl text-center uppercase tracking-wider font-serif title-font mb-2 text-red-900 mt-8"
    >
      Instalacja / uruchomienie aplikacji
    </h1>

    <div class="flex items-center justify-center flex-wrap">
      <div class="flex items-center flex-col m-auto p-10 text-center">
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="//www.youtube.com/embed/8y07CuTQfOc?rel=0"
          width="460"
          height="265"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        />
      </div>
      <div
        class="flex items-center flex-col m-auto text-center border-gray-800 border-2 rounded-lg"
      >
        <!-- svelte-ignore a11y-missing-attribute -->
        <iframe
          src="/data/detector/CREDO_detector_install.mp4"
          width="460"
          height="265"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        />
      </div>
    </div>
  </div>
</section>
