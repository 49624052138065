<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b> Podcast</b>
      </h1>
      <h1
        class="lg:w-4/5 mx-auto text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        Transkrypcja rozmowy Michała Kuźmińskiego (Tygodnik Powszechny) z
        Piotrem Homolą koordynatorem międzynarodowego Cosmic Ray Extremely
        Distributed Observatory (CREDO)
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          Odnośnik do wersji audio (
          <a
            style="color: #0000ff;"
            href="https://www.tygodnikpowszechny.pl/jak-zmienic-swoj-smartfon-w-detektor-promieniowania-kosmicznego-160352"
            >Podcast Powszechny, cykl “Wielkie Pytania na nowo”</a
          >)
          <br />
          Oznaczenia skrótowe:<br />

          <b>MK</b> – Michał Kuźmiński<br />

          <b>PH</b> – Piotr Homola<br /><br />

          <strong> Wprowadzenie</strong>
          <br />
          <i>Promieniowanie kosmiczne… </i>
          <i
            >Ślad dawnych i odległych zdarzeń w przestrzeni kosmicznej… Jego
            cząstki niosą tropy największych tajemnic wszechświata… Przez
            powierzchnię naszej planety i przez nas samych w każdej chwili
            przelatują wysoko przenikliwe cząstki powstające w efekcie
            oddziaływań pierwotnego promieniowania kosmicznego z atmosferą w
            Ziemi. Naukowcy budują specjalne detektory żeby wśród tych cząstek
            szukać odpowiedzi na największe pytania nauki… Ale czy wiecie, że
            promieniowanie kosmiczne mogą wykrywać także nasze zwyczajne
            smartfony? Jeden smartfon nie zdziała wiele, ale smartfona ma dziś
            ponad 5 mld ludzi. Co by się więc stało gdyby do pracy zaprząc
            miliony smartfonów na całym świecie i połączyć ich siły z
            istniejącymi specjalistycznymi detektorami? Takie pytanie zadali
            sobie naukowcy z Krakowa i stworzyli projekt CREDO: ogólnoplanetarny
            społecznościowy detektor promieniowania kosmicznego, który właśnie
            11 września oficjalnie zainaugurował swoją działalność. Od teraz w
            poszukiwanie odpowiedzi na wielkie pytania nauki może się włączyć
            każdy z nas.</i
          >
        </p>
        <p class="mt-3">
          Tu Michał Kuźmiński, <i
            >zapraszam na podcast powszechny w cyklu “Wielkie pytania”.</i
          >
        </p>
        <p class="mt-8">
          <span
            ><b>Michał Kuźmiński (MK):</b>
            Dzisiaj ja goszczę w Instytucie Fizyki Jądrowej Polskiej Akademii Nauk
            w gabinecie Pana doktora habilitowanego Piotra Homoli – dzień dobry.
          </span>
        </p>
        <p class="mt-2">
          <b>Piotr Homola (PH):</b> Dzień dobry,
        </p>
        <p class="mt-2">
          <b>MK:</b>
          A pan doktor Piotr Homola jest koordynatorem i “duszą” chciałoby się powiedzieć
          projektu o wdzięcznej nazwie CREDO -Cosmic-Ray Extremely Distributed Observatory.
          Co należałoby chyba przełożyć jako“ skrajnie rozproszone obserwatorium
          promieniowania kosmicznego”. Zanim zapytam o to skrajne rozproszenie i
          oto obserwatorium to chciałem zapytać o promieniowanie kosmiczne – dlatego,
          że my sobie tutaj tak spokojnie siedzimy a tutaj w każdej sekundzie przez
          nas “przelatuje” tysiące cząstek promieniowania kosmicznego i to jeszcze
          w dodatku wysoko energetycznych. Co to jest to promieniowanie kosmiczne?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Tak to można o głowie pomyśleć.. przez głowę w ciągu sekundy mniej więcej
          średnio 5 cząstek przenikliwych mionów – są to cząstki nisko energetyczne
          tzw. “wtórne promieniowanie kosmiczne” pochodzące z oddziaływań w atmosferze
          cząstek o wyższych energiach. Promieniowanie kosmiczne to jest wszechobecne
          – dociera wszędzie za darmo, dlatego to też jest wdzięczny obiekt badań
          – nie trzeba budować ogromnych urządzeń, wystarczy nawet zwykłe rozejrzenie
          się dookoła. Bardzo wiele urządzeń może rejestrować promieniowanie i to
          co proponuje CREDO to ogarnięcie tego na skalę globalną.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          No właśnie bo zwykle tego rodzaju badania w fizyce polegały na tym, że
          buduje się wielki detektor za ogromne pieniądze i wyposaża się go w niezwykle
          czułą aparaturę która jest zdolna wykonywać rozmaite iteracje, a CREDO
          to taki projekt który przyjął wydaje się odwrotne założenia. A najważniejsze
          w tym chyba wszystkim jest to, że po skali badań na jakie CREDO się nastawia
          potrzebny jest detektor kuli ziemskiej prawda? i trochę o to tutaj chodzi.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          No powiedziałbym, że nawet więcej, jeżeli będziemy mieć stacje na marsie,
          na księżycu, jeżeli mamy satelity to strategia CREDO wymagać będzie również
          umieszczenia tam detektorów promieniowania kosmicznego które będą potrzebne
          skądinąd – będzie potrzeba monitorować promieniowanie kosmiczne na Marsie
          ze względów zdrowotnych – tam inne natężenia mogą wystąpić niż na ZIemi
          i to będzie trzeba mieć pod kontrolą, no a w strategii CREDO mieści się
          poszukiwanie zjawisk bardzo rozległych tzn. promieni kosmicznych które
          mogą być skorelowane w dużej skali – więc np. wyobrażamy sobie, że dzisiaj
          w Krakowie rejestrujemy zdarzenie np. wielki pęk atmosferyczny – kaskadę
          cząstek wtórnych zainicjowaną przez promień kosmiczny o bardzo dużej energii
          i w tym samym czasie jednocześnie na Marsie, na księżycu i na międzynarodowej
          stacji kosmicznej również rejestrujemy nadwyżkę sygnału. No takie zdarzenie
          to jest właśnie nasz cel – przynajmniej gdy mówimy o tych celach astro
          i my nazywamy takie zjawisko które powinno występować w przyrodzie, ale
          jeszcze go nikt nie zaobserwował.. zespołem promieni kosmicznych po angielsku
          “Cosmic Ray Ensemble” co też współgra z naszym akronimem. No i tenże zespół
          tak naprawdę może mieć rozmiary niewielkie – być może lokalne np. krakowskie,
          ale może też mieć rozpiętość planetarną albo nawet międzyplanetarną. TO
          jest szansa na badanie produktów czy efektów oddziaływań cząstek o najwyższych
          znanych energiach we wszechświecie – a być może na zaobserwowanie zjawisk,
          które powiedzą nam jak poszukiwać przełomów w naszym rozumieniu wszechświata.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Zanim powiemy jak to zrobić, żeby zbudować detektor wielkości kuli ziemskiej
          i jaki jest pomysł CREDO, to chciałbym jeszcze dopytać o to promieniowanie
          – ile my właściwie o nim wiemy? Czym są cząstki które dobiegają do Ziemi
          i przebiegają przez Ziemię i przez nas wszystkich? Czym są źródła, skąd
          mogą się brać? i czym są (piękne pojęcie) “pęki atmosferyczne”?
        </p>
        <p class="mt-2">
          <b>PH: </b>Promieniowanie kosmiczne można właściwie powiedzieć, że
          znamy dość dobrze – jeżeli chodzi o niskie energie. Najbliższym
          źródłem promieniowania kosmicznego jest Słońce, wszelakie procesy
          również te katastroficzne które zachodzą we wszechświecie takie jak
          wybuchy supernowych czy kolizje galaktyk, czy np. ostatnio modne
          kolizje czarnych dziur z gwiazdami neutronowymi. To są wszystko
          zdarzenia, które mogą wyemitować cząstki o bardzo dużych energiach i
          do Ziemi te cząstki docierają – to to jest fakt, obserwujemy je
          pośrednio i bezpośrednio, przy tych energiach które są już znaczne
          mamy szansę tylko na obserwację pośrednie – właśnie przy pomocy
          wielkich pęków atmosferycznych. Cząstka o dużej energii – tu mówimy o
          energii rzędu 10<sup>15</sup> eV…
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Ile to jest?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          To jest mniej więcej tysiąc razy więcej niż najwyższe energie możliwe do
          uzyskania w CERNie. Może ja mam pod ręką przykład skrajnie wysokiej energii
          10<sup>20</sup>
          eV, czyli jeszcze 100 000 razy więcej niż 10<sup>15</sup>. To jest
          mniej więcej tyle ile dobrze zaserwowana piłka tenisowa, albo
          uderzenie dobrze wyszkolonego pięściarza wagi ciężkiej powiedzmy
          Tysona z najlepszych lat – tylko tu mówimy o jednej cząstce…
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Ja dopowiem, że w CERNie obrazuje się to tak, że tam rozpędzany w akceleratorze
          pęk protonów osiąga energię porównywalną ze “zderzeniem z komarem” – to
          jest chyba dość dobre porównanie między komarem a pięściarzem..
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Tak i właśnie duża energia oznacza mniej więcej tyle, że taka cząstka ma
          małe szanse na dotarcie do powierzchni Ziemi, oddziałuję wcześniej z molekułami
          atmosfery i wywołuje Kaskadę cząstek wtórnych. Ta kaskada właśnie nazywana
          jest wielkim pękiem atmosferycznym. To mogą być nawet miliardy cząstek,
          które mogą zajmować powierzchnię nawet wielu kilometrów kwadratowych, z
          tym że oczywiście im bliżej osi pęku im bliżej rdzenia tym gęściej. I właśnie
          te zjawiska (wielkie pęki atmosferyczne) mogą być rejestrowane przez duże
          obserwatoria – ale mamy nadzieję że przez małe detektory również i analiza
          tych.. no bardzo zdegenerowanych danych kaskady wtórnej prowadzi do wniosków.
          Najłatwiej określić kierunek przylotu cząstki pierwotnej, następnie energię
          a najtrudniej określić rodzaj, ale dla tych mniejszych energii dość dobrze
          znamy skład promieniowania kosmicznego. Można powiedzieć że dominującą
          w nim protony, wiemy też, że fotony o dużych energiach docierają do ziemi
          bo na tej podstawie funkcjonuje cała wielka gałąź astrofizyki pod tytułem
          astronomia gamma która właśnie zajmuje się takimi wysokoenergetycznym fotonami.
          No i co ciekawe najmniej wiemy o tych przypadkach które są najrzadsze –
          czyli o przypadkach o skrajnych wysokich energiach czyli właśnie ten przysłowiowy
          cios Tysona. Takie takie przypadki zdarzają się niezmiernie rzadko, na
          kilometrze kwadratowym spodziewalibyśmy się jednego zdarzenia w ciągu tysiąca
          lat – także tutaj potrzeba dużej cierpliwości, albo dużego doktora, ewentualnie
          bardzo rozproszonego detektora.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          przed nami na biurku leżą dwa urządzenia zwane scyntylatorami i co pewien
          czas mrugają – każde takie mrugnięcie oznacza, przejście przez nie cząstki
          wysokoenergetyczne – prawda?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Tak, specjalnie dla Pana, Panie Michale odpaliłem urządzenie które ma wdzięczną
          nazwę Cosmic Watch. Nawiasem mówiąc jest to urządzenie zaprojektowane na
          zasadzie Open hardware przez dwóch tytularnych partnerów CREDO – nie byle
          jakich partnerów bo mowa o Massachusetts Institute of Technology – tak
          to jest ten słynny “MIT” i nasz tutaj wspaniały Instytut: Narodowe Centrum
          Badań Jądrowych. Oni wspólnie opracowali detektor którego cena myślę że
          można powiedzieć bo to jest projekt otwarty nie komercyjny jest rzędu 100
          $ – więc to jest w zasięgu myślę każdej szkoły a może nawet każdej klasy
          biorąc pod uwagę to jak bardzo dobrze nauczyciele i uczniowie reagują właśnie
          na pomysł włączenia się aktywnego włączenia się do badań naukowych na najwyższym
          poziomie. No spodziewałbym się że szybko uda nam się dojść do programu
          krajowego a wkrótce do międzynarodowego to znaczy detektor w każdej szkole
          – będziemy próbować.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Ale tak naprawdę sedno i klucz programu CREDO polega na tym, że detektor
          nie tyle w każdej szkole, co detektor w każdej kieszeni. Z pewnym zdumieniem
          dowiaduję się, że dobrze nam znane i zadomowione urządzenie jakim są smartfony
          zupełnie nieźle sprawdzają się w charakterze detektorów takich właśnie
          cząstek. Jak to możliwe?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Tak, państwo nas nie widzą, ale wyciągam właśnie z kieszeni to urządzenie
          czyli Smartfon, smartfon ma to do siebie że można nim robić zdjęcia, to
          znaczy jest tam kamera, jest matryca światłoczuła, jest to tak zwana matryca
          CMOS. I ta matryca jest czuła nie tylko na światło widzialne ale, również
          na promieniowanie przenikliwe. Czyli to co należy zrobić żeby to promieniowanie
          przenikliwe zarejestrować to po pierwsze zainstalować aplikację, na przykład
          aplikację projektu CREDO, która nazywa się CREDO Detector; następnie należy
          kamerę zakryć i potem pozostaje nam tylko uruchomienie aplikacji i najlepszym
          trybem aktywności naukowej z odpaloną aplikacją jest sen. Tutaj konsumpcja
          energii przy ciągłym fotografowaniu – bo tak naprawdę działa aplikacja
          – jest spora i to najlepiej robić podczas snu gdy smartfon się ładuję.
          My budzimy się rano i patrzymy jakie piękne wykrycia przez noc się nam
          trafiły – te wykrycia są automatycznie do centralnego systemu akwizycji
          (przetwarzania) danych i to jest tak naprawdę ten pierwszy krok, a potem
          można robić kolejne i kolejne .. można rozpocząć z CREDO “podróż” nawet
          (miejmy nadzieję) na całe życie.
        </p>
        <p class="mt-2">
          <b>MK: </b>Czy to jest tak, że matryca jest wzbudzana? Bo poco
          zakrywamy ten aparat? Po to by zwyczajnie do niego światło nie
          docierało? I wtedy jest szansa, że pojawią się na nim, na zdjęciu
          jakieś zakłócenia i będzie oznaczać, że przez nasz smartfon
          przefrunęła sobie cząstka, tak?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Tak, to dokładnie o to chodzi – gdyby światło widzialne docierało do kamery,
          mielibyśmy bardzo duży problem z wyróżnieniem śladu który mógłby pochodzić
          od cząstki. Podobny mechanizm działa w społeczności astronomów którzy używają
          teleskopów wyposażone w matryce CCD, tzn. również w matryce światłoczułe.
          Astronomowie muszą od czasu do czasu (choćby nie chcieli) muszą zrobić
          zdjęcie albo serię zdjęć z zakrytym obiektywem, po to żeby zidentyfikować
          tak zwane złe piksele / uszkodzone piksele.. no jeżeli kamera jest zakryta,
          czyli światło widzialne nie dociera do matrycy a mimo tego pojawiają się
          jakieś jasne kropki to do tego na na kolejnych zdjęciach no to najprawdopodobniej
          są to właśnie te złe piksele które potem można wyekstrahować w ten sposób
          by mieć bardziej dokładną analizę. I co ciekawe podczas tejże procedury
          rejestrowane są również i zdarzenia promieniowania kosmicznego, to wie
          każdy astronom, że żeby takie niechciane efekty usunąć – to jest znany
          efekt my tego nie wymyśliliśmy, my wymyśliliśmy jak z tego zrobić pożytek
          masowy, globalny i atakujący tak zwane wielkie pytanie To jest właśnie
          nasza misja.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          I to chyba jest dobry moment żeby powiedzieć co to są za wielkie pytania,
          po co właściwie oprócz tego że to oczywiście jest bardzo ciekawe bada się
          promieniowanie kosmiczne? Czego można się dowiedzieć za pomocą takiej sieci
          smartfonów?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Tutaj zacznę od tego, że być może najciekawszym efektem, najciekawszą rzeczą
          której możemy się dowiedzieć jest obserwacja nieoczekiwanego zjawiska.
          To często się powtarza w fizyce, że najciekawsze jest nieoczekiwane, no
          w ten sposób na przykład odkryto mikrofalowe promieniowanie tła. Naukowcy
          myśleli, że to co widzą w danych ze swojego radioteleskopu to jest po prostu
          efekt przesiadywania gołębi na urządzeniu, a tymczasem z tego wyszedł Nobel,
          więc tego typu strategię nazywamy po angielsku: “fishingiem” czyli właśnie
          wędkowaniem, zarzucaniem sieci bez góry narzuconych założeń i polega to
          w skrócie na tym, że znamy skądinąd średnią częstotliwość detekcji w każdym
          urządzeniu, więc łatwo jest wyobrazić sobie mechanizm który wykrywa anomalie
          krótko- lub długotrwałe i celem było zrozumienie tych anomalii, jeżeli
          jest anomalia nie wpadamy w panikę tylko właśnie się cieszymy i staramy
          się jej bliżej przyglądnąć, ale oprócz fishingu, oprócz polowania na anomalie
          i prób korelowania tych anomalii z innymi dostępnymi informacjami – bazami
          danych. Mamy też oczywiście w planach weryfikację konkretnych hipotez naukowych,
          tylko co ważne to nie jest zamknięta lista. CREDO jest otwartym obserwatorium
          i nawet jeżeli dzisiaj powiem Panu o pięciu pięciu tezach z potencjałem
          noblowskim, to jeżeli jutro przyjdzie ktoś i powie że ma ciekawszy inny
          pomysł to może go realizować w CREDO. Bo CREDO jest otwartym obserwatorium
          i każdy nie tylko może dostarczyć danych zebranych przez swoje prywatne
          urządzenia, nie ma tu żadnych restrykcji ani kulturowych ani geograficznych
          i na tej samej zasadzie każdy może uzyskać do tych danych dostęp. Oczywiście
          z pełnym poszanowaniem prywatności prawa – tutaj mówimy o danych naukowych
          z pełną anonimowością, więc nie ma żadnego niebezpieczeństwa naruszenia
          prywatności. Natomiast jest szansa, każdy z nas nawet jeżeli nie podpisał
          Memorandum of Understanding (MoU) może do danych dotrzeć i może dostać
          nagrodę i może się nią nie podzielić z nami i my nie będziemy się gniewać.
          To jest właśnie nasza misja, że my robimy urządzenie otwarte.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          A jeszcze wracając do tych przynajmniej trzech hipotez z potencjałem Noblowskimi…
          bo tak sobie myślę kiedy czytałem o CREDO dowiedziałem się, że potencjalne
          odpowiedzi jakie jakie dane gromadzone w tym projekcie mogą przynieść,
          mogą dotyczyć na przykład struktury czasoprzestrzeni.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Tak, to jest moja ulubiona moja ulubiona rzecz… więc mamy taki potencjał.
          To może brzmieć jak sen wariata, ale to jest realne dobrze postawione pytanie
          naukowe. Bardzo duże wyzwanie – ale realne do osiągnięcia przy pomocy globalnej
          sieci detektorów, które byłyby czułe na wielkie pęki atmosferyczne i tutaj
          klucz do zrozumienia tego tematu polega na uzmysłowieniu sobie szansy na
          rejestrację zespołu promieni kosmicznych. Zespołu który miałby wspólny
          początek gdzieś w głębokim odległym wszechświecie i w wyniku tego pierwszego
          oddziaływania czy pierwszego procesu fizycznego powstawałaby grupa cząstek
          która propagowałaby przez Wszechświat. Jeżeli ta grupa.. powiedzmy, że
          są to fotony… czyli te fotony miałyby różne energie, no to zgodnie ze standardową
          wiedzą fizyczną oczekiwalibyśmy, że wszystkie fotony nadlecą w tym samym
          czasie, ponieważ poruszają się z prędkością światła i tutaj żadnych opóźnień
          nie ma. Natomiast jeżeli czasoprzestrzeń ma strukturę, a skąd inąd przy
          próbach uzgodnienia czy połączenia naszej wiedzy o mikroświecie z wiedzą
          o makroświecie – czyli kwantowej teorii pola i ogólnej teorii względności..
          czyli mówię tutaj o podejściach do kwantowej grawitacji oczekiwalibyśmy,
          że nasza czasoprzestrzeń – coś co może nie dla każdego jest oczywiste,
          że czasoprzestrzeń to jest obiekt fizyczny. Ale załóżmy, że czasoprzestrzeń
          ma strukturę…
        </p>
        <p class="mt-2">
          nie musi być gładka scena (struktura), być może to jest sieć, być może
          to jest piana.. jest hipoteza którą się określa mianem piany kwantowej
          więc może nasza czasoprzestrzeń jest pianą. Jeżeli taka struktura
          faktycznie faktycznie jest realizowana w rzeczywistości to wtedy ta
          grupa cząstek – te fotony o różnych energiach mogą w różny sposób z tą
          strukturą czasoprzestrzeni oddziaływać, a konkretnie fotony o wyższych
          energiach czyli te z wyższymi częstotliwościami fali
          elektromagnetycznej mogą być bardziej czułe na strukturę
          czasoprzestrzeni niż fotony o mniejszych czystościach czyli o
          mniejszych energiach. To można też zilustrować samochodowo, samochód o
          dużych kołach łatwiej przejedzie po dziurach niż o małych. Jeżeli one
          początkowo mają tę samą prędkość to oczywiście samochód o dużych
          kołach dotrzy bez problemu do celu, a samochód o małych kołach zacznie
          wpadać w dziury i przyjdzie później.
        </p>
        <p class="mt-2">
          I to jest właśnie to czego byśmy poszukiwali w CREDO już zresztą to
          robimy. Jest właśnie eksperyment właśnie z masowym udziałem
          właścicieli smartfonów o nazwie “Quantum Gravity Previewer“ czyli
          podglądacz kwantowej grawitacji, gdzie każdy tak naprawdę smartfon
          przyczynia się do do tego żeby znaleźć grupę zdarzeń która jest
          nienaturalnie zbita, tak mówiąc brzydko “sklastrowana” w czasie.
          Powiedzmy to można łatwo zilustrować, przykładem z jednego urządzenia,
          jeżeli pańskie urządzenie rejestruje cząstki z częstotliwością średnio
          raz na godzinę to może pana zaskoczyć odkrycie powiedzmy pewnego
          poranka pan sobie zdaje sprawę, że zeszłej nocy w ciągu 10 minut
          zaobserwował pan 63 zdarzenia. Teraz pytanie: czy tam jakieś zwierzę
          panu może tam się zbliżyło napromieniowane, czy jakieś inne zjawisko.
          Czy może właśnie jest to zagęszczenie/ nienaturalne zagęszczenie w
          czasie promieni kosmicznych, teraz jeżeli to byłoby takie zagęszczenie
          które trwa ułamek sekundy praktycznie w ogóle nie rozprzestrzenione w
          czasie .. no to wtedy mielibyśmy potwierdzenie tutaj braku
          oddziaływania ze strukturą czasoprzestrzeni. Natomiast rozpiętość w
          czasie może być sygnaturą oddziaływania czyli wtedy należałoby jeszcze
          powiedzieć i potwierdzić czy te cząstki o wyższych energiach, czy te
          cząstki pierwotne o wyższych energiach faktycznie nadleciały później –
          to jest klucz. Jeżeli większa energia przylatuje później przy wspólnym
          starcie to jest nasza sygnatura, to jest to nasze “wow”.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Czy dobrze rozumiem że właśnie po to jest to ekstremalne rozproszenie które
          się w nazwie CREDO pojawia? Że im więcej, im bardziej rozległa jest ta
          struktura projektu, im dalej od siebie są te smartfony które wykrywają
          te zdarzenia tym lepiej można wykrywać korelacje bądź jakieś takie wydarzenia?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Trudno powiedzieć czy lepiej, ale na pewno inaczej to znaczy jeżeli ma
          Pan urządzenia odległe o 10 000 km i pańskie zjawisko jest rozciągłe na
          na 10 000 km, ale nie w taki sposób że jest że jest duża gęstość cząstek
          tylko te cząstki są rozbiegane.. no to oczywiście że jeśli pańskie urządzenie
          ma powierzchnię 10 m kwadratowych to pan nie ogarnie całości więc po to
          jest rozproszenie.. żeby była jak największa baza.
        </p>
        <p class="mt-2">
          Podobny efekt osiąga się przy tych słynnych zdjęciach czarnej dziurze
          potrzebna jest duża baza, duża odległość i teraz oczywiście jeżeli
          mówimy o zdarzeniu które miało miejsce gdzieś tam w głębokim
          wszechświecie – powstały w wyniku tego zdarzenia fotony których kąt
          otwarcia – prawdopodobnie nie lecą równoległe od samego początku tylko
          od razu pod jakimś kątem. Kąt otwarcia mówi też nam o możliwości
          zaobserwowania zdarzenia – to znaczy co najmniej dwóch cząstek z tego
          zespołu dla danej odległości.. jeżeli ten kod jest duży, a odległość
          też znaczna to one po prostu się rozjadą. Jeżeli odległość między
          cząstkami wynosi 100 mln km to Ziemia .. to za mało.. także dlatego
          mówię.. o Marsie.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Aha, to w ogóle jest fantastyczne – bo ja sobie zdałem sprawę, że to co
          się wydarza w atmosferze.. kiedy dobiega do mnie taka cząstka, to jest
          właściwie naturalny zderzacz cząstek, prawda? Coś takiego co robią fizycy
          w CERNie wspominanym tylko w naturze, natomiast no w CERNie dzieje się
          to precyzyjnie w jednym miejscu które jest odbudowywane gigantyczną ilością
          detektorów na a na Ziemi musimy dostarczyć my użytkownicy czy też jak to
          się ładnie nazywa “naukowcy społecznościowi”, prawda?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          RiTak, to jest oczywiście bardzo wdzięczny obiekt badań pojedynczy wielki
          pęk atmosferyczny to faktycznie zderzenie cząstki o gigantycznej energii..
        </p>
        <p class="mt-2">
          <b>MK:</b>
          I am sorry to interrupt, but this noise that our listeners can hear at
          this moment is not being produced by high-energy particles but by planes
          flying over the Institute on their way to the Balice airport.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Yes, we seem to be safe here.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          <i>/laughs/</i>
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Tak, to jest oczywiście bardzo wdzięczny obiekt badań pojedynczy wielki
          pęk atmosferyczny to faktycznie zderzenie cząstki o gigantycznej energii
          . Wielki pęk atmosferyczny sam w sobie nawet bez korelacji z odległymi
          zdarzeniami jest bardzo interesujący i takie pęki się bada od lat 30-stych
          ubiegłego wieku – kiedy to Pierre Auger po raz pierwszy takie zjawisko
          właśnie lokalnych korelacji pomiędzy detektorami zaobserwował. I to tak
          jak Pan powiedział jest zasada zderzenia. Tutaj trudność jest taka, że
          widzimy informacje zdegenerowaną – generacje cząstek po drodze już w jakiś
          sposób tą informacje zamazują – to co mamy w akceleratorach to widzimy
          produkty bardzo szybko po zderzeniu.
        </p>
        <p class="mt-2">
          utaj sytuacja jest inna ale to co robi CREDO to też jest jest
          filozofia zderzania – tylko my wtedy raczej chcielibyśmy myśleć o
          zdarzeniach w głębokim Wszechświecie.. nie tutaj w atmosferze, chociaż
          to też jest dla nas ważne. Proszę sobie wyobrazić właśnie: dawno dawno
          temu miliardy lat temu coś się dzieje we wszechświecie i pan w swoim
          prywatnym smartfonie widzi wysłannika – cząstkę właśnie z tak odległej
          przeszłości – to dla wielu jest bardzo atrakcyjny element naszego
          projektu.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Aż tak bardzo fundamentalne są pytania na które nasze smartfony mogą odpowiedzieć.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          No to jest ten najbardziej fundamentalny.. faktycznie najbardziej fundamentalne
          pytanie.. Co może być bardziej fundamentalnego od fundamentów fundamentów..
          aż się zakręciłem troszeczkę.. no czasoprzestrzeń to jest coś na czym się
          dzieje świat, no niektórzy rozważają nawet scenariusz w którym czasoprzestrzeń
          w ogóle jest wszystkim a czym to tylko jakieś tam zakręcenia tej struktury.
          Ale to też jest też potencjalnie do wykazania właśnie przy pomocy badania
          tych danych które i tak do nas docierają, to jest właśnie szansa na zaobserwowania
          nowych zjawisk fizycznych – to co co bardzo by się marzyło teoretykom.
          Teoretycy bardzo ciężko pracują nad modelowaniem Wszechświata, poszukują
          kwantowej grawitacji ale właśnie każdy gdzie tylko się nie obejrzeć mówi,
          że to co by się bardzo przydało to nowe dane, nowe zjawiska i liczymy na
          to, że CREDO tych nowych danych dostarczy.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Dopowiedzmy jeszcze.. kiedy już te dane smartfonów zostaną przez aplikację
          zarejestrowane co się z nimi dalej dzieje?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Automatycznie są przekazywane do centralnego systemu akwizycji przetwarzania
          i też backupowania danych to centrum jest zarządzane też przez przez partnera
          CREDO, przez Akademickie Centrum Komputerowe CYFRONET AGH i w tymże centrum
          również organizowany jest dostęp do danych. To znaczy według określonej
          procedury naprawde każdy kto tylko zechce podać powód zainteresowania może
          taki dostęp uzyskać i włączyć się aktywnie już na wyższym poziomie w tworzenie
          i rozwijanie projektu CREDO.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Tymczasem nawet samo zbieranie danych, akwizycja danych czy udział mojego
          smartfona może mieć niebagatelne skutki w nauce, bo jeżeli takimi dziedzinami
          badań jak struktura czasoprzestrzeni czy badania nad ciemną materią zajmuję
          się czy też mogą pomóc zajmować się dane z CREDO no to w zasadzie należałoby
          zapytać o ten potencjał Noblowski, a tymczasem tak jak Pan wcześniej mówił
          akwizycja danych jest współautorstwem, co oznacza, że każdy użytkownik
          smartfona może się podpisać, czy też może zostać podpisany (jeśli tego
          chce) pod pracami naukowymi które z takich badań wynikają.. no i może czuć
          się troszeczkę współautorem i posiadaczem potencjalnego Nobla.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Mało tego Panie Michale, nawet jak Pan nie zainstaluje tej aplikacji to
          Pan też może się podpisać, bo Pan pomaga w akwizycji danych poprzez upowszechnianie
          informacji o tym, że do CREDO można i warto iść i jest fajnie się przyłączyć.
        </p>
        <p class="mt-2">
          <b>MK: </b>Więc wszystkich państwo bardzo zachęcam do tego, żeby wejść
          na stronę projektu…
        </p>
        <p class="mt-2">
          <b>PH:</b>
          …credo.science…
        </p>
        <p class="mt-2">
          <b>MK:</b>
          …albo do Google Play, bo aplikacji na iPhony jeszcze nie ma, prawda?
        </p>
        <p class="mt-2">
          <b>PH: </b>Jeszcze nie ma, ale liczymy że nasi koledzy z Australii
          zrobią coś w tym kierunku, bo ostatnio pojawiła się właśnie u tego
          naszego partnera za oceanu taka możliwość, żeby wystąpić o
          finansowanie na budowę aplikacji właśnie na iOS.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          I zainstalować tę aplikację a następnie uruchomić, przy czym tu chyba trzeba
          jeszcze powiedzieć, jedną rzecz, że nieszczęście rozwoju technologii polega
          na tym, że nowsze smartfony mają tzw. hardwarowe filtry szumu, prawda?
          które będą oszukiwać tę aplikację.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          To nie jest do końca jeszcze rozpoznany problem, oczywiście najnowsze aparaty…
          to jest najnowsza technologia i też większe możliwości odszumiania zwykłych
          zdjęć. Natomiast myślę, że tutaj wiele rzeczy da się też softwarowo ulepszyć
          jeżeli chodzi o możliwości detekcji cząstek. W tym momencie w testach jest
          alternatywna aplikacja, która realizuje detekcje inny sposób i innym algorytmie..
          i mogę zdradzić, chyba to nie jest tajemnica że moje urządzenie które obecnie
          używam na oficjalnej aplikacji ma kilka zdarzeń na dobę, a na aplikacji
          alternatywnej już 300 co jest częstotliwością można powiedzieć oczekiwaną
          przy praktycznie 100-stu procentowej efektywności urządzenia.
        </p>
        <p class="mt-2">
          Więc tutaj nie należy się zarzekać nowe urządzenie też jest mile
          widziane w CREDO.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Czyli instalujemy aplikację, podłączamy telefon do ładowarki, zostawiamy
          na noc. Koszt takiego przedsięwzięcia będzie porównywalny z kosztem funkcjonowania
          diody w naszej lodówce, prawda? A satysfakcja z przyczynia się do tak fundamentalnych
          badań naukowych – bezcenna.
        </p>
        <!-- DOkonczyc od slow "Tak I tutaj jeszcze może tylko dodałbym," -->
        <p class="mt-2">
          <b>PH:</b>
          Tak I tutaj jeszcze może tylko dodałbym,że ten pierwszy krok oczywiście
          jest bardzo ważny ale jednak my liczymy na następny. To znaczy faktycznie
          na to, że po tym początkowym zachwycie danymi które rejestrujemy naszymi
          prywatnymi urządzeniami jednak przyjdzie to zaciekawienie: po co te dane?
          do czego one są przydatne? jakie wielkie pytania faktycznie mogą być w
          zasięgu?
        </p>
        <p class="mt-2">
          Pan prosił o listę i nie mamy już czasu ale faktycznie pięć mógłbym
          wymienić od razu. Mówiliśmy: struktura czasoprzestrzeni, tajemnica
          ciemnej materii, ale mamy też potencjał interdyscyplinarny i
          transdyscyplinarny na przykład biorąc pod uwagę geofizykę – jednym z
          wyzwań, które stoi przed CREDO jest poszukiwanie ewentualnych
          korelacji pomiędzy zjawiskami sejsmicznymi a częstotliwością detekcji
          promieniowania kosmicznego i tutaj połączeniem może być pole
          geomagnetyczne.
        </p>
        <p class="mt-2">
          Wiemy skądinąd że pole geomagnetyczne jest tarczą, która chroni nas
          przed promieniowaniem kosmicznym i jeżeli jakieś zmiany w tej tarczy
          zachodzą to widzimy to widzimy to w detektorach promieniowania
          kosmicznego może być tego promieniowania więcej albo mniej i z drugiej
          strony również wiemy, że procesy zachodzące wewnątrz Ziemi wpływają na
          pole geomagnetyczne. I tutaj właśnie pojawia się szansa na
          potwierdzenie obserwowania korelacji, my w zasadzie jesteśmy pewni
          jakościowo, że takie korelacje występują natomiast nie jesteśmy pewni
          czy da się je zaobserwować, czy są na tyle wyraźne, że te dostępne
          techniki detekcji mogą faktycznie pomóc w zaobserwowaniu tych
          korelacji, nie wiemy też czy przypadkiem nie mamy szans na to by
          zwiastun trzęsienia ziemi nie pojawił nam się w detektorach
          promieniowania kosmicznego nawet na godzinę przed samym zdarzeniem
          sejsmicznym i Tutaj mówimy bardzo poważnej kwestii, bo mówimy o
          ratowaniu życia ludzkiego. Jeżeli jest nawet mała – mikroskopijna
          szansa żeby wyniku badań naukowych uratować choćby jedno ludzkie
          życie, to naszym zdaniem jest to moralnym obowiązkiem nas naukowców i
          również wszystkich uczestników projektu żeby to doprowadzić do końca
          to znaczy odpowiedzieć tak albo nie: Jest korelacja do wykrycia albo
          jej nie ma, da się przewidzieć trzęsienie ziemi – jeśli tak to z jaką
          dokładnością. To jest podejście czysto naukowe, natomiast niosące w
          sobie bardzo duży ładunek można powiedzieć społeczny ale też i
          emocjonalny bo przecież wszyscy znamy i choćby z mediów sceny z
          tragicznych trzęsień ziemi gdzie giną setki, tysiące ludzi. Jeżeli
          możemy zrobić coś w tym kierunku żeby ginęło mniej to zróbmy to.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          A jeszcze jeden poziom dodajmy do tego wszystkiego bo przecież kiedy mówimy
          o promieniowaniu jonizującym mówimy o efektach takiego promieniowania na
          ludzkie zdrowie, tutaj i te dane mogą być przydatne – prawda?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Też mogą być przydatne, i też skądinąd wiemy, że promieniowanie jonizujące
          nie pozostawia nas obojętnienie. Wiemy że zdjęcia rentgenowskie mogą być
          wykonywane niekoniecznie codziennie zwłaszcza w przypadku dzieci – gdzie
          układ nerwowy jest niedojrzały, przypadku dzieci nienarodzonych czyli kobiety
          w ciąży muszą bardzo uważać. Więc wiemy, że promieniowanie przenikliwe
          może zostawić ślad w ludzkim organizmie to może być taki dobry albo ślad
          zły, bo mutacje być może wynikające z promieniowania kosmicznego sprawiły
          że dzisiaj tutaj rozmawiamy więc tutaj nie należy z góry tego szufladkować
          jako niebezpieczeństwo ale może jako szansa.
        </p>
        <p class="mt-2">
          I teraz pytanie jakie może postawić CREDO to nie jest pytanie o wpływ
          średniego promieniowania kosmicznego na ludzkie zdrowie – bo to jest w
          zasadzie można powiedzieć dobrze wybadane i wiemy, że to średnie
          promieniowanie kosmiczne jest dużo niższe niż lokalna radioaktywność.
          Więc tutaj w tym sensie możemy czuć się bezpieczni – natomiast to
          czego nie wiemy to możliwy wpływ znalezienia się w pobliżu wielkiego
          rdzenia pęku – czyli tej kaskady cząstek wtórnych zainicjowanych przez
          cząstkę promieniowania kosmicznego o bardzo dużej energii. Gdybyśmy
          mogli identyfikować te pęki w dużych skupiskach ludzkich na przykład w
          Krakowie i gdybyśmy znaleźli grupę chętnych którzy by chcieliby
          wiedzieć (bo to też nie jest oczywiste), którzy chcieliby wiedzieć, że
          znaleźli się… akurat mieli pecha lub szczęście bo znaleźli się w
          pobliżu rdzenia wielkiego pęku… no to droga otwarta do poszukiwania
          korelacji w bazach danych medycznych.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          Czyli od grawitacji kwantowej przez biologię, przez geologię po ciemną
          materie i największe struktury wszechświata dane z CREDO są przydatne właściwie
          na każdym poziomie organizacji wiedzy prawda?
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Nie wiem czy słuchacze, bo to można by dalej ciągnąć, nie wiem czy słuchacze
          lubią fantastykę Naukową, ale jest znany projekt SETI – poszukiwanie pozaziemskich
          cywilizacji i to się robi badając fale radiowe. CREDO jest typowym projektem
          w kategorii tak zwanej kategorii Multi Messenger czy wiele kanałów informacji,
          dla nas to wiele to jest promieniowanie kosmiczne o różnych energiach.
          Więc trudno z góry założyć, że obcy przyjaciele wysyłają do nas wiadomości
          na falach radiowych. Może właśnie w promieniowaniu kosmicznym, tutaj właśnie
          jakieś niezwykłe sekwencje zdarzeń również można sobie wyobrazić, że mopsem
          do nas na balon globalny monitoring może pomóc obcy przyjaciele wysyłają
          do nas wiadomości na falach radiowych Może właśnie z promieniowaniem kosmicznym
          tutaj jakieś niezwykłe sekwencje zdarzeń i również nie ma sobie wyobrazić
          że morsem do nas nadają. I globalny monitoring może wykryć taki sygnał.
        </p>
        <p class="mt-2">
          <b>MK:</b>
          A ja widzę jeszcze jeden pożytek z tego przedsięwzięcia, bo otóż mianowicie
          zainstalowanie takiej aplikacji i odłożenie smartfona w spokoju żeby sobie
          zbierał dane, chociaż na godzinę też może nam wyjść na dobre, zamiast nieustannego
          scrollowania mediów społecznościowych.
        </p>
        <p class="mt-2">
          Państwa i moim gościem właściwie gospodarzem dzisiaj w Instytucie
          Fizyki Jądrowej w Krakowie był pan dr hab. Piotr Homola, bardzo
          serdecznie dziękuję.
        </p>
        <p class="mt-2">
          <b>PH:</b>
          Thank you! Please join our CREDO project!
          <br />
          <b>MK:</b>
          Dziękuję bardzo i zapraszam do udziału.
        </p>
        <p class="mt-8">
          <b
            >Rozmowa powstała w Podkaście Powszechnym – oficjalnym <a
              style="color: #0000ff;"
              href="https://tygodnikpowszechny.pl/podkast"
              target="_blank"
              rel="noopener noreferrer">podkaście “Tygodnika Powszechnego”</a
            >
          </b><b>
            w ramach projektu <a
              style="color: #0000ff;"
              href="https://www.tygodnikpowszechny.pl/wielkiepytania"
              target="_blank"
              rel="noopener noreferrer">“Wielkie Pytania na nowo”</a
            >.</b
          >
          <br /><br />
          <b>Opracowanie transkrypcji: </b>Sławomir Stuglik
        </p>
      </div>
    </div>
  </div>
</section>
