<script>
  import {
    deleteCookie,
    setCookie,
    getBrowserDetails,
  } from "./../../../__AllFunctions.svelte";
  export let user = {};

  function validateForm() {
    // jesli nie chcesz co chwila sie logowac to odkomentuj to ponizej

    const pError = document.getElementById("p-error");
    let komunikaty = [];
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    const code = document.getElementById("code").value;

    if (email.length < 7) komunikaty.push("za krótki login");
    if (name.length < 3)
      komunikaty.push("name musi składać się z conajmniej 3 znaków!");
    if (message.length == 40)
      komunikaty.push("Message musi zawierać minimum 40 znaków");
    if (code != "CR_SS_ML-W@%") komunikaty.push("błędny kod");

    if (komunikaty.length) {
      pError.textContent = komunikaty[0];
      pError.style.display = "block";
      return -1;
      setCookie("send-message", "False", 1);
    } else {
      return 0;
    }
  }

  const prepareData = async () => {
    if (validateForm() == -1) return;

    let obj = getBrowserDetails(browserName, browserVersion);
    let browserName = obj.browserName;
    let browserVersion = obj.browserVersion;

    const data = new FormData();
    data.append("name", user.name);
    data.append("email", user.email);
    data.append("message", user.message);
    data.append("code", user.code);

    let res = await fetch(
      "https://user.credo.science/user-interface/svelte/credo/backend/form-to-email.php",
      {
        method: "POST",
        body: data,
        headers: new Headers(),
      }
    );

    res = await res.json();
    if (res["status"] == 1) {
      setCookie("send-message", "True", 1);
      setCookie("send-message-info", res["message"], 1);
    }
    if (res["status"] == -1) {
      setCookie("send-message", "False", 1);
    }
  };
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-ML Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/about/team/credo-ml"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >About</a
      >
      <a
        href="#/about/team/credo-ml/research"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      >
      <a
        href="#/about/team/credo-ml/people"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <a
        href="#/about/team/credo-ml/activites"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Activites</a
      >
      <a
        href="#/about/team/credo-ml/publications"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href="#/about/team/credo-ml/contact"
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>Contact</strong></a
      >
    </nav>
  </div>
</header>

<section class="text-gray-600 body-font relative">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b>Contact</b>
      </h1>
      <p
        class="lg:w-4/5 mx-auto leading-relaxed text-xl text-justify font-serif"
      >
        Our team is open to cooperate with any scientist or institution that
        would like to join the CREDO (or CREDO-ML) project and research the
        topics we are working on. We are also open to young people who want to
        take science seriously. You can also write your master's and engineering
        thesis under the professional guidance of our team specialists.
        <br /><br />

        If you need more information or would like to join us please contact us
        via our
        <a
          class="text-blue-900 tracking-wider font-serif "
          href="mailto:credo-ml[at]credo.science"
          target="_blank">official email</a
        >, or by writing to our
        <a
          class="text-blue-900 tracking-wider font-serif "
          href="mailto:lukasz.bibrzycki[at]up.krakow.pl"
          target="_blank">Chief Scientific</a
        >.
      </p>
    </div>
  </div>
</section>
