<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO in RMF and on naukawpolsce.pap.pl
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/BBC_Radio4.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >In an interview with BBC Radio 4, Johanna Jarvis talks about the CREDO
      project, dark matter, detections, public involvement and research
      methodology.
      <br />

      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://www.bbc.co.uk/sounds/play/m00017wq?fbclid=IwAR10T1r8VH7h3OJ6brcjEB7DL6SkYQSdo-zWTHsULnxkc_yAqQ4_XfVRZho"
          target="_blank"
          rel="noreferrer"
        >
          Listen to the interview</a
        >
      </span> [the material on CREDO starts at 6:12].
    </span>
  </div>
</div>
