<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO in SONDA2
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/Bez-tytułu-570x350.png" alt="credo img" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000;"
      >Few minutes plus animation on CREDO in Sonda 2, the most popular
      scientific program in Polish prime television, TVP1, with ca. 500,000
      audience. Practical & immediate result: 350 more users of CREDO Detector.
      Thank you TVP1, thank you dr Tomasz Rożek</span
    >
    <br />
    Link to video [click on picture]

    <a
      href="//vod.tvp.pl/video/sonda-2,odc-73-proznia,35698569"
      target="_blank"
      rel="noopener noreferrer"><span style="color: #000000;" /></a
    ><a href="https://vod.tvp.pl/video/sonda-2,odc-73-proznia,35698569"
      ><img
        class="alignnone wp-image-3236"
        src="./images/2-730x350.png"
        alt=""
        width="400"
        height="192"
      /></a
    >
  </div>
</div>
