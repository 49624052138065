<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    A newest peer-reviewed article "for CREDO"
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/posts/mdpi_2022_09.png" alt="images" width="400" />
  </div>
  Both the lack of observation of ultra-high energy (UHE) photons and the limitations
  of the state-of-the-art methodology being applied for their identification motivate
  studies on alternative approaches to the relevant simulations and the related observational
  strategies. One such new approach is proposed in this report and it concerns new
  observables allowing indirect identification of UHE photons through cosmic ray
  phenomena composed of many spatially correlated extensive air showers or primary
  cosmic rays observed at one time. The study is based on simulations of interactions
  of UHE photons with the magnetic field of the Sun using the PRESHOWER program with
  some essential modifications. One of the expected results of such interactions
  is a generation of cosmic ray ensembles (CREs) in the form of very thin and very
  elongated cascades of secondary photons of energies spanning the whole cosmic ray
  energy spectrum.
  <br />
  If you are interested in this topic then read more:
  <a class="text-blue-600" href="https://doi.org/10.3390/universe8100498"
    >https://doi.org/10.3390/universe8100498</a
  >.
</div>
