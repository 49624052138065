<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO in I LO Chrzanów
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <div class="flex flex-wrap m-4 mx-auto">
      <img
        src="./images/posts/1lowchrzanowie/1.jpg"
        alt="images1"
        class=" px-1"
        width="200"
      />
      <img
        src="./images/posts/1lowchrzanowie/2.jpg"
        alt="images2"
        class=" px-1"
        width="200"
      />
      <img
        src="./images/posts/1lowchrzanowie/3.jpg"
        alt="images3"
        class=" px-1"
        width="200"
      />
      <img
        src="./images/posts/1lowchrzanowie/4.jpg"
        alt="images4"
        class=" px-1"
        width="200"
      />
      <img
        src="./images/posts/1lowchrzanowie/5.jpg"
        alt="images5"
        class=" px-1"
        width="200"
      />
    </div>
    CREDO representative Prof. Robert Kaminski (IFJ PAN) gave two lectures to students
    from the S. Staszic High School I in Chrzanow. Thanks to the lecture, the students
    were able to learn about the CREDO project, and they also learned important and
    interesting information about Black Holes, neutron stars, gravitational waves
    and cosmic radiation.<br /><br />
    We thank the school for the invitation and the opportunity to hold the lecture.
  </div>
</div>
