<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>Announcement of the VI edition of "Particle Hunters"</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
        class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/2 lg:w-2/5"
        alt="img"
        src="./images/posts/VIedPH.png"
      />

      For all those who like to work together as a team, compete with other
      teams or like to use the CREDO Detector application, we have news about
      the upcoming 6th edition of the "Particle Hunters" competition. <br />
      <b>The competition will start on November 15</b> this year and will last
      until June 14, 2024. The goal of each edition of the "Particle Hunters"
      contest is to get elementary and high school students (but not only!)
      interested in the physics and astrophysics of cosmic radiation using the
      CREDO Detector app (available on
      <a
        class="text-blue-900 hover:text-blue-500"
        href="https://play.google.com/store/apps/details?id=science.credo.mobiledetector&pli=1"
        >Google Play</a
      >).
      <br />
      Each participant taking part in the competition downloads the app, joins a
      team with other students, family, friends under the supervision of a team coordinator(who
      is usually a teacher from his school).
    </p>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2">
    <p class="mb-8 leading-relaxed font-serif text-2xl text-justify mx-auto">
      The goal of each team is to detect as many cosmic ray particles as
      possible using the CREDO Detector app in two categories Marathon and
      League. <br />
      <b>More about the competition: </b><br />
      <a
        class="text-blue-900 hover:text-blue-500"
        href="https://credo.science/particle_hunters/#/about"
        >https://credo.science/particle_hunters/#/about</a
      ><br />
      <b> Registration of your team (school) can be done at:</b><br />
      <a
        class="text-blue-900 hover:text-blue-500"
        href="https://credo.science/particle_hunters/#/register"
        >https://credo.science/particle_hunters/#/register</a
      > <br />
      Please note that registration for active teams from the 5th edition of the
      competition is not required. <br />

      Everyone is welcome to participate and share information about the contest
      with your loved ones.
    </p>
  </div>
</section>
