<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO in Astronarium
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/posts/astronarium-2023.png" alt="images" width="400" />
    We invite everyone to watch the latest (#155) episode of Astronarium,<br />
    one of the leading Polish YT channels popularizing astro-science (160k
    subscribers),<br />
    was entirely dedicated to CREDO! Link to the video:
    <a class="text-blue-600" href="https://youtu.be/0oC2sFJYMfg">
      https://youtu.be/0oC2sFJYMfg
    </a> <br />
  </div>
</div>
