<script>
  let step = 1;
  let activeButton = "btn1";

  async function goToStep(value) {
    step = value;

    let btn = document.getElementById(activeButton);
    btn.classList.remove("border-red-700");
    btn.classList.remove("text-red-700");
    btn.classList.remove("bg-gray-200");
    // console.log("prev: " + activeButton + ", new: btn" + value);

    btn = document.getElementById("btn" + value);
    btn.classList.add("border-red-700");
    btn.classList.add("text-red-700");
    btn.classList.add("bg-gray-200");
    activeButton = "btn" + value;
  }
</script>

<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <p class="inline-flex">
        <a class="px-1" href="#/detector/tutorial" rel="noopener noreferrer"
          ><img
            class="alignleft"
            src="./images/flags/wb.png"
            alt=""
            width="50"
            height="25"
          /></a
        >
        <a
          class="px-1"
          href="https://ciencia-cidada.blogspot.com/p/blog-page.html"
          rel="noopener noreferrer"
          target="_blank"
          ><img
            class="alignleft"
            src="./images/flags/portugal.png"
            alt=""
            width="40"
            height="25"
          /></a
        >
        <!-- <a class="px-1" href="#/detector/tutorial-fr" rel="noopener noreferrer"
          ><img
            class="alignleft"
            src="./images/flags/france.png"
            alt=""
            width="40"
            height="25"
          /></a
        > -->
      </p>
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900"
      >
        <b>Aplikacja mobilna CREDO Detector</b>
      </h1>
      <h2 class="text-base text-red-700 tracking-widest font-medium title-font">
        Informacje i pobieranie
      </h2>
      <div class="container px-5 mx-auto flex flex-col max-h-max">
        <div class="flex mx-auto flex-wrap mb-5">
          <button
            id="btn1"
            on:click|preventDefault={() => goToStep(1)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t border-red-700 text-red-700 bg-gray-200"
          >
            <img
              alt="info"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/detector/info.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                Wprowadzenie
              </p></strong
            >
          </button>
          <button
            id="btn2"
            on:click|preventDefault={() => goToStep(2)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t"
            ><img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/detector/download.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                Pobieranie aplikacji
              </p></strong
            >
          </button>
          <button
            id="btn3"
            on:click|preventDefault={() => goToStep(3)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t"
          >
            <img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/team/credo-cs/research.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                Podgląd detekcji
              </p></strong
            >
          </button>

          <button
            id="btn4"
            on:click|preventDefault={() => goToStep(4)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium items-center leading-none tracking-wider rounded-t"
          >
            <img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/detector/question.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                Przydatne linki
              </p></strong
            >
          </button>
        </div>
      </div>
      {#if step == 1}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-center font-serif"
        >
          <p class="w-full">
            Poświęć 2 / 4 minuty na obejrzenie filmów wprowadzających do tematu
            o CREDO Detector:
            <br /> <em>(do filmów dodano napisy po polsku)</em>
          </p>
          <div class="container px-5 py-6 mx-auto">
            <center>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/6rHnW--PZQk"
                title="YouTube video player"
                frameborder="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              /></center
            >
            <center class="py-3">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/4riZZANp1X4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              /></center
            >
            <p class="mt-4">
              Możesz również zapoznać się z instrukcjami (<a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="./data/PL-UserGuide.pdf">PL</a
              >
              |
              <a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="./data/ENG-UserGuide.pdf">EN</a
              >),
              <br />lub zapoznać się z poradnikiem jednego z naszych
              użytkowników (<a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="https://mawmarecki.blogspot.com/2022/07/kilka-uwag-do-projektu-credo.html"
                >PL</a
              >)
              <br /><br />
              Jeśli masz pytanie,
              <br />sprawdź
              <a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="#/education/faq">Oficjalny</a
              >, lub
              <a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="https://mawmarecki.blogspot.com/2023/03/pytania-i-odpowiedzi-do-projektu-credo.html"
                >stworzony przez naszego użytkownika</a
              >
              FAQ
              <br /> jeśli nie znajdziesz tam odpowiedzi, napisz do nas maila na
              <br /> contact[at]credo.science
            </p>
          </div>
        </div>
      {:else if step == 2}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-center font-serif"
        >
          <p class="w-full">
            Możesz
            <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=science.credo.mobiledetector"
              >BEZPŁATNIE Pobrać aplikację mobilną CREDO Detector
            </a>
            na Google Play (wymagany Android v. &gt;= 5.0).<br />
            Jeżeli używasz urządzenia które straciło wsparcie Google Play (starsze
            wersje Androida) to możesz
            <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="./data/detector/credo-app-32.apk"
              download
              >pobrać plik APK
            </a>
            naszej aplikacji.<br />
            (Po pobraniu na urządzenie dokonujemy instalacji z poziomu menadżera
            plików)
          </p>
          <div class="flex items-center flex-col m-auto text-center">
            <a
              href="//play.google.com/store/apps/details?id=science.credo.mobiledetector"
              ><img
                class="aligncenter wp-image-3193"
                src="./images/credodetector.png"
                alt="credo-detector google play"
                width="550"
                height="476"
              /></a
            >

            <p class="mt-4">
              lub <a
                class="text-indigo-800 hover:text-blue-600"
                rel="noopener noreferrer"
                target="_blank"
                href="https://apps.apple.com/pl/app/credo-detector/id1598629085"
                >BEZPŁATNIE Pobrać aplikację mobilną CREDO Detector</a
              > na IOS Store (Requires iOS 11.0 or later.)
            </p>
            <a href="https://apps.apple.com/pl/app/credo-detector/id1598629085"
              ><img
                class="aligncenter"
                src="./images/ios.png"
                alt="credo-detector IOS store"
                width="550"
                height="476"
              /></a
            >
          </div>
        </div>
      {:else if step == 3}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-center font-serif"
        >
          <p class="w-full">
            Odwiedź <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="//api.credo.science"
            >
              api.credo.science</a
            >
            i zobacz swoje cząsteczki (wykrycia) / rankingi
          </p>
          <div class="flex items-center flex-col m-auto text-center">
            <a href="https://api.credo.science/web/"
              ><img
                class="size-medium aligncenter"
                src="./images/detector/1.png"
                alt=""
                width="600"
                height="440"
              />
            </a>
          </div>
          <p class="w-full p-3">
            Możesz skorzystać z dodatkowych analiz (~2x w tygodniu)<br />
            gwarantowanych zespołom biorącym udział w konkursie
            <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="https://credo.science/particle_hunters/"
            >
              Particle Hunters</a
            >.
          </p>
          <div class="flex items-center flex-col m-auto text-center">
            <img
              class="size-medium aligncenter"
              src="./images/detector/p_h.jpg"
              alt=""
              width="600"
              height="675"
            />
          </div>
          <!-- <p class="w-full p-3">
            Możesz również wziąć udział w pracach i testach nad <a
              class="text-indigo-800 hover:text-blue-600"
              rel="noopener noreferrer"
              target="_blank"
              href="https://user.credo.science/user-interface/new/"
            >
              user-interface</a
            >,
          </p>
          <div class="flex items-center flex-col m-auto text-center">
            <img
              class="size-medium aligncenter"
              src="./images/detector/ui.png"
              alt=""
              width="600"
              height="390"
            />
          </div>
          <p class="w-full">
            wystarczy skontaktować się z nami poprzez e-mail na adres
            contact[at]credo.science
          </p> -->
        </div>
      {:else if step == 4}
        Pobieranie rocznego zestawu danych:<a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://user.credo.science/user-interface/dataset/"
          >user.credo.science/user-interface/dataset
        </a><br />
        Rejestracja:<a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://api.credo.science/web/register/"
          >api.credo.science/web/register</a
        ><br />
        Zmiana hasła:
        <a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://api.credo.science/web/password_reset/"
          >api.credo.science/web/password_reset</a
        ><br />
        Zmiana nazwy zespołu:<a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://credo.science/particle_hunters/#/change_team"
          >credo.science/particle_hunters/#/change_team</a
        ><br />
        Najbardziej aktywne urządzenia w 2023 r.:<a
          class="text-indigo-800 hover:text-blue-600"
          rel="noopener noreferrer"
          target="_blank"
          href="https://credo.science/#/detector/api"
          >credo.science/#/detector/api</a
        ><br />
      {/if}
    </div>
  </div>
</section>
