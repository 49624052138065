<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    "Noc z Grą"
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/I_XI.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:justify"
      >👉Dziś ostatni dzień gry „Zajrzyj w głąb Wszechświata” 🌠 <br />
      👉Jednak to nie koniec zabawy🎉 <br />
      👉W nocy z 0️⃣1️⃣ września na 0️⃣2️⃣ września w godzinach od 22:00-08:00
      <br />
      👉włączcie aplikację CREDO DETECTOR i łapcie cząstki 💫 Osoby z największą
      ilością detekcji otrzymają drobne nagrody niespodzianki 🎁 <br />
      ❗️Pamiętajcie, że musicie być zarejestrowani w grze❗️
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/zajrzyjwglabwszechswiata/#/register"
          >https://credo.science/zajrzyjwglabwszechswiata/#/register</a
        ></span
      >
      <br />
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://credo.science"
          >CREDO Science</a
        ></span
      >
      i
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://www.ifj.edu.pl/fizykakluczem/"
          >Fizyka Kluczem</a
        ></span
      > ŻYCZĄ POWODZENIA.
    </span>
  </div>
</div>
