<script>
  import Fa from "svelte-fa/src/fa.svelte";
  import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
</script>

<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <span
      ><a href="#/about/MediaAboutUs"
        ><Fa
          class="text-black hover:text-gray-400"
          icon={faArrowAltCircleLeft}
          size="2x"
          secondaryOpacity={1}
        />
      </a></span
    ><a
      class="text-indigo-800 hover:text-blue-600"
      rel="noopener noreferrer"
      href="#/about/materialyprasowe/"
      ><strong>Press Kits (Po polsku)</strong></a
    >
    <a
      class="text-indigo-800 hover:text-blue-600"
      rel="noopener noreferrer"
      href="#/about/presskits/"><strong>Press Kits (English)</strong></a
    >
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-2 text-red-900"
      >
        <b> CREDO - Press Kit </b>
      </h1>

      <h2 class="text-base text-red-700 tracking-widest font-medium title-font">
        (Spanish material)
      </h2>
    </div>
    <div class="lg:w-4/5 mx-auto text-xl text-justify font-serif mb-8">
      <p>
        <li>
          [14-Jun-2023] Una correlación intrigante entre terremotos y radiación
          cósmica (
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="./data/press-kit/IFJ230614a-Una-correlacion-intrigante-entre-terremotos-y-radiacion-cosmica.pdf"
            target="_blank">leer pdf</a
          >,
          <a
            class="text-indigo-800 hover:text-blue-600"
            rel="noopener noreferrer"
            href="#/una_correlacion_intrigante_entre_terremotos_y_radiacion_cosmica"
            target="_blank">Leer en la subpágina</a
          >)
        </li>
      </p>
    </div>
  </div>
</section>
