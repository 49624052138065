<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Wyniki z Gry "zajrzyj w gląb Wszechswiata"
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/mini_wyniki.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:justify"
      >Właśnie zakończyliśmy Grę, wyniki zobaczysz na stronie:
      <span style="color: #0000ff;"
        ><a
          style="color: #0000ff;"
          href="https://credo.science/zajrzyjwglabwszechswiata/#/results"
          >https://credo.science/zajrzyjwglabwszechswiata/#/results</a
        ></span
      >
    </span>
  </div>
</div>
