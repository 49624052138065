<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Career
  </h1>
  <div class="flex flex-col  w-full mb-2">
    <span style="color: #000000;"
      ><a style="color: #000000;" href="#/about/kariera"
        ><img
          class="wp-image-5484 alignright"
          src="./images/flags/polska.png"
          alt=""
          width="40"
          height="25"
        /></a
      ></span
    >
    <h2 style="text-align: center;">
      <strong
        ><span style="color: #000080;"
          >Consider working for CREDO in Kraków!
        </span></strong
      >
    </h2>
    <p style="text-align: center;">
      <span style="color: #000000;"
        ><strong>A postdoc position</strong> at the
        <span style="color: #000080;"
          ><a style="color: #000080;" href="https://www.ifj.edu.pl/index.php"
            >Instytut Fizyki Jądrowej PAN</a
          >
          (<a style="color: #000080;" href="https://www.ifj.edu.pl/en/index.php"
            >Institute of Nuclear Physics Polish Academy of Sciences</a
          >)</span
        >, Kraków, Poland,
        <strong
          >under the supervision of the CREDO Project Coordinator <span
            style="color: #000080;"
            ><a style="color: #000080;" href="https://credo.science/homola/"
              >Piotr Homola</a
            ></span
          ></strong
        >, can be requested via the PASIFIC - Postdoctoral Fellowships program
        <em>
          - see the details below. For questions concerning the CREDO group and
          activities at INP PAS please contact us at contact@credo.science.</em
        ></span
      >
    </p>
    &nbsp;

    <span style="color: #000000;"
      >The Office of Scientific Excellence of the Polish Academy of Sciences
      informs about the launch of the PASIFIC programme co-financed by the
      European Union under the MSCA COFUND. The aim of the programme is to
      enable 50 scientists to spend two years in institutes of the Polish
      Academy of Sciences.</span
    >
    <span style="color: #000000;"
      ><strong
        >The application is open to scientists* at postdoctoral level, of any
        nationality and representing any area of research.</strong
      ></span
    >
    <span style="color: #000000;">PASIFIC program offers:</span>
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >a) financing the implementation of an individual research project and
        support in creating a long-term career development plan; b)
        participation in scientific and supplementary training courses at host
        institutes of the Polish Academy of Sciences; c) a wide range of
        training courses, trainings and workshops, concerning, among others, the
        possibilities of financing research in Poland and Europe, writing
        applications for research grants, intellectual property rights and
        project management; d) possibility of a maximum 6-month internship in
        the non-academic sector. e) Scholarship holders will be granted <strong
          >a monthly salary of EUR 2 500</strong
        >
        and if you decide to move with your family there will be an extra financial
        support - check the details here:
        <span style="color: #000080;"
          ><strong
            ><a style="color: #000080;" href="https://pasific.pan.pl/"
              >pasific.pan.pl</a
            ></strong
          ></span
        >. In addition, the PASIFIC programme will provide researchers with
        <strong>a research budget of up to EUR 93 000 per project.</strong>
        <strong>Recruitment</strong> will be carried out through two competitions:</span
      >
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >I CONTRACT - <strong>from 15 March 2021 to 30 June 2021</strong></span
      >
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >II CONTRACT -<strong>
          from 15 September 2021 to 31 December 2021</strong
        ></span
      >
    </p>
    <p style="text-align: justify;">
      <span style="color: #000000;"
        >More information about the PASIFIC project can be found <a
          style="color: #000000;"
          href="//institution.pan.pl/index.php/pasific"
          target="_blank"
          rel="noopener noreferrer"><strong>on the English website</strong></a
        > of the Polish Academy of Sciences.</span
      >
    </p>
    <span style="color: #000000;"
      >Interested? Register for a webinar on how to apply:</span
    >
    <span style="color: #000080;"
      ><strong
        ><a
          style="color: #000080;"
          href="https://pasific.pan.pl/webinar-for-pasific-call-1-applicants"
          >pasific.pan.pl/webinar-for-pasific-call-1-applicants</a
        ></strong
      ></span
    >
    <span style="color: #000000;"
      >(15 Apr 2021, 10.30-11.00 Warsaw time). Not interested yourself? Tell the
      friends!</span
    >
    <div style="text-align: justify;">
      * Interested applicants must comply with the MSCA mobility principle: they
      may not live or perform their main activity (work, studies, etc.) in
      Poland for a period longer than 12 months within the three years
      immediately preceding the application deadline.
    </div>
  </div>
</div>
