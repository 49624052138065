<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Sensors: CNN-Based Classifier as an Offline Trigger for the CREDO Experiment
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/sensor-2021-07.png"
      alt="sensor-2021-07.png"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >A publication on detection analyses from credo applications written by
      our CREDO-ML team is now available. We invite you to read it!<br />
      Sensors 2021, 21(14), 4804;
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://doi.org/10.3390/s21144804"
          >doi.org/10.3390/s21144804</a
        ></span
      ></span
    >
  </div>
</div>
