<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO Anniversary Symposium 2017
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="./images/cosmicrays2-570x350.jpg"
      alt="cosmicrays2-570x350.jpg"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      ><div style="text-align: justify;">
        <span style="color: #000000;"
          >The <strong
            >1st Anniversary Symposium and Collaboration Meeting</strong
          >
          of the
          <strong>Cosmic-Ray Extremely Distributed Observatory (CREDO)</strong>
          collaboration will be held at the Institute of Nuclear Physics PAN, Krakow,
          Poland on <strong>30th and 31st August, 2017</strong>. The conference
          is dedicated to <strong>ensembles of cosmic rays</strong>: yet unseen
          messengers of the Universe.</span
        >
      </div>
      <div style="text-align: justify;"><span style="color: #000000;" /></div>
      <div style="text-align: justify;">
        <span style="color: #000000;"
          >What is an ensemble of cosmic rays? Consider the state-of-the-art
          cosmic-ray or gamma ray research focused on the detection and study of
          single events; but extend this model to encompass potential
          correlations between arrival times at spatially separated detectors.
          These correlated events form an ensemble: the as yet unseen phenomenon
          the CREDO Collaboration focused on. Such a phenomenon might only be
          detectable by a global network of cosmic-ray detectors and its
          fingerprint could enable event-by-event identification which would
          provide an insight to the physics at the highest energies known.</span
        >
      </div>
      <div style="text-align: justify;"><span style="color: #000000;" /></div>
      <div style="text-align: justify;">
        <span style="color: #000000;"
          >As the individual components of an ensemble of cosmic rays may span
          the whole known cosmic-ray energy spectrum an efficient detection
          strategy relies upon a global effort from the astroparticle physics
          community. That is why we invite everybody: you are welcome to
          contribute or simply observe the channel being opened right now by
          CREDO. The CREDO Anniversary Symposium is an opportunity to learn
          about the science case, status and vision of this global cosmic-ray
          mission, you are then very welcome to stay with us for the second day,
          the Collaboration Meeting, where we will discuss our initial
          results and the steps yet to be taken on the road map towards our
          goals.</span
        >
      </div>
      <div style="text-align: justify;"><span style="color: #000000;" /></div>
      <div style="text-align: justify;">
        <span style="color: #000000;"
          >The <strong>1st Anniversary Symposium (30th August)</strong> is open
          to everybody, while the
          <strong>Collaboration Meeting (31st August)</strong> is dedicated to those
          who already contribute or are considering directly contributing to CREDO.
          To attend the Collaboration Meeting you are invited to join the CREDO Collaboration
          by subscribing to the general mailing list (link available through the
          organizers) by 31th August 2017.</span
        >
      </div>
      <div style="text-align: justify;"><span style="color: #000000;" /></div>
      <div style="text-align: center;">
        <span style="color: #000000;"
          ><strong>Registration and details</strong>: [<span
            style="color:  #0000ff;"
            ><a
              style="color: #0000ff;"
              href="https://indico.ifj.edu.pl/indico/event/182/overview"
              target="_blank"
              rel="noopener noreferrer">click here</a
            ></span
          >]</span
        >
      </div>
      <div style="text-align: justify;" />
      <div style="text-align: center;">
        <span style="color: #000000;"
          ><strong>Conference poster </strong>(click the icon)<strong>:</strong
          ></span
        >
      </div>
      <div style="text-align: center;" />
      <div style="text-align: center;">
        <a
          href="https://indico.ifj.edu.pl/indico/event/182/material/poster/0.pdf"
          target="_blank"
          rel="noopener noreferrer"
          ><img
            class="aligncenter wp-image-1987 size-medium"
            src="./images/plakat2x-212x300.png"
            alt="plakat2x-212x300.png"
            width="212"
            height="300"
          /></a
        >
      </div>
    </span>
  </div>
</div>
