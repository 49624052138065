<script>
  import {
    descriptionPlan,
    descriptionHeader,
    descriptionPlanTable,
  } from "./utils.js";
  import { setCookie, getCookie } from "./../../__AllFunctions.svelte";

  let lang = getCookie("lang") != null ? getCookie("lang") : "pl";
  let langCode = lang === "eng" ? 0 : 1;

  async function reloads() {
    lang = lang === "eng" ? "pl" : "eng";
    langCode = lang === "eng" ? 0 : 1;
    setCookie("lang", lang, 1);
  }

  let schedule = [
    {
      day: "Monday 31.03 2025",
      sessions: [
        {
          personInCharge: "Robert Kamiński",
          room: "4402/0108",
          titleTopic:
            "The most spectacular astrophysical phenomena, the CREDO project",
          hours: "~10:00 - 11:00",
          files: [],
        },
        {
          personInCharge: "",
          room: "4402",
          titleTopic: "Meeting with the director",
          hours: "10:30 - 11:00",
          files: [],
        },
        {
          personInCharge: "Anna Czerny",
          room: "IFJ",
          titleTopic: "Visiting the institute",
          hours: "~11:30 - 15:30",
          files: [],
        },
      ],
    },
    {
      day: "Tuesday 01.04 2025",
      sessions: [
        {
          personInCharge: "Noemi Zabari",
          room: "4402",
          titleTopic: "About Astroteq and startup",
          hours: "09:00 - 11:00",
          files: [],
        },
        {
          personInCharge: "Robert Kamiński",
          room: "4402",
          titleTopic:
            "The most spectacular astrophysical phenomena, the CREDO project",
          hours: "11:00 - 12:00",
          files: [],
        },
        {
          personInCharge: "Paolo Tealdi",
          room: "4402",
          titleTopic: "Blebricks and detector from Mondovi",
          hours: "13:00 - 15:00",
          files: [],
        },
      ],
    },
    {
      day: "Wednesday 02.04 2025",
      sessions: [
        {
          personInCharge: "Konrad Kopański, Wojciech Noga",
          room: "4003 (SC Room)",
          titleTopic: "Workshop on detector electronics and others",
          hours: "09:00 - 15:00",
          files: [],
        },
      ],
    },
    {
      day: "Thursday 03.04 2025",
      sessions: [
        {
          personInCharge: "Bartosz Izydorczyk",
          room: "4003 (SC Room)",
          titleTopic:
            "Introduction to Python and analysis of the data collected by CREDO; lectures and workshops",
          hours: "09:00 - 15:00",
          files: ["https://github.com/Bart-Iz/Python_workshop"],
        },
      ],
    },
    {
      day: "Friday 04.04 2025",
      sessions: [
        {
          personInCharge: "Bartosz Izydorczyk",
          room: "DS Room",
          titleTopic: "Analysis of the data collected by CREDO; workshops (6h)",
          hours: "11:00 - 17:00",
          files: ["https://github.com/Bart-Iz/Python_workshop"],
        },
      ],
    },
    {
      day: "Monday 07.04 2025",
      sessions: [
        {
          personInCharge: "Jerzy Pryga",
          room: "4402",
          titleTopic: "Cosmic Ray Detector - Theory and Demonstrations",
          hours: "09:00 - 12:00",
          files: [],
        },
        {
          personInCharge: "Sławomir Stuglik",
          room: "4402",
          titleTopic:
            "website CREDO, Particle Hunters, Detector Advacam - demonstrations",
          hours: "12:00 - 13:00",
          files: [],
        },
        {
          personInCharge: "Jerzy Mietelski",
          room: "4402",
          titleTopic:
            "Natural Radioactivity, Measurement and Detection of Radiation",
          hours: "13:00 - 16:00",
          files: [],
        },
      ],
    },
    {
      day: "Tuesday 08.04 2025",
      sessions: [
        {
          personInCharge: "Piotr Homola",
          room: "4402/0108",
          titleTopic:
            "Correlations between cosmic ray recordings and tectonic activity, workshops",
          hours: "09:00 - 15:00",
          files: [],
        },
      ],
    },
    {
      day: "Wednesday 09.04 2025",
      sessions: [
        {
          personInCharge: "Piotr Homola",
          room: "4402/0108",
          titleTopic:
            "Correlations between cosmic ray recordings and tectonic activity, locally and globally; theoretical introduction and workshops (6h)",
          hours: "09:00 - 15:00",
          files: [],
        },
      ],
    },
    {
      day: "Thursday 10.04 2025",
      sessions: [
        {
          personInCharge: "Robert Kamiński",
          room: "UJ",
          titleTopic:
            "Visiting the Jagiellonian University Astronomical Observatory",
          hours: "10:00 - 12:00",
          files: [],
        },
        {
          personInCharge: "David Castillo",
          room: "4402/0108",
          titleTopic: "Lectures about multi-messenger astronomy.",
          hours: "14:00 - 16:00",
          files: [],
        },
      ],
    },
    {
      day: "Friday 11.04",
      sessions: [
        {
          personInCharge: "Bartosz Izydorczyk",
          room: "4402/0108",
          titleTopic:
            "Python – exercises and analysis of the data collected by CREDO; lectures and workshops",
          hours: "09:00 - 15:00",
          files: [],
        },
      ],
    },
  ];

  // Zmienna do przechowywania poprzedniego dnia
  import { onMount } from "svelte";
  let clientWidth = 0;

  onMount(() => {
    clientWidth = window.innerWidth;
    window.addEventListener("resize", () => (clientWidth = window.innerWidth));
  });
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong
        ><span class="ml-3 text-xl">CREDO Science Camp – Italy@Kraków 2025</span
        ></strong
      >
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        class="mr-5 uppercase tracking-wider font-serif hover:text-blue-700"
        href="#/italy2025">Information</a
      >
      <a
        href="#/italy2025/plan"
        class="text-blue-800 mr-5 uppercase tracking-wider font-serif hover:text-blue-700"
      >
        <strong>Plan of events</strong>
      </a>
    </nav>
  </div>
</header>

<section class="text-gray-800 body-font w-4/5 mx-auto my-5">
  <div class="flex flex-col text-center w-full">
    <h1
      class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-blue-900"
    >
      <b> Schedule</b>
    </h1>
  </div>
  <p class="text-center">
    Room Legend:
    <b>SC Room</b> → Scientific Council Room;
    <b>DS Room</b> → Doctoral Study Room
  </p>
  {#if clientWidth > 768}
    <!-- Wersja desktopowa -->
    <table class="w-full border-collapse">
      <thead class="font-serif uppercase bg-blue-100">
        <tr>
          <th class="border border-blue-500 px-4 py-2">Hours[CEST]</th>
          <th class="border border-blue-500 px-4 py-2">Room</th>
          <th class="border border-blue-500 px-4 py-2">Person</th>
          <th class="border border-blue-500 px-4 py-2">Title/Topic</th>
          <th class="border border-blue-500 px-4 py-2">Materials</th>
        </tr>
      </thead>
      {#each schedule as { day, sessions }}
        <tbody>
          <tr class="bg-blue-100 text-center">
            <td
              colspan="5"
              class="px-4 py-2 font-bold border-t-4 border-b-4 border-blue-500 uppercase"
              >{day}</td
            >
          </tr>
          {#each sessions as { hours, room, personInCharge, titleTopic, files }}
            <tr class="hover:bg-blue-200">
              <td class="border border-blue-500 px-4 py-2">{hours}</td>
              <td class="border border-blue-500 px-4 py-2">{room}</td>
              <td class="border border-blue-500 px-4 py-2">{personInCharge}</td>
              <td class="border border-blue-500 px-4 py-2">{titleTopic}</td>
              <td class="border border-blue-500 px-4 py-2 text-center">
                {#if files.length > 0}
                  {#each files as file}
                    <a
                      href={file}
                      target="_blank"
                      class="text-blue-500 hover:underline">Download</a
                    >
                  {/each}
                {:else}
                  -----------
                {/if}
              </td>
            </tr>
          {/each}
        </tbody>
      {/each}
    </table>
  {:else}
    <!-- Wersja mobilna -->
    {#each schedule as { day, sessions }}
      <div
        class="bg-blue-100 text-center p-2 font-bold border-t-4 border-b-4 border-blue-500 uppercase"
      >
        {day}
      </div>
      {#each sessions as { hours, room, personInCharge, titleTopic, files }}
        <div class="w-full p-2 border-b border-blue-300 bg-gray-50 mt-2">
          <p><strong>Hours:</strong> {hours}</p>
          <p><strong>Room:</strong> {room}</p>
          <p><strong>Person:</strong> {personInCharge}</p>
          <p><strong>Topic:</strong> {titleTopic}</p>
          <p>
            <strong>Materials:</strong>
            {#if files.length > 0}
              {#each files as file}
                <a
                  href={file}
                  target="_blank"
                  class="text-blue-500 hover:underline">Download</a
                >
              {/each}
            {:else}
              -----------
            {/if}
          </p>
        </div>
      {/each}
    {/each}
  {/if}
</section>

<p class="text-center">Last update: 2025-04-02 14:13</p>
