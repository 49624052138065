<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO Inaguration
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/credo.png"
      alt="credo.png"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >CREDO Inauguration! The official history of the Cosmic-Ray Extremely
      Distributed Observatory (CREDO) begun. The kick-off meeting of the CREDO
      Collaboration was held in Krakow, Poland, on 30th August 2016, at the
      Institute of Nuclear Physics Polish Academy of Sciences, with around 50
      attendees from 9 countries. The event was readily covered by media , and
      all the talks are available for public. The CREDO science case is focused
      on indirect search for Super Heavy Dark Matter through the observation of
      very large electromagnetic cascades initiated above the Earth atmosphere.</span
    >
  </div>
</div>
