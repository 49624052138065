<script>
  let home_path = "#/about/team/credo-cs/";

  function pathes(subpage) {
    return home_path + subpage;
  }

  let step = 1;
  let activeButton = "btn1";

  async function goToStep(value) {
    step = value;

    let btn = document.getElementById(activeButton);
    btn.classList.remove("border-red-700");
    btn.classList.remove("text-red-700");
    btn.classList.remove("bg-black");
    // console.log("prev: " + activeButton + ", new: btn" + value);

    btn = document.getElementById("btn" + value);
    btn.classList.add("border-red-700");
    btn.classList.add("text-red-700");
    btn.classList.add("bg-black");
    activeButton = "btn" + value;
  }
</script>

<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-CS Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href={pathes("")}
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>About</strong></a
      >
      <a
        href={pathes("research")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      >
      <a
        href={pathes("people")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <!-- <a
        href={pathes("activites")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Activites</a
      > -->
      <a
        href={pathes("publications")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href={pathes("contact")}
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>

<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b>Cosmo-Seismic Task</b>
      </h1>
      <p
        class="lg:w-4/5 mx-auto leading-relaxed text-xl text-justify font-serif mb-10"
      >
        <b class="text-center"
          >The global scale cosmic ray research has recently been focused on the
          search for so-called cosmo-seismic correlations that are correlations
          between cosmic ray rates and seismic activity.</b
        >
      </p>

      <div class="container px-5 mx-auto flex flex-col max-h-max">
        <div class="flex mx-auto flex-wrap mb-5">
          <button
            id="btn1"
            on:click|preventDefault={() => goToStep(1)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium  items-center leading-none  tracking-wider rounded-t border-red-700 text-red-700 bg-black"
          >
            <img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20  hover:opacity-70"
              src="./images/team/credo-cs/concept.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif ">
                our concept
              </p></strong
            >
          </button>
          <button
            id="btn2"
            on:click|preventDefault={() => goToStep(2)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium  items-center leading-none  tracking-wider rounded-t"
            ><img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/team/credo-cs/inspired.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif ">
                what inspired us
              </p></strong
            >
          </button>
          <button
            id="btn3"
            on:click|preventDefault={() => goToStep(3)}
            class="sm:px-4 py-3 w-5/6 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium  items-center leading-none  tracking-wider rounded-t"
          >
            <img
              alt="testimonial"
              class="w-40 h-30 object-cover object-center rounded-2 inline-block border-20 hover:opacity-70"
              src="./images/team/credo-cs/research.png"
            />
            <strong
              ><p class="mt-2 uppercase tracking-wider font-serif">
                research performed
              </p></strong
            >
          </button>
        </div>
      </div>
      {#if step == 1}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
        >
          <p>
            Mass movements inside the Earth that lead to earthquakes
            simultaneously cause temporary changes in gravitational and
            geomagnetic fields. These changes are propagating at the speed of
            light and can potentially be observed on the surface of the planet
            earlier than earthquakes. It can be possible, for example, by
            registering changes in the frequency of detection of secondary
            cosmic radiation, which is very sensitive to the geomagnetic
            conditions.
          </p>
        </div>
      {:else if step == 2}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
        >
          <p>
            The inspiration for the investigation on the possible earthquake
            precursor effects in cosmic ray data originates in the research
            undertaken after the devastating M 8.8 earthquake in Chile, in 2010.
            The most intriguing results include ionospheric anomalies above the
            earthquake region, geomagnetic fluctuations, and unexplained
            variations of secondary cosmic radiation detection rates. The cosmic
            ray anomaly, was recorded by the Pierre Auger Observatory, the
            largest cosmic ray infrastructure dedicated mostly to the research
            related to ultra-high energy cosmic rays but also offering
            interdisciplinary opportunities like the space weather studies with
            their scaler data.
          </p>
        </div>
      {:else if step == 3}
        <div
          class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif"
        >
          <p>
            Report on an observation of the correlations between variation of
            locally measured secondary cosmic ray fluxes and global seismic
            activity:<br />
            <a
              class="text-blue-900 tracking-wider font-serif "
              href="https://arxiv.org/ftp/arxiv/papers/2204/2204.12310.pdf"
              target="_blank"
              rel="noopener noreferrer">ArXiv: 2204/2204.12310.pdf</a
            >

            <br />
            Parallel research is performed by the company Astrotectonic being a partner
            of CREDO:
            <a
              class="text-blue-900 tracking-wider font-serif "
              href="https://astrotectonic.com/"
              target="_blank"
              rel="noopener noreferrer">https://astrotectonic.com</a
            >
          </p>
        </div>
      {/if}
    </div>
  </div>
</section>
