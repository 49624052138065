<header class="text-black body-font uppercase">
  <div
    class="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center bg-gray-100"
  >
    <p
      class="flex title-font uppercase tracking-wider font-serif items-center text-black mb-4 md:mb-0"
    >
      <strong><span class="ml-3 text-xl">CREDO-ML Task</span></strong>
    </p>
    <nav class="md:ml-auto flex flex-wrap items-center text-lg justify-center">
      <a
        href="#/about/team/credo-ml"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >About</a
      >
      <a
        href="#/about/team/credo-ml/research"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Research</a
      >
      <a
        href="#/about/team/credo-ml/people"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >People</a
      >
      <a
        href="#/about/team/credo-ml/activites"
        class="text-red-800 mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        ><strong>Activites</strong></a
      >
      <a
        href="#/about/team/credo-ml/publications"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Publications</a
      >
      <a
        href="#/about/team/credo-ml/contact"
        class="mr-5 uppercase tracking-wider font-serif hover:text-red-700"
        >Contact</a
      >
    </nav>
  </div>
</header>
<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900"
      >
        <b>Activites</b>
      </h1>
      <p
        class="mx-auto leading-relaxed tracking-wider text-base font-serif mb-4"
      >
        A calendar of the team's activities at various events.
      </p>
    </div>
    <div class="w-4/5 mx-auto -my-8 divide-y-2 divide-gray-100">
      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">1stCVW2024</span>
          <span class="mt-1 text-gray-500 text-sm">15-17 Jan 2024</span>
        </div>
        <div class="md:flex-grow">
          <h2
            class="text-gray-900 text-2xl title-font tracking-wider font-serif mb-2"
          >
            Machine Learning @CREDO
          </h2>
          <p class="leading-relaxed text-xl tracking-wider font-serif">
            At the First CREDO Visegrad Workshop 2024, the CREDO-ML Leader
            (Łukasz Bibrzycki) presented an activity report of the entire
            team/task. Recent, ongoing and planned CREDO ML research activities
            were discussed.
            <br />
            <a
              class="text-red-800 inline-flex items-center tracking-wider font-serif"
              href="https://indico.ifj.edu.pl/event/1176/contributions/4277/"
              target="_blank"
              rel="noopener noreferrer"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <a
              href="https://youtu.be/G1L0yaIYSZs"
              target="_blank"
              rel="noopener noreferrer"
              class="text-red-600"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                class="bi bi-youtube"
                viewBox="0 0 17 17"
              >
                <path
                  d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"
                />
              </svg></a
            >
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">IJCRS 2023</span>
          <span class="mt-1 text-gray-500 text-sm">05 Oct 2023</span>
        </div>
        <div class="md:flex-grow">
          <h2
            class="text-gray-900 text-2xl title-font tracking-wider font-serif mb-2"
          >
            Searching of potentially anomalous signals in cosmic-ray particle
            tracks images using rough k-means clustering combined with
            eigendecomposition-derived embedding.
          </h2>
          <p class="leading-relaxed text-xl tracking-wider font-serif">
            CREDO-ML representatives (Marcin Piekarczyk and Tomasz Hachaj) and
            Jarosław Wąs presented a new method for finding anomalies in a set
            of cosmic ray traces using clustering.
            <br />
            <a
              class="text-red-800 inline-flex items-center tracking-wider font-serif"
              href="https://ecai2023.eu/programm"
              target="_blank"
              rel="noopener noreferrer"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">ECAI 2023</span>
          <span class="mt-1 text-gray-500 text-sm">02 Oct 2023</span>
        </div>
        <div class="md:flex-grow">
          <h2
            class="text-gray-900 text-2xl title-font tracking-wider font-serif mb-2"
          >
            Cosmic-Ray Extremely Distributed Observatory - application of AI in
            high-energy particles detection, filtering, recognition and analysis
          </h2>
          <p class="leading-relaxed text-xl tracking-wider font-serif">
            At the ECAI conference, as part of a special session, CREDO-ML
            representatives (Marcin Piekarczyk and Tomasz Hachaj) presented the
            results of detecting traces of their filtration and analysis using
            AI.
            <br />
            <a
              class="text-red-800 inline-flex items-center tracking-wider font-serif"
              href="https://ecai2023.eu/programm"
              target="_blank"
              rel="noopener noreferrer"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">WORKSHOP</span>
          <span class="mt-1 text-gray-500 text-sm">24 Nov 2021</span>
        </div>
        <div class="md:flex-grow">
          <h2
            class="text-gray-900 text-2xl title-font tracking-wider font-serif mb-2"
          >
            Micro-Workshop on Machine Learning aided shower detection
          </h2>
          <p class="leading-relaxed text-xl tracking-wider font-serif">
            CREDO-ML hosted a one-day workshop for anyone interested in the
            CREDO project. The meeting discussed detection analysis topics and
            the current work of the team and other speakers.
            <br />
            <a
              class="text-red-800 inline-flex items-center tracking-wider font-serif"
              href="https://indico.ifj.edu.pl/event/740/"
              target="_blank"
              rel="noopener noreferrer"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
          </p>
        </div>
      </div>

      <div class="py-8 flex flex-wrap md:flex-nowrap">
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span class="font-semibold title-font text-gray-700">ICRC2021</span>
          <span class="mt-1 text-gray-500 text-sm">14 Jun 2021</span>
        </div>
        <div class="md:flex-grow">
          <h2
            class="text-gray-900 text-2xl title-font tracking-wider font-serif mb-2"
          >
            Machine learning aided noise filtration and signal classification
            for CREDO experiment
          </h2>
          <p class="leading-relaxed text-xl tracking-wider font-serif">
            CREDO-ML Task Leader: Lukasz Bibrzycki presented current results and
            work on detection analysis at the most important conference in the
            Cosmic Ray world - ICRC 2021.<br />
            <a
              href="https://doi.org/10.22323/1.395.0227"
              target="_blank"
              rel="noopener noreferrer"
              class="text-red-800 inline-flex items-center tracking-wider font-serif"
              >Read More
              <svg
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
