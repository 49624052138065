<script>
  // ustaw wszystkie dane jakie nas interesuja

  let username;
  let display_name;
  let password;
  let email;

  let codeError = 0;
  let okMessage = "";
  let errMessage = "";

  async function wyslijZgloszenie() {
    let zgloszenieRoute =
      "https://user.credo.science/user-interface/svelte/lowcy/backend/delete_account.php";

    if (display_name && email && password && username) {
      codeError = 0;

      const data = new FormData();
      data.append("display_name", display_name);
      data.append("email", email);
      data.append("password", password);
      data.append("username", username);

      let res = await fetch(zgloszenieRoute, {
        method: "POST",
        body: data,
        headers: new Headers(),
      });

      res = await res.json();
      // console.log(res["message"]);

      let box = document.getElementById("okmessage");

      if (res["status"] == 1) {
        codeError = 1;
        okMessage = res["result"]; //"Zmiana zespołu przebiegła pomyślnie";
      } else {
        codeError = -1;
        errMessage = res["message"];
      }

      setTimeout(function () {
        codeError = 0;
      }, 1 * 50000);
    } else {
      console.log("nie jest OK2");
      codeError = -1;

      if (!display_name) {
        errMessage = "Uzupełnij pole: display_name!";
        return;
      }
      if (!email) {
        errMessage = "Uzupełnij pole: email!";
        return;
      }
    }
  }
</script>

<section class="text-gray-600 body-font">
  <div class="flex flex-col text-center w-full mb-4 my-4">
    {#if codeError == -1}
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-red-500 msg">
        {errMessage}
      </p>
    {:else if codeError == 1}
      <p
        id="okmessage"
        class="lg:w-2/3 mx-auto leading-relaxed text-base text-green-500 msg"
      >
        {okMessage}
      </p>
    {:else}
      <p />
    {/if}
  </div>
  <div class="container px-5 py-5 mx-auto flex flex-wrap items-center">
    <h2
      class="text-gray-800 text-2xl font-medium title-font mb-5 text-center mx-auto"
    >
      Delete CREDO Detector App account
    </h2>
    <div
      class="xl:w-1/3 lg:w-1/2 md:w-3/5 bg-gray-500 bg-opacity-50 rounded-lg p-8 flex flex-col md:mx-auto w-full mt-10 md:mt-0"
    >
      <div class="relative mb-4">
        <label for="username" class="leading-7 text-sm uppercase text-gray-900"
          >username</label
        >
        <input
          type="text"
          id="username"
          bind:value={username}
          placeholder="username"
          minlength="3"
          autocomplete="off"
          class="w-full bg-gray-600 bg-opacity-20 focus:bg-transparent focus:ring-2 focus:ring-yellow-900 rounded border border-gray-600 focus:border-yellow-500 text-base outline-none text-yellow-600 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div class="relative mb-4">
        <label for="password" class="leading-7 text-sm uppercase text-gray-900"
          >password</label
        >
        <input
          type="password"
          id="password"
          bind:value={password}
          placeholder="password"
          minlength="3"
          autocomplete="off"
          class="w-full bg-gray-600 bg-opacity-20 focus:bg-transparent focus:ring-2 focus:ring-yellow-900 rounded border border-gray-600 focus:border-yellow-500 text-base outline-none text-yellow-600 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div class="relative mb-4">
        <label
          for="display_name"
          class="leading-7 text-sm uppercase text-gray-900">display_name</label
        >
        <input
          type="text"
          id="display_name"
          bind:value={display_name}
          placeholder="display_name"
          minlength="3"
          autocomplete="off"
          class="w-full bg-gray-600 bg-opacity-20 focus:bg-transparent focus:ring-2 focus:ring-yellow-900 rounded border border-gray-600 focus:border-yellow-500 text-base outline-none text-yellow-600 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div class="relative mb-12">
        <label for="email" class="leading-7 text-sm uppercase text-gray-900"
          >Email (given at registration)</label
        >
        <input
          type="email"
          id="email"
          bind:value={email}
          placeholder="email"
          minlength="1"
          autocomplete="off"
          class="w-full bg-gray-600 bg-opacity-20 focus:bg-transparent focus:ring-2 focus:ring-yellow-900 rounded border border-gray-600 focus:border-yellow-500 text-base outline-none text-yellow-600 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <button
        on:click={wyslijZgloszenie}
        class="text-white bg-yellow-500 border-0 py-2 px-8 focus:outline-none hover:bg-yellow-600 rounded text-lg"
        >Delete account</button
      >
    </div>
    <div />
  </div>
</section>
