<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>CREDO Summer Camp</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2 mb-5">
    <p class="mb-2 leading-relaxed font-serif text-2xl text-justify mx-auto">
      <img
        class="object-cover object-center rounded float-right ml-4 mt-4 md:w-1/3 mb-3"
        alt="img"
        src="./images/posts/summer_camp.png"
      />

      The CREDO Summer Camp, will take place from 12-14 August in Załęcze
      Wielkie at the Nadwarciański Gród resort.<br /><br />
      During the camp we will focus on the CREDO theme, popularisation of science,
      cosmic radiation and astronomy. It will be an excellent opportunity to broaden
      knowledge, exchange experiences and make new friends among people with similar
      interests.<br /><br />
      We are planning a series of lectures, workshops, astronomical observations
      and other attractions that will certainly provide you with many unforgettable
      experiences. It will also be a time for joint reflection on the possibilities
      for further cooperation and development of the CREDO project.<br /><br />
      The organiser of the CREDO Summer Camp is the Visegrad grant implemented by
      the Institute of Nuclear Physics of the Polish Academy of Sciences. Translated
      with DeepL.com (free version). <br /><br />
      Read more:
      <a class="text-blue-900 hover:text-blue-500" href="#/summer_camp"
        >credo.science/summer_camp</a
      >
    </p>
  </div>
</section>
