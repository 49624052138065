<section class="text-gray-600 body-font">
  <div class="container px-5 py-14 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1
        class="text-4xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        <b> About the CREDO Project</b>
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          Scientists have dedicated their lives to trying to understand the
          Universe we live in but one mystery has remained; 95% of the Universe
          is invisible to us. We call 68% of the missing Universe Dark Energy
          and 27% Dark Matter. We know these things exist because of a variety
          of otherwise unexplained phenomena including the manner in which we
          see galaxies rotating but we have no idea what they are.
        </p>
        <br />
        <p class="mt-4">
          The Cosmic Ray Extremely Distributed Observatory (CREDO) Project is
          testing one of the many theories for what this dark matter could be –
          super massive particles born in the early Universe. If this theory is
          correct, we can't see the super massive particles themselves, but we
          should be able to detect what is left when these particles come to the
          end of their lives, or 'decay'. The very high energy photons predicted
          to result from this decay are unlike anything scientists have detected
          so far coming from outer space. This could be because they are very
          rare or, more likely, that they don't make it all the way across the
          Universe to the Earth without being affected by other particles.
        </p>
        <br />
        <p class="mt-4">
          From experiments with high energy particles carried out on the Earth
          we know that the interactions with other particles is likely to result
          in lots of lower energy photons being produced in a large 'cascade'.
          CREDO refers to these cascades as super-preshowers and they have the
          potential to be widely distributed across both space (an area larger
          than the Earth) and time (reaching different parts of the Earth at
          different times).
        </p>
        <div class="flex items-center flex-col m-auto p-10 text-center">
          <a href="./images/SPS-Timeline.png"
            ><img
              class="w-full mx-auto"
              src="./images/SPS-Timeline.png"
              alt="SPS-Timeline.png"
            /></a
          >
          <p class="mt-4">
            <em
              >Figure 1: The creation of particle cascades detectable on the
              Earth originating from massive particles in the early Universe.</em
            >
          </p>
        </div>

        <p class="mt-4">
          Existing observatories looking for particles from space are highly
          unlikely to recognise these super-preshower particles for what they
          are as they cover a tiny fraction of the Earth's surface. To identify
          these widely distributed showers we'd need a detector the size of the
          Earth which is clearly not possible! However, what we can do, with
          your help, is have lots and lots of little detectors spread out across
          the Earth's surface.
        </p>
      </div>
      <br />

      <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        CREDO Detector Smartphone Application
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p>
          If you have a smart phone with a camera you already have the hardware
          you need to help detect these particles so by downloading the
          <a
            href="//play.google.com/store/apps/details?id=science.credo.mobiledetector&amp;hl=en"
            ><span style="color: #000080;">CREDO Detector App here</span></a
          >, you can become part of the world-wide CREDO detector and join this
          quest for the unexpected.
        </p>
        <div class="flex items-center flex-col m-auto p-10 text-center">
          <iframe
            title="Messengers from space"
            src="//www.youtube.com/embed/6rHnW--PZQk"
            width="560"
            height="315"
            frameborder="0"
            allowfullscreen="allowfullscreen"
          />
          <p class="mt-4">
            <em
              >Video 1: Messengers from Space - How the CREDO project is using
              modern technology to engage the public with cutting edge science
              exploring our Universe.</em
            >
          </p>
        </div>

        <p class="mt-4">
          The app uses your smart phone camera (while your phone is charging and
          the camera is covered) to look for the bright pixels caused by the
          impact of a high energy particle on the CCD detector in the camera. A
          small 'thumbnail' centred on this detection along with the time and
          date it occurred and were your smartphone was is sent to the CREDO
          servers. To learn more about how to use the CREDO Detector App watch
          this quick tutorial...
        </p>
        <div class="flex items-center flex-col m-auto p-10 text-center">
          <iframe
            title="CREDO Detector App"
            src="https://www.youtube.com/embed/4riZZANp1X4"
            width="560"
            height="315"
            frameborder="0"
            allowfullscreen="allowfullscreen"
          />
          <p class="mt-4">
            <em
              >Video 2: How the CREDO Detector Smartphone Application is
              operated by you and utilises your smartphone camera.</em
            >
          </p>
        </div>
      </div>

      <!-- <h1
        class="text-3xl uppercase tracking-wider font-serif title-font mb-8 text-red-900 "
      >
        Private Particle Detective Citizen Science Experiment
      </h1>
      <div
        class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-8"
      >
        <p class="mt-4" style="text-align: justify;">
          Whether you have a smart phone or not there are still many ways you
          can get involved in CREDO. With a large number of smartphones
          detecting particle impacts its important to filter through these
          images to determine the type of particle detected and also to look out
          for any unexpected detections. Eventually, a lot of this analysis will
          be carried out by computers but we need to train the computers to do
          this saving a lot of time. However, they will never be able to
          highlight the unusual signals - they just don't ask the enquiring
          questions that humans do - so there will always be something valuable
          for CREDO supporters to do! You can therefore use the
          <span style="color: #000080;"
            ><a
              style="color: #000080;"
              href="//www.zooniverse.org/projects/credo/private-particle-detective"
              >Private Particle Detective Experiment here</a
            ></span
          >
          [under development] to start to follow your particle detections (if you
          also have the CREDO App) through the analysis process.
        </p>
        <div class="flex items-center flex-col m-auto p-10 text-center">
          <a href="./images/Method.png"
            ><img
              class="size-medium wp-image-3193 aligncenter"
              src="./images/Method.png"
              alt="Method.png"
            /></a
          >
          <p class="mt-4" style="text-align: justify;">
            <em
              >Figure 2: The flow of particle detections through the CREDO
              project and the role of the public and scientists in this
              collaboration.</em
            >
          </p>
        </div>

        <strong> Dark Universe Welcome Citizen Science Experiment </strong>

        <p class="mt-4" style="text-align: justify;">
          Having detected particle impacts with the CREDO App and filtered
          through those detections to group them into categories with Private
          Particle Detective we now need to determine if a given detection is
          part of a super pre-shower. So the Dark Universe Welcome experiment
          takes the confirmed and categorised detections from Private Particle
          Detective and asks CREDO supporters to look for groups of detections
          in space and/or time. Of course computers can also do some of this
          analysis but they will take much longer to learn how to do it than
          people - so we need your help to train them. Also, once again, only a
          human will accurately pick out and question any unusual groupings so
          CREDO supporters are essential to the project at this stage. You can
          therefore use the
          <span style="color: #000080;"
            ><a
              style="color: #000080;"
              href="//www.zooniverse.org/projects/credo/dark-universe-welcome"
              >Dark Universe Welcome Experiment here</a
            ></span
          >
          to see your particle detections (if you also have the CREDO App) in context
          with other detections and continue the analysis process.
        </p>
        <br />
        <strong> More Information</strong>

        <p class="mt-4" style="text-align: justify;">
          You can find the answers to some
          <span style="color: #000080;"
            ><a style="color: #000080;" href="#/education/faq"
              >Frequently Asked Questions here</a
            >.</span
          >
        </p>
      </div> -->
    </div>
  </div>
</section>
