<script>
  let images = [
    "./images/posts/italy2025/0.jpg",
    "./images/posts/italy2025/1.jpg",
    "./images/posts/italy2025/2.jpg",
    "./images/posts/italy2025/3.jpg",
    "./images/posts/italy2025/4.jpg",
  ];
  let imageClass =
    "w-full h-auto rounded-lg shadow-md transition-transform duration-300 hover:scale-105";
</script>

<section class="text-gray-600 body-font w-full mx-auto">
  <div
    class="container mx-auto flex px-5 md:flex-row flex-col items-center text-center"
  >
    <h1
      class="mt-6 w-full title-font sm:text-4xl text-3xl mb-4 font-serif text-center text-blue-800"
    >
      <b>CREDO Science Camp – Italy@Kraków 2025</b>
    </h1>
  </div>
  <div class="text-center lg:w-4/5 w-full mx-auto px-2">
    <div
      class="lg:w-4/5 mx-auto flex flex-wrap leading-relaxed text-xl text-justify font-serif mb-4"
    >
      <p>
        📅 March 31 – April 11, 2025<br />
        📍
        <a
          class="text-blue-900"
          href="https://maps.app.goo.gl/vnapTNEgywDUZewi8"
          target="_blank"
          rel="noreferrer"
          >Henryk Niewodniczański Institute of Nuclear Physics</a
        >, Polish Academy of Sciences, Kraków
        <br />
        <br />CREDO Science Camp – Italy@Kraków 2025 is a two-week educational
        program organized as part of PON PCTO, an Italian initiative that
        integrates theoretical education with practical professional experience.
        <br /><br />
        During the camp, participants will explore topics related to cosmic radiation,
        astronomy, and scientific data analysis. The program includes theoretical
        lectures and hands-on programming workshops using Python.
        <br />
        <br />
        The activities of CREDO Science Camp – Italy@Kraków are carried out by the
        Institute of Nuclear Physics of the Polish Academy of Sciences in Kraków,
        in collaboration with invited experts. The participating students come
        <a
          class="text-blue-900"
          href="https://cigna-baruffi-garelli.edu.it/"
          target="_blank"
          rel="noreferrer">Istituto Gianfrancesco Cigna in Mondovì</a
        >, and the program is supported by the Italian Ministry of Education,
        providing them with valuable skills as part of an international
        educational exchange.
        <br />
        <br />
        <a
          class="text-blue-900"
          href="#/italy2025/plan"
          target="_blank"
          rel="noreferrer"
          >See 2-week schedule
        </a>
      </p>
    </div>
    <div class="grid grid-cols-3 gap-4 p-4">
      {#each images as img (img)}
        <div>
          <!-- svelte-ignore a11y-img-redundant-alt -->
          <img class={imageClass} src={img} alt="Random Image" />
        </div>
      {/each}
    </div>
  </div>
</section>
