<script>
  import { onMount } from "svelte";

  export let inputType = "text";
  export let value = "";
  export let placeHolder = "";

  let inputElement;
  onMount(() => {
    inputElement.type = inputType;
    inputElement.placeholder = placeHolder;
  });
</script>

<input
  on:keyup
  on:change
  bind:value
  bind:this={inputElement}
  class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-yellow-500 focus:bg-transparent focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
/>
