<script>
  export let device_info;
  export let tailwindcss;
  //   import Link from "svelte-link";
</script>

<tr class={tailwindcss + " hover:bg-gray-900 hover:text-white"}>
  <!-- <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.id}
  </td> -->
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.device_id}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.all_traces}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.bad_margin}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.hot_pixels}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.artefact}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.too_bright}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.no_ml_detect}
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.no_ml_proc}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.hour_no_ml_detect}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.time_work}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.temperature_mean}
  </td>
  <!-- <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.temperature_median}
  </td> -->
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.hour_traces}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.device_model}
  </td>
  <td class="px-4 py-3 text-center border-collapse border border-b-gray-800">
    {device_info.system_version}
  </td>
</tr>
