<div class="container px-20 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
  Can we see dark matter in CREDO? <br/>(lecture as part of the Copernicus Festival)
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
      <img
        src="./images/posts/25052023.png"
        alt="images1"
        class=" px-1 w-full md:w-1/3"
      />
    We invite everyone to watch Piotr Homola's popular lecture on CREDO's most recent and so far greatest achievement:
    <br/><b>the observation of space-synthesis correlations.</b>
    <br/> The lecture is presented as part of the Copernicus Festival.
<br/>Lecture in Polish,
Link to video (accessed via Facebook): <a class="text-blue-700" href="https://fb.watch/ljL6siC75h/" target="_blank" rel="noreferrer">https://fb.watch/ljL6siC75h/</a>
  </div>
</div>
