<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Determination of Zenith Angle Dependence of Incoherent Cosmic Ray Muon Flux
    Using Smartphones of the CREDO Project
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/applsci.png" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000;"
      >Smartphones really see cosmic-ray muons! Of course we've expected so but
      now we've got a proof published in a peer-reviewed article - the first
      paper for the CREDO Collaboration that includes an analysis of our
      smartphone data. Big thank you and congratulations to the authors and to
      the users! We are now looking forward to hearing about more studies based
      on this pioneer work, hopefully closer and closer to some big questions of
      science... The access to the article is open, and please feel free to cite
      it wherever appropriate: Appl. Sci. 2021, 11(3), 1185;</span
    ><span style="color: #0000ff;">
      <a
        href="https://doi.org/10.3390/app11031185"
        target="_blank"
        rel="noopener noreferrer">https://doi.org/10.3390/app11031185</a
      >
    </span>
  </div>
</div>
