<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO & The International Particle Physics Outreach Group
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img
      src="https://user.credo.science/user-interface/credo.science/images/ppog.png"
      alt="credo.png"
      width="400"
    />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >CREDO at International Particle Physics Outreach Group (IPPOG) Meeting at
      CERN. This meeting has gathered representatives of 24 countries and
      several other persons involved in promotion of particle physics studied
      mostly in accelerator experiments, but also using cosmic rays. Krzysztof
      Wozniak has presented a talk “CREDO – citizens science project”. This was
      an excellent opportunity to spread the news and to invite join CREDO.<br
      />
      <span style="color: #0000ff;"
        ><a style="color: #0000ff;" href="https://ippog.org">IPPOG website.</a
        ></span
      ></span
    >
  </div>
</div>
