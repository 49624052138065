<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    Meet CREDO at Scientists Night in Krakow [20.09.2017]
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/29-540x350.jpg" alt="29-540x350.jpg" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <span style="color: #000000; text-align:center"
      >Meet CREDO to join a ground-breaking scientific research to discover the
      most intimate secrets of the Universe. Bring your smartphone to join and
      get active immediately.</span
    >
  </div>
</div>
