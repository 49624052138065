<div class="container px-5 py-6 mx-auto">
  <h1
    class="sm:text-4xl text-3xl text-center font-medium title-font mb-2 text-gray-900 uppercase"
  >
    CREDO in Warsaw on “Particle Astrophysics in Poland 2019”
  </h1>
  <div class="flex items-center flex-col m-auto p-1 text-center">
    <img src="./images/backgrounda2-570x350.jpg" alt="images" width="400" />
  </div>
  <div class="flex flex-col  w-full mb-2 py-2">
    <p style="text-align: justify;">
      W dniach <strong>19-21 września</strong> .b.r.
      <strong>w Instytucie Fizyki Jądrowej PAN w Krakowie</strong>
      <strong>odbędzie się konferencja</strong> <strong>projektu CREDO</strong>
      (credo.science). Będzie ona miała charakter warsztatów i przeznaczona będzie
      w całości dla nauczycieli i uczniów. Celem warsztatów będzie przedstawienie
      specjalnie przygotowanych dla nich prezentacji/wykładów
      <strong>na następujący temat: </strong><strong
        >przedstawienie zjawiska promieniowania kosmicznego, jego natury, źródeł
        i konsekwencji dla ludzi, sposobów rejestracji przez duże obserwatoria i
        przez indywidualnych ludzi a także omówienie projektu CREDO.</strong
      >
    </p>
  </div>
</div>
